import _ from 'lodash'
import { moment, DATE_FORMATS } from 'uikit'
import { notNull } from 'providers/helpers/null'
import { bankMap } from 'providers/helpers/bank'
import { shortUserMap } from 'providers/helpers/user'
import { pointMap } from 'providers/helpers/point'
import { currentStep } from 'providers/helpers/questionary'

export const questionaryShortMap = ({ questionary }) => ({
  id: questionary.id ?? questionary._id,
  creditRequestID: _.get(questionary, 'credit_request_id', null),
  pickupResultID: _.get(questionary, 'pickup_result_id', null),
  createdAt: _.get(questionary, 'created_at') ? moment(_.get(questionary, 'created_at'), DATE_FORMATS).toDate() : null,
  isFromRestore: _.get(questionary, 'from_estore', false),
  isClonable: _.get(questionary, 'clonable', false),
  currentState: _.get(questionary, 'current_state', '') ||
    _.get(questionary, 'state', ''),
  borrowerFullName: _.get(questionary, 'borrower_fullname', ''),
  questionaryType: _.get(questionary, 'questionary_type', ''),
  apiVersion: _.get(questionary, 'api_version', ''),
  isOnline: _.get(questionary, 'online', false),
  isPsd: _.get(questionary, 'ses', false),
  bank: notNull(questionary.bank, bank => bankMap({ bank })),
  owner: notNull(questionary.owner, owner => shortUserMap({ user: owner })),
  rejectedBy: notNull(questionary.rejected_by, rejectedBy => shortUserMap({ user: rejectedBy })),
  point: notNull(questionary.point, point => ({ ...pointMap({ point }), address: _.get(point, 'address') })),
  step: currentStep(questionary.step),
  canReject: _.get(questionary, 'can_reject', false),
  canCancel: _.get(questionary, 'can_cancel', false),
  operator: questionary?.operator ? {
    id: questionary?.operator?.id ?? questionary?.operator?._id,
    fullName: _.get(questionary?.operator, 'fullname', ''),
    email: _.get(questionary?.operator, 'email', ''),
    login: _.get(questionary?.operator, 'login', '')
  } : null
})
