import _ from 'lodash'
import { observable, computed, action, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class PartnerApplicationListModel extends BaseModel {
  @observable list = []
  @observable isLoadingList = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.list = []
    this.isLoadingList = false
  })

  @computed get isExist() {
    return !_.isEmpty(this.list)
  }

  getPartnerApplicationList = async () => {
    try {
      this.applyData({ isLoadingList: true })
      const list = await FactoryProvider.PartnerProvider.getPartnerApplicationList()
      this.applyData({ list })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }
  // downloadApplication = async id => {
  //   const res = await FactoryProvider.ActProvider.downloadApplication(id || this.details.actID)
  //   this.downloadFile(res)
  // }
  // downloadFile = (res) => {
  //   const blob = new Blob([res.data])
  //   const url = window.URL.createObjectURL(blob)
  //   const ref = document.createElement('a')
  //   ref.href = url
  //   const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
  //   ref.setAttribute('download', decodeURIComponent(escape(filename)) || 'file.pdf')
  //   ref.click()
  //   ref.remove()
  // }
}

export { PartnerApplicationListModel }
