import _ from 'lodash'
import { moment, DATE_FORMATS } from 'uikit'
import { notNull } from './null'

const macroRegionMap = ({ macroRegion, isShort = true }) => {
  let resolve = {
    id: macroRegion.id ?? macroRegion._id,
    name: _.get(macroRegion, 'name', '')
  }

  if (!isShort) {
    resolve = {
      ...resolve,
      regionCodes: _.get(macroRegion, 'region_codes', [])
    }
  }

  return resolve
}

const regionMap = ({ region, isShort = true }) => {
  let resolve = {
    id: region.id ?? region._id,
    name: _.get(region, 'name', ''),
    macroregion: notNull(region.macroregion, macroRegion => macroRegionMap({ macroRegion }))
  }

  if (!isShort) {
    resolve = {
      ...resolve,
      isActive: _.get(region, 'active', false),
      createdAt: region.created_at ? moment(region.created_at, DATE_FORMATS).toDate() : null
    }
  }

  return resolve
}

export { macroRegionMap, regionMap }
