import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import { cleanBankAccountMap } from 'providers/helpers/partner'
import deepMerge from 'deepmerge'
import { Confirm, notifyError, validRequired } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import { validBik, validRs } from 'utils/validators/bank'
import { v4 as uuid } from 'uuid'
import BaseModel from '../../baseModel'

class BankAccountModel extends BaseModel {
  @observable partnerId = null
  @observable pointId = null

  @observable bankAccounts = []
  @observable isLoadingBankAccounts = false

  @observable originalPoints = []
  @observable points = []
  @observable isLoadingPoints = false
  @observable currentBankAccount = null
  @observable isOpenSelectPoints = false
  @observable isProcessSavePoints = false

  @observable blockPointErrors = []
  @observable isOpenBlockErrors = false
  confirmRemoveAccount = null

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.partnerId = null
    this.pointId = null

    this.bankAccounts = []
    this.isLoadingBankAccounts = false

    this.points = []
    this.originalPoints = []
    this.isLoadingPoints = false
    this.currentBankAccount = null
    this.isOpenSelectPoints = false
    this.isProcessSavePoints = false

    this.blockPointErrors = []
    this.isOpenBlockErrors = false
    this.confirmRemoveAccount = null
  })

  @computed get isExist() {
    return !_.isEmpty(this.bankAccounts)
  }

  validBik = value => {
    const bik = `${value}`.replace(/_/ig, '')
    if (validRequired(bik)) return validRequired(bik)
    if (validBik(bik)) return validBik(bik)
    return null
  }

  validRs = (value, bik) => {
    const rs = `${value}`.replace(/_/ig, '')
    if (validRequired(rs)) return validRequired(rs)
    if (validRs(rs, bik)) return validRs(rs, bik)
    return null
  }

  isValidBankAccount = account => !this.validBik(account.bik) && !this.validRs(account.rs, account.bik)

  @computed get isValidBankAccounts() {
    return this.isExist && _.reduce(
      this.bankAccounts,
      (s, r) => s + (!this.isValidBankAccount(r) ? 1 : 0),
      0
    ) === 0
  }

  isDisableBankAccount = (account, notExistStates = []) => {
    const existStates = ['isProcessLock', 'isProcessSave']
    notExistStates.forEach(state => {
      const index = _.findIndex(existStates, s => s === state)
      if (index === -1) return
      existStates.splice(index, 1)
    })

    return _.reduce(existStates, (s, r) => s + (account[r] ? 1 : 0), 0) > 0
  }

  @computed get isValidBankAccountPoints() {
    return !_.isEmpty(this.currentBankAccount?.points) &&
      _.reduce(this.currentBankAccount?.points, (s, point) => s + (point.isSelect && !point.isDisable ? 1 : 0), 0) > 0
  }

  @computed get preparePoints() {
    return _.flow(
      l => _.filter(l, point => !_.find(this.currentBankAccount?.points, p => p.id === point.id)),
      l => _.map(l, point => ({
        value: point.id,
        label: point.name
      }))
    )(this.points)
  }

  @computed get currentAccountPoints() {
    return _.filter(this.currentBankAccount?.points, point => !point.isDisable)
  }

  @computed get isAccessFillPoints() {
    return !_.isEmpty(this.originalPoints)
  }

  @computed get isNotAccessFillPoints() {
    return _.isEmpty(this.originalPoints) && !this.isLoadingBankAccounts && !this.isLoadingPoints
  }

  isAccessAssignSinglePoint = account => !_.find(account?.points, point => point.id === this.pointId)

  @computed get isExistAccountsAssignedCurrentPoint() {
    return !!this.pointId && this.isExist &&
      _.reduce(this.bankAccounts, (s, account) => s + (!this.isAccessAssignSinglePoint(account) ? 1 : 0), 0) > 0
  }

  applyAccounts = action(data => {
    if (data?.bankAccounts) this.bankAccounts = data?.bankAccounts
  })

  checkBankAccount = (cb, index, id) => {
    if ((!id && this.bankAccounts[index] && !this.bankAccounts[index].id) ||
      (this.bankAccounts[index] && this.bankAccounts[index].id === id)) cb()
  }

  addBankAccount = action(() => {
    this.bankAccounts.unshift({ bik: '', rs: '', tempId: uuid() })
  })

  requestRemoveBankAccount = (index, account) => {
    this.confirmRemoveAccount = Confirm({
      title: 'Вы действительно хотите удалить счёт?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk: async () => {
        await this.removeBankAccount(index, account)
      }
    })
  }

  removeBankAccount = async (index, account) => {
    const id = account.id
    if (!id) {
      this.action(() => this.bankAccounts.splice(index, 1))
      return
    }

    try {
      this.action(() => this.bankAccounts[index].isProcessRemove = true)
      await FactoryProvider.PartnerProvider.removePartnerBankAccount(this.partnerId, account.id)

      this.checkBankAccount(() => this.action(() => this.bankAccounts.splice(index, 1)), index, id)
    } catch (e) {
      if (e?.response?.status === 422) {
        this.applyData({
          blockPointErrors: e?.response?.data?.errors?.exist_on_points,
          isOpenBlockErrors: true
        })
        if (this.confirmRemoveAccount) this.confirmRemoveAccount.destroy()
      } else {
        notifyError('Ошибка удаления счёта', prepareErrorMessage(e))
        throw e
      }
      throw e
    } finally {
      this.checkBankAccount(() => this.action(() => this.bankAccounts[index].isProcessRemove = false), index, id)
    }
  }

  setBankAccount = action((index, field, value) => {
    if (this.bankAccounts[index][field] === value) return

    if (!this.bankAccounts[index].isChange) {
      this.bankAccounts[index].prev = deepMerge({}, this.bankAccounts[index])
      this.bankAccounts[index].isChange = true
    }

    this.bankAccounts[index][field] = value
  })

  cancelEdit = action(index => {
    this.bankAccounts[index] = deepMerge({}, this.bankAccounts[index].prev)
  })

  applyParams = (params = {}) => {
    const { partnerId } = params
    if (partnerId) this.clear()

    if (partnerId) this.applyData({ partnerId })

    if (partnerId) {
      this.getBankAccounts()
      this.searchPoints()
    }
  }

  getBankAccounts = async () => {
    try {
      this.applyData({ isLoadingBankAccounts: true })
      const bankAccounts = await FactoryProvider.PartnerProvider.getPartnerBankAccounts(this.partnerId)
      this.applyData({ bankAccounts })
    } catch (e) {
      notifyError('Ошибка получения банковских счетов', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingBankAccounts: false })
    }
  }

  createPartnerBankAccount = async (index, account) => {
    try {
      this.action(() => this.bankAccounts[index].isProcessSave = true)
      const newAccount = await FactoryProvider.PartnerProvider.createPartnerBankAccount(this.partnerId, account)

      this.checkBankAccount(() => this.action(() => this.bankAccounts[index] = newAccount), index)
    } catch (e) {
      notifyError('Ошибка создания счёта', prepareErrorMessage(e))
      throw e
    } finally {
      this.checkBankAccount(() => this.action(() => {
        this.bankAccounts[index].isProcessSave = false
        this.bankAccounts[index].isChange = false
      }), index)
    }
  }

  updatePartnerBankAccount = async account => {
    const id = account.id

    const index = _.findIndex(this.bankAccounts, a => a.id === id)
    try {
      this.action(() => this.bankAccounts[index].isProcessSave = true)
      const newAccount = await FactoryProvider.PartnerProvider.updatePartnerBankAccount(this.partnerId, id, account)

      this.checkBankAccount(() => this.action(() => this.bankAccounts[index] = newAccount), index, id)
    } catch (e) {
      notifyError('Ошибка обновления счёта', prepareErrorMessage(e))
      throw e
    } finally {
      this.checkBankAccount(() => this.action(() => {
        this.bankAccounts[index].isProcessSave = false
        this.bankAccounts[index].isChange = false
      }), index, id)
    }
  }

  lockPartnerBankAccount = async account => {
    const id = account.id
    const isLock = account.isActive

    const index = _.findIndex(this.bankAccounts, a => a.id === id)
    try {
      this.action(() => this.bankAccounts[index].isProcessLock = true)
      await FactoryProvider.PartnerProvider.lockPartnerBankAccount(this.partnerId, id, isLock)

      this.checkBankAccount(() => this.action(() => this.bankAccounts[index].isActive = !isLock), index, id)
    } catch (e) {
      if (e?.response?.status === 422) {
        this.applyData({
          blockPointErrors: e?.response?.data?.errors?.exist_on_points,
          isOpenBlockErrors: true
        })
      } else {
        notifyError(`Ошибка ${isLock ? 'блокировки' : 'разблокировки'} счёта`, prepareErrorMessage(e))
        throw e
      }
    } finally {
      this.checkBankAccount(() => this.action(() => this.bankAccounts[index].isProcessLock = false), index, id)
    }
  }

  searchPoints = async (params = {}) => {
    const { isOriginal = true, search = '' } = params

    try {
      this.applyData({ isLoadingPoints: true })

      const points = await FactoryProvider.PointProvider.searchPoint({ search, partnerId: this.partnerId, pageSize: 40 })
      this.applyData({ [isOriginal ? 'originalPoints' : 'points']: points })
    } finally {
      this.applyData({ isLoadingPoints: false })
    }
  }

  selectBankAccount = action(account => {
    this.currentBankAccount = cleanBankAccountMap(account)
    this.points = deepMerge([], this.originalPoints)
    this.isLoadingPoints = false
    this.isOpenSelectPoints = true
  })

  closeSelectPoints = action((account) => {
    const index = _.findIndex(this.bankAccounts, a => a.id === account.id)
    if (index === -1) return

    this.bankAccounts[index] = account
    this.isLoadingPoints = false
    this.isOpenSelectPoints = false
  })

  setPoint = action(id => {
    const index = _.findIndex(this.currentBankAccount?.points, p => p.id === id)

    if (index === -1) {
      const point = _.find(this.points, p => p.id === id)
      if (!point) return

      this.currentBankAccount.points.push({
        ...point,
        isSelect: false,
        isDisable: false
      })
    } else {
      this.currentBankAccount.points[index].isSelect = !this.currentBankAccount.points[index].isSelect
    }
  })

  savePoints = async () => {
    try {
      this.applyData({ isProcessSavePoints: true })
      const account = await FactoryProvider.PartnerProvider.assignPointsBankAccount(
        this.partnerId,
        this.currentBankAccount.id,
        _.filter(this.currentBankAccount.points, p => p.isSelect && !p.isDisable)
      )
      this.closeSelectPoints(account)
    } catch (e) {
      notifyError('Ошибка применения точек к счёту', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSavePoints: false })
    }
  }
}

export { BankAccountModel }
