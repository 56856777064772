import _ from 'lodash'
import fp from 'lodash/fp'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import BaseHttpProvider from '../api/baseHttpProvider'
import { dadataAddressMap, dadataFmsMap, dadataFioMap, dadataOrganizationMap, dadataBanknMap } from '../helpers/dadata'

class DadataProvider extends BaseHttpProvider {
  token() {
    return FactoryModel.AuthModel.CommonModel.token
  }

  host() {
    return FactoryProvider.ConfigProvider.config.dadataUri || '/dadata-gate'
  }

  async getAddress(search) {
    const data = await this.execute({
      uri: '/address',
      data: {
        query: search
      },
      headers: {
        Authorization: `Bearer ${this.token()}`
      },
      method: 'POST'
    }, this.host())
    return fp.map(r => dadataAddressMap(r))(_.get(data, 'suggestions', []))
  }

  async getFMS(search) {
    const data = await this.execute({
      uri: '/fms_unit',
      data: {
        query: search
      },
      headers: {
        Authorization: `Bearer ${this.token()}`
      },
      method: 'POST'
    }, this.host())
    return _.uniqBy(fp.map(dadataFmsMap)(_.get(data, 'suggestions', [])), v => v.value)
  }

  async getFio(search) {
    const data = await this.execute({
      uri: '/fio',
      data: {
        query: search
      },
      headers: {
        Authorization: `Bearer ${this.token()}`
      },
      method: 'POST'
    }, this.host())
    return _.uniqBy(fp.map(dadataFioMap)(_.get(data, 'suggestions', [])), v => v.value)
  }

  async getOrganization(search) {
    const data = await this.execute({
      uri: '/party',
      data: {
        query: search
      },
      headers: {
        Authorization: `Bearer ${this.token()}`
      },
      method: 'POST'
    }, this.host())
    return _.uniqBy(fp.map(dadataOrganizationMap)(_.get(data, 'suggestions', [])), v => v.inn)
  }

  async getBank(search) {
    const data = await this.execute({
      uri: '/bank',
      data: {
        query: search
      },
      headers: {
        Authorization: `Bearer ${this.token()}`
      },
      method: 'POST'
    }, this.host())
    return _.map(_.get(data, 'suggestions', []), r => dadataBanknMap(r))
  }
}

export { DadataProvider }
