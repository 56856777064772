import { observable, computed, action, makeObservable } from 'mobx'
import { validRequired } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class IdeaModel extends BaseModel {
  @observable message = ''
  @observable isProcessSendIdea = false
  @observable isFailedSendIdea = false
  @observable isSuccessSendIdea = false

  @observable result = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.message = ''
    this.isFailedSendIdea = false
    this.isProcessSendIdea = false
  })

  @computed get validMessage() {
    if (this.message.trim() === '') return 'Введите сообщение'
    return null
  }

  sendMessage = async () => {
    try {
      this.applyData({ isProcessSendIdea: true, isFailedSendIdea: false, isSuccessSendIdea: false })
      const result = await FactoryProvider.IdeaProvider.sendIdea(this.message)
      this.applyData({ result })
    } catch (e) {
      this.applyData({ isFailedSendIdea: true, isSuccessSendIdea: false })
    } finally {
      this.applyData({ isProcessSendIdea: false })
    }
    if (this.result === true) {
      this.applyData({ isSuccessSendIdea: true })
      this.clear()
    }
  }
}

export { IdeaModel }
