import _ from 'lodash'
import { shortPointMap } from './point'

export const SOLAR_STAFF_TYPE = 'Solar Staff'
export const REG_NULL = 'reg_null'
export const VERIFIED_STATUS = 'verified'
export const NOT_VERIFIED_STATUS = 'not_verified'

export const AgentRewardMap = ({ agentReward }) => {
  const resolve = {
    dateFrom: _.get(agentReward, 'date_from', ''),
    period: _.get(agentReward, 'period', ''),
    value: _.get(agentReward, 'value', 0.0),
    agent: _.get(agentReward, 'agent', ''),
    creditsCount: _.get(agentReward, 'credits_count', 0),
    refundsCount: _.get(agentReward, 'refunds_count', 0)
  }
  return resolve
}

export const AgentRewardDetailedMap = ({ AgentRewardDetailed }) => ({
  period: _.get(AgentRewardDetailed, 'period', ''),
  value: _.get(AgentRewardDetailed, 'value', 0.0),
  isClosed: _.get(AgentRewardDetailed, 'closed', false),
  details: _.map(AgentRewardDetailed.details, agentReward => ({
    date: _.get(agentReward, 'date', ''),
    questionaryId: _.get(agentReward, 'questionary_id', ''),
    point: agentReward.point ? shortPointMap({ point: agentReward.point }) : null,
    bank: _.get(agentReward, 'bank', ''),
    creditTax: _.get(agentReward, 'credit_tax', 0.0),
    creditAmount: _.get(agentReward, 'credit_amount', 0.0),
    bankReward: _.get(agentReward, 'bank_reward', 0.0),
    insuranceReward: _.get(agentReward, 'insurance_reward', 0.0),
    moblawReward: _.get(agentReward, 'moblaw_reward', 0.0),
    telemedReward: _.get(agentReward, 'telemed_reward', 0.0),
    smsReward: _.get(agentReward, 'sms_reward', 0.0),
    totalReward: _.get(agentReward, 'total_reward', 0.0),
    additionalServices: _.map(_.get(agentReward, 'additional_services', []), service => ({
      groupName: _.get(service, 'group_name', ''),
      price: _.get(service, 'price', 0.0),
      isCanceled: _.get(service, 'cancelled', false)
    })),
    additionalServicesAmount: _.get(agentReward, 'additional_services_amount', 0.0)
  }))
})
