import _ from 'lodash'
import { observable, computed, action, makeObservable } from 'mobx'
import { notifyError, notifySuccess } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { prepareErrorMessage } from 'utils/error'
import { percentageError, separatedPercentageError } from 'utils/validators/percentage'
import BaseModel from '../baseModel'
import { BankPieceData } from './bankPieceData'

class BankMixModel extends BaseModel {
  partnerID = null
  pointID = null

  @observable pointsPartnerID = null

  @observable applyToPartner = false
  @observable insuranceDiscount = false
  @observable insuranceInitialPayment = true
  @observable initialPaymentVolatility = 20
  @observable macroregionID = null
  @observable bankPieces = []

  @observable isLoading = false
  @observable isSaving = false
  @observable isShowForceValidate = false

  @observable isNoBankMixID = false
  @observable havePartnerBankMixID = false
  @observable isBlocked = false

  @observable newBankID = null
  @observable isLoadingDelete = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.partnerID = null
    this.pointID = null
    this.pointsPartnerID = null
    this.isLoadingBankMix = false
    this.applyToPartner = false
    this.insuranceDiscount = false
    this.insuranceInitialPayment = true
    this.initialPaymentVolatility = 20
    this.macroregionID = null
    this.bankPieces = []

    this.isLoading = false
    this.isSaving = false
    this.isShowForceValidate = false
    this.isNoBankMixID = false
    this.havePartnerBankMixID = false
    this.isBlocked = false

    this.newBankID = null
    this.isLoadingDelete = false
  })

  clearPartnersBankMix = action(() => {
    this.applyToPartner = false
    this.insuranceDiscount = false
    this.insuranceInitialPayment = true
    this.initialPaymentVolatility = 20
    this.bankPieces = []

    this.isNoBankMixID = true
    this.havePartnerBankMixID = false
    this.isBlocked = false
  })

  @computed get isValid() {
    return !this.percentageError && !this.bankPiecesError
  }

  @computed get newBankIDError() {
    if (!this.newBankID) return null
    if (_.find(this.bankPieces, (bp) => bp.bankID === this.newBankID)) return 'Уже существует'
    return null
  }

  @computed get percentageError() {
    return separatedPercentageError(_.map(this.bankPieces, (bankPiece) => bankPiece.percentage))
  }

  @computed get bankPiecesError() {
    if (_.find(this.bankPieces, (bankPiece) => !bankPiece.isValid)) return 'Невалидные настройки банков'
    return null
  }

  @computed get initialPaymentVolatilityPercentageError() {
    return percentageError(this.initialPaymentVolatility)
  }

  @computed get availablePercentage() {
    return 100 - _.sumBy(this.bankPieces, (bankPiece) => bankPiece.percentage)
  }
  @computed get isPartnerBankMix() {
    if (this.partnerID) return false
    return !FactoryModel.PointModel.bankMixID && !!FactoryModel.PointModel.partnerBankMix
  }

  applyBankMix = action((bankMix) => {
    const bankPieces = _.map(bankMix.bankPieces, (bankPieceData) => {
      const bankPiece = new BankPieceData()
      bankPiece.applyBankPiece(bankPieceData)
      return bankPiece
    })
    this.applyData({ ...bankMix, bankPieces })
  })

  addBankPiece = action(() => {
    const bankPiece = new BankPieceData()
    const bank = _.find(FactoryModel.BankModel.banks, (b) => b.id === this.newBankID)
    bankPiece.applyData({ bankID: this.newBankID, bank })
    this.applyData({ bankPieces: [bankPiece].concat(this.bankPieces), newBankID: null })
    bankPiece.getBankProducts()
  })

  removeBankPiece = action((bankPieceData) => {
    if (this.bankPieces.filter(bp => bp.bankID === bankPieceData.bankID).length > 1 && !!bankPieceData.bankDBID) { // for mass operations
      const bankPieces = this.bankPieces.filter(bp => bp.bankDBID !== bankPieceData.bankDBID)
      this.applyData({ bankPieces })
      return
    }
    const bankPieces = this.bankPieces.filter(bp => bp.bankID !== bankPieceData.bankID)
    this.applyData({ bankPieces })
  })

  applyInitialData = action(async ({ partnerID, pointID }) => {
    // todo double request
    if (pointID && !FactoryModel.PointModel.bankMixID) await FactoryModel.PointModel.getPoint(pointID)
    const bankMixID = partnerID ? FactoryModel.PartnerModel.bankMixID : FactoryModel.PointModel.bankMixID
    const initial = { partnerID, pointID }
    if (!bankMixID) initial.isNoBankMixID = true
    if (this.isPartnerBankMix) {
      initial.isNoBankMixID = true
      initial.havePartnerBankMixID = true
      initial.pointsPartnerID = FactoryModel.PointModel.partnerOrganization
      const bankMix = await FactoryProvider.PartnerProvider.getBankMix(initial.pointsPartnerID)
      this.applyBankMix({ ...bankMix })
      initial.isBlocked = true
    }
    this.applyData(initial)
  })

  bankMixFields = () => [
    'macroregionID',
    'applyToPartner',
    'insuranceDiscount',
    'insuranceInitialPayment',
    'initialPaymentVolatility',
    'partnerOrganization'
  ]

  bankMix = () => {
    const resolve = {}
    resolve.bankPieces = _.map(this.bankPieces, (bankPiece) => bankPiece.bankPiece())
    _.each(this.bankMixFields(), field => {
      resolve[field] = this[field]
    })
    return resolve
  }

  getBankMix = async () => {
    if (this.isNoBankMixID) return
    try {
      this.applyData({ isLoading: true })
      const provider = this.partnerID ? FactoryProvider.PartnerProvider : FactoryProvider.PointProvider
      const ownerEntityID = this.partnerID || this.pointID
      const bankMix = await provider.getBankMix(ownerEntityID)
      this.applyBankMix({ ...bankMix })
    } catch (e) {
      notifyError('Ошибка получения информации о плане продаж', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  save = async () => {
    if (!this.isValid) {
      this.applyData({ isShowForceValidate: true })
      return
    }
    try {
      this.applyData({ isSaving: true })
      const provider = this.partnerID ? FactoryProvider.PartnerProvider : FactoryProvider.PointProvider
      const ownerEntityID = this.partnerID ? this.partnerID : this.pointID
      if (this.isNoBankMixID) {
        await provider.createBankMix(ownerEntityID, this.bankMix())
        this.applyData({ isNoBankMixID: false })
      } else {
        await provider.updateBankMix(ownerEntityID, this.bankMix())
      }
      notifySuccess(`План продаж организации успешно ${this.isNoBankMixID ? 'создан' : 'обновлен'}`)
    } catch (e) {
      notifyError('Ошибка сохранения плана продаж', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSaving: false })
    }
  }

  deleteBankMix = async () => {
    try {
      const provider = this.partnerID ? FactoryProvider.PartnerProvider : FactoryProvider.PointProvider
      const ownerEntityID = this.partnerID ? this.partnerID : this.pointID
      this.applyData({ isLoadingDelete: true })
      await provider.deleteBankMix(ownerEntityID)
      notifySuccess('План продаж организации успешно удален')
      this.applyData({
        applyToPartner: false,
        insuranceDiscount: false,
        insuranceInitialPayment: true,
        initialPaymentVolatility: 20,
        bankPieces: [],
        isShowForceValidate: false,
        newBankID: null
      })
      const model = this.partnerID ? FactoryModel.PartnerModel : FactoryModel.PointModel
      model.applyData({ bankMixID: '' })
      await this.applyInitialData({
        partnerID: this.partnerID,
        pointID: this.pointID
      })
    } catch (e) {
      notifyError('Ошибка удаления плана продаж', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingDelete: false })
    }
  }

  createBasedOnPartner = action(() => {
    this.isBlocked = false
  })
}

export { BankMixModel }
