import _ from 'lodash'

export const actMap = ({ act, existFields }) => {
  const resolve = {
    id: _.get(act, '_id', '') || _.get(act, 'id', ''),
    number: _.get(act, 'number', 'number_human'),
    date: new Date(_.get(act, 'created_at', '')),
    type: _.get(act, 'receiver_type', ''),
    receiver: _.get(act, 'receiver', ''),
    author: _.get(act, 'author', ''),
    bank: _.get(act, 'bank', ''),
    beginPeriod: new Date(_.get(act, 'from_date', '')),
    endPeriod: new Date(_.get(act, 'to_date', '')),
    count: _.get(act, 'credits_count', 0),
    status: _.get(act, 'current_state_human', ''),
    state: _.get(act, 'current_state', ''),
    scan: _.get(act, 'scan', null)
  }
  _.forEach(existFields, field => {
    _.unset(resolve, field)
  })
  return resolve
}
