import _ from 'lodash'
import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'
import { macroRegionMap, regionMap } from '../helpers/region'

class RegionProvider extends BaseApiProvider {
  async searchRegions(params = {}) {
    const { search = '' } = params
    const data = await this.fetch({
      uri: '/regions',
      query: safeObject({
        query: search !== '' ? search : null
      })
    })
    return _.map(data, region => regionMap({ region }))
  }

  async searchMacroRegions(isShort = true) {
    const data = await this.fetch({
      uri: '/macroregions'
    })
    return _.map(data, macroRegion => macroRegionMap({ macroRegion, isShort }))
  }
}

export { RegionProvider }
