import { v4 as uuid } from 'uuid'
import { scanMap } from 'providers/helpers/poscans'
import { moment, DATE_FORMATS, safeObject } from 'uikit'
import _ from 'lodash'

export const contentMap = ({ data }) => ({
  tempId: uuid(),
  slug: data?.slug,
  isTop: !!data?.always_on_top,
  title: data?.title ?? '',
  preview: data?.preview ?? '',
  content: data?.body ?? '',
  category: data?.category ?? '',
  packageId: data?.attachments_package?.package_id ?? '',
  scans: _.map(data?.attachments_package?.scans, scan => scanMap({ scan })) ?? [],
  publishedAt: data?.published_at ? moment(data?.published_at, DATE_FORMATS) : null,
  publishedBy: data?.published_by?.fullname ?? null,
  updatedAt: data?.updated_at ? moment(data?.updated_at, DATE_FORMATS) : null,
  updatedBy: data?.updated_by?.fullname ?? null,
  isNew: !data?.viewed,
  viewsCount: data?.views_count ? Number(data?.views_count) : 0
})

export const contentMapApi = (data) => ({
  text: safeObject({
    preview: data.preview,
    body: data.content,
    slug: data.slug,
    title: data.title,
    category: data.category,
    always_on_top: data.isTop
  })
})
