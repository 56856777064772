import _ from 'lodash'
import { safeObject } from 'uikit'
import { retailMap } from '../helpers/retail'
import BaseApiProvider from './baseApiProvider'

class RetailProvider extends BaseApiProvider {
  async searchRetails(params = {}) {
    const { search = '' } = params
    const data = await this.fetch({
      uri: '/retails',
      query: safeObject({
        query: search !== '' ? search : null
      })
    })
    return _.map(data, retail => retailMap({ retail }))
  }
}

export { RetailProvider }
