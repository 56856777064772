import { observable, action, makeObservable } from 'mobx'
import { getHistoryQuery, pathname, push } from 'utils/history'
import { notifyError, notifySuccess, isSafe, safeObject } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'
import FactoryModel from '../factoryModel'

class UserListModel extends BaseModel {
  @observable pointID = ''

  @observable list = []
  @observable page = 1
  pageSize = 20
  @observable isLoadingList = false
  @observable queryText = ''
  @observable isActive = true

  defaultUrlParams = {
    page: 1,
    queryText: '',
    isActive: true
  }

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.pointID = ''

    this.list = []
    this.page = this.defaultUrlParams.page
    this.pageSize = 20
    this.isLoadingList = false
    this.queryText = this.defaultUrlParams.queryText
    this.isActive = this.defaultUrlParams.isActive
  })

  isCurrentPathName = () => pathname() === '/users'

  applyParamsToHistory = () => {
    push({
      uri: '/users',
      query: safeObject({
        page: this.page !== this.defaultUrlParams.page ? this.page : null,
        queryText: this.queryText !== this.defaultUrlParams.queryText ? this.queryText : null,
        isActive: this.isActive !== this.defaultUrlParams.isActive ? this.isActive : null
      })
    })
  }

  applyParamsFromHistory = () => {
    const { page = this.defaultUrlParams.page, queryText = this.defaultUrlParams.queryText, isActive } = getHistoryQuery()
    const resolvePage = Number(page) > 1 ? Number(page) : this.defaultUrlParams.page
    const resolveIsActive = isSafe(isActive) ? isActive === 'true' : true

    this.applyData({
      page: Number(resolvePage),
      queryText,
      isActive: resolveIsActive
    })

    FactoryModel.MenuModel.setMenuItem('users')
    this.getList(false)
  }

  applyPoint = action(pointID => {
    this.pointID = pointID
    this.pageSize = 10
    this.getList()
  })

  getList = async (isReload = true) => {
    try {
      this.applyData({
        isLoadingList: true,
        page: isReload ? 1 : this.page
      })
      const list = await FactoryProvider.UserProvider.getUserList(safeObject({
        page: this.page,
        pageSize: this.pageSize,
        queryText: this.queryText,
        pointID: this.pointID || null,
        isActive: this.isActive
      }))

      if (!this.isCurrentPathName() && !this.pointID) return
      if (!this.pointID) this.applyParamsToHistory()
      this.applyData({ list })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  setPage = page => {
    this.applyData({ page })
    this.getList(false)
  }

  setQueryText = text => {
    this.applyData({ queryText: isSafe(text) ? text : '' })
  }

  lockUser = async (id, reason = '') => {
    const userIndex = _.findIndex(this.list, l => l.id === id)
    if (userIndex === -1) return
    try {
      const isActive = this.list[userIndex].isActive
      this.action(() => {
        this.list[userIndex].isProcessLock = true
        this.list = deepMerge([], this.list)
      })
      await FactoryProvider.UserProvider.lockUser(id, isActive, isActive ? reason : '')
      notifySuccess(`Пользователь успешно ${isActive ? 'заблокирован' : 'разблокирован'}`)
      this.action(() => {
        this.list[userIndex].isActive = !isActive
        this.list[userIndex].isProcessLock = false
        this.list[userIndex].lockReason = reason
        this.list = deepMerge([], this.list)
      })
    } catch (e) {
      notifyError(`Ошибка ${this.isActive ? 'блокировки' : 'разблокировки'} пользователя`, prepareErrorMessage(e))
      this.action(() => {
        this.list[userIndex].isProcessLock = false
        this.list = deepMerge([], this.list)
      })
      throw e
    }
  }

  setIsActive = action(isActive => {
    this.isActive = isActive
    this.getList()
  })

  switchUser = async (id) => {
    const { token, target, isPointSelectionRequired } = await FactoryProvider.UserProvider.switchUser(id)
    await FactoryModel.AuthModel.CommonModel.switchUser({ token, target, isPointSelectionRequired })
  }
}

export { UserListModel }
