import BaseModel from '../baseModel'
import { PartnerModel } from './partnerModel'
import { RegistrationModel } from './registrationModel'
import { ResetPasswordModel } from './resetPasswordModel'
import { CommonModel } from './commonModel'

class AuthModel extends BaseModel {
  ctx = null

  constructor() {
    super()

    this.CommonModel = new CommonModel()
    this.PartnerModel = new PartnerModel()
    this.RegistrationModel = new RegistrationModel()
    this.ResetPasswordModel = new ResetPasswordModel()

    this.ctx = {
      CommonModel: this.CommonModel,
      PartnerModel: this.PartnerModel,
      RegistrationModel: this.RegistrationModel,
      ResetPasswordModel: this.ResetPasswordModel
    }

    this.CommonModel.ctx = this.ctx
    this.PartnerModel.ctx = this.ctx
    this.RegistrationModel.ctx = this.ctx
    this.ResetPasswordModel.ctx = this.ctx
  }
}

export { AuthModel }
