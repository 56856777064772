const CryptoJS = require('crypto-js')
const cookie = require('cookie')
const internalConfig = require('../../config/internalConfig')

const encrypt = value => CryptoJS.AES.encrypt(value, internalConfig.secret).toString()

const decrypt = value => {
  const bytes = CryptoJS.AES.decrypt(value, internalConfig.secret)
  return bytes.toString(CryptoJS.enc.Utf8)
}

const C_ID = 'f2e'
const TOKEN_FIELD = 'token'
const TEMP_TOKEN_FIELD = 'tempToken'
const EXPIRES = 'expires'
const CURRENT_URI = 'currentUri'
const TARGET = 'target'
const IS_POINT_SELECTION_REQUIRED = 'isPointSelectionRequired'

const parseCId = req => {
  if (!req) return {}

  try {
    if (req) req.cookies = cookie.parse(req.headers.cookie || '')
    const cId = req.cookies[C_ID]
    let current = {}
    if (cId) {
      current = JSON.parse(decrypt(cId))
    }
    return current
  } catch (e) {
    return { }
  }
}

const encodeCId = (current = {}) => encrypt(JSON.stringify(current))

const setCookies = ({ current = {}, req, res }) => {
  if (!req || !res) return

  res.cookie(C_ID, encodeCId(current), {
    path: '/',
    httpOnly: true,
    secure: /^https/i.test(req.protocol)
  })
}

module.exports = {
  encrypt, decrypt, C_ID, TOKEN_FIELD, TEMP_TOKEN_FIELD, EXPIRES, CURRENT_URI, parseCId, encodeCId, setCookies, TARGET, IS_POINT_SELECTION_REQUIRED
}
