import _ from 'lodash'
import { observable, computed, action, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { push, getHistoryQuery, pathname } from 'utils/history'
import { safeObject, isSafe, validRequired, notifyError } from 'uikit'
import FactoryModel from 'models/factoryModel'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../baseModel'

class QuestionaryListModel extends BaseModel {
  @observable list = []
  @observable page = 1
  @observable isLoadingList = false
  @observable status = 'all'
  @observable queryType = 'borrower_fullname'
  @observable queryText = ''
  @observable unauthorizedCount = 0
  @observable isOnlyUnauthorized = false
  @observable isLoadingUnauthorizedCount = false

  @observable preview = null
  @observable isLoadingPreview = true

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isExistList() {
    return !_.isEmpty(this.list)
  }

  defaultUrlParams = {
    page: 1,
    status: 'all',
    queryText: '',
    queryType: 'borrower_fullname',
    isOnlyUnauthorized: false
  }

  clear = action(() => {
    this.list = []
    this.page = this.defaultUrlParams.page
    this.isLoadingList = false
    this.status = this.defaultUrlParams.status
    this.queryType = this.defaultUrlParams.queryType
    this.queryText = this.defaultUrlParams.queryText
    this.unauthorizedCount = 0
    this.isOnlyUnauthorized = this.defaultUrlParams.isOnlyUnauthorized
    this.isLoadingUnauthorizedCount = false
  })

  applyParamsToHistory = () => {
    push({
      uri: '/questionaries',
      query: safeObject({
        page: this.page !== this.defaultUrlParams.page ? this.page : null,
        status: this.status !== this.defaultUrlParams.status ? this.status : null,
        queryType: this.queryType !== this.defaultUrlParams.queryType && !validRequired(this.queryText) ? this.queryType : null,
        queryText: this.queryText !== this.defaultUrlParams.queryText ? this.queryText : null,
        isOnlyUnauthorized: this.isOnlyUnauthorized !== this.defaultUrlParams.isOnlyUnauthorized ? this.isOnlyUnauthorized : null
      })
    })
  }

  applyParamsFromHistory = () => {
    if (!this.isExistList) {
      const {
        page = this.defaultUrlParams.page,
        status = this.defaultUrlParams.status,
        queryText = this.defaultUrlParams.queryText,
        queryType = this.defaultUrlParams.queryType,
        isOnlyUnauthorized
      } = getHistoryQuery()
      const resolvePage = Number(page) > 1 ? Number(page) : this.defaultUrlParams.page

      this.applyData({
        page: resolvePage,
        status,
        queryType,
        queryText,
        isOnlyUnauthorized: isSafe(isOnlyUnauthorized) ? isOnlyUnauthorized === 'true' : false
      })
    }

    FactoryModel.MenuModel.setMenuItem('questionaries')
    if (!this.isExistList) this.getList(false)
  }

  applyParamsFromHistoryProcess = () => {
    const query = getHistoryQuery()
    const isOnlyUnauthorized = query.isOnlyUnauthorized === 'true'

    if (isSafe(query.isOnlyUnauthorized) && isOnlyUnauthorized !== this.isOnlyUnauthorized) {
      this.applyData({
        isOnlyUnauthorized
      })
      this.getList(false, false)
    }
  }

  getList = async (isReload = true, isApplyHistory = true) => {
    try {
      this.applyData({
        isLoadingList: true,
        page: isReload ? 1 : this.page
      })

      if (!this.isOnlyUnauthorized) this.getUnauthorizationCount()
      const list = await FactoryProvider.QuestionaryProvider.getQuestionaryList({
        status: this.status !== 'all' ? this.status : null,
        queryType: this.queryType,
        queryText: this.queryText,
        page: this.page,
        pageSize: 20,
        isOnlyUnauthorized: this.isOnlyUnauthorized
      })

      if (pathname() !== '/questionaries') return
      if (isApplyHistory) this.applyParamsToHistory()
      this.applyData(safeObject({
        list
      }))
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  getUnauthorizationCount = async () => {
    try {
      this.applyData({ isLoadingUnauthorizedCount: true })
      const unauthorizedCount = await FactoryProvider.QuestionaryProvider.getUnauthorizationCount()
      this.applyData({ unauthorizedCount })
    } finally {
      this.applyData({ isLoadingUnauthorizedCount: false })
    }
  }

  setIsOnlyUnauthorized = (isOnlyUnauthorized = true) => {
    if (isOnlyUnauthorized !== this.isOnlyUnauthorized) {
      this.applyData({ isOnlyUnauthorized })
      this.applyParamsToHistory()
      this.getList()
    }
  }

  setPage = page => {
    this.applyData({ page })
    this.getList(false)
  }

  setStatus = status => {
    if (status !== this.status) {
      this.applyData({ status: status || 'all' })
      this.getList()
    }
  }

  setQueryType = type => {
    this.applyData({ queryType: type })
  }

  setQueryText = text => {
    this.applyData({ queryText: text || null })
  }

  duplicateQuestionary = async id => {
    try {
      this.applyData({ isLoadingList: true })
      const newID = await FactoryProvider.QuestionaryProvider.duplicateQuestionary(id)
      push({ uri: '/questionaries/[action]', href: `/questionaries/${newID}` })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  assignQuestionary = async id => {
    try {
      this.applyData({ isLoadingList: true })
      await FactoryProvider.QuestionaryProvider.assignQuestionary(id)
      push({ uri: '/questionaries/[action]', href: `/questionaries/${id}` })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  getPreview = async (id) => {
    try {
      this.applyData({ isLoadingPreview: true })
      const preview = await FactoryProvider.QuestionaryProvider.getPreview(id)
      this.applyData({
        preview
      })
    } catch (e) {
      notifyError('Ошибка получения анкеты', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingPreview: false })
    }
  }

  clearPreview = () => {
    try {
      this.applyData({
        preview: null
      })
    } catch (e) {
      notifyError('Ошибка очистки анкеты', prepareErrorMessage(e))
      throw e
    }
  }

  getPreviewBundle = async () => {
    const packageID = this.preview?.packageId
    if (!packageID) return null
    try {
      const bundles = await FactoryProvider.PoscansProvider.getBundle({ packageID })
      const preview = {
        ...this.preview,
        bundles
      }
      this.applyData({
        preview
      })
    } catch (e) {
      notifyError('Ошибка получения сканов', prepareErrorMessage(e))
      throw e
    }
  }
}

export { QuestionaryListModel }
