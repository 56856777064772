module.exports = {
  secret: process.env.COOKIE_SECRET || 'finservice_god_key',
  port: process.env.PORT || 3434,
  isProd: process.env.NODE_ENV === 'production',
  showRocketChat: true,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ORG: process.env.SENTRY_ORG,
  SENTRY_PROJECT: process.env.SENTRY_PROJECT,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  SENTRY_URL: process.env.SENTRY_URL
}
