import { colors, viewPortsResolve } from 'uikit'

const breakPoints = {
  mobile: 768,
  tablet: 1200,
  desktop: 1400,
  largeDesktop: 1600
}

const viewPorts = viewPortsResolve(breakPoints)

const theme = {
  colors,
  breakPoints,
  viewPorts
}

const GlobalStyles = `
  html, body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    ${viewPorts.largeDesktop} {
        min-width: 1600px;
    }
    width: 100%;
    height: 100%;
    color: ${colors.text};
    font-family: 'Plumb', sans-serif;
    -webkit-touch-callout: none !important;
  }

  * {
    box-sizing: border-box;
  }

  #__next {
    width: 100%;
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover, &:visited, &:active, &:focus {
    color: inherit;
    text-decoration: none;
  }
`

export { breakPoints, colors, viewPorts, theme, GlobalStyles }
