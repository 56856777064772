import fp from 'lodash/fp'
import { bankMap } from 'providers/helpers/bank'
import { safeObject } from 'uikit'
import _ from 'lodash'
import { actMap } from 'providers/helpers/act'
import BaseApiProvider from './baseApiProvider'

class ActProvider extends BaseApiProvider {
  async getBanks() {
    const data = await this.fetch({ uri: '/banks', query: { active: true } })
    return fp.map(bank => bankMap({ bank }))(data)
  }

  async getPoints(searchText = '') {
    const data = await this.fetch({
      uri: '/my_points',
      query: _.omitBy({
        query: searchText || null,
        per_page: 40
      }, _.isNull)
    })
    return fp.map(point => ({
      id: point?.id ?? point._id,
      name: _.get(point, 'name', '')
    }))(data)
  }

  async getUsers(searchText = '') {
    const data = await this.fetch({
      uri: '/users',
      query: _.omitBy({
        query: searchText || null,
        active: true
      }, _.isNull)
    })
    return fp.map(user => ({
      id: user?._id ?? user.id,
      name: user?.fullname ?? user.login
    }))(data)
  }

  async getRegistriesCredits({ periodBegin, periodEnd, bank, point }) {
    const data = await this.fetch({
      uri: '/registries/credits',
      query: {
        from_date: periodBegin,
        to_date: periodEnd,
        bank_id: bank,
        point_ids: point
      }
    })
    return {
      credits: _.map(_.get(data, 'credits', []), (credit, index) => ({
        id: _.get(credit, 'id', ''),
        num: index + 1,
        number: _.get(credit, 'contract_number', ''),
        fullName: _.get(credit, 'borrower_fullname', ''),
        payments: _.get(credit, 'amount', 0),
        date: new Date(_.get(credit, 'created_at', '')),
        questionaryDate: new Date(_.get(credit, 'questionary_date', '')),
        pointName: _.get(credit, 'point', '')
      })),
      countConstrains: _.get(data, 'credits_count', 0),
      maxConstrains: _.get(data, 'max_credits_count', 0)
    }
  }

  async createRegistriesAct({
    periodBegin, periodEnd, bank, workerType, worker, creditIDS, pointIDS, isForce = false
  }) {
    const data = await this.fetch({
      uri: '/registries',
      method: 'POST',
      data: _.omitBy({
        registry: {
          from_date: periodBegin,
          to_date: periodEnd,
          bank_id: bank,
          receiver_type: workerType,
          receiver: worker,
          credit_ids: creditIDS,
          point_ids: pointIDS
        },
        ignore_credit_intersections: isForce || null
      }, _.isNull)
    })
    const actID = _.get(data, 'registry.id', null)
    const intersectingActID = _.get(data, 'intersecting.id', null)
    return { actID, intersectingActID }
  }

  async getActs({ searchNumber, filterType, receiverType, page = 1, pageSize = 10 }) {
    const data = await this.fetch({
      uri: '/registries',
      query: safeObject({
        query_value: searchNumber.trim() !== '' ? searchNumber.trim() : null,
        query_types: filterType,
        receiver_type: receiverType,
        page,
        per_page: pageSize
      })
    })
    return {
      list: fp.map(act => actMap({ act }))(_.get(data, 'registries', [])),
      pageSize: _.get(data, 'per_page', 10),
      total: _.get(data, 'total', 0)
    }
  }

  async addScan(actID, file) {
    const formData = new FormData()
    formData.append('scan', file, file.name)

    const data = await this.fetch({
      uri: `/registries/${actID}/scan`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    })
    return actMap({ act: _.get(data, 'registry', null) })
  }

  async removeAct(actID) {
    await this.fetch({
      uri: `/registries/${actID}`,
      method: 'DELETE'
    })
  }

  async removeActScan(actID) {
    const data = await this.fetch({
      uri: `/registries/${actID}/scan`,
      method: 'DELETE'
    })
    return actMap({ act: _.get(data, 'registry', null) })
  }

  async downloadScan(actID) {
    return await this.fetch({
      uri: `/registries/${actID}/scan`,
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf'
      },
      responseType: 'blob',
      isFullResponse: true
    })
  }
  async downloadAct(actID) {
    return await this.fetch({
      uri: `/registries/${actID}/print`,
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf'
      },
      responseType: 'blob',
      isFullResponse: true
    })
  }
}

export { ActProvider }
