export const validateSnils = snils => {
  if (typeof snils === 'number') {
    snils = snils.toString()
  } else if (typeof snils !== 'string') {
    snils = ''
  }
  if (!snils.length) {
    return 'СНИЛС пуст'
  } if (/\D/.test(snils)) {
    return 'Только цифры'
  } if (snils.length !== 11) {
    return 'Только 11 цифр'
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(snils[i]) * (9 - i)
  }
  let checkDigit = 0
  if (sum < 100) {
    checkDigit = sum
  } else if (sum > 101) {
    checkDigit = parseInt(sum % 101)
    if (checkDigit === 100) {
      checkDigit = 0
    }
  }
  if (checkDigit === parseInt(snils.slice(-2))) {
    return null
  }

  return 'Неверная контрольная сумма'
}
