import BaseApiProvider from './baseApiProvider'

class SettingsProvider extends BaseApiProvider {
  async getSettings() {
    return await this.fetch({
      uri: '/settings'
    })
  }
  async getTempSettings(token) {
    return await this.execute({
      uri: '/settings',
      withToken: token
    })
  }
}

export { SettingsProvider }
