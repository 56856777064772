import _ from 'lodash'
import FactoryModel from 'models/factoryModel'
import BaseHttpProvider from './baseHttpProvider'

class TokenError extends Error {
  name = 'EmptyToken'
  constructor({ message }) {
    super()
    this.name = message
    this.message = message
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenError)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

class BaseApiProvider extends BaseHttpProvider {
  baseHost = ''

  async fetch({ ...params }, host) {
    try {
      if (!FactoryModel.AuthModel.CommonModel.isExistToken) throw new TokenError({ message: 'EmptyToken' })
      if (FactoryModel.AuthModel.CommonModel.isExpires) throw new TokenError({ message: 'ExpiresToken' })
      return await super.execute(params, host)
    } catch (e) {
      if (e instanceof TokenError || _.get(e, 'response.status', null) === 401) {
        await FactoryModel.AuthModel.CommonModel.prepareCurrentUri()
        await FactoryModel.AuthModel.CommonModel.removeAllTokens()

        throw e
      }
      throw e
    }
  }
}

export default BaseApiProvider
