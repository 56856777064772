import _ from 'lodash'
import BaseApiProvider from './baseApiProvider'

class AccreditationProvider extends BaseApiProvider {
  async getData() {
    const data = await this.fetch({
      uri: '/accreditation'
    })
    return {
      point: data.point,
      user: data.user
    }
  }
}

export { AccreditationProvider }
