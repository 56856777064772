import PickupChannel from './channels/PickupChannel'
import CreditRequestsChannel from './channels/CreditRequestsChannel'
import QuestionaryV2Channel from './channels/QuestionaryV2Channel'
import UserChannel from './channels/UserChannel'
import UpdatesChannel from './channels/UpdatesChannel'

export default {
  PickupChannel,
  QuestionaryV2Channel,
  CreditRequestsChannel,
  UserChannel,
  UpdatesChannel
}
