import { moment, parseDate, isEqualDates } from 'uikit'

const FIRST_ISSUE_AGE = 14
const MIDDLE_ISSUE_AGE = 20
const LAST_ISSUE_AGE = 45

const getAge = birthDate => moment().diff(parseDate(birthDate), 'years')

const getPlusYears = (birthDate, plus) => parseDate(birthDate).add(plus, 'years')

const ERROR_MESSAGE = 'Паспорт недействительный'

export const passportDateValidator = (passportDate, birthDate) => {
  const age = getAge(birthDate)
  const date = parseDate(passportDate)

  const firstAgeDate = getPlusYears(birthDate, FIRST_ISSUE_AGE)
  const middleAgeDate = getPlusYears(birthDate, MIDDLE_ISSUE_AGE)
  const lastAgeDate = getPlusYears(birthDate, LAST_ISSUE_AGE)

  if (age < 14) return ERROR_MESSAGE

  if (age >= FIRST_ISSUE_AGE && age < MIDDLE_ISSUE_AGE) {
    return !(isEqualDates(date, firstAgeDate) || date.isBetween(firstAgeDate, middleAgeDate)) ?
      ERROR_MESSAGE : null
  }

  if (age == MIDDLE_ISSUE_AGE && moment().diff(middleAgeDate, 'days') < 90 && date.isBetween(firstAgeDate, middleAgeDate)) {
    return null
  }

  if (age >= MIDDLE_ISSUE_AGE && age < LAST_ISSUE_AGE) {
    return !(isEqualDates(date, middleAgeDate) || date.isBetween(middleAgeDate, lastAgeDate)) ?
      ERROR_MESSAGE : null
  }

  if (age == LAST_ISSUE_AGE && moment().diff(lastAgeDate, 'days') < 90 && date.isBetween(middleAgeDate, lastAgeDate)) {
    return null
  }

  if (age >= LAST_ISSUE_AGE) {
    return !(isEqualDates(date, lastAgeDate) || date.isAfter(lastAgeDate)) ?
      ERROR_MESSAGE : null
  }

  return null
}
