import _ from 'lodash'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import deepMerge from 'deepmerge'
import { v4 as uuid } from 'uuid'
import { validRequired, isSafe, notifyError } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import FactoryModel from 'models/factoryModel'
import { ORDERS_STEP } from 'providers/helpers/questionary'
import BaseModel from '../../baseModel'

const defaultFields = {
  orders: { orderName: '', producer: '', cost: '', category: null, count: 1 }
}
export const defaultOrderId = uuid()

class OrderModel extends BaseModel {
  ctx = null

  @observable orders = {
    [defaultOrderId]: deepMerge({ id: defaultOrderId, index: 0 }, defaultFields.orders)
  }

  @observable originalCategoryList = []
  @observable isLoadingOriginalCategoryList = false
  @observable categoryList = {}
  @observable isLoadingCategoryList = {}

  @observable isForceValidate = false
  @observable isLoading = false
  @observable isProcessSave = false
  @observable isReminderOpen = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.orders = {
      [defaultOrderId]: deepMerge({ id: defaultOrderId, index: 0 }, defaultFields.orders)
    }

    this.originalCategoryList = []
    this.isLoadingOriginalCategoryList = false
    this.categoryList = {}
    this.isLoadingCategoryList = {}

    this.isForceValidate = false
    this.isLoading = false
    this.isProcessSave = false
    this.isReminderOpen = false
  })

  @computed get isExist() {
    return !_.isEmpty(this.orders)
  }

  validOrderName = name => {
    if (validRequired(name)) return validRequired(name)
    return null
  }

  validOrderProducer = producer => {
    if (validRequired(producer)) return validRequired(producer)
    return null
  }

  validOrderCategory = category => {
    if (validRequired(category)) return validRequired(category)
    return null
  }

  validOrderCost = cost => {
    if (validRequired(cost)) return 'Не указана'
    if (Number(cost) <= 0) return 'Меньше 0'
    return null
  }

  validOrderCount = count => {
    if (validRequired(count)) return 'Не указана'
    if (Number(count) <= 0) return 'Меньше 0'
    return null
  }

  validOrders = orders => _.reduce(orders, (s, r) => s + (
    !this.validOrderName(r.orderName) && !this.validOrderProducer(r.producer) &&
      !this.validOrderCategory(r.category) && !this.validOrderCount(r.count) && !this.validOrderCost(r.cost) ?
      0 : 1
  ), 0) === 0

  @computed get isNext() {
    if (_.isEmpty(this.orders)) return false
    return this.validOrders(this.prepareOrders(this.orders))
  }

  @computed get ordersTotal() {
    return _.reduce(this.orders, (s, r) => s + Number(r.cost) * r.count, 0)
  }

  afterChange = () => {
    FactoryModel.PickupModel.clearOffers()
  }

  getCategories = async (params = {}) => {
    const { search = '', isOriginal = true, orderId } = params

    if (isOriginal && !_.isEmpty(this.originalCategoryList)) return

    try {
      if (isOriginal) this.applyData({ isLoadingOriginalCategoryList: true })
      else {
        this.action(() => {
          this.isLoadingCategoryList[orderId] = true
        })
      }

      const categoryList = await FactoryProvider.PickupProvider.getCategories({ search })
      if (isOriginal) this.applyData({ originalCategoryList: categoryList })
      else {
        this.action(() => {
          this.categoryList[orderId] = categoryList
        })
      }
    } finally {
      if (isOriginal) this.applyData({ isLoadingOriginalCategoryList: false })
      else {
        this.action(() => {
          this.isLoadingCategoryList[orderId] = false
        })
      }
    }
  }

  addOrder = action(() => {
    const orderId = uuid()
    let index = 0
    _.each(this.orders, order => {
      if (order.index >= index) {
        index += 1
      }
    })
    this.orders[orderId] = deepMerge({ id: orderId, index }, defaultFields.orders)
    this.afterChange()
  })

  removeOrder = action(orderId => {
    _.unset(this.orders, orderId)
    _.unset(this.categoryList, orderId)
    _.unset(this.isLoadingCategoryList, orderId)

    const keys = []
    _.each(this.orders, (o, key) => {
      keys.push(key)
    })

    let index = 0
    _.each(keys, key => {
      const newId = uuid()
      const order = deepMerge({}, this.orders[key])
      order.id = newId
      order.index = index
      this.orders[newId] = order
      _.unset(this.orders, key)
      index += 1
    })
    this.afterChange()
  })

  setOrder = action((field, value, orderId) => {
    this.orders[orderId][field] = value
    this.afterChange()
  })

  transformCount = action((orderId, type) => {
    if (!isSafe(this.orders[orderId].count)) {
      this.orders[orderId].count = 1
      return
    }
    switch (type) {
      case 'subtraction':
        if (Number(this.orders[orderId].count) <= 1) break
        this.orders[orderId].count = Number(this.orders[orderId].count) - 1
        break
      case 'add': {
        const prepare = Number(this.orders[orderId].count) + 1
        this.orders[orderId].count = prepare > 99 ? 99 : prepare
        break
      }
    }
    this.afterChange()
  })

  applyOrders = action(data => {
    if (_.isEmpty(data.orders)) return

    const resolve = {}
    _.each(data?.orders, (order, index) => {
      const orderId = uuid()
      resolve[orderId] = {
        ...order,
        id: orderId,
        index,
        category: order.category?.id
      }
      this.categoryList[orderId] = [{
        ...order.category
      }]
    })
    this.orders = resolve
  })

  setIsReminderOpen = (v) => {
    this.applyData({ isReminderOpen: v })
  }

  next = async () => {
    if (this.ctx.CommonModel.isMetaStep()) return

    if (!this.isNext) this.applyData({ isForceValidate: true })
    else {
      await this.patchQuestionary()
    }
  }

  patchQuestionary = async () => {
    try {
      this.applyData({ isProcessSave: true })
      const data = await FactoryProvider.QuestionaryProvider.updateQuestionary(this.ctx.CommonModel.id, { orders: this.prepareOrders() }, ORDERS_STEP)

      this.ctx.CommonModel.applyDataAfterUpdate(data)
      FactoryModel.PickupModel.applyOwnerChangeAccess({ questionaryAccess: data.options.isOwnerChange })

      if (!FactoryModel.PickupModel.pointId) {
        let pickupData
        if (this.ctx?.CommonModel?.creditRequest) {
          const { point, owner, creditParams } = this.ctx?.CommonModel?.creditRequest || {}
          const { term, initialPayment } = creditParams
          if (term) FactoryModel.PickupModel.setField('term')(Number(term))
          if (initialPayment) FactoryModel.PickupModel.setField('initialPayment')(Number(initialPayment))
          pickupData = {
            point,
            user: owner
          }
        } else {
          pickupData = {
            point: FactoryModel.SettingsModel.CommonModel.point,
            user: {
              id: FactoryModel.SettingsModel.ProfileModel.id,
              fullName: FactoryModel.SettingsModel.ProfileModel.fullName
            }
          }
        }

        FactoryModel.PickupModel.applyPrepareData(pickupData)
      }

      if (!this.ctx.AdditionalModel.isStartFilling) {
        await this.ctx.CommonModel.fillDataFromClient()
      }
    } catch (e) {
      notifyError('Ошибка обновления товаров', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSave: false })
      this.setIsReminderOpen(false)
    }
  }

  prepareOrders = source => {
    const orders = []
    _.each(source || this.orders, order => {
      orders.push(toJS(order))
    })
    return orders
  }

  silentNext = async () => {
    try {
      const { step, ...data } = await FactoryProvider.QuestionaryProvider.updateQuestionary(this.ctx.CommonModel.id, { orders: this.prepareOrders() }, ORDERS_STEP)

      FactoryModel.PickupModel.applyOwnerChangeAccess({ questionaryAccess: data.options.isOwnerChange })
      this.ctx.CallCenterModel.applyCallCenter({ options: data.options })

      if (!FactoryModel.PickupModel.pointId) {
        FactoryModel.PickupModel.applyPrepareData({
          point: FactoryModel.SettingsModel.CommonModel.point,
          user: {
            id: FactoryModel.SettingsModel.ProfileModel.id,
            fullName: FactoryModel.SettingsModel.ProfileModel.fullName
          }
        })
      }
    } catch (e) {
      notifyError('Ошибка обновления товаров', prepareErrorMessage(e))
      throw e
    }
  }

  back = () => {
    this.ctx.CommonModel.setStep(0)
  }
}

export { OrderModel }
