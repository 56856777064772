import _ from 'lodash'
import { observable, action, computed, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { APPLICATION_POSITIVE_DECISION_STATE } from 'providers/helpers/questionary'
import BaseModel from '../../baseModel'

class CreditModel extends BaseModel {
  ctx = null

  @observable results = []
  @observable isExpand = true
  @observable isInit = false
  subId = null

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isExist() {
    return !_.isEmpty(this.results)
  }

  @computed get positiveCount() {
    return _.reduce(this.results, (s, r) => s + (r.currentState === APPLICATION_POSITIVE_DECISION_STATE ? 1 : 0), 0)
  }

  clear = action(() => {
    this.results = []
    this.isExpand = true
    this.isInit = false
    this.clearSubscription()
  })

  clearSubscription = () => {
    if (this.subId) FactoryProvider.ActionCableProvider.removeSubscription(this.subId)
    this.subId = null
  }

  expand = action(() => {
    this.isExpand = !this.isExpand
    this.isInit = true
  })

  initApplications = async () => {
    if (this.isExist) return
    this.subId = FactoryProvider.ActionCableProvider.addSubscription({
      channel: 'QuestionaryV2Channel',
      options: {
        questionary_id: this.ctx.CommonModel.id
      }
    })
    const results = await FactoryProvider.QuestionaryProvider.getApplications(this.ctx.CommonModel.id)
    this.applyData({ results })
  }

  applyApplications = apps => {
    if (!this.ctx.CommonModel.id) return

    this.applyData({
      results: apps
    })
  }
}

export { CreditModel }
