import _ from 'lodash'
import { observable, computed, action, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { push, getHistoryQuery, pathname } from 'utils/history'
import { notifyError, safeObject } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import FactoryModel from 'models/factoryModel'
import { QUESTIONARY_STATE_CANCELED } from 'providers/helpers/questionary'
import BaseModel from '../baseModel'

class CreditRequestListModel extends BaseModel {
  ACTUAL_SCOPE = 'actual'

  defaultRoute = '/credit_requests'
  defaultScope = 'actual'
  scopes = {
    actual: 'Новые и назначенные',
    my: 'Мои заявки',
    all: 'Все'
  }

  defaultUrlParams = {
    page: 1,
    queryText: '',
    scope: this.defaultScope
  }

  subId = null

  @observable list = []
  @observable page = 1
  @observable queryText = ''
  @observable isLoadingList = false
  @observable scope = this.defaultScope
  @observable numberOfNew = 0

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isExistList() {
    return !_.isEmpty(this.list)
  }

  clear = action(() => {
    this.list = []
    this.page = 1
    this.queryText = this.defaultUrlParams.queryText
    this.isLoadingList = false
    this.scope = this.defaultScope
    this.numberOfNew = 0
  })

  clearList = action(() => {
    this.list = []
    this.page = this.defaultUrlParams.page
    this.isLoadingList = false
    this.scope = this.defaultScope
    this.total = 0
    this.numberOfNew = 0

    if (this.subId) FactoryProvider.ActionCableProvider.removeSubscription(this.subId)
  })

  applyParamsToHistory = () => {
    push({
      uri: this.defaultRoute,
      query: safeObject({
        page: this.page !== this.defaultUrlParams.page ? this.page : null,
        queryText: this.queryText !== this.defaultUrlParams.queryText ? this.queryText : null,
        scope: this.scope !== this.defaultUrlParams.scope ? this.scope : null
      })
    })
  }

  applyParamsFromHistory = () => {
    const {
      page = this.defaultUrlParams.page,
      scope = this.defaultUrlParams.scope,
      queryText = this.defaultUrlParams.queryText
    } = getHistoryQuery()
    const resolvePage = Number(page) > 1 ? Number(page) : this.defaultUrlParams.page
    const resolveScope = !FactoryModel.SettingsModel.CommonModel.isFullCallCenter ? this.ACTUAL_SCOPE : scope

    this.applyData({
      page: resolvePage,
      queryText,
      scope: resolveScope
    })

    FactoryModel.MenuModel.setMenuItem(this.defaultRoute.replace('/', ''))
    this.getList(false)

    this.subId = FactoryProvider.ActionCableProvider.addSubscription({ channel: 'CreditRequestsChannel' })
  }

  setQueryText = text => {
    this.applyData({ queryText: text || null })
  }

  getList = async (isReload = true) => {
    try {
      this.applyData({
        isLoadingList: true,
        page: isReload ? 1 : this.page
      })
      const { list, total } = await FactoryProvider.CreditRequestProvider.getCreditRequestList({
        scope: this.scope,
        page: this.page,
        pageSize: 20,
        queryText: this.queryText
      })

      if (pathname() !== this.defaultRoute) return
      this.applyParamsToHistory()
      this.applyData({ list, total, numberOfNew: 0 })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  getCreditRequestDetails = async (id) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const creditRequest = await FactoryProvider.CreditRequestProvider.getCreditRequestDetails({
        id
      })

      this.increaseNumberOfNew()
      if (this.page !== 1) {
        this.applyData({
          total: this.total + 1
        })
        return
      }
      const list = [{ ...creditRequest, isNew: true }, ...this.list].slice(0, -1)
      this.applyData({
        total: this.total + 1,
        list
      })
    } catch (e) {
      throw e
    }
  }

  setPage = page => {
    this.applyData({ page })
    this.getList(false)
  }

  setScope = scope => {
    if (!this.scopes[scope] || scope === this.scope) return

    this.applyData({ scope })
    this.getList()
  }

  setNumberOfNew = number => {
    this.applyData({ numberOfNew: number })
  }

  increaseNumberOfNew = () => {
    this.applyData({ numberOfNew: this.numberOfNew + 1 })
  }

  sendAction = async (sentAction, id, comment) => {
    try {
      const creditRequest = await FactoryProvider.CreditRequestProvider.sendAction(safeObject({ action: sentAction, id, comment: comment || null }))
      const index = _.findIndex(this.list, c => c.id === id)
      this.action(() => {
        this.list[index] = {
          ...creditRequest,
          questionaries: !_.isEmpty(creditRequest?.questionaries) ? creditRequest?.questionaries :
            this.list[index].questionaries
        }
      })
    } catch (e) {
      notifyError('Ошибка выполнения', prepareErrorMessage(e))
      throw e
    }
  }

  assign = async (id) => {
    await this.sendAction('assign', id)
  }

  duplicateQuestionary = async (creditRequestId, questionaryId) => {
    try {
      this.applyData({ isLoadingList: true })
      const newID = await FactoryProvider.QuestionaryProvider.duplicateQuestionary(questionaryId, creditRequestId)
      push({ uri: '/questionaries/[action]', href: `/questionaries/${newID}` })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  cancelQuestionary = async (id, creditRequestId) => {
    try {
      await FactoryProvider.QuestionaryProvider.cancelQuestionary(id)

      const index = _.findIndex(this.list, c => c.id === creditRequestId)
      const questionaryIndex = _.findIndex(this.list[index].questionaries, q => q.id === id)
      const questionary = this.list[index].questionaries[questionaryIndex]

      this.action(() => {
        this.list[index].questionaries[questionaryIndex] = {
          ...questionary,
          currentState: QUESTIONARY_STATE_CANCELED,
          canCancel: false
        }
      })
    } catch (e) {
      notifyError('Ошибка отмены заявки', prepareErrorMessage(e))
      throw e
    }
  }
}

export { CreditRequestListModel }
