import { observable, computed, action, makeObservable } from 'mobx'
import zxcvbn from 'zxcvbn'
import _ from 'lodash'
import { passwordGenerator } from 'utils/passwordGenerator'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { push } from 'utils/history'
import deepMerge from 'deepmerge'
import {
  notifyError,
  notifySuccess,
  scrollToTop,
  validRequired,
  validEmail,
  isSafe,
  CyrillicNameWithSpace, moment, DATE_FORMAT
} from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../baseModel'

class UserModel extends BaseModel {
  @observable id = ''

  @observable isActive = true
  @observable isProcessLock = false
  @observable lockReason = ''

  @observable login = ''
  @observable lastName = ''
  @observable firstName = ''
  @observable patronymic = ''
  @observable password = ''
  @observable selfPassword = true
  @observable manager = null
  @observable accessRole = null
  @observable workerType = 'external'
  @observable status = 'working'
  @observable hireType = null
  @observable callCenters = []
  @observable qrSpec = ''
  @observable macroRegions = []
  @observable organizations = []
  @observable points = []
  @observable shortQuestionaryPoints = []
  @observable phone = ''
  @observable email = ''
  @observable showAgentCommission = null

  @observable isShowForceValidate = false

  @observable managersList = []
  @observable isLoadingManagersList = false
  @observable accessRolesList = []
  @observable isLoadingAccessRolesList = false
  @observable macroRegionsList = []
  @observable callCentersList = []
  @observable partnersList = []
  @observable pointsList = []
  @observable shortPointsList = []

  @observable isLoadingUser = false
  @observable isProcessSave = false

  @observable codes = []
  @observable isShowForceValidateCodes = false
  @observable isLoadingCodes = false
  @observable isSavingCodes = false
  @observable bankCode = null

  @observable historyEdit = []
  @observable isLoadingHistoryEdit = false

  @observable agentCommissionGroups = []
  @observable newACGroupID = null
  @observable isSavingACGroups = false
  @observable isShowForceValidateACGroups = false

  @observable isPasswordActivationAvailable = true
  @observable isSendInvite = false
  @observable isEmailConfirmed = false
  @observable isSendConfirmation = false
  @observable isMobilePhoneConfirmed = false

  @observable comment = ''

  dataFromPoint = {}

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.id = ''

    this.isActive = true
    this.isProcessLock = false
    this.lockReason = ''

    this.login = ''
    this.lastName = ''
    this.firstName = ''
    this.patronymic = ''
    this.password = ''
    this.selfPassword = true
    this.manager = null
    this.accessRole = null
    this.workerType = 'external'
    this.status = 'working'
    this.hireType = null
    this.callCenters = []
    this.qrSpec = ''
    this.macroRegions = []
    this.organizations = []
    this.points = []
    this.shortQuestionaryPoints = []
    this.phone = ''
    this.email = ''
    this.showAgentCommission = null
    this.isShowForceValidate = false

    this.managersList = []
    this.accessRolesList = []
    this.macroRegionsList = []
    this.callCentersList = []
    this.partnersList = []
    this.pointsList = []
    this.shortPointsList = []

    this.isLoadingUser = false
    this.isProcessSave = false

    this.isPasswordActivationAvailable = true
    this.isSendInvite = false
    this.isEmailConfirmed = false
    this.isSendConfirmation = false
    this.isMobilePhoneConfirmed = false

    this.codes = []
    this.isShowForceValidateCodes = false
    this.isLoadingCodes = false
    this.isSavingCodes = false
    this.bankCode = null

    this.historyEdit = []
    this.isLoadingHistoryEdit = false

    this.agentCommissionGroups = []
    this.newACGroupID = null
    this.isSavingACGroups = false
    this.isShowForceValidateACGroups = false
    this.comment = ''
  })

  clearDataFromPoint = action(() => {
    this.dataFromPoint = {}
  })

  applyDataFromPoint = action(() => {
    if (this.dataFromPoint?.point) {
      this.points.push({ key: this.dataFromPoint.point.id, label: this.dataFromPoint.point.name })
      this.workerType = 'external'
      this.status = 'working'
    }
  })

  @computed get validLockReason() {
    return validRequired(this.lockReason)
  }

  @computed get validLogin() {
    if (validRequired(this.login)) return validRequired(this.login)
    return null
  }

  @computed get validLastName() {
    if (validRequired(this.lastName)) validRequired(this.lastName)
    if (!CyrillicNameWithSpace.test(this.lastName)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validFirstName() {
    if (validRequired(this.firstName)) validRequired(this.firstName)
    if (!CyrillicNameWithSpace.test(this.firstName)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validPatronymic() {
    if (this.patronymic !== '' && !CyrillicNameWithSpace.test(this.patronymic)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validPassword() {
    if (this.id && !this.password) return
    if (this.selfPassword) return null
    if (validRequired(this.password)) return validRequired(this.password)
    if (this.password.length < 6) return 'Длина пароля меньше 6 символов'
    if (zxcvbn(this.password).score < 2) return 'Пароль слишком простой'
    return null
  }

  @computed get validRole() {
    if (validRequired(this.accessRole)) return validRequired(this.accessRole)
    return null
  }

  @computed get validWorkerType() {
    if (validRequired(this.workerType)) return validRequired(this.workerType)
    return null
  }

  @computed get validHireType() {
    if (validRequired(this.hireType)) return validRequired(this.hireType)
    return null
  }

  @computed get validStatus() {
    if (validRequired(this.status)) return validRequired(this.status)
    return null
  }

  @computed get validQrSpec() {
    if (!this.qrSpec) return null
    if (this.qrSpec.length < 7) return 'Некорректный QR'
    return null
  }

  @computed get validPoints() {
    // if (this.accessRole.behaviors.includes('member')) return null
    return _.isEmpty(this.points) ? 'Торговые точки обязательны' : null
  }

  @computed get validPhone() {
    if (validRequired(this.phone)) return validRequired(this.phone)
    if (this.phone.length !== 10) return 'Неверный формат'
    return null
  }

  @computed get validEmail() {
    if (validRequired(this.email)) return validRequired(this.email)
    if (validEmail(this.email)) return validEmail(this.email)
    return null
  }

  @computed get isNext() {
    return !this.validLogin &&
      !this.validLastName && !this.validFirstName && !this.validPatronymic &&
      !this.validPassword && !this.validHireType &&
      !this.validRole && !this.validWorkerType && !this.validStatus &&
      !this.validQrSpec && !this.validPoints && !this.validPhone && !this.validEmail
  }

  @computed get isValidCodes() {
    if (_.isEmpty(this.codes)) return true
    const required = this.codes.map(code => this.isRequiredCodeValue(code))
    const flattened = _.reduce(required, (s, r) => [...s, ...r.values], [])
    return _.reduce(flattened, (s, r) => s + (`${r.value}`.trim() === '' ? 1 : 0), 0) === 0
  }

  isRequiredCodeValue = (code) => {
    const bankTemplates = _.find(FactoryModel.BankModel.banks, b => b.id === code.peerID)?.userTemplates.filter(t => !!t.isRequired)
    const values = _.intersectionBy(code.values, bankTemplates, 'name')
    return { ...code, values }
  }

  @computed get isValidBankCode() {
    return isSafe(this.bankCode) &&
      _.findIndex(this.codes, code => code.peerID === this.bankCode) === -1
  }

  validCodeValue(value, isRequired) {
    if (isRequired && validRequired(value)) return validRequired(value)
    return null
  }

  @computed get isValidACGroups() {
    if (_.isEmpty(this.agentCommissionGroups)) return true
    const invalidGroups = _.filter(this.agentCommissionGroups, (acg, index) => !_.isNull(this.acGroupStartsOnError(index)))
    return _.isEmpty(invalidGroups)
  }

  @computed get isRequiresRegionsAndMacroregionsForUser() {
    return this.accessRolesList.find(accessRole => accessRole.id === this.accessRole)?.isRequiresRegionsAndMacroregionsForUser
  }

  setSelfPassword = action(selfPassword => {
    this.selfPassword = selfPassword
    if (selfPassword) this.password = ''
  })

  generatePassword = () => {
    let password = ''
    let counter = 0
    while (zxcvbn(password).score < 2 && counter++ < 100) {
      password = passwordGenerator(7)
    }
    this.applyData({ password })
  }

  getManagersList = async (params = {}) => {
    const { search = '' } = params
    try {
      this.applyData({ isLoadingManagersList: true })
      const list = await FactoryProvider.UserProvider.searchUsers({ search })
      this.applyData({
        managersList: _.map(list, m => ({ id: m.id, name: m.fullName || m.login }))
      })
    } finally {
      this.applyData({ isLoadingManagersList: false })
    }
  }

  getAccessRolesList = async () => {
    const list = await FactoryProvider.UserProvider.searchRoles()
    this.applyData({
      accessRolesList: _.uniqBy(deepMerge(this.accessRolesList, list), 'id')
    })
  }

  getMacroRegionsList = async () => {
    const list = await FactoryProvider.RegionProvider.searchMacroRegions()
    this.applyData({
      macroRegionsList: _.uniqBy(deepMerge(this.macroRegionsList, list), 'id')
    })
  }

  getCallCentersList = async () => {
    const list = await FactoryProvider.CallCenterProvider.searchCallCenters()
    this.applyData({
      callCentersList: _.uniqBy(deepMerge(this.callCentersList, list), 'id')
    })
  }

  getPartnersList = async (params = {}) => {
    const { search = '', isActive = false } = params
    const list = await FactoryProvider.PartnerProvider.searchPartners({ search, isActive })
    this.applyData({
      partnersList: list
    })
  }

  getPointsList = async (params = {}) => {
    const { isShort = false, search = '', isBoth = false, isActive = false } = params
    const pointsField = isShort ? 'shortPointsList' : 'pointsList'
    const list = await FactoryProvider.PointProvider.searchPoint({ search, isActive, pageSize: 40 })
    const resolveList = _.map(list, l => ({ id: l.id, name: l.name }))
    if (!isBoth) {
      this.applyData({
        [pointsField]: resolveList
      })
    } else {
      this.applyData({
        pointsList: resolveList,
        shortPointsList: resolveList
      })
    }
  }

  userFields = () => [
    'id',
    'isActive',
    'lockReason',
    'login',
    'lastName',
    'firstName',
    'patronymic',
    'password',
    'selfPassword',
    'manager',
    'accessRole',
    'workerType',
    'status',
    'hireType',
    'qrSpec',
    'email',
    'phone',
    'showAgentCommission',
    'callCenters',
    'organizations',
    'points',
    'shortQuestionaryPoints',
    'macroRegions',
    'agentCommissionGroups',
    'isSendInvite',
    'isSendConfirmation',
    'isMobilePhoneConfirmed',
    'isEmailConfirmed',
    'isPasswordActivationAvailable',
    'comment'
  ]

  user = () => {
    const resolve = {}
    _.each(this.userFields(), field => {
      resolve[field] = this[field]
    })

    if (this.isActive) _.unset(resolve, 'lockReason')
    return resolve
  }

  createUser = async () => {
    if (!this.isNext) {
      this.applyData({ isShowForceValidate: true })
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const id = await FactoryProvider.UserProvider.createUser(this.user())
        this.applyData({ id })
        scrollToTop()
        push({ uri: '/users/[action]', href: `/users/${id}`, isShallow: true })
        notifySuccess('Пользователь успешно создан')
      } catch (e) {
        notifyError('Ошибка создания пользователя', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  updateUser = async () => {
    if (!this.isNext) {
      this.applyData({ isShowForceValidate: true })
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const { fullName } = await FactoryProvider.UserProvider.updateUser(this.user())
        notifySuccess('Пользователь успешно сохранён')
        scrollToTop()
        if (this.id === FactoryModel.SettingsModel.ProfileModel.id) FactoryModel.SettingsModel.ProfileModel.setField('fullName')(fullName)
      } catch (e) {
        notifyError('Ошибка сохранения пользователя', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  applyUser = user => {
    const resolve = {}
    _.each(this.userFields(), field => {
      resolve[field] = user[field]
    })

    if (resolve.manager) {
      if (!_.find(this.managersList, m => m.id === resolve.manager.id)) {
        resolve.managersList = _.uniqBy(deepMerge.all([[], this.managersList, [resolve.manager]]), 'id')
      }
      resolve.manager = resolve.manager.id
    }

    if (resolve.accessRole) {
      if (!_.find(this.accessRolesList, m => m.id === resolve.accessRole.id)) {
        resolve.accessRolesList = _.uniqBy(deepMerge.all([[], this.accessRolesList, [resolve.accessRole]]), 'id')
      }
      resolve.accessRole = resolve.accessRole.id
    }

    if (!_.isEmpty(resolve.callCenters)) {
      resolve.callCentersList = _.uniqBy(deepMerge(this.callCentersList, resolve.callCenters), 'id')
      resolve.callCenters = _.map(resolve.callCenters, c => c.id)
    }
    if (!_.isEmpty(resolve.organizations)) {
      resolve.partnersList = _.uniqBy(deepMerge(this.partnersList, resolve.organizations), 'id')
      resolve.organizations = _.map(resolve.organizations, o => ({ key: o.id, label: o.name }))
    }
    if (!_.isEmpty(resolve.points)) {
      resolve.points = _.map(resolve.points, p => ({ key: p.id, label: p.name }))
    }
    if (!_.isEmpty(resolve.shortQuestionaryPoints)) {
      resolve.shortQuestionaryPoints = _.map(resolve.shortQuestionaryPoints, s => ({ key: s.id, label: s.name }))
    }
    if (!_.isEmpty(resolve.macroRegions)) {
      resolve.macroRegionsList = _.uniqBy(deepMerge(this.macroRegionsList, resolve.macroRegions), 'id')
      resolve.macroRegions = _.map(resolve.macroRegions, r => r.id)
    }

    this.applyData({ ...resolve, isShowForceValidate: true })
  }

  getUser = async (id) => {
    if (this.id) return
    try {
      this.applyData({ isLoadingUser: true })
      const user = await FactoryProvider.UserProvider.getUser(id)
      this.applyUser(user)
    } catch (e) {
      notifyError('Ошибка получения информации о пользователе', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingUser: false })
    }
  }

  getCodes = async id => {
    if (!_.isEmpty(this.codes)) return
    try {
      this.applyData({ isLoadingCodes: true })
      const codes = await FactoryProvider.UserProvider.getCodes(id)
      this.applyData({ codes })
    } catch (e) {
      notifyError('Ошибка получения кодов пользователя', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingCodes: false })
    }
  }

  // TODO DRY
  setCode = action((peerID, name, value) => {
    const codes = this.codes.map(code => {
      if (code.peerID !== peerID) return code
      const isExistCode = _.findIndex(code.values, v => v.name === name) !== -1
      const values = isExistCode ? code.values : [...code.values, {
        name,
        value
      }]
      return {
        ...code,
        values: values.map(v => (v.name === name ? { ...v, value } : v))
      }
    })
    this.applyData({ codes })
  })

  removeCode = action(index => {
    this.codes.splice(index, 1)
  })

  addCode = action(() => {
    const bank = _.find(FactoryModel.BankModel.banks, b => b.id === this.bankCode)
    if (!bank) return

    this.codes.unshift({ peerID: bank.id,
      values: _.map(bank.userTemplates, value => ({
        name: _.get(value, 'name', ''),
        value: ''
      })) })
    this.applyData({ bankCode: null })
  })

  updateCodes = async () => {
    if (!this.isValidCodes) {
      this.applyData({ isShowForceValidateCodes: true })
      return
    }
    try {
      this.applyData({ isSavingCodes: true })
      this.applyData({
        codes: this.codes.map(code => ({
          ...code,
          values: code.values.map(v => ({
            name: v.name,
            value: v.value.trim()
          }))
        }))
      })
      await FactoryProvider.UserProvider.updateCodes(this.id, this.codes, this.comment)
      notifySuccess('Коды пользователя успешно обновлены')
      this.getEditHistory(this.id, true)
    } catch (e) {
      notifyError('Ошибка обновления кодов пользователя', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSavingCodes: false })
    }
  }

  getEditHistory = async (id, isForce = false) => {
    if (!_.isEmpty(this.historyEdit) && !isForce) return
    try {
      this.applyData({ isLoadingHistoryEdit: true })
      const historyEdit = await FactoryProvider.UserProvider.getEditHistory(id)
      this.applyData({ historyEdit })
    } catch (e) {
      notifyError('Ошибка получения истории изменений пользователя', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingHistoryEdit: false })
    }
  }

  lockUser = async () => {
    try {
      this.applyData({ isProcessLock: true })
      await FactoryProvider.UserProvider.lockUser(this.id, this.isActive, this.isActive ? this.lockReason : '')
      notifySuccess(`Пользователь успешно ${this.isActive ? 'заблокирован' : 'разблокирован'}`)
      this.applyData({
        isActive: !this.isActive,
        lockReason: !this.isActive ? '' : this.lockReason
      })
    } catch (e) {
      notifyError(`Ошибка ${this.isActive ? 'блокировки' : 'разблокировки'} пользователя`, prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessLock: false })
    }
  }

  addACGroup = action(() => {
    const acGroup = _.find(FactoryModel.AgentCommissionGroupListModel.groups, (acg) => acg.id === this.newACGroupID)
    const startsOn = new Date()
    const pointACGroup = { agentCommissionGroup: acGroup, startsOn: moment(startsOn - (startsOn.getTimezoneOffset() * 60000)).startOf('month') }
    this.applyData({ agentCommissionGroups: this.agentCommissionGroups.concat(pointACGroup), newACGroupID: null })
  })

  removeACGroup = action((index) => {
    const agentCommissionGroups = this.agentCommissionGroups.filter((v, i) => i !== index)
    this.applyData({ agentCommissionGroups })
  })

  setACGroupStartsOn = action((index, startsOn) => {
    this.agentCommissionGroups[index].startsOn = startsOn ? moment(startsOn - (startsOn.getTimezoneOffset() * 60000)).startOf('month') : startsOn
  })

  acGroupStartsOnError = (index) => {
    if (validRequired(this.agentCommissionGroups[index]?.startsOn)) return validRequired(this.agentCommissionGroups[index]?.startsOn)
    if (this.agentCommissionGroups.find((group, i) => (
      group.agentCommissionGroup.id === this.agentCommissionGroups[index].agentCommissionGroup.id
      && moment(group.startsOn)?.format(DATE_FORMAT) === moment(this.agentCommissionGroups[index]?.startsOn)?.format(DATE_FORMAT)
      && index !== i
    ))) {
      return 'Совпадает период'
    }
    return null
  }

  updateACGroups = async () => {
    if (!this.isValidACGroups) {
      this.applyData({ isShowForceValidateACGroups: true })
      return
    }
    try {
      this.applyData({ isSavingACGroups: true })
      await FactoryProvider.UserProvider.updateACGroups(this.id, this.agentCommissionGroups)
      notifySuccess('Группы агентского вознаграждения пользователя успешно обновлены')
    } catch (e) {
      notifyError('Ошибка обновления групп агентского вознаграждения пользователя', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSavingACGroups: false })
    }
  }
}

export { UserModel }
