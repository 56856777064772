import _ from 'lodash'
import { safeObject, isSafe } from 'uikit'
import { calculateIntervalExpires } from 'utils/interval'
import BaseApiProvider from './baseApiProvider'
import {
  shortPartnerMap,
  partnerMap,
  partnerMapApi,
  partnerSettingsMap,
  partnerSettingsMapApi,
  partnerDocumentsDataMap,
  partnerDocumentsDataMapApi,
  bankAccountMap,
  bankAccountMapApi
} from '../helpers/partner'
import {
  partnerPropositionMap, partnerPropositionMapApi, ORGANIZATION_STEP,
  partnerPropositionUserMapApi, partnerPropositionUserMap, USERS_STEP
} from '../helpers/partnerProposition'
import { bankMixMap, bankMixMapApi } from '../helpers/bankMix'
import { codesMap, codesMapApi, codesHistoryMap } from '../helpers/codes'

class PartnerProvider extends BaseApiProvider {
  async searchPartners(params = {}) {
    const { search = '', isActive = false } = params
    const data = await this.fetch({
      uri: '/partners',
      query: safeObject({
        query: search !== '' ? search : null,
        active: isActive || null
      })
    })
    return _.map(data, partner => partnerMap({ partner }))
  }

  async getPartnerList(params = {}) {
    const { page = 1, pageSize = 10, queryText = '', isActive = true } = params
    const data = await this.fetch({
      uri: '/partners',
      query: safeObject({
        extended: true,
        query: queryText !== '' ? queryText : null,
        page,
        per_page: pageSize,
        active: isActive || null
      })
    })
    return _.map(data, partner => shortPartnerMap({ partner }))
  }

  async lockPartner(id, isLock, reason = '') {
    return await this.fetch(safeObject({
      uri: `/partners/${id}/${isLock ? 'lock' : 'unlock'}`,
      method: 'PUT',
      query: reason !== '' ? { lock_reason: reason } : null
    }))
  }

  async getPartner(id) {
    const data = await this.fetch({
      uri: `/partners/${id}`
    })
    return partnerMap({ partner: data, isShort: false })
  }

  async createPartner(partner) {
    const data = await this.fetch({
      uri: '/partners',
      method: 'POST',
      data: {
        partner: partnerMapApi({ partner })
      }
    })
    return data.id ?? data._id
  }

  async updatePartner(partner) {
    if (!isSafe(partner.id)) return

    const data = await this.fetch({
      uri: `/partners/${partner.id}`,
      method: 'PUT',
      data: {
        partner: partnerMapApi({ partner })
      }
    })
    return data.id ?? data._id
  }

  async getCodes(id) {
    const data = await this.fetch({
      uri: `/partners/${id}/codes`
    })
    return codesMap(data)
  }

  async updateCodes(id, codes) {
    return await this.fetch({
      uri: `/partners/${id}/codes`,
      method: 'PUT',
      data: { peer_configs: codesMapApi({ codes }) }
    })
  }

  async getBankMix(id) {
    const data = await this.fetch({
      uri: `/partners/${id}/bank_mix`
    })
    return bankMixMap({ bankMix: data })
  }

  async createBankMix(id, bankMix) {
    await this.fetch({
      uri: `/partners/${id}/bank_mix`,
      method: 'POST',
      data: {
        bank_mix: bankMixMapApi({ bankMix })
      }
    })
  }

  async updateBankMix(id, bankMix) {
    await this.fetch({
      uri: `/partners/${id}/bank_mix`,
      method: 'PUT',
      data: {
        bank_mix: bankMixMapApi({ bankMix })
      }
    })
  }

  async deleteBankMix(id) {
    return await this.fetch({
      uri: `/partners/${id}/bank_mix`,
      method: 'DELETE'
    })
  }

  async getEditHistory(id) {
    const data = await this.fetch({
      uri: `/partners/${id}/codes_history`
    })
    return _.map(_.get(data, 'codes_history', []), history => codesHistoryMap({ history }))
  }

  async getSettings(id) {
    const data = await this.fetch({
      uri: `/partners/${id}/settings`
    })
    return partnerSettingsMap(data)
  }

  async updateSettings(params) {
    const data = await this.fetch({
      uri: `/partners/${params?.id}/settings`,
      method: 'PUT',
      data: {
        partner: partnerSettingsMapApi(params)
      }
    })
    return data.id ?? data._id
  }

  async getDocumentsData(id) {
    const data = await this.fetch({
      uri: `/partners/${id}/documents`
    })
    return { documentsData: partnerDocumentsDataMap({ documentsData: data }) }
  }

  async updateDocumentsData({ id, documentsData }) {
    const data = await this.fetch({
      uri: `/partners/${id}/documents`,
      method: 'PUT',
      data: {
        partner: partnerDocumentsDataMapApi({ documentsData })
      }
    })
    return data.id ?? data._id
  }

  async getProposition(id) {
    const data = await this.fetch({ uri: `/partner_applications/${id}` })
    return partnerPropositionMap({ proposition: data })
  }

  async createProposition(proposition) {
    const data = await this.fetch({
      uri: '/partner_applications',
      method: 'POST',
      data: {
        partner_application: partnerPropositionMapApi({
          proposition: {
            ...proposition,
            step: ORGANIZATION_STEP
          }
        })
      }
    })
    return {
      id: data?.id ?? data?._id,
      ...partnerPropositionMap({ proposition: data })
    }
  }

  async updateProposition(id, proposition, step) {
    const data = await this.fetch({
      uri: `/partner_applications/${id}`,
      method: 'PUT',
      data: {
        partner_application: partnerPropositionMapApi({
          proposition: {
            ...proposition,
            step
          }
        })
      }
    })
    return partnerPropositionMap({ proposition: data })
  }

  async savePartnerUser(partnerId, user, userId) {
    if (!userId) {
      const data = await this.fetch({
        uri: `/partner_applications/${partnerId}/users`,
        method: 'POST',
        data: {
          user: partnerPropositionUserMapApi({ user })
        }
      })
      return partnerPropositionUserMap({ user: data })
    }

    const data = await this.fetch({
      uri: `/partner_applications/${partnerId}/users/${userId}`,
      method: 'PUT',
      data: {
        user: partnerPropositionUserMapApi({ user })
      }
    })
    return partnerPropositionUserMap({ user: data })
  }

  async updatePropositionUsers(id) {
    const data = await this.fetch({
      uri: `/partner_applications/${id}`,
      method: 'PUT',
      data: {
        partner_application: {
          step: USERS_STEP
        }
      }
    })
    return partnerPropositionMap({ proposition: data })
  }

  async sendAttachmentConfirmCode(id) {
    const data = await this.fetch({
      uri: `/partner_applications/${id}/sign_code`,
      method: 'POST'
    })
    return calculateIntervalExpires(data?.signing_retry_available_at)
  }

  async confirmAttachmentCode(id, code) {
    await this.fetch({
      uri: `/partner_applications/${id}/sign`,
      method: 'PATCH',
      query: {
        code
      }
    })
  }

  async getPartnerApplicationList() {
    const data = await this.fetch({
      uri: '/partner_applications'
    })
    return _.map(data, value => ({
      id: value?.id ?? '',
      fullOpfName: value?.full_opf_name ?? '',
      createdAt: value?.created_at ?? '',
      status: value?.status ?? '',
      step: value?.step ?? '',
      number: value?.number ?? ''
    }))
  }

  async getPartnerBankAccounts(partnerId) {
    const data = await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts`
    })
    return _.map(data, account => bankAccountMap({ account }))
  }

  async createPartnerBankAccount(partnerId, account) {
    const data = await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts`,
      method: 'POST',
      data: {
        bank_account: bankAccountMapApi({ account })
      }
    })
    return bankAccountMap({ account: data })
  }

  async updatePartnerBankAccount(partnerId, accountId, account) {
    const data = await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts/${accountId}`,
      method: 'PUT',
      data: {
        bank_account: bankAccountMapApi({ account })
      }
    })
    return bankAccountMap({ account: data })
  }

  async removePartnerBankAccount(partnerId, accountId) {
    await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts/${accountId}`,
      method: 'DELETE'
    })
  }

  async lockPartnerBankAccount(partnerId, accountId, isLock = false) {
    return await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts/${accountId}/${isLock ? 'lock' : 'unlock'}`,
      method: 'PUT'
    })
  }

  async assignPointsBankAccount(partnerId, accountId, points) {
    const data = await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts/${accountId}/assign`,
      method: 'PUT',
      data: {
        point_ids: _.map(points, p => p.id)
      }
    })
    return bankAccountMap({ account: data })
  }

  async assignSinglePointBankAccount(partnerId, accountId, pointId) {
    const data = await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts/${accountId}/points/${pointId}`,
      method: 'POST'
    })
    return bankAccountMap({ account: data })
  }

  async unAssignSinglePointBankAccount(partnerId, accountId, pointId) {
    const data = await this.fetch({
      uri: `/partners/${partnerId}/bank_accounts/${accountId}/points/${pointId}`,
      method: 'DELETE'
    })
    return bankAccountMap({ account: data })
  }

  async getPartnerChannels() {
    const data = await this.fetch({
      uri: '/partner_channels'
    })
    return _.map(data, value => ({
      id: value?.id ?? '',
      name: value?.name ?? ''
    }))
  }
}

export { PartnerProvider }
