import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'
import { contentMap } from '../helpers/content'

class ContentProvider extends BaseApiProvider {
  async getContent(slugs) {
    return await this.fetch({
      uri: '/content',
      query: safeObject({
        slugs
      })
    })
  }

  async getDetails(slug) {
    const data = await this.fetch({
      uri: `/content/${slug}`
    })
    return contentMap({ data })
  }

  async putDetails(slug, data) {
    return await this.fetch({
      method: 'PUT',
      uri: `/content/${slug}`,
      data
    })
  }

  async togglePublished(slug) {
    return await this.fetch({
      method: 'PATCH',
      uri: `/content/${slug}/toggle_published`
    })
  }
}

export { ContentProvider }
