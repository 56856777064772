import { computed, makeObservable, observable } from 'mobx'
import { validRequired, notifyError, notifySuccess, CyrillicNameWithSpace } from 'uikit'
import _ from 'lodash'
import FactoryModel from 'models/factoryModel'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'
import { prepareErrorMessage } from '../../utils/error'

class CallBackModel extends BaseModel {
  @observable fullName = ''
  @observable mobilePhone = ''
  @observable comment = ''
  @observable isSending = false

  constructor() {
    super()
    makeObservable(this)
  }

  init = () => {
    this.applyData({
      fullName: !this.fullName ? FactoryModel.SettingsModel.ProfileModel?.fullName : this.fullName,
      mobilePhone: !this.mobilePhone ? FactoryModel.SettingsModel.ProfileModel?.mobilePhone : this.mobilePhone
    })
  }

  @computed get validName() {
    if (validRequired(this.fullName)) return validRequired(this.fullName)
    if (!CyrillicNameWithSpace.test(this.fullName)) return 'Только кириллические символы'
    return null
  }
  @computed get validPhone() {
    if (validRequired(this.mobilePhone)) return validRequired(this.mobilePhone)
    if (this.mobilePhone.length !== 10) return 'Неверный формат'
    if (_.get(this.mobilePhone.replace(/[\s()-]*/gi, ''), '0') !== '9') return 'Начинается не с 9'
    return null
  }

  @computed get isInvalid() {
    return this.validName || this.validPhone
  }

  requestCall = async () => {
    try {
      this.applyData({
        isSending: true
      })
      await FactoryProvider.RequestCallProvider.requestCall({
        fullName: this.fullName,
        mobilePhone: this.mobilePhone,
        comment: this.comment
      })
      notifySuccess('Обратный звонок успешно заказан')
    } catch (e) {
      notifyError('Ошибка заказа обратного звонка', prepareErrorMessage(e))
    } finally {
      this.applyData({
        isSending: false
      })
    }
  }
}

export { CallBackModel }
