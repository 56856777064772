export const validBik = value => {
  const bik = `${value}`

  if (bik === '') return 'БИК пуст'
  if (/\D/.test(bik)) return 'БИК может состоять только из цифр'
  if (bik.length !== 9) return 'БИК может состоять только из 9 цифр'

  return null
}

export const validRs = (value, bik) => {
  const rs = `${value}`
  if (rs === '') return 'РC пуст'
  if (/\D/.test(rs)) return 'РС может состоять только из цифр'
  if (rs.length !== 20) return 'РС может состоять только из 20 цифр'
  if (rs.substring(0, 4) === '4081') return 'Не допускаются РС физических лиц'

  const bikRs = `${bik}`.toString().slice(-3) + rs
  let checksum = 0
  const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
  for (const i in coefficients) {
    checksum += coefficients[i] * (bikRs[i] % 10)
  }

  if (checksum % 10 !== 0) return 'Неверное контрольное число'

  return null
}
