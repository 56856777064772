import _ from 'lodash'
import { moment, DATE_FORMATS } from 'uikit'
import { notNull } from './null'
import { phoneSimpleMap } from './phone'

export const borrowerMap = ({ borrower, isShort = true }) => {
  const passport = _.get(borrower, 'passport') || _.get(borrower, 'passports.0') || {}
  const familyType = borrower.marital_status ? Number(borrower.marital_status) : null

  const resolve = {
    id: _.get(borrower, 'id', '') || _.get(borrower, '_id', ''),
    firstName: _.get(borrower, 'firstname', ''),
    lastName: _.get(borrower, 'lastname', ''),
    patronymic: _.get(borrower, 'patronymic', ''),
    fullName: _.get(borrower, 'fullname', ''),
    packageID: _.get(borrower, 'photo.package_id', ''),
    gender: _.get(borrower, 'gender', null),
    passport: passport ? {
      series: _.get(passport, 'series', ''),
      number: _.get(passport, 'number', ''),
      issuedBy: _.get(passport, 'issued_by', ''),
      code: _.get(passport, 'issued_by', ''),
      issueDate: notNull(_.get(passport, 'issue_date', null), issueDate => moment(issueDate, DATE_FORMATS).toDate())
    } : null,
    phone: phoneSimpleMap({ phone: _.find(_.get(borrower, 'contacts', []), c => c._type === 'Phone') }),
    birthDate: _.get(borrower, 'birthdate', null) ? moment(borrower.birthdate, DATE_FORMATS).toDate() : null,
    birthPlace: _.get(borrower, 'birth_place', ''),
    familyType: familyType ? `${familyType}` : null,
    familyChildren: _.get(borrower, 'children_count', 0),
    isWithoutPhoto: !_.get(borrower, 'has_photo') || _.isEmpty(_.get(borrower, 'photo')) || _.get(borrower, 'without_photo', false)
  }

  if (!isShort) {
    // TODO: дополнительные поля
  }

  return resolve
}
