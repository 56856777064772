import _ from 'lodash'
import fp from 'lodash/fp'
import FactoryProvider from 'providers/factoryProvider'
import { v4 as uuid } from 'uuid'
import { notifyError } from 'uikit'
import * as Sentry from '@sentry/node'
import channelsManifest from './channelsManifest'
import FactoryModel from '../../models/factoryModel'

const ActionCableConnectionErrorMessage = 'Action Cable connection error'
class ActionCableConnectionError extends Error {
  constructor() {
    super()
    this.name = ActionCableConnectionErrorMessage
    this.message = ActionCableConnectionErrorMessage

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ActionCableConnectionError)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

const ActionCableSubscriptionErrorMessage = 'Action Cable subscription error'
class ActionCableSubscriptionError extends Error {
  constructor() {
    super()
    this.name = ActionCableSubscriptionErrorMessage
    this.message = ActionCableSubscriptionErrorMessage

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ActionCableSubscriptionError)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

class ActionCableProvider {
  constructor() {
    this.tryConnectSubscription = 30
  }

  run(token) {
    this.disconnect()

    if (!token) {
      console.error('Error action cable connect', 'Miss token')
      return
    }

    try {
      this.host = FactoryProvider.ConfigProvider.config.actionCableUri ||
        `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/ws`
      this.host = `${this.host}?token=${token}`

      this.subscriptions = []

      const ActionCable = require('actioncable')
      this.ws = ActionCable.createConsumer(this.host)
      this.addSubscription({ channel: 'UpdatesChannel' })
    } catch (e) {
      notifyError('Не удается подключится', 'Ошибка Websocket')
      Sentry.withScope(scope => {
        scope.setTag('token', token)
        scope.setExtra('token', token)
        scope.setExtra('currentUser', FactoryModel.SettingsModel.ProfileModel.currentUser())
        Sentry.captureEvent(new ActionCableConnectionError())
      })
    }
  }

  disconnect() {
    fp.forEach(sub => {
      this.ws.subscriptions.remove(sub.connection)
    })(this.subscriptions)
    if (this.ws) this.ws.disconnect()
  }

  addSubscription({ channel, options, meta = null }) {
    try {
      if (!channel) return
      if (this.getSubscriptionIndex({ ...options, channel }) !== -1) return

      const subID = uuid()

      const initSubscription = () => {
        const connection = this.ws.subscriptions.create({ ...options, channel },
          {
            connected: data => {
              this.subscriptions.push({ id: subID, connection, options: { ...options, channel }, meta })
              const handler = _.get(channelsManifest, `${channel}.onConnected`, null)
              if (handler) {
                handler({
                  data,
                  options: { ...options, channel },
                  meta,
                  close: () => this.removeSubscription(subID)
                })
              }
            },
            disconnected: data => {
              const subIndex = this.getSubscriptionIndex({ subID })
              if (subIndex) this.subscriptions.splice(subIndex, 1)

              const handler = _.get(channelsManifest, `${channel}.onDisconnected`, null)
              if (handler) {
                handler({
                  data,
                  options: { ...options, channel },
                  meta
                })
              }
            },
            received: data => {
              const handler = _.get(channelsManifest, `${channel}.onReceived`, null)
              if (handler) {
                handler({
                  data,
                  options: { ...options, channel },
                  meta,
                  close: () => this.removeSubscription(subID)
                })
              }
            }
          })
      }

      if (!_.isEmpty(_.get(this, 'ws.subscriptions'))) {
        initSubscription()
      } else {
        let tryCount = this.tryConnectSubscription
        const tryInterval = setInterval(() => {
          tryCount -= 1
          if (tryCount < 0) {
            clearInterval(tryInterval)
          } else if (!_.isEmpty(_.get(this, 'ws.subscriptions'))) {
            clearInterval(tryInterval)
            initSubscription()
          }
        }, 1000)
      }

      return subID
    } catch (e) {
      this.sendSubscriptionError(channel, options)
    }
  }

  sendSubscriptionError = (channel, options) => {
    notifyError(`Не удается подключиться к каналу ${channel}`, 'Ошибка Websocket')
    Sentry.withScope(scope => {
      scope.setTag('channel', channel)
      scope.setExtra('options', options)
      scope.setExtra('currentUser', FactoryModel.SettingsModel.ProfileModel.currentUser())
      Sentry.captureEvent(new ActionCableSubscriptionError())
    })
  }

  getSubscriptionIndex({ subID, options }) {
    return fp.findIndex(s => (subID ? s.id === subID : _.isEqual(options, s.options)))(this.subscriptions)
  }

  removeSubscription = subID => {
    const subIndex = this.getSubscriptionIndex({ subID })
    if (subIndex !== -1) {
      this.ws.subscriptions.remove(this.subscriptions[subIndex].connection)
      this.subscriptions.splice(subIndex, 1)
    }
  }

  removeSubscriptionByOptions = options => {
    const subIndex = fp.findIndex(s => _.isEqual(options, s.options))(this.subscriptions)
    if (subIndex !== -1) {
      this.ws.subscriptions.remove(this.subscriptions[subIndex].connection)
      this.subscriptions.splice(subIndex, 1)
    }
  }
}

export { ActionCableProvider }
