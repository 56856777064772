import BaseModel from '../baseModel'
import { ProfileModel } from './profileModel'
import { CommonModel } from './commonModel'
import { ConfirmEmailModel } from './confirmEmailModel'

class SettingsModel extends BaseModel {
  constructor() {
    super()

    const profileModel = new ProfileModel()
    const commonModel = new CommonModel()
    const confirmEmailModel = new ConfirmEmailModel()

    const ctx = {
      CommonModel: commonModel,
      ProfileModel: profileModel,
      ConfirmEmailModel: confirmEmailModel
    }

    profileModel.ctx = ctx
    commonModel.ctx = ctx
    confirmEmailModel.ctx = ctx

    this.CommonModel = commonModel
    this.ProfileModel = profileModel
    this.ConfirmEmailModel = confirmEmailModel
  }
}

export { SettingsModel }
