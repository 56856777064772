import _ from 'lodash'
import { observable, action, computed, makeObservable } from 'mobx'
import { notifyError, notifySuccess } from 'uikit'
import { push } from 'utils/history'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import { scanMap, BORROWER_PHOTO, CC_PASSPORT23, CC_PASSPORT19, CC_PASSPORTREG } from 'providers/helpers/poscans'
import BaseModel from '../../baseModel'
import FactoryModel from '../../factoryModel'

class CallCenterModel extends BaseModel {
  @observable isLoadingCreditRequest = false
  @observable callCenterSettings = null
  @observable isCallCenterOpened = false
  @observable callCenterStep = ''
  @observable comment = ''
  @observable packageID = ''
  @observable qrCode = ''
  @observable scans = []
  @observable isScansUpload = {}
  @observable requestType = 'credit'
  @observable isConfirmCodeSent = false
  @observable isSendingConfirm = false
  @observable isProcessSend = false

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isEnabled() {
    return !!this.callCenterSettings && this.callCenterSettings.isTransfer && this.ctx.OrderModel.isNext
  }
  @computed get isCanContinue() {
    return !this.callCenterSettings?.isScansRequired || this.isScansUploaded
  }
  @computed get isScansUploaded() {
    return !this.scans.find(scan => !scan.url && !scan.minUrl &&
      scan.commonCode !== BORROWER_PHOTO) && !_.isEmpty(this.scans)
  }
  @computed get isPhotoUploaded() {
    return this.photoScan?.url || this.photoScan?.minUrl
  }
  @computed get photoScan() {
    return this.scans.find(scan => scan.commonCode === BORROWER_PHOTO)
  }
  @computed get passportMainScan() {
    return this.scans.find(scan => scan.commonCode === CC_PASSPORT23)
  }
  @computed get passportRegScan() {
    return this.scans.find(scan => scan.commonCode === CC_PASSPORTREG)
  }
  @computed get passport19Scan() {
    return this.scans.find(scan => scan.commonCode === CC_PASSPORT19)
  }

  clear = action(() => {
    this.isLoadingCreditRequest = false
    this.callCenterSettings = null
    this.isCallCenterOpened = false
    this.callCenterStep = ''
    this.comment = ''
    this.packageID = ''
    this.qrCode = ''
    this.scans = []
    this.requestType = 'credit'
    this.isConfirmCodeSent = false
    this.ctx.ConfirmPhoneModel.clear()
    this.isScansUpload = {}
    this.isSendingConfirm = false
    this.isProcessSend = false
  })

  applyCallCenter = action((questionary) => {
    this.applyData({
      callCenterSettings: _.get(questionary, 'options.callCenter', null)
    })
  })
  applyScan = scan => {
    const scans = this.scans.map(s => (s.poscanID === scan.id ? { ...s, url: scan.url, minUrl: scan.minUrl } : s))
    this.applyData({
      scans
    })
  }
  openCallCenter = () => {
    const callCenterStep = this.callCenterStep || 'scans'
    this.applyData({
      isCallCenterOpened: true,
      callCenterStep
    })
  }
  closeCallCenter = () => {
    this.applyData({
      isCallCenterOpened: false
    })
  }
  back = () => {
    this.applyData({
      callCenterStep: 'scans'
    })
  }
  continue = () => {
    const callCenterStep = this.callCenterSettings.isPhoneConfirmationRequired && !this.isConfirmCodeSent ? 'confirm' : 'send'
    this.applyData({
      callCenterStep
    })
  }
  confirm = async () => {
    try {
      this.applyData({ isSendingConfirm: true })
      await FactoryModel.QuestionaryModel.ConfirmPhoneModel.sendConfirmPhone()
      this.applyData({
        callCenterStep: 'send',
        isConfirmCodeSent: true
      })
    } catch (e) {
      notifyError('Ошибка отправки кода', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSendingConfirm: false })
    }
  }
  send = async () => {
    try {
      this.applyData({ isProcessSend: true })
      if (this.callCenterSettings.isPhoneConfirmationRequired) {
        await FactoryProvider.QuestionaryProvider.confirmPhone(this.ctx.CommonModel.id, this.ctx.ConfirmPhoneModel.confirmSmsCode)
      }
      const creditRequest = {
        firstName: FactoryModel.QuestionaryModel.MainModel.firstName,
        lastName: FactoryModel.QuestionaryModel.MainModel.lastName,
        patronymic: FactoryModel.QuestionaryModel.MainModel.patronymic,
        gender: FactoryModel.QuestionaryModel.MainModel.gender,
        packageID: this.packageID,
        comment: this.comment,
        orders: FactoryModel.QuestionaryModel.OrderModel.orders,
        phone: FactoryModel.QuestionaryModel.MainModel.phone,
        requestType: this.requestType,
        questionaryID: FactoryModel.QuestionaryModel.CommonModel.id,
        withoutPhoto: !this.isPhotoUploaded
      }
      await this.ctx.OrderModel.silentNext()
      await FactoryProvider.CreditRequestProvider.createCreditRequest(creditRequest)
      this.clear()
      push({ uri: '/credit_requests', href: '/credit_requests' })
      notifySuccess('Заявка успешно передана в КЦ')
    } catch (e) {
      notifyError('Ошибка при передаче в КЦ', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSend: false })
    }
  }
  uploadPhoto = async ({ file, scanID, metadata = null }) => {
    try {
      this.action(() => {
        this.isScansUpload[scanID] = true
      })
      const scan = await FactoryProvider.PoscansProvider.putScan({
        packageID: this.packageID,
        scanID,
        file,
        metadata
      })
      this.applyScan(scan)
    } catch (e) {
      notifyError('Ошибка загрузки фото', prepareErrorMessage(e))
      throw e
    } finally {
      this.action(() => {
        this.isScansUpload[scanID] = false
      })
    }
  }
  getNewCreditRequest = async () => {
    if (this.packageID) return
    try {
      this.applyData({ isLoadingCreditRequest: true })
      const creditRequest = await FactoryProvider.CreditRequestProvider.getNewCreditRequest()
      this.applyData(creditRequest)
      FactoryProvider.PoscansWsProvider.subscribe({
        packageID: creditRequest.packageID,
        onReceived: source => {
          switch (source?.Action) {
            case 'Upload': {
              if (!source?.Payload) break

              const scan = scanMap({ scan: source?.Payload })
              this.applyScan(scan)
            }
          }
        }
      })
    } catch (e) {
      notifyError('Ошибка создания новой заявки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingCreditRequest: false })
    }
  }
}

export { CallCenterModel }
