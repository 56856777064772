import _ from 'lodash'
import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class BankModel extends BaseModel {
  @observable banks = []
  @observable isLoadingBanks = false

  constructor() {
    super()
    makeObservable(this)
  }

  getBanks = async (extended = true) => {
    if (!_.isEmpty(this.banks)) return
    try {
      this.applyData({ isLoadingBanks: true })
      const banks = await FactoryProvider.BankProvider.getBanks(extended)
      this.applyData({ banks })
    } finally {
      this.applyData({ isLoadingBanks: false })
    }
  }
}

export { BankModel }
