export const MENU_ITEMS = [
  {
    id: 'questionaries',
    name: 'Мои заявки',
    icon: 'menu'
  },
  {
    id: 'credit_requests',
    name: 'Контакт-центр',
    icon: 'old-phone'
  },
  {
    id: 'calc',
    name: 'Калькулятор',
    icon: 'calc'
  },
  {
    id: 'settings',
    name: 'Настройки',
    icon: 'wrench',
    items: [
      {
        id: 'users',
        name: 'Пользователи'
      },
      {
        id: 'points',
        name: 'Торговые точки'
      },
      {
        id: 'organizations',
        name: 'Организации'
      }
    ]
  },
  {
    id: 'my_applications',
    name: 'Заявления',
    icon: 'home'
  },
  {
    id: 'credit_offer',
    name: 'Услуги для бизнеса',
    icon: 'message',
    color: 'primary'
  },
  {
    id: 'tutorial',
    name: 'Обучение',
    icon: 'question'
  }
]
