import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import FactoryModel from 'models/factoryModel'
import FactoryProvider from 'providers/factoryProvider'
import { notifyError, notifySuccess } from 'uikit'

import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../../baseModel'

const ADDITIONAL_SCAN_COMMON_CODE = 'ADDITIONAL_PARTNER_DOC'
const APPLICATION_BUNDLE_CODE = 'APPLICATION'
const ADDITIONAL_FILES_BUNDLE_CODE = 'PARTNER_ADDITIONAL_FILES'

class DocumentsDataModel extends BaseModel {
  @observable id = ''
  @observable createdAt = {}
  @observable dateTurnOff = {}
  @observable isAutoDeactivationDisabled = false
  @observable canUpdateDeactivation = false
  @observable bundles = []
  @observable poscansPackageID = ''
  @observable renameScanID = ''
  @observable renameScanName = ''
  @observable addScanName = ''
  @observable addScanBundleID = ''
  @observable isOpenAddingFile = false
  @observable isLoading = true
  @observable isProcessSave = false
  @observable processingDelete = []
  @observable processingUpload = []
  @observable isProcessRename = false
  @observable isProcessAdd = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.id = ''
    this.createdAt = {}
    this.dateTurnOff = {}
    this.isAutoDeactivationDisabled = false
    this.canUpdateDeactivation = false
    this.bundles = []
    this.poscansPackageID = ''
    this.renameScanID = ''
    this.scanName = ''
    this.addScanBundleID = ''
    this.isOpenAddingFile = false
    this.isLoading = false
    this.isProcessSave = false
    this.processingDelete = []
    this.processingUpload = []
    this.isProcessRename = false
    this.isProcessAdd = false
  })

  addScanOpen = action(({ bundleID }) => {
    this.applyData({ isOpenAddingFile: true, addScanName: '', addScanBundleID: bundleID })
  })

  addScanCancel = action(() => {
    this.applyData({ isOpenAddingFile: false, addScanName: '', addScanBundleID: '' })
  })

  renameScanOpen = action(({ bundleID, scanID }) => {
    const bundle = _.find(this.bundles, b => b.id === bundleID)
    const scan = _.find(bundle.scans, s => s.id === scanID)
    this.applyData({ renameScanID: scanID, renameScanName: scan.name })
  })

  renameScanCancel = action(() => {
    this.applyData({ renameScanID: '', renameScanName: '' })
  })

  addDeleteScanID = action(scanID => {
    this.processingDelete.push(scanID)
  })

  removeDeleteScanID = action(scanID => {
    this.processingDelete = this.processingDelete.filter(id => id !== scanID)
  })

  addUploadScanID = action(scanID => {
    this.processingUpload.push(scanID)
  })

  removeUploadScanID = action(scanID => {
    this.processingUpload = this.processingUpload.filter(id => id !== scanID)
  })

  @computed get isProcess() {
    return this.isLoading || this.isProcessSave
  }

  @computed get applicationBundle() {
    return _.find(this.bundles, bundle => bundle.code === APPLICATION_BUNDLE_CODE)
  }

  @computed get additionalFilesBundle() {
    return _.find(this.bundles, bundle => bundle.code === ADDITIONAL_FILES_BUNDLE_CODE)
  }

  @computed get partnerID() {
    return FactoryModel.PartnerModel.id
  }

  isDeleteScan = (scanID) => (
    _.findIndex(this.processingDelete, id => id === scanID) !== -1
  )

  isUploadScan = (scanID) => (
    _.findIndex(this.processingUpload, id => id === scanID) !== -1
  )

  isRenameScan = (scanID) => (
    this.renameScanID === scanID
  )

  addScanToBundle = action(({ bundleID, scan }) => {
    const bundleIndex = _.findIndex(this.bundles, b => b.id === bundleID)
    if (bundleIndex === -1) return

    const bundle = this.bundles[bundleIndex]
    bundle.scans.push(scan)
    this.bundles[bundleIndex] = bundle
  })

  changeScanInBundle = action(({ bundleID, scan }) => {
    const bundleIndex = _.findIndex(this.bundles, b => b.id === bundleID)
    if (bundleIndex === -1) return

    const bundle = this.bundles[bundleIndex]
    const scanIndex = _.findIndex(bundle?.scans, s => s.id === scan.id)
    if (scanIndex === -1) return

    bundle.scans[scanIndex] = scan
    this.bundles[bundleIndex] = bundle
  })

  deleteScanFromBundle = action(({ bundleID, scanID }) => {
    const bundleIndex = _.findIndex(this.bundles, b => b.id === bundleID)
    if (bundleIndex === -1) return

    const bundle = this.bundles[bundleIndex]
    bundle.scans = bundle.scans.filter(s => s.id !== scanID)
    this.bundles[bundleIndex] = bundle
  })

  documentsData = () => {
    const resolve = {}
    _.each(this.documentsDataFields(), field => {
      resolve[field] = this[field]
    })

    return resolve
  }

  documentsDataFields = () => ['dateTurnOff', 'isAutoDeactivationDisabled']

  get = async () => {
    try {
      this.applyData({ isLoading: true })
      const { documentsData } = await FactoryProvider.PartnerProvider.getDocumentsData(this.partnerID)
      this.applyData(documentsData)
    } catch (e) {
      notifyError('Ошибка получения документов', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  update = async () => {
    try {
      this.applyData({ isProcessSave: true })
      await FactoryProvider.PartnerProvider.updateDocumentsData({ id: this.partnerID, documentsData: this.documentsData() })
      notifySuccess('Настройки отключения организации успешно обновлены')
    } catch (e) {
      notifyError('Ошибка обновления настроек отключения организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSave: false })
    }
  }

  putScan = async ({ scanID, file }) => {
    if (this.isUploadScan(scanID)) return

    try {
      this.addUploadScanID(scanID)
      const scan = await FactoryProvider.PoscansProvider.putScan({
        packageID: this.poscansPackageID,
        scanID,
        file
      })
      this.changeScanInBundle({ bundleID: scan.bundleID, scan })
      notifySuccess('Скан загружен')
    } catch (e) {
      notifyError('Ошибка загрузки скана', prepareErrorMessage(e))
      throw e
    } finally {
      this.removeUploadScanID(scanID)
    }
  }

  addScan = async () => {
    if (!this.addScanName || this.isProcessAdd) return

    try {
      this.applyData({ isProcessAdd: true })
      const scan = await FactoryProvider.PoscansProvider.addScan({
        packageID: this.poscansPackageID,
        bundleID: this.addScanBundleID,
        scan: { name: this.addScanName, commonCode: ADDITIONAL_SCAN_COMMON_CODE }
      })
      this.addScanToBundle({ bundleID: scan.bundleID, scan })
      this.addScanCancel()
      notifySuccess('Скан добавлен')
    } catch (e) {
      notifyError('Ошибка добавления скана', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessAdd: false })
    }
  }

  renameScan = async () => {
    if (!this.renameScanID || !this.renameScanName || this.isProcessRename) return

    try {
      this.applyData({ isProcessRename: true })
      const scan = await FactoryProvider.PoscansProvider.renameScan({
        packageID: this.poscansPackageID,
        scanID: this.renameScanID,
        scan: { name: this.renameScanName }
      })
      this.changeScanInBundle({ bundleID: scan.bundleID, scan })
      this.renameScanCancel()
      notifySuccess('Скан переименован')
    } catch (e) {
      notifyError('Ошибка переименования скана', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessRename: false })
    }
  }

  clearScan = async ({ scanID }) => {
    if (this.isDeleteScan(scanID)) return

    try {
      this.addDeleteScanID(scanID)
      const scan = await FactoryProvider.PoscansProvider.clearScan({
        packageID: this.poscansPackageID,
        scanID
      })
      this.changeScanInBundle({ bundleID: scan.bundleID, scan })
      notifySuccess('Скан очищен')
    } catch (e) {
      notifyError('Ошибка очистки скана', prepareErrorMessage(e))
      throw e
    } finally {
      this.removeDeleteScanID(scanID)
    }
  }

  deleteScan = async ({ bundleID, scanID }) => {
    if (this.isDeleteScan(scanID)) return

    try {
      this.addDeleteScanID(scanID)
      await FactoryProvider.PoscansProvider.deleteScan({
        packageID: this.poscansPackageID,
        scanID
      })
      this.deleteScanFromBundle({ bundleID, scanID })
      notifySuccess('Скан удален')
    } catch (e) {
      notifyError('Ошибка удаления скана', prepareErrorMessage(e))
      throw e
    } finally {
      this.removeDeleteScanID(scanID)
    }
  }
}

export { DocumentsDataModel }
