import React from 'react'
import { inject, observer } from 'mobx-react'
import { UIKitProvider, mountHook } from 'uikit'
import Link from 'components/Link'
import { breakPoints } from 'styles'

const Root = ({ children, ResponsiveModel, AnalyticsModel }) => {
  mountHook(async () => {
    ResponsiveModel.postInit()
    window.addEventListener('resize', () => {
      ResponsiveModel.setWidth(window.innerWidth)
    })
    window.addEventListener('orientationchange', () => {
      ResponsiveModel.setWidth(window.innerWidth)
    })
    // AnalyticsModel.initMatomo()
  })

  return (
      <UIKitProvider
          width={ResponsiveModel.width}
          Link={props => <Link to={props.to} uri={props.uri} href={props.href}>{props.children}</Link>}
          remPoint={14}
          label={{
            weight: 400
          }}
          responsiveModel={{
            isTouch: ResponsiveModel.isTouch,
            isMobile: ResponsiveModel.isMobile,
            isTablet: ResponsiveModel.isTablet,
            isTabletAndPhone: ResponsiveModel.isTabletAndPhone,
            isDesktop: ResponsiveModel.isDesktop,
            isMiddleDesktop: ResponsiveModel.isMiddleDesktop,
            isLargeDesktop: ResponsiveModel.isLargeDesktop,
            breakPoints
          }}
      >
          {children}
      </UIKitProvider>
  )
}

export default inject(store => ({
  ResponsiveModel: store.ResponsiveModel,
  AnalyticsModel: store.AnalyticsModel
}))(observer(Root))
