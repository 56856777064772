import _ from 'lodash'
import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel/baseModel'

class AgentRewardModel extends BaseModel {
  @observable period = ''
  @observable value = 0.0
  @observable closed = false
  @observable details = []
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  get = async (month) => {
    try {
      this.applyData({ isLoading: true })
      const data = await FactoryProvider.AgentRewardProvider.getAgentReward(month)
      this.applyData({ ...data })
    } finally {
      this.applyData({ isLoading: false })
    }
  }
}

export { AgentRewardModel }
