import _ from 'lodash'
import { safeObject } from 'uikit'
import { calculateIntervalExpires } from 'utils/interval'
import BaseHttpProvider from './baseHttpProvider'

class AuthProvider extends BaseHttpProvider {
  async signIn(login = '', password = '') {
    const data = await this.execute({
      uri: '/sign_in',
      method: 'POST',
      data: {
        auth: {
          login,
          password
        }
      }
    })
    return {
      token: _.get(data, 'token', ''),
      target: _.get(data, 'target', ''),
      isPointSelectionRequired: _.get(data, 'point_selection_required', false)
    }
  }

  async signOut() {
    await this.execute({
      uri: '/sign_out',
      method: 'DELETE'
    })
  }

  async searchPoints(token, search = '') {
    const data = await this.execute({
      uri: '/my_points',
      query: safeObject({
        query: search !== '' ? search : null
      }),
      withToken: token
    })
    return _.map(data, point => ({
      id: point._id ?? point.id,
      name: _.get(point, 'name', ''),
      address: _.get(point, 'address', '')
    }))
  }

  async selectPoint(pointID, token) {
    const data = await this.execute({
      uri: `/my_points/${pointID}/select`,
      method: 'PUT',
      withToken: token
    })
    return _.get(data, 'token', '')
  }

  async partnerSignUp(params) {
    const data = await this.execute({
      skipAuthorize: true,
      uri: '/partners/sign_up',
      method: 'POST',
      data: {
        user: safeObject({
          first_name: params.firstName,
          last_name: params.lastName,
          middle_name: params.patronymic !== '' ? params.patronymic : null,
          email: params.email,
          mobile_phone: `7${params.phone}`,
          password: params.password,
          password_confirmation: params.confirmPassword,
          refer_code: params.referId
        })
      }
    })

    return {
      partnerId: data?.id ?? data?._id,
      interval: calculateIntervalExpires(data?.code_retry_available_at)
    }
  }

  async partnerSendConfirmCode(partnerId) {
    const data = await this.execute({
      skipAuthorize: true,
      uri: `/users/${partnerId}/code`,
      method: 'POST'
    })
    return calculateIntervalExpires(data?.code_retry_available_at)
  }

  async partnerConfirmPhone(code, partnerId) {
    const data = await this.execute({
      skipAuthorize: true,
      uri: `/users/${partnerId}/confirm`,
      query: {
        code
      },
      method: 'PATCH'
    })
    return data?.token
  }

  async selfPasswordSignUp(login = '', password = '', confirmPassword = '', code = '') {
    const data = await this.execute({
      uri: '/users/finish_registration',
      method: 'PUT',
      data: {
        login,
        password,
        password_confirmation: confirmPassword,
        code
      }
    })
    return {
      token: _.get(data, 'token', ''),
      target: _.get(data, 'target', ''),
      isPointSelectionRequired: _.get(data, 'point_selection_required', false)
    }
  }

  async restorePasswordByEmail(login = '') {
    const data = await this.execute({
      uri: '/users/reset_password',
      method: 'POST',
      data: { login }
    })
    return data
  }

  async restorePasswordByPhone(phone = '') {
    const data = await this.execute({
      uri: '/users/reset_password',
      method: 'POST',
      data: { mobile_phone: phone }
    })
    return data
  }

  async changePasswordByEmail(login = '', password = '', confirmPassword = '', code = '') {
    const data = await this.execute({
      uri: '/users/reset_password',
      method: 'PUT',
      data: {
        login,
        password,
        password_confirmation: confirmPassword,
        code
      }
    })
    return data
  }
  async changePasswordByPhone(login = '', password = '', confirmPassword = '', code = '') {
    const data = await this.execute({
      uri: '/users/reset_password',
      method: 'PUT',
      data: {
        mobile_phone: login,
        password,
        password_confirmation: confirmPassword,
        code
      }
    })
    return data
  }
  async restoreConfirmPhone(code, phone) {
    const data = await this.execute({
      skipAuthorize: true,
      uri: '/users/reset_password',
      query: {
        code,
        mobile_phone: phone
      },
      method: 'POST'
    })
    return {
      code: _.get(data, 'code', ''),
      phone: _.get(data, 'mobile_phone', '')?.substr(1)
    }
  }
}

export { AuthProvider }
