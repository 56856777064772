import { notifyError } from 'uikit'
import FactoryModel from 'models/factoryModel'
import { toJS } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import { CALC_STEP } from 'providers/helpers/questionary'
import BaseModel from '../../baseModel'

class PickupModel extends BaseModel {
  ctx = null

  next = async (params = {}) => {
    const { isForce = false } = params
    if (!isForce && this.ctx.CommonModel.isMetaStep()) return

    try {
      FactoryModel.PickupModel.setField('isProcessSave')(true)
      const data = await FactoryProvider.QuestionaryProvider.updateQuestionary(
        this.ctx.CommonModel.id, {
          calculationId: FactoryModel.PickupModel.calculationId,
          offers: toJS(FactoryModel.PickupModel.offers),
          pointId: FactoryModel.PickupModel.pointId,
          userId: FactoryModel.PickupModel.userId,
          isGetOffersAfterWait: FactoryModel.PickupModel.isGetOffersAfterWait,
          postSentryOffers: FactoryModel.PickupModel.postSentryOffers
        }, CALC_STEP
      )

      this.ctx.CommonModel.applyDataAfterUpdate(data)
    } catch (e) {
      notifyError('Ошибка обновления данных расчёта', prepareErrorMessage(e))
      throw e
    } finally {
      FactoryModel.PickupModel.setField('isProcessSave')(false)
    }
  }

  back = () => {
    this.ctx.CommonModel.setStep(1)
  }
}

export { PickupModel }
