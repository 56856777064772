import { action, makeObservable, observable } from 'mobx'
import BaseModel from '../baseModel'

class UpdateModel extends BaseModel {
  @observable isUpdate = false

  constructor() {
    super()
    makeObservable(this)
  }

  setUpdate = action((isUpdate = true) => {
    this.isUpdate = isUpdate
  })
}

export { UpdateModel }
