import _ from 'lodash'
import { safeObject, isSafe } from 'uikit'
import BaseApiProvider from './baseApiProvider'
import {
  shortPointMap,
  pointMap,
  pointMapApi,
  segmentMap,
  pointSalesMap,
  pointACGroupMapApi,
  pointSettingsMap,
  pointSettingsMapApi,
  pointAccreditationMap
} from '../helpers/point'
import { codesMap, codesMapApi, codesHistoryMap } from '../helpers/codes'
import { bankMixMap, bankMixMapApi } from '../helpers/bankMix'

class PointProvider extends BaseApiProvider {
  async getPointList(params = {}) {
    const {
      page = 1, pageSize = 10, queryText = '', partnerID = '', isActive = true
    } = params
    const data = await this.fetch({
      uri: '/points',
      query: safeObject({
        extended: true,
        query: queryText !== '' ? queryText : null,
        partner_organization_id: partnerID !== '' ? partnerID : null,
        page,
        per_page: pageSize,
        active: isActive || null
      })
    })
    return _.map(data, point => shortPointMap({ point }))
  }

  async searchPoint(params = {}) {
    const { search = '', isActive, pageSize = 10, partnerId = '' } = params
    const data = await this.fetch({
      uri: '/points',
      query: safeObject({
        query: search !== '' ? search : null,
        active: isActive || null,
        per_page: pageSize,
        partner_organization_id: partnerId || null
      })
    })
    return _.map(data, point => pointMap({ point }))
  }

  async searchMyPoint(params = {}) {
    const { search = '', isActive } = params
    const data = await this.fetch({
      uri: '/my_points',
      query: safeObject({
        query: search !== '' ? search : null,
        active: isActive || null
      })
    })
    return _.map(data, point => pointMap({ point }))
  }

  async lockPoint(id, isLock, reason = '') {
    return await this.fetch(safeObject({
      uri: `/points/${id}/${isLock ? 'lock' : 'unlock'}`,
      method: 'PUT',
      query: reason !== '' ? { lock_reason: reason } : null
    }))
  }

  async searchPointSegments(params = {}) {
    const { search = '' } = params
    const data = await this.fetch({
      uri: '/point_segments',
      query: safeObject({
        query: search !== '' ? search : null
      })
    })
    return _.map(data, segment => segmentMap({ segment }))
  }

  async getPoint(id) {
    const data = await this.fetch({
      uri: `/points/${id}`
    })
    return pointMap({ point: data, isShort: false })
  }

  async createPoint(point) {
    const data = await this.fetch({
      uri: '/points',
      method: 'POST',
      data: {
        point: pointMapApi({ point })
      }
    })
    return data._id ?? data.id
  }

  async updatePoint(point) {
    if (!isSafe(point)) return

    return await this.fetch({
      uri: `/points/${point.id}`,
      method: 'PUT',
      data: {
        point: pointMapApi({ point })
      }
    })
  }

  async changePartner(partnerId, pointId, singleAccount) {
    if (!singleAccount) return

    await this.fetch({
      uri: `/points/${pointId}`,
      method: 'PUT',
      data: {
        point: {
          partner_organization_id: partnerId,
          bank_account_id: singleAccount
        }
      }
    })
  }

  async getBankMix(id) {
    const data = await this.fetch({
      uri: `/points/${id}/bank_mix`
    })
    return bankMixMap({ bankMix: data })
  }

  async createBankMix(id, bankMix) {
    await this.fetch({
      uri: `/points/${id}/bank_mix`,
      method: 'POST',
      data: {
        bank_mix: bankMixMapApi({ bankMix })
      }
    })
  }

  async updateBankMix(id, bankMix) {
    await this.fetch({
      uri: `/points/${id}/bank_mix`,
      method: 'PUT',
      data: {
        bank_mix: bankMixMapApi({ bankMix })
      }
    })
  }

  async deleteBankMix(id) {
    return await this.fetch({
      uri: `/points/${id}/bank_mix`,
      method: 'DELETE'
    })
  }

  async getSales(id) {
    const data = await this.fetch({
      uri: `/points/${id}/sales`,
      method: 'GET'
    })

    return pointSalesMap({ sales: data })
  }

  async getEditHistory(id) {
    const data = await this.fetch({
      uri: `/points/${id}/codes_history`
    })
    return _.map(_.get(data, 'codes_history', []), history => codesHistoryMap({ history }))
  }

  async getCodes(id) {
    const data = await this.fetch({
      uri: `/points/${id}/codes`
    })
    return codesMap(data)
  }

  async updateCodes(id, codes, comment) {
    return await this.fetch({
      uri: `/points/${id}/codes`,
      method: 'PUT',
      data: {
        comment,
        peer_configs: codesMapApi({ codes })
      }
    })
  }

  async updateACGroups(id, acGroups) {
    return await this.fetch({
      uri: `/points/${id}/agent_commission_groups`,
      method: 'PUT',
      data: {
        point: {
          agent_commission_groups: pointACGroupMapApi({ acGroups })
        }
      }
    })
  }

  async getPointSettings(id) {
    const data = await this.fetch({
      uri: `/points/${id}/settings`
    })
    return pointSettingsMap(data)
  }

  async updatePointSettings({ id, settings }) {
    const data = await this.fetch({
      uri: `/points/${id}/settings`,
      method: 'PUT',
      data: {
        point: { settings: pointSettingsMapApi({ settings }) }
      }
    })
    return data.id ?? data._id
  }

  async getPointAccreditation(id) {
    const data = await this.fetch({
      uri: `/points/${id}/accreditation`
    })
    return pointAccreditationMap(data)
  }

  async accreditPoint({ id, bank_id }) {
    const data = await this.fetch({
      uri: `/points/${id}/accredit`,
      method: 'PUT',
      data: {
        bank_id
      }
    })
    return pointAccreditationMap(data)
  }
}

export { PointProvider }
