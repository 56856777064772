import { action, computed, makeObservable, observable } from 'mobx'
import _ from 'lodash'
import { getHistoryQuery, pathname, push } from 'utils/history'
import { safeObject, notifyError } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import FactoryProvider from 'providers/factoryProvider'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'
import FactoryModel from '../factoryModel'

const DEFAULT_PARAMS = {
  slug: '',
  title: '',
  content: '',
  publishedAt: null,
  viewsCount: 0
}

class PostModel extends BaseModel {
  @observable list = []
  @observable page = 1
  @observable isLoadingList = false

  @observable details = deepMerge({}, DEFAULT_PARAMS)
  @observable isLoadingDetails = true

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isExistList() {
    return !_.isEmpty(this.list)
  }

  defaultUrlParams = {
    page: 1
  }

  clear = action(() => {
    this.list = []
    this.page = this.defaultUrlParams.page
    this.isLoadingList = false

    this.details = deepMerge({}, DEFAULT_PARAMS)
    this.isLoadingDetails = true
  })

  applyParamsToHistory = () => {
    push({
      uri: '/news',
      query: safeObject({
        page: this.page !== this.defaultUrlParams.page ? this.page : null
      })
    })
  }

  applyParamsFromHistory = () => {
    if (!this.isExistList) {
      const {
        page = this.defaultUrlParams.page
      } = getHistoryQuery()
      const resolvePage = Number(page) > 1 ? Number(page) : this.defaultUrlParams.page

      this.applyData({
        page: resolvePage
      })
    }

    FactoryModel.MenuModel.setMenuItem('news')
    if (!this.isExistList) this.getList(false)
  }

  getList = async (isReload = true) => {
    try {
      this.applyData({
        isLoadingList: true,
        page: isReload ? 1 : this.page
      })

      const list = await FactoryProvider.PostProvider.getPosts({
        page: this.page
      })

      if (pathname() !== '/news') return
      this.applyParamsToHistory()
      this.applyData(safeObject({
        list
      }))
    } catch (e) {
      notifyError('Ошибка получения новостей', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  setPage = page => {
    this.applyData({ page })
    this.getList(false)
  }

  getDetails = async slug => {
    FactoryModel.MenuModel.setMenuItem('news', false)

    if (!slug) return

    try {
      this.applyData({ isLoadingDetails: true })
      const data = await FactoryProvider.PostProvider.getDetails(slug)
      this.action(() => {
        this.details.slug = data?.slug
        this.details.title = data?.title
        this.details.content = data?.content
        this.details.publishedAt = data?.publishedAt
        this.details.viewsCount = data?.viewsCount
      })
    } catch (e) {
      notifyError('Ошибка получения новости', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingDetails: false })
    }
  }
}

export { PostModel }
