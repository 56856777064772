import _ from 'lodash'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import {
  validRequired,
  scrollTo,
  CyrillicNameWithSpace,
  dateLessThan,
  getDateByAge,
  dateGreaterThan, moment, notifyError, validEmail
} from 'uikit'
import { validationAddress } from 'utils/validators/address'
import { v4 as uuid } from 'uuid'
import FactoryProvider from 'providers/factoryProvider'
import { passportDateValidator } from 'utils/validators/passport'
import { prepareErrorMessage } from 'utils/error'
import FactoryModel from 'models/factoryModel'
import BaseModel from '../../baseModel'

const generateUser = () => ({
  id: '',
  tempId: uuid(),
  lastName: '',
  firstName: '',
  patronymic: '',
  fullName: '',
  passport: '',
  passportSeries: '',
  passportNumber: '',
  passportDate: null,
  passportCode: '',
  passportBy: '',
  birthPlace: '',
  birthDate: null,
  addressRegistration: null,
  contactPhone: '',
  email: '',
  documentsUpload: [],
  points: []
})

class UsersModel extends BaseModel {
  ctx = null

  USER_BLOCK = 'usersData:user'

  @observable users = [
    generateUser()
  ]

  @observable isForceValidate = false
  @observable isProcessSave = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.users = [
      generateUser()
    ]

    this.isForceValidate = false
    this.isProcessSave = false
  })

  validFio(lastName, firstName, patronymic) {
    if (validRequired(lastName)) return 'Отсутствует фамилия'
    if (validRequired(firstName)) return 'Отсутствует имя'
    if (!CyrillicNameWithSpace.test(lastName)) return 'Допустимы только кириллические символы'
    if (!CyrillicNameWithSpace.test(firstName)) return 'Допустимы только кириллические символы'
    if (patronymic !== '' && !CyrillicNameWithSpace.test(patronymic)) return 'Допустимы только кириллические символы'
    return null
  }

  validPassportSeries(passportSeries) {
    if (validRequired(passportSeries)) return validRequired(passportSeries)
    if (passportSeries.length < 4) return 'Некорректная серия'
    return null
  }

  validPassportNumber(passportNumber) {
    if (passportNumber === '') return 'Номер не найден'
    if (passportNumber.length < 6) return 'Некорректный номер'
    return null
  }

  validPassportDate(passportDate, birthDate) {
    if (validRequired(passportDate)) return validRequired(passportDate)

    const validPassport = passportDateValidator(passportDate, birthDate)
    if (validPassport) return validPassport

    return null
  }

  validPassportCode(passportCode) {
    if (validRequired(passportCode)) return validRequired(passportCode)
    if (passportCode.length < 6) return 'Неверный код'

    return null
  }

  validPassportBy(passportBy) {
    if (validRequired(passportBy)) return validRequired(passportBy)
    return null
  }

  validBirthPlace(birthPlace) {
    if (validRequired(birthPlace)) return validRequired(birthPlace)
    if (!/^[\d\s"(),.А-яё\-]+$/i.test(birthPlace)) return 'Допустимы только кириллические символы'
    return null
  }

  validBirthDate(birthDate) {
    if (validRequired(birthDate)) return validRequired(birthDate)

    const young = dateLessThan(birthDate, getDateByAge(18), 'Младше 18 лет')
    if (young) return young

    const old = dateGreaterThan(birthDate, moment()
      .subtract(99, 'years'), 'Старше 99 лет')
    if (old) return old

    return null
  }

  validAddressRegistration(addressRegistration) {
    if (validRequired(addressRegistration)) return validRequired(addressRegistration)
    if (validationAddress(addressRegistration).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  validContactPhone(contactPhone) {
    if (validRequired(contactPhone)) return validRequired(contactPhone)
    if (contactPhone.length !== 10) return 'Неверный формат'
    if (_.get(contactPhone.replace(/[\s()-]*/gi, ''), '0') !== '9') return 'Начинается не с 9'
    return null
  }

  validEmail(email) {
    if (validRequired(email)) return validRequired(email)
    if (validEmail(email)) return validEmail(email)
    return null
  }

  validPoints(points) {
    if (_.isEmpty(points)) return 'Обязательное поле'
    return null
  }

  isInvalidRequiredScans = (user) => user.documentsUpload.find(doc => doc.isRequired && !doc.fileUrl)

  isValidUser = user => !this.validFio(user.lastName, user.firstName, user.patronymic) &&
      !this.validPassportSeries(user.passportSeries) && !this.validPassportNumber(user.passportNumber) &&
      !this.validPassportDate(user.passportDate, user.birthDate) &&
      !this.validPassportCode(user.passportCode) && !this.validPassportBy(user.passportBy) &&
      !this.validBirthPlace(user.birthPlace) && !this.validBirthDate(user.birthDate) &&
      !this.validAddressRegistration(user.addressRegistration) && !this.validContactPhone(user.contactPhone) &&
      !this.validEmail(user.email) && !this.validPoints(user.points)

  @computed get isNext() {
    return !_.isEmpty(this.users) &&
      _.reduce(this.users, (s, r) => s +
        (!this.isValidUser(r) || this.isInvalidRequiredScans(r) || r.isNeedSave ? 1 : 0), 0) === 0
  }

  addUser = action(() => {
    this.users.push(generateUser())
  })

  setUser = action((index, field, value) => {
    this.users[index][field] = value
  })

  setFullName = action((index, fullName = '') => {
    this.users[index].fullName = fullName
    this.users[index].lastName = ''
    this.users[index].firstName = ''
    this.users[index].patronymic = ''
  })

  setFio = action((index, item) => {
    const { name, surname, patronymic } = item
    let fullName = ''
    if (surname) fullName += `${surname} `
    if (name) fullName += `${name} `
    if (patronymic) fullName += `${patronymic}`
    this.users[index].firstName = name
    this.users[index].lastName = surname
    this.users[index].patronymic = patronymic
    this.users[index].fullName = fullName
  })

  setPassport = action((index, v) => {
    const series = v.substring(0, 4)
    const number = v.substring(4)
    this.users[index].passportSeries = series
    this.users[index].passportNumber = number
    this.users[index].passport = v
  })

  removeUser = action(index => {
    this.users.splice(index, 1)
  })

  saveUser = async ({ id, tempId, index }) => {
    try {
      this.action(() => {
        this.users[index].isProcessSave = true
      })
      const user = await FactoryProvider.PartnerProvider.savePartnerUser(
        this.ctx.CommonModel.id, this.getUser(this.users[index]), id
      )
      const resolveIndex = _.findIndex(this.users, u => u.tempId === tempId)
      if (resolveIndex !== -1) {
        this.applyUser({
          ...user,
          agreementUrl: ''
        }, index)
      }
    } catch (e) {
      notifyError('Ошибка сохранения пользователя', prepareErrorMessage(e))
      throw e
    } finally {
      this.action(() => {
        const resolveIndex = _.findIndex(this.users, user => user.tempId === tempId)
        if (resolveIndex !== -1) this.users[resolveIndex].isProcessSave = false
      })
    }
  }

  applyUser = action((user, index) => {
    this.users[index] = {
      ...this.users[index],
      ...user
    }
  })

  uploadAgreement = async ({ index, id, packageId, scanId, tempId, file }) => {
    if (!id || !packageId || !scanId || !file) return

    try {
      this.action(() => {
        this.users[index].isUploadAgreement = true
      })

      const { url, updatedAt } = await FactoryProvider.PoscansProvider.putScan({
        packageID: packageId,
        scanID: scanId,
        file
      })
      const resolveIndex = _.findIndex(this.users, user => user.tempId === tempId)
      if (resolveIndex !== -1) {
        this.applyUser({
          ...this.users[resolveIndex],
          agreementUrl: url,
          agreementDate: updatedAt
        }, index)
      }
    } catch (e) {
      notifyError('Ошибка загрузки согласия', prepareErrorMessage(e))
      throw e
    } finally {
      this.action(() => {
        const resolveIndex = _.findIndex(this.users, user => user.tempId === tempId)
        if (resolveIndex !== -1) this.users[resolveIndex].isUploadAgreement = false
      })
    }
  }

  uploadDocument = async ({ index, userIndex, packageId, scanId, file }) => {
    if (!packageId || !scanId || !file) return

    if (file.size > 2 * 1024 * 1024) {
      notifyError(`Максимальный размер - ${2} МБ`)
      return
    }

    try {
      this.action(() => {
        _.set(this.users, `${userIndex}.documentsUpload.${index}.isProcessSave`, true)
      })
      const { url, fileName, fileSize, updatedAt } = await FactoryProvider.PoscansProvider.putScan({
        packageID: packageId,
        scanID: scanId,
        file
      })
      this.action(() => {
        _.set(this.users, `${userIndex}.documentsUpload.${index}`, {
          ...this.users[userIndex]?.documentsUpload?.[index],
          fileUrl: url,
          fileName,
          fileSize,
          updatedAt
        })
      })
    } catch (e) {
      notifyError('Ошибка загрузки документа', prepareErrorMessage(e))
      throw e
    } finally {
      if (this.users[userIndex]?.documentsUpload?.[index]) {
        this.action(() => {
          _.set(this.users, `${userIndex}.documentsUpload.${index}.isProcessSave`, false)
        })
      }
    }
  }

  applyUsers = action(data => {
    if (!_.isEmpty(data?.users)) {
      this.users = _.map(data?.users, user => this.getUser(user))
    }
    if (_.isEmpty(this.users)) this.users = [generateUser()]
    this.users = _.map(this.users, user => {
      let points = _.filter(user?.points,
        point => _.findIndex(this.ctx.PointModel.finishedPoints, p => p.id === point) !== -1)
      if (_.isEmpty(points) && this.ctx.PointModel.finishedPoints.length === 1 && !user.id) {
        points = [_.get(this.ctx.PointModel.finishedPoints, '0.id')]
      }

      const isSave = points.length === user?.points?.length && !_.isEmpty(points)

      return {
        ...user,
        points,
        agreementUrl: isSave ? user?.agreementUrl : '',
        agreementDate: isSave ? user?.agreementDate : null,
        isNeedSave: !isSave
      }
    })
  })

  getUser = user => ({
    id: user?.id,
    tempId: user?.tempId ?? uuid(),
    lastName: user?.lastName,
    firstName: user?.firstName,
    patronymic: user?.patronymic,
    fullName: user?.fullName,
    passport: user?.passport,
    passportSeries: user?.passportSeries,
    passportNumber: user?.passportNumber,
    passportDate: user?.passportDate,
    passportCode: user?.passportCode,
    passportBy: user?.passportBy,
    birthPlace: user?.birthPlace,
    birthDate: user?.birthDate,
    addressRegistration: toJS(user?.addressRegistration),
    contactPhone: user?.contactPhone,
    email: user?.email,
    points: user?.points,

    packageId: user?.packageId,
    scanId: user?.scanId,
    agreementUrl: user?.agreementUrl,
    agreementDate: user?.agreementDate,
    documentsUpload: user?.documentsUpload,
    poscansPackageId: user?.poscansPackageId
  })

  getUsers = () => _.map(this.users, user => this.getUser(user))

  next = async () => {
    if (!this.isNext) {
      this.applyData({ isForceValidate: true })
      let notValidIndex = -1
      _.each(this.users, (user, index) => {
        if (!this.isValidUser(user) || this.isInvalidRequiredScans(user)) {
          notValidIndex = index
          return false
        }
      })
      if (notValidIndex !== -1) scrollTo(`${this.USER_BLOCK}-${notValidIndex}`)
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const { users } = await FactoryProvider.PartnerProvider.updatePropositionUsers(this.ctx.CommonModel.id)
        this.applyData({ users })
        await FactoryModel.SettingsModel.CommonModel.getSettings()
        this.ctx.CommonModel.setStep(3)
      } catch (e) {
        notifyError('Ошибка сохранения пользователей', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }
}

export { UsersModel }
