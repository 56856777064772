import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class DictModel extends BaseModel {
  @observable dict = {}
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  getDict = async () => {
    try {
      this.applyData({ isLoading: true })
      const dict = await FactoryProvider.DictProvider.getDict()
      this.applyData({ dict })
    } finally {
      this.applyData({ isLoading: false })
    }
  }
}

export { DictModel }
