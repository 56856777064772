const checkDigit = (inn, coefficients = []) => {
  let n = 0
  for (const i in coefficients) {
    n += coefficients[i] * inn[i]
  }
  return parseInt(n % 11 % 10)
}

export const validInn = (src, isEmpty = true) => {
  let inn = src
  if (inn === '' && !isEmpty) return null

  if (typeof inn === 'number') {
    inn = inn.toString()
  } else if (typeof inn !== 'string') {
    inn = ''
  }
  if (!inn.length) {
    return 'Не указан'
  } if (/\D/.test(inn)) {
    return 'Только цифры'
  } if ([10, 12].indexOf(inn.length) === -1) {
    return 'Только 10 или 12 цифр'
  }

  switch (inn.length) {
    case 10: {
      const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
      if (n10 === parseInt(inn[9])) {
        return null
      }
      break
    }
    case 12: {
      const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
      const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
      if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
        return null
      }
      break
    }
  }
  return 'ИНН некорректный'
}
