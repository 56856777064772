import _ from 'lodash'
import { isSafe, safeObject } from 'uikit'
import { calculateIntervalExpires } from 'utils/interval'
import BaseApiProvider from './baseApiProvider'
import { shortUserMap, userMap, userMapApi, userACGroupMapApi } from '../helpers/user'
import { profileMapApi } from '../helpers/profile'
import { codesMap, codesMapApi, codesHistoryMap } from '../helpers/codes'
import { accessRoleMap } from '../helpers/roles'

class UserProvider extends BaseApiProvider {
  async getUserList(params = {}) {
    const { page = 1, pageSize = 10, queryText = '', isActive = true, pointID } = params
    const data = await this.fetch({
      uri: '/users',
      query: safeObject({
        extended: true,
        query: queryText !== '' ? queryText : null,
        page,
        per_page: pageSize,
        point_id: pointID || null,
        active: isActive || null
      })
    })
    return _.chain(data)
      .map(user => shortUserMap({ user }))
      .filter(user => !!user.fullName || !!user.login)
      .value()
  }

  async lockUser(id, isLock, reason = '') {
    return await this.fetch(safeObject({
      uri: `/users/${id}/${isLock ? 'lock' : 'unlock'}`,
      method: 'PUT',
      query: reason !== '' ? { lock_reason: reason } : null
    }))
  }

  async searchRoles() {
    const data = await this.fetch({
      uri: '/roles'
    })
    return _.map(data, role => accessRoleMap({ role }))
  }

  async searchUsers(params = {}) {
    const {
      search = '',
      isPointManager = null,
      pointID = null,
      isActive
    } = params
    const data = await this.fetch({
      uri: '/users',
      query: safeObject({
        query: search !== '' ? search : null,
        as_point_manager: isPointManager,
        point_id: pointID || null,
        active: isActive || null
      })
    })
    return _.chain(data)
      .map(user => shortUserMap({ user }))
      .filter(user => !!user.fullName || !!user.login)
      .value()
  }

  async getUser(id) {
    const data = await this.fetch({
      uri: `/users/${id}`
    })
    return userMap({ user: data })
  }

  async createUser(user) {
    const data = await this.fetch({
      uri: '/users',
      method: 'POST',
      data: {
        user: userMapApi({ user })
      }
    })
    return data.id ?? data._id
  }

  async updateUser(user) {
    if (!isSafe(user.id)) return

    const data = await this.fetch({
      uri: `/users/${user.id}`,
      method: 'PUT',
      data: {
        user: userMapApi({ user })
      }
    })
    return userMap({ user: data })
  }

  async getCodes(id) {
    const data = await this.fetch({
      uri: `/users/${id}/codes`
    })
    return codesMap(data)
  }

  async updateCodes(id, codes, comment) {
    return await this.fetch({
      uri: `/users/${id}/codes`,
      method: 'PUT',
      data: {
        comment,
        peer_configs: codesMapApi({ codes })
      }
    })
  }

  async getEditHistory(id) {
    const data = await this.fetch({
      uri: `/users/${id}/codes_history`
    })
    return _.map(_.get(data, 'codes_history', []), history => codesHistoryMap({ history }))
  }

  async switchUser(id) {
    const data = await this.fetch({
      uri: `/switch_user/${id}`,
      method: 'POST'
    })
    return {
      token: data?.token ?? '',
      target: data?.target ?? '',
      isPointSelectionRequired: _.get(data, 'point_selection_required', false)
    }
  }

  async sendConfirmEmailCode(userId) {
    const data = await this.fetch({
      uri: `/users/${userId}/email_code`,
      method: 'POST'
    })
    return calculateIntervalExpires(data?.email_retry_available_at)
  }

  async confirmEmail(userId, code) {
    await this.fetch({
      uri: `/users/${userId}/confirm_email`,
      method: 'PATCH',
      query: {
        code
      }
    })
  }

  async changeEmail(userId, email) {
    await this.fetch({
      uri: `/users/${userId}/emails`,
      method: 'POST',
      query: { email }
    })
  }

  async saveProfile(profile) {
    return await this.fetch({
      uri: '/profile',
      method: 'PUT',
      data: profileMapApi({ profile })
    })
  }

  async getRevision() {
    return await this.fetch({
      uri: '/revision'
    })
  }

  async getReferUrl() {
    return await this.fetch({
      uri: '/profile/refer_code',
      method: 'POST'
    })
  }

  async updateACGroups(id, acGroups) {
    return await this.fetch({
      uri: `/users/${id}/agent_commission_groups`,
      method: 'PUT',
      data: {
        user: {
          agent_commission_groups: userACGroupMapApi({ acGroups })
        }
      }
    })
  }
}

export { UserProvider }
