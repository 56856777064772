import axios from 'axios'

class StorageProvider {
  async setToken({ value, isTemp = false, target = '', isPointSelectionRequired = false }) {
    try {
      if (!isTemp) this.token = value
      else this.tempToken = value
      const { data: { currentUri, expires } } = await axios({
        responseType: 'json',
        method: 'POST',
        url: '/f2e/token',
        data: {
          token: value,
          isTemp,
          target,
          isPointSelectionRequired
        }
      })
      this.expires = new Date(expires)
      return { currentUri, expires }
    } catch (e) {
      return ''
    }
  }

  async removeToken(params = {}) {
    const { isTemp = false, isAll = false } = params

    if (isAll) {
      this.token = ''
      this.tempToken = ''
    } else if (!isTemp) this.token = ''
    else this.tempToken = ''
    try {
      await axios({
        responseType: 'json',
        method: 'DELETE',
        url: '/f2e/token',
        params: {
          isTemp,
          isAll
        }
      })
    } catch (e) {}
  }

  setSessionItem = (key, value) => {
    sessionStorage.setItem(key, value)
  }
  getSessionItem = (key) => sessionStorage.getItem(key)
  removeSessionItem = (key) => {
    sessionStorage.removeItem(key)
  }
}

export { StorageProvider }
