import _ from 'lodash'
import { observable, computed, makeObservable } from 'mobx'
import { REG_NULL, SOLAR_STAFF_TYPE, VERIFIED_STATUS, NOT_VERIFIED_STATUS } from 'providers/helpers/agentReward'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel/baseModel'

class AgentRewardListModel extends BaseModel {
  @observable list = []
  @observable isLoading = false
  @observable status = null
  @observable type = null
  @observable balance = null
  @observable taxationTitle = null

  constructor() {
    super()
    makeObservable(this)
  }

  get = async () => {
    try {
      this.applyData({ isLoading: true })
      const rewards = await FactoryProvider.AgentRewardProvider.getAgentRewards()
      this.applyData({ list: rewards.calculations, status: rewards.status, type: rewards.type, balance: rewards.balance, taxationTitle: rewards.taxationTitle })
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  @computed get isExistList() {
    return this.list.length > 0
  }

  @computed get isSolarStaff() {
    return this.type == SOLAR_STAFF_TYPE
  }

  @computed get isRegNull() {
    return this.isSolarStaff && this.status == REG_NULL
  }

  @computed get isNotVerified() {
    return this.isSolarStaff && this.status == NOT_VERIFIED_STATUS
  }

  @computed get isVerified() {
    return this.isSolarStaff && this.status == VERIFIED_STATUS
  }

  @computed get isDisplayBalance() {
    return this.isSolarStaff && !this.isRegNull && !!this.balance
  }
}

export { AgentRewardListModel }
