import _ from 'lodash'
import BaseApiProvider from './baseApiProvider'
import { AgentRewardMap, AgentRewardDetailedMap } from '../helpers/agentReward'

class AgentRewardProvider extends BaseApiProvider {
  async getAgentRewards() {
    const data = await this.fetch({
      uri: '/agent_rewards'
    })
    return {
      status: data.status,
      type: data.type,
      balance: data.balance,
      taxationTitle: data.taxation_title,
      calculations: _.map(data.calculations, agentReward => AgentRewardMap({ agentReward }))
    }
  }

  async getAgentReward(month) {
    const data = await this.fetch({
      uri: `/agent_rewards/${month}`
    })
    return AgentRewardDetailedMap({ AgentRewardDetailed: data })
  }
}

export { AgentRewardProvider }
