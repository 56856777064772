import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import * as Sentry from '@sentry/node'
import FactoryModel from '../../models/factoryModel'

const ErrorMessage = 'Poscans websocket error'
class PoscansError extends Error {
  constructor() {
    super()
    this.name = ErrorMessage
    this.message = ErrorMessage

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorMessage)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

class PoscansWsProvider {
  uri = () => FactoryProvider.ConfigProvider.config.poscans.ws

  packages = {}
  reconnectCounter = 0

  subscribe = ({ packageID, onReceived, onError, version = 2 }) => {
    if (!onReceived || !packageID) return

    const isHost = /^ws(s?):\/\//i.test(this.uri())
    const connect = new WebSocket(`${this.uri() === '/' || !this.uri() ? `${/s/i.test(window.location.protocol) ? 'wss' : 'ws'}://${window.location.host}/scans-backend` :
      isHost ? this.uri() : `${/s/i.test(window.location.protocol) ? 'wss' : 'ws'}://${window.location.host}${this.uri()}`
    }/ws/v${version}/package/${packageID}`)
    connect.onerror = (e) => {
      console.log('[POSCANS WS] error event on package', packageID, e)
      connect.close()
      _.unset(this.packages, `${packageID}`)
      if (onError) onError()
      Sentry.withScope(scope => {
        scope.setTag('packageId', packageID)
        scope.setTag('version', version)
        scope.setExtra('packageId', packageID)
        scope.setExtra('version', version)
        scope.setExtra('currentUser', FactoryModel.SettingsModel.ProfileModel.currentUser())
        Sentry.captureEvent(new PoscansError())
      })
    }
    connect.onopen = () => {
      console.log('[POSCANS WS] opened package', packageID)
      this.reconnectCounter = 0
      _.set(this.packages, `${packageID}`, connect)
    }
    connect.onmessage = e => {
      try {
        const message = JSON.parse(e.data)
        onReceived(message)
      } catch (err) {
        if (onError) onError()
      }
    }
    connect.onclose = e => {
      console.log('[POSCANS WS] close event on package', packageID, e)
      if (this.timer) return
      if (this.reconnectCounter >= 5) {
        return
      }
      if (!this.packages[packageID]) return
      this.timer = setTimeout(() => {
        this.reconnectCounter++
        this.timer = undefined
        this.subscribe({ packageID, onReceived, onError })
      }, 10000)
    }
  }

  close = packageID => {
    const connect = _.get(this.packages, `${packageID}`)
    if (!connect) return

    connect.close()
    _.unset(this.packages, `${packageID}`)
  }

  closeAll = () => {
    _.each(this.packages, connect => {
      connect.close()
    })
    this.packages = {}
  }

  isExist = packageID => !!_.get(this.packages, `${packageID}`)
}

export { PoscansWsProvider }
