import _ from 'lodash'
import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { EMPTY_AG_ID, EMPTY_AG_NAME } from 'providers/helpers/agentCommissionGroup'
import BaseModel from '../baseModel'

class AgentCommissionGroupListModel extends BaseModel {
  @observable groups = []
  @observable isLoadingGroups = false

  constructor() {
    super()
    makeObservable(this)
  }

  getAgentCommissionGroups = async (withEmpty = false) => {
    if (!_.isEmpty(this.groups)) {
      const emptyIndex = _.findIndex(this.groups, group => group?.id === EMPTY_AG_ID)
      if (withEmpty && emptyIndex !== -1) return
      if (withEmpty && emptyIndex === -1) {
        this.action(() => this.groups.unshift({ id: EMPTY_AG_ID, name: EMPTY_AG_NAME }))
      } else if (!withEmpty && emptyIndex !== -1) {
        this.action(() => this.groups.splice(emptyIndex, 1))
      }
      return
    }

    try {
      this.applyData({ isLoadingGroups: true })
      const groups = await FactoryProvider.AgentCommissionGroupsProvider.getAgentCommissionGroups()

      if (withEmpty) groups.unshift({ id: EMPTY_AG_ID, name: EMPTY_AG_NAME })
      this.applyData({ groups, withEmpty })
    } finally {
      this.applyData({ isLoadingGroups: false })
    }
  }
}

export { AgentCommissionGroupListModel }
