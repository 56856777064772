import _ from 'lodash'

const callCenterMap = ({ callCenter, isShort = true }) => {
  let resolve = {
    id: callCenter.id ?? callCenter._id,
    name: _.get(callCenter, 'name', '')
  }

  if (!isShort) {
    resolve = {
      ...resolve,
      notificationRecipients: _.get(callCenter, 'notification_recipients', []),
      ownerCode: _.get(callCenter, 'owner_code', ''),
      contacts: _.get(callCenter, 'contacts', [])
    }
  }

  return resolve
}

export { callCenterMap }
