import { StorageProvider } from 'providers/storageProvider'
import { ConfigProvider } from 'providers/configProvider'
import { AuthProvider } from 'providers/api/authProvider'
import { QuestionaryProvider } from 'providers/api/questionaryProvider'
import { RefundProvider } from 'providers/api/refundProvider'
import { CreditRequestProvider } from 'providers/api/creditRequestProvider'
import { UserProvider } from 'providers/api/userProvider'
import { SettingsProvider } from 'providers/api/settingsProvider'
import { DictProvider } from 'providers/api/dictProvider'
import { RegionProvider } from 'providers/api/regionProvider'
import { CallCenterProvider } from 'providers/api/callCenterProvider'
import { PartnerProvider } from 'providers/api/partnerProvider'
import { PointProvider } from 'providers/api/pointProvider'
import { ServiceProvider } from 'providers/api/serviceProvider'
import { BankProvider } from 'providers/api/bankProvider'
import { RetailProvider } from 'providers/api/retailProvider'
import { DadataProvider } from 'providers/dadataProvider'
import { ActProvider } from 'providers/api/actProvider'
import { AgentCommissionGroupsProvider } from 'providers/api/agentCommissionGroupsProvider'
import { AgentRewardProvider } from 'providers/api/agentRewardProvider'
import { AccreditationProvider } from 'providers/api/accreditationProvider'
import { PoscansProvider } from 'providers/api/poscansProvider'
import { PickupProvider } from 'providers/api/pickupProvider'
import { TutorialProvider } from 'providers/api/tutorialProvider'
import { PostProvider } from 'providers/api/postProvider'
import { ContentProvider } from 'providers/api/contentProvider'
import { RequestCallProvider } from 'providers/api/requestCallProvider'
import { PartnerFormProvider } from 'providers/api/partnerFormProvider'
import { CreditOfferProvider } from 'providers/api/creditOfferProvider'
import { ActionCableProvider } from 'providers/actionCableProvider'
import { PoscansWsProvider } from 'providers/poscansWsProvider'
import { IdeaProvider } from 'providers/api/ideaProvider'
import { isServer } from 'utils/isomorphic'
import FactoryProvider from './factoryProvider'

export const factoryProviderInit = () => {
  const providers = {
    StorageProvider: new StorageProvider(),
    ConfigProvider: new ConfigProvider(),

    SettingsProvider: new SettingsProvider(),
    DictProvider: new DictProvider(),
    AuthProvider: new AuthProvider(),
    QuestionaryProvider: new QuestionaryProvider(),
    CreditRequestProvider: new CreditRequestProvider(),
    UserProvider: new UserProvider(),
    RegionProvider: new RegionProvider(),
    CallCenterProvider: new CallCenterProvider(),
    PartnerProvider: new PartnerProvider(),
    PointProvider: new PointProvider(),
    BankProvider: new BankProvider(),
    DadataProvider: new DadataProvider(),
    RetailProvider: new RetailProvider(),
    ServiceProvider: new ServiceProvider(),
    ActProvider: new ActProvider(),
    AgentCommissionGroupsProvider: new AgentCommissionGroupsProvider(),
    AgentRewardProvider: new AgentRewardProvider(),
    AccreditationProvider: new AccreditationProvider(),
    PoscansProvider: new PoscansProvider(),
    PickupProvider: new PickupProvider(),
    TutorialProvider: new TutorialProvider(),
    RefundProvider: new RefundProvider(),
    PostProvider: new PostProvider(),
    ContentProvider: new ContentProvider(),
    RequestCallProvider: new RequestCallProvider(),
    PartnerFormProvider: new PartnerFormProvider(),
    CreditOfferProvider: new CreditOfferProvider(),
    IdeaProvider: new IdeaProvider()
  }

  if (!isServer()) providers.ActionCableProvider = new ActionCableProvider()
  providers.PoscansWsProvider = new PoscansWsProvider()

  FactoryProvider.init(providers)
}
