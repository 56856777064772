import FactoryModel from 'models/factoryModel'

export default {
  onReceived: async ({ data }) => {
    switch (data?.event) {
      case 'email_confirmed': {
        if (FactoryModel?.SettingsModel?.ProfileModel?.id !== data?.id) return
        FactoryModel.SettingsModel.ProfileModel.setConfirmEmail(true)
        break
      }
    }
  }
}
