import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import { notifyError } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../../baseModel'

class BankAccountModel extends BaseModel {
  ctx = null

  @observable partnerId = null
  @observable pointId = null

  @observable bankAccounts = []
  @observable isLoadingBankAccounts = false

  @observable singleAccount = null

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.partnerId = null
    this.pointId = null

    this.bankAccounts = []
    this.isLoadingBankAccounts = false

    this.singleAccount = null
  })

  @computed get list() {
    return _.flow(
      l => _.filter(l, account => account.isActive),
      l => _.map(l, account => ({ ...account, pointCreatedAt: _.get(_.find(account?.points, point => point.id === this.pointId), 'createdAt', null) })),
      l => l.sort((a, b) => ((a.pointCreatedAt || new Date()) > (b.pointCreatedAt || new Date()) ? -1 : 1))
    )(this.bankAccounts)
  }

  @computed get isExist() {
    return !_.isEmpty(this.list) && !this.isLoadingBankAccounts
  }

  @computed get currentAccounts() {
    return _.flow(
      l => _.filter(l, account => this.isAccessAssignSinglePoint(account) || account.isAdded),
      l => _.map(l, account => ({ ...account, pointCreatedAt: _.get(_.find(account?.points, point => point.id === this.pointId), 'createdAt', null) })),
      l => l.sort((a, b) => ((a.pointCreatedAt || new Date()) > (b.pointCreatedAt || new Date()) ? -1 : 1))
    )(this.list)
  }

  @computed get isFillAccounts() {
    return this.currentAccounts.length === this.bankAccounts.length
  }

  isAccessAssignSinglePoint = account => _.find(account?.points, point => point.id === this.pointId)

  @computed get isExistAccountsAssignedCurrentPoint() {
    if (!this.ctx.CommonModel.id || this.ctx.CommonModel.isChangePartner) return this.isExist && !!this.singleAccount

    return this.isExist && _.reduce(this.bankAccounts, (s, account) => s + (this.isAccessAssignSinglePoint(account) ? 1 : 0), 0) > 0
  }

  checkBankAccount = (cb, index, id) => {
    if ((!id && this.bankAccounts[index] && !this.bankAccounts[index].id) ||
      (this.bankAccounts[index] && this.bankAccounts[index].id === id)) cb()
  }

  applyAccounts = action(data => {
    if (data?.bankAccounts) this.bankAccounts = data?.bankAccounts
  })

  applyParams = async (params = {}) => {
    const { partnerId, pointId } = params
    if (partnerId || pointId) this.clear()

    if (partnerId) this.applyData({ partnerId })
    if (pointId) this.applyData({ pointId })

    if (partnerId || pointId) {
      await this.getBankAccounts()
    }
  }

  setAccount = action(id => {
    if (!this.ctx.CommonModel.id || this.ctx.CommonModel.isChangePartner) {
      this.singleAccount = id
      return
    }

    const index = _.findIndex(this.bankAccounts, account => account.id === id)
    if (index === -1) return
    if (this.isAccessAssignSinglePoint(this.bankAccounts[index]) || this.bankAccounts[index].isAdded) return

    this.bankAccounts[index].isAdded = true
    this.assign(this.bankAccounts[index])
  })

  getBankAccounts = async () => {
    try {
      this.applyData({ isLoadingBankAccounts: true })
      const bankAccounts = await FactoryProvider.PartnerProvider.getPartnerBankAccounts(this.partnerId)
      this.applyData({ bankAccounts })
    } catch (e) {
      notifyError('Ошибка получения банковских счетов', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingBankAccounts: false })
    }
  }

  assign = async account => {
    const index = _.findIndex(this.bankAccounts, a => a.id === account.id)
    if (index === -1) return

    try {
      this.action(() => this.bankAccounts[index].isProcessSaveSinglePoint = true)

      if (!this.isAccessAssignSinglePoint(account)) {
        const resolve = await FactoryProvider.PartnerProvider.assignSinglePointBankAccount(this.partnerId, account.id, this.pointId)
        this.checkBankAccount(() => this.action(() => {
          this.bankAccounts[index] = resolve
        }), index, account.id)
      } else {
        await FactoryProvider.PartnerProvider.unAssignSinglePointBankAccount(this.partnerId, account.id, this.pointId)
        this.checkBankAccount(() => this.action(() => {
          const pointIndex = _.findIndex(this.bankAccounts[index]?.points, point => point.id === this.pointId)
          if (pointIndex === -1) return

          this.bankAccounts[index].points.splice(pointIndex, 1)
        }), index, account.id)
      }
    } catch (e) {
      notifyError(`Ошибка ${!this.isAccessAssignSinglePoint(account) ? 'отвязка' : 'привязки'} счёта`, prepareErrorMessage(e))
      throw e
    } finally {
      this.checkBankAccount(() => this.action(() => {
        this.bankAccounts[index].isProcessSaveSinglePoint = false
      }), index, account.id)
    }
  }
}

export { BankAccountModel }
