import { makeObservable } from 'mobx'
import BaseModel from '../baseModel'
import { CommonModel } from './commonModel'
import { OrganizationModel } from './organizationModel'
import { PointModel } from './pointModel'
import { UsersModel } from './usersModel'
import { AttachmentModel } from './attachmentModel'

class PartnerPropositionModel extends BaseModel {
  constructor() {
    super()

    const commonModel = new CommonModel()
    const organizationModel = new OrganizationModel()
    const pointModel = new PointModel()
    const usersModel = new UsersModel()
    const attachmentModel = new AttachmentModel()

    const ctx = {
      CommonModel: commonModel,
      OrganizationModel: organizationModel,
      PointModel: pointModel,
      UsersModel: usersModel,
      AttachmentModel: attachmentModel
    }

    commonModel.ctx = ctx
    organizationModel.ctx = ctx
    pointModel.ctx = ctx
    usersModel.ctx = ctx
    attachmentModel.ctx = ctx

    this.CommonModel = commonModel
    this.OrganizationModel = organizationModel
    this.PointModel = pointModel
    this.UsersModel = usersModel
    this.AttachmentModel = attachmentModel

    makeObservable(this)
  }
}

export { PartnerPropositionModel }
