import { makeObservable } from 'mobx'
import BaseModel from '../baseModel'
import { MainModel } from './mainModel'
import { AdditionalModel } from './additionalModel'
import { OtherModel } from './otherModel'
import { OrderModel } from './orderModel'
import { PickupModel } from './pickupModel'
import { CommonModel } from './commonModel'
import { CreditModel } from './creditModel'
import { PdAgreementModel } from './pdAgreementModel'
import { ConfirmPhoneModel } from './confirmPhoneModel'
import { CallCenterModel } from './callCenterModel'

class QuestionaryModel extends BaseModel {
  ctx = null

  constructor() {
    super()

    this.CommonModel = new CommonModel()
    this.MainModel = new MainModel()
    this.OrderModel = new OrderModel()
    this.PickupModel = new PickupModel()
    this.AdditionalModel = new AdditionalModel()
    this.OtherModel = new OtherModel()
    this.CreditModel = new CreditModel()
    this.PdAgreementModel = new PdAgreementModel()
    this.ConfirmPhoneModel = new ConfirmPhoneModel()
    this.CallCenterModel = new CallCenterModel()

    this.ctx = {
      CommonModel: this.CommonModel,
      MainModel: this.MainModel,
      OrderModel: this.OrderModel,
      PickupModel: this.PickupModel,
      AdditionalModel: this.AdditionalModel,
      OtherModel: this.OtherModel,
      CreditModel: this.CreditModel,
      PdAgreementModel: this.PdAgreementModel,
      ConfirmPhoneModel: this.ConfirmPhoneModel,
      CallCenterModel: this.CallCenterModel
    }

    this.CommonModel.ctx = this.ctx
    this.MainModel.ctx = this.ctx
    this.OrderModel.ctx = this.ctx
    this.PickupModel.ctx = this.ctx
    this.AdditionalModel.ctx = this.ctx
    this.OtherModel.ctx = this.ctx
    this.CreditModel.ctx = this.ctx
    this.PdAgreementModel.ctx = this.ctx
    this.ConfirmPhoneModel.ctx = this.ctx
    this.CallCenterModel.ctx = this.ctx

    makeObservable(this)
  }
}

export { QuestionaryModel }
