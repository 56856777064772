import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { push } from 'utils/history'
import BaseModel from '../baseModel'

class MenuModel extends BaseModel {
  @observable activeID = ''
  @observable subItemActive = {}

  constructor() {
    super()
    makeObservable(this)
  }

  setMenuItem = action((id, isFollowHistory = true) => {
    if (!id) return
    if (isFollowHistory) push({ uri: `/${id}` })
    this.activeID = id
  })

  setSubItemActive = action((subItemActive = {}) => {
    if (!subItemActive || _.isEmpty(subItemActive)) {
      const resolve = {}
      _.each(this.subItemActive, (v, key) => {
        resolve[key] = []
      })
      this.subItemActive = resolve
      return
    }

    this.subItemActive = {
      ...subItemActive
    }
  })
}

export { MenuModel }
