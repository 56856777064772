import _ from 'lodash'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import { validRequired, scrollTo, notifyError, CyrillicNameWithSpace } from 'uikit'
import { validationAddress } from 'utils/validators/address'
import { ORGANIZATION_INDIVIDUAL, ORGANIZATION_NOT_INDIVIDUAL } from 'providers/helpers/dadata'
import { prepareErrorMessage } from 'utils/error'
import FactoryProvider from 'providers/factoryProvider'
import { GROUNDS_TYPE_PROXY } from 'utils/dict'
import { ORGANIZATION_STEP } from 'providers/helpers/partnerProposition'
import { validRs } from 'utils/validators/bank'
import { v4 as uuid } from 'uuid'
import BaseModel from '../../baseModel'

class OrganizationModel extends BaseModel {
  ctx = null

  FIND_BLOCK = 'organizationData:find'
  ORGANIZATION_BLOCK = 'organizationData:organization'
  BANK_BLOCK = 'organizationData:bank'

  @observable isStartFind = false
  @observable organizationName = ''
  @observable organizationShortName = ''
  @observable organizationOpfFullName = ''
  @observable organizationOpfShortName = ''
  @observable inn = ''
  @observable kpp = ''
  @observable ogrn = ''
  @observable ogrnDate = null
  @observable address = null
  @observable managerFullName = ''
  @observable activityType = null
  @observable proxyNumber = ''
  @observable proxyDate = null
  @observable taxType = null
  @observable organizationType = ''
  @observable isForceValidate = false

  @observable bankAccounts = []

  @observable isProcessSave = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.isStartFind = false
    this.organizationName = ''
    this.organizationShortName = ''
    this.organizationOpfFullName = ''
    this.organizationOpfShortName = ''
    this.inn = ''
    this.kpp = ''
    this.ogrn = ''
    this.ogrnDate = null
    this.address = null
    this.managerFullName = ''
    this.activityType = null
    this.proxyNumber = ''
    this.proxyDate = ''
    this.taxType = null
    this.organizationType = false
    this.isForceValidate = false
    this.bankAccounts = []
  })

  @computed get isFinding() {
    return !!this.ctx.CommonModel.id || this.isStartFind
  }

  @computed get validOrganization() {
    if (validRequired(this.organizationName)) return validRequired(this.organizationName)
    if (this.organizationName.length < 2) return 'Длина меньше 2 символов'
    return null
  }

  @computed get validInn() {
    if (validRequired(this.inn)) return validRequired(this.inn)
    return null
  }

  @computed get validKpp() {
    if (!this.isNotIndividual) return null
    if (validRequired(this.kpp)) return validRequired(this.kpp)
    return null
  }

  @computed get validOgrn() {
    if (validRequired(this.ogrn)) return validRequired(this.ogrn)
    return null
  }

  @computed get validAddress() {
    if (validRequired(this.address)) return validRequired(this.address)
    if (validationAddress(this.address).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  @computed get validTaxType() {
    if (validRequired(this.taxType)) return validRequired(this.taxType)
    return null
  }

  @computed get validManager() {
    if (this.isIndividual) return null
    if (validRequired(this.managerFullName)) return validRequired(this.managerFullName)
    if (!CyrillicNameWithSpace.test(this.managerFullName)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validActivityType() {
    if (this.isIndividual) return null
    if (validRequired(this.activityType)) return validRequired(this.activityType)
    return null
  }

  @computed get isProxy() {
    return this.activityType === GROUNDS_TYPE_PROXY &&
      this.isNotIndividual
  }

  @computed get validProxyNumber() {
    if (!this.isProxy) return null
    if (validRequired(this.proxyNumber)) return validRequired(this.proxyNumber)
    return null
  }

  @computed get validProxyDate() {
    if (!this.isProxy) return null
    if (validRequired(this.proxyDate)) return validRequired(this.proxyDate)
    return null
  }

  @computed get isValidMain() {
    return !this.validInn && !this.validKpp && !this.validOgrn &&
        !this.validAddress && !this.validTaxType && !this.validManager &&
        !this.validActivityType && !this.validProxyNumber && !this.validProxyDate
  }

  @computed get isIndividual() {
    return this.organizationType === ORGANIZATION_INDIVIDUAL
  }

  @computed get isNotIndividual() {
    return this.organizationType === ORGANIZATION_NOT_INDIVIDUAL
  }

  @computed get isNext() {
    return !this.validOrganization && this.isValidMain && this.isValidBankAccounts
  }

  validBank = bank => {
    if (validRequired(bank)) return validRequired(bank)
    return null
  }

  validRs = (bank, value) => {
    const check = value
    const bik = bank?.bik
    const rs = `${check}`.replace(/_/ig, '')
    if (validRequired(rs)) return validRequired(rs)
    if (validRs(rs, bik)) return validRs(rs, bik)
    return null
  }

  @computed get isExistBankAccounts() {
    return !_.isEmpty(this.bankAccounts)
  }

  @computed get isValidBankAccounts() {
    return this.isExistBankAccounts &&
      _.reduce(this.bankAccounts, (s, account) => s + (
        this.validBank(account?.bank) || this.validRs(account?.bank, account?.rs) ? 1 : 0
      ), 0) === 0
  }

  setOrganization = action(organization => {
    this.organizationName = organization.name
    this.organizationShortName = organization.shortName
    this.organizationOpfFullName = organization.opfFullName
    this.organizationOpfShortName = organization.opfShortName
    this.inn = organization.inn
    this.kpp = organization.kpp
    this.ogrn = organization.ogrn
    this.ogrnDate = organization.ogrnDate
    if (this.isNotIndividual) this.address = organization.address
    else this.address = null
    this.managerFullName = organization.managerName
    this.organizationType = organization.organizationType
    this.isStartFind = true

    this.ctx.PointModel.setField('isInitPoints')(false)
  })

  addBankAccounts = action(() => {
    this.bankAccounts.push({ tempId: uuid(), bank: null, rs: '' })
  })

  removeBankAccounts = action(index => {
    this.bankAccounts.splice(index, 1)
  })

  setBank = action((bank, index) => {
    if (!bank?.bik) {
      this.bankAccounts[index].bank = null
      this.bankAccounts[index].isForceRsValidate = true
      return
    }

    if (!this.bankAccounts[index]?.bank) {
      this.bankAccounts[index].bank = {}
    }
    this.bankAccounts[index].bank.bik = bank.bik
    this.bankAccounts[index].bank.inn = bank.inn
    this.bankAccounts[index].bank.kpp = bank.kpp
    this.bankAccounts[index].bank.fullName = bank.fullName
    this.bankAccounts[index].bank.paymentName = bank.paymentName
    this.bankAccounts[index].bank.shortName = bank.shortName
    this.bankAccounts[index].bank.opf = bank.opf
    this.bankAccounts[index].isForceRsValidate = true
  })

  setBankRs = action((value, index) => {
    this.bankAccounts[index].rs = value
  })

  applyOrganizationData = action(data => {
    this.isStartFind = true
    this.organizationName = data.organizationName
    this.organizationShortName = data.organizationShortName
    this.organizationOpfFullName = data.organizationOpfFullName
    this.organizationOpfShortName = data.organizationOpfShortName
    this.inn = data.inn
    this.kpp = data.kpp
    this.ogrn = data.ogrn
    this.ogrnDate = data.ogrnDate
    this.address = data.address
    this.managerFullName = data.managerFullName
    this.activityType = data.activityType
    this.proxyNumber = data.proxyNumber
    this.proxyDate = data.proxyDate
    this.taxType = data.taxType
    this.bankAccounts = data?.bankAccounts
    this.organizationType = data.organizationType
    this.isForceValidate = true
  })

  getOrganization = () => ({
    organizationName: this.organizationName,
    organizationShortName: this.organizationShortName,
    organizationOpfFullName: this.organizationOpfFullName,
    organizationOpfShortName: this.organizationOpfShortName,
    inn: this.inn,
    kpp: this.kpp,
    ogrn: this.ogrn,
    ogrnDate: this.ogrnDate,
    address: toJS(this.address),
    managerFullName: this.managerFullName,
    activityType: this.activityType,
    proxyNumber: this.proxyNumber,
    proxyDate: this.proxyDate,
    taxType: this.taxType,
    bankAccounts: this.bankAccounts,
    organizationType: this.organizationType
  })

  next = async () => {
    if (!this.isNext) {
      this.applyData({ isForceValidate: true })
      if (this.validOrganization) scrollTo(this.FIND_BLOCK)
      else if (!this.isValidMain) scrollTo(this.ORGANIZATION_BLOCK)
      else if (!this.isValidBankAccounts) scrollTo(this.BANK_BLOCK)
    } else {
      try {
        this.applyData({ isProcessSave: true })
        if (!this.ctx.CommonModel.id) {
          const { id, bankAccounts } = await FactoryProvider.PartnerProvider.createProposition(this.getOrganization())
          this.applyData({ bankAccounts })
          this.ctx.CommonModel.setField('id')(id)
        } else {
          const { bankAccounts } = await FactoryProvider.PartnerProvider.updateProposition(this.ctx.CommonModel.id, this.getOrganization(), ORGANIZATION_STEP)
          this.applyData({ bankAccounts })
        }
        this.ctx.PointModel.applyPoints()

        this.ctx.CommonModel.setStep(1)
      } catch (e) {
        notifyError(`Ошибка ${!this.ctx.CommonModel.id ? 'создания' : 'обновления'} организации`, prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }
}

export { OrganizationModel }
