import _ from 'lodash'
import { observable, computed, action, toJS, makeObservable } from 'mobx'
import {
  validRequired,
  parseDate,
  moment,
  isEqualDates,
  DATE_FORMATS,
  DATE_FORMAT,
  notifyError,
  isSafe,
  scrollTo
} from 'uikit'
import { validationAddress } from 'utils/validators/address'
import deepMerge from 'deepmerge'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import { ADDITIONAL_STEP } from 'providers/helpers/questionary'
import { isValidNumber } from 'utils/validators/number'
import BaseModel from '../../baseModel'

class AdditionalModel extends BaseModel {
  ctx = null

  PASSPORT_BLOCK = 'additionalData:passport'
  RESIDENCE_BLOCK = 'additionalData:residence'
  PREV_PASSPORT_BLOCK = 'additionalData:prevPassport'

  @observable passportCode = ''
  @observable passportBy = ''
  @observable birthPlace = ''
  @observable addressRegistration = null
  @observable registrationDate = null

  @observable residenceAddress = null
  @observable residenceDate = null
  @observable isRepeatResidenceAddress = true

  @observable prevPassport = ''
  @observable prevPassportSeries = ''
  @observable prevPassportNumber = ''
  @observable isDisablePrevPassport = true

  @observable isForceValidate = false
  @observable isLoading = false
  @observable isProcessSave = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.passportCode = ''
    this.passportBy = ''
    this.birthPlace = ''
    this.addressRegistration = null
    this.registrationDate = null

    this.residenceAddress = null
    this.residenceDate = null
    this.isRepeatResidenceAddress = true

    this.prevPassport = ''
    this.prevPassportSeries = ''
    this.prevPassportNumber = ''
    this.isDisablePrevPassport = true

    this.isForceValidate = false
    this.isLoading = false
    this.isProcessSave = false
  })

  @computed get validPassportCode() {
    if (validRequired(this.passportCode)) return validRequired(this.passportCode)
    if (!isValidNumber(this.passportCode) || this.passportCode.length < 6) return 'Неверный код'

    return null
  }

  @computed get validPassportBy() {
    if (validRequired(this.passportBy)) return validRequired(this.passportBy)
    if (!/^[\d\s"(),.А-я№\-]+$/i.test(this.passportBy)) return 'Некорректный формат'
    return null
  }

  @computed get validBirthPlace() {
    if (validRequired(this.birthPlace)) return validRequired(this.birthPlace)
    if (!/^[\d\s"(),.А-яё\-]+$/i.test(this.birthPlace)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validAddressRegistration() {
    if (validRequired(this.addressRegistration)) return validRequired(this.addressRegistration)
    if (validationAddress(this.addressRegistration).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  @computed get validRegistrationDate() {
    const birthDate = this.ctx.MainModel.birthDate

    if (validRequired(this.registrationDate)) return validRequired(this.registrationDate)
    if (!parseDate(this.registrationDate).isBefore(moment())) return 'Больше текущей даты'
    if (birthDate && validRequired(birthDate)) return 'Не указана дата рождения'
    if (birthDate && parseDate(this.registrationDate).isBefore(birthDate) && !isEqualDates(this.registrationDate, birthDate)) return 'Меньше даты рождения'
    return null
  }

  @computed get validResidenceAddress() {
    if (validRequired(this.residenceAddress)) return validRequired(this.residenceAddress)
    if (validationAddress(this.residenceAddress).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  @computed get validResidenceDate() {
    const birthDate = this.ctx.MainModel.birthDate

    if (validRequired(this.residenceDate)) return validRequired(this.residenceDate)
    if (!parseDate(this.residenceDate).isBefore(moment())) return 'Больше текущей даты'
    if (birthDate && validRequired(birthDate)) return 'Не указана дата рождения'
    if (birthDate && parseDate(this.residenceDate).isBefore(birthDate) && !isEqualDates(this.residenceDate, birthDate)) return 'Меньше даты рождения'
    return null
  }

  @computed get validPrevPassportSeries() {
    if (this.isDisablePrevPassport) return null
    if (validRequired(this.prevPassportSeries)) return 'Серия обязательна'
    if (this.prevPassportSeries.length < 4) return 'Некорректная серия'
    return null
  }

  @computed get validPrevPassportNumber() {
    if (this.isDisablePrevPassport) return null
    if (validRequired(this.prevPassportNumber)) return 'Номер обязателен'
    if (this.prevPassportNumber.length < 6) return 'Некорректный номер'
    return null
  }

  @computed get isValidPassport() {
    return !this.validPassportCode && !this.validPassportBy &&
      !this.validBirthPlace && !this.validAddressRegistration && !this.validRegistrationDate
  }

  @computed get isValidResidence() {
    return !this.validResidenceAddress && !this.validResidenceDate
  }

  @computed get isValidPrevPassport() {
    return !this.validPrevPassportSeries && !this.validPrevPassportNumber
  }

  @computed get isNext() {
    return this.isValidPassport && this.isValidResidence && this.isValidPrevPassport
  }

  @computed get isStartFilling() {
    return this.passportCode !== '' || this.passportBy !== '' ||
      this.birthPlace !== '' || isSafe(this.addressRegistration) ||
      isSafe(this.registrationDate) || isSafe(this.residenceAddress) ||
      isSafe(this.residenceDate) || this.prevPassportSeries !== '' ||
      this.prevPassportNumber !== ''
  }

  setRegistrationAddress = action(address => {
    this.addressRegistration = address
    if (this.isRepeatResidenceAddress) this.residenceAddress = address ? deepMerge({}, toJS(address)) : null
  })

  setRegistrationDate = action(date => {
    this.registrationDate = date
    if (this.isRepeatResidenceAddress) this.residenceDate = date
  })

  setIsRepeatResidenceAddress = action(v => {
    this.isRepeatResidenceAddress = v
    if (v) {
      this.residenceAddress = this.addressRegistration ? deepMerge({}, toJS(this.addressRegistration)) : null
      this.residenceDate = this.registrationDate ? new Date(this.registrationDate) : null
    }
  })

  setPrevPassport = async v => {
    const series = v.substring(0, 4)
    const number = v.substring(4)
    this.applyData({
      prevPassportSeries: series,
      prevPassportNumber: number,
      prevPassport: v
    })
  }

  disablePrevPassport = action(v => {
    this.isDisablePrevPassport = v

    if (v) {
      this.prevPassport = ''
      this.prevPassportSeries = ''
      this.prevPassportNumber = ''
    }
  })

  getFields = () => [
    'passportCode',
    'passportBy',
    'birthPlace',
    'addressRegistration',
    'registrationDate',

    'residenceAddress',
    'residenceDate',

    'prevPassport',
    'prevPassportSeries',
    'prevPassportNumber'
  ]

  get = (questionary = null) => {
    const resolve = {}
    _.each(this.getFields(), field => {
      resolve[field] = questionary ? questionary[field] : this[field]
    })
    return resolve
  }

  applyAdditionalData = questionary => {
    const data = this.get(questionary)
    if (questionary.birthPlace &&
      !questionary?.prevPassportSeries && !questionary?.prevPassportNumber) {
      data.isDisablePrevPassport = true
    }
    if (data.addressRegistration &&
      data.addressRegistration?.kladrID === data.residenceAddress?.kladrID &&
      moment(data.registrationDate, DATE_FORMATS).format(DATE_FORMAT) === moment(data.residenceDate, DATE_FORMATS).format(DATE_FORMAT)) {
      data.isRepeatResidenceAddress = true
    }
    this.applyData(data)
  }

  next = async () => {
    if (this.ctx.CommonModel.isMetaStep()) return

    if (!this.isNext) {
      this.applyData({ isForceValidate: true })

      if (!this.isValidPassport) scrollTo(this.PASSPORT_BLOCK)
      else if (!this.isValidResidence) scrollTo(this.RESIDENCE_BLOCK)
      else if (!this.isValidPrevPassport) scrollTo(this.PREV_PASSPORT_BLOCK)
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const data = await FactoryProvider.QuestionaryProvider.updateQuestionary(this.ctx.CommonModel.id, {
          ...this.get(),
          passportSeries: this.ctx.MainModel.passportSeries,
          passportNumber: this.ctx.MainModel.passportNumber,
          passportDate: this.ctx.MainModel.passportDate
        }, ADDITIONAL_STEP)

        this.ctx.CommonModel.applyDataAfterUpdate(data)
        this.ctx.OtherModel.applyOptions(data.options)
        this.ctx.OtherModel.initChange()
      } catch (e) {
        notifyError('Ошибка обновления дополнительных данных', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  back = () => {
    this.ctx.CommonModel.setStep(2)
  }
}

export { AdditionalModel }
