import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class ConfigModel extends BaseModel {
  @observable ciFrontend = null
  @observable ciBackend = null

  @observable isStaging = true

  constructor() {
    super()
    makeObservable(this)
  }

  initConfig = async () => {
    this.applyData({
      isStaging: /staging|rc|demo/i.test(window.location.origin)
    })

    if (!this.isStaging) return

    const frontCfg = FactoryProvider.ConfigProvider.config.ci || {}
    this.applyData({
      ciFrontend: {
        commit: frontCfg?.commit ?? '',
        branch: frontCfg?.branch ?? '',
        buildAuthor: frontCfg?.buildAuthor ?? '',
        buildDate: frontCfg?.buildDate ? new Date(frontCfg?.buildDate) : null
      }
    })

    const data = await FactoryProvider.UserProvider.getRevision()
    this.applyData({
      ciBackend: {
        commit: data?.commit ?? '',
        branch: data?.branch ?? '',
        buildAuthor: data?.build_author ?? '',
        buildDate: data?.build_date ? new Date(data?.build_date) : null
      }
    })
  }
}

export { ConfigModel }
