import _ from 'lodash'
import debounce from 'lodash/debounce'
import { push } from 'utils/history'
import { action, observable, computed, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { notifyError, isSafe, moment, DATE_FORMATS, scrollTo } from 'uikit'
import {
  PRODUCT_CONFIRMED_STATES,
  PRODUCT_CONFIRM_WAITING_STATES,
  PRODUCT_SES_CODE_WAITING_STATES,
  PRODUCT_SELECTED_STATES,
  PRODUCT_SELECT_WAIT_STATES,
  PRODUCT_PRE_POSITIVE_DECISION_STATES,
  PRODUCT_POSITIVE_DECISION_STATES,
  PRODUCT_CANCEL_STATES,
  PRODUCT_REJECT_STATES,
  PRODUCT_ERRORS_STATES,
  PRODUCT_WAITING_REGISTER_STATES,
  OFFER_ACTION_ATTACH_SCANS,
  OFFER_ACTION_SELECT,
  OFFER_ACTION_MARK_AS_SELECT,
  OFFER_ACTION_CONFIRM,
  OFFER_ACTION_SELECT_EXTERNAL,
  OFFER_ACTION_FALLBACK_TO_MANUAL_SIGN,
  OFFER_ACTION_CHECK_EXTERNAL,
  OFFER_ACTION_RETRY_REGISTRATION,
  OFFER_ENTER_SES_CODE,
  OFFER_RESEND_SES_CODE,
  REFUND_INIT_STATE,
  REFUND_REJECTED_STATE,
  REFUND_APPROVED_STATE,
  REFUND_REVIEWING_STATE,
  PRODUCT_STATES,
  CARD_STATES
} from 'providers/helpers/questionary'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../baseModel'

const REFUND_GROUP = 'refund'
const CREDIT_GROUP = 'credit'
const CREDIT_GROUP_WAIT = 'creditWait'
const SES_CODE_WAIT_GROUP = 'ses_code_waiting'
const SELECT_PRODUCT_GROUP = 'selectProduct'
const SELECT_PRODUCT_GROUP_WAIT = 'selectProductWait'
const CANCEL_PRODUCTS_GROUP = 'cancelProducts'
const PRE_POSITIVE_PRODUCTS_GROUP = 'prePositiveProducts'
const POSITIVE_PRODUCTS_GROUP = 'positiveProducts'
const REGISTRATION_ERRORS_GROUP = 'registrationErrorsProducts'
const REGISTRATION_WAIT_GROUP = 'registrationWaitProducts'
const CARD_GROUP = 'cardProducts'
const REJECT_GROUP = 'rejectProducts'

const PRODUCT_START = 'product-start'
const CARD_START = 'product-start'

class QuestionaryDashboardModel extends BaseModel {
  constructor() {
    super([])
    this.updatePostProducts = debounce(this.getPostProducts, 350)
    makeObservable(this)
  }

  REFUND_GROUP = REFUND_GROUP
  CREDIT_GROUP = CREDIT_GROUP
  CREDIT_GROUP_WAIT = CREDIT_GROUP_WAIT
  SES_CODE_WAIT_GROUP = SES_CODE_WAIT_GROUP
  SELECT_PRODUCT_GROUP = SELECT_PRODUCT_GROUP
  SELECT_PRODUCT_GROUP_WAIT = SELECT_PRODUCT_GROUP_WAIT
  CANCEL_PRODUCTS_GROUP = CANCEL_PRODUCTS_GROUP
  PRE_POSITIVE_PRODUCTS_GROUP = PRE_POSITIVE_PRODUCTS_GROUP
  POSITIVE_PRODUCTS_GROUP = POSITIVE_PRODUCTS_GROUP
  REGISTRATION_ERRORS_GROUP = REGISTRATION_ERRORS_GROUP
  REGISTRATION_WAIT_GROUP = REGISTRATION_WAIT_GROUP
  CARD_GROUP = CARD_GROUP
  REJECT_GROUP = REJECT_GROUP

  OFFER_ACTION_ATTACH_SCANS = OFFER_ACTION_ATTACH_SCANS
  OFFER_ACTION_SELECT = OFFER_ACTION_SELECT
  OFFER_ACTION_MARK_AS_SELECT = OFFER_ACTION_MARK_AS_SELECT
  OFFER_ACTION_CONFIRM = OFFER_ACTION_CONFIRM
  OFFER_ACTION_SELECT_EXTERNAL = OFFER_ACTION_SELECT_EXTERNAL
  OFFER_ACTION_FALLBACK_TO_MANUAL_SIGN = OFFER_ACTION_FALLBACK_TO_MANUAL_SIGN
  OFFER_ACTION_CHECK_EXTERNAL = OFFER_ACTION_CHECK_EXTERNAL
  OFFER_ACTION_RETRY_REGISTRATION = OFFER_ACTION_RETRY_REGISTRATION
  OFFER_ENTER_SES_CODE = OFFER_ENTER_SES_CODE
  OFFER_RESEND_SES_CODE = OFFER_RESEND_SES_CODE

  PRODUCT_START = PRODUCT_START
  CARD_START = CARD_START

  PRODUCT_STATES = PRODUCT_STATES
  CARD_STATES = CARD_STATES

  @observable id = ''
  @observable isLoading = false
  subId = null
  @observable isRejectQuestionaryProcess = false
  @observable isCompleteQuestionaryProcess = false

  @observable fullName = ''
  @observable pointName = ''
  @observable pointId = ''
  @observable operatorName = ''
  @observable operatorEmail = ''
  @observable ownerEmail = ''
  @observable ownerFullname = ''
  @observable ownerId = ''
  @observable retailName = ''
  @observable state = ''
  @observable createdDate = null
  @observable isAvailDiscount = false
  @observable orders = []
  @observable services = []

  @observable pickupAdditionalServices = []

  @observable payment = 0
  @observable initialPayment = 0
  @observable term = 0

  @observable isCanReject = false
  @observable isCanComplete = false

  @observable pdAgreement = {}
  @observable isLoadingPdAgreement = false
  @observable isUploadPdAgreement = false

  @observable refund = {}

  prepareProducts = {}
  isBlockAdded = false
  @observable products = []
  productsSubscribes = []

  @observable isCreateRefundProcess = false
  @observable isReviewRefundProcess = false
  @observable isCancelRefundProcess = false
  @observable isClonable = false

  @observable isVisibleBrief = true

  @observable isProcessSendDocuments = false

  clear = action(() => {
    if (this.subId) FactoryProvider.ActionCableProvider.removeSubscription(this.subId)
    this.subId = null
    this.id = ''
    this.isLoading = false
    this.isRejectQuestionaryProcess = false
    this.isCompleteQuestionaryProcess = false

    this.fullName = ''
    this.pointName = ''
    this.pointId = ''
    this.operatorName = ''
    this.operatorEmail = ''
    this.ownerEmail = ''
    this.ownerFullname = ''
    this.ownerId = ''
    this.retailName = ''
    this.state = ''
    this.createdDate = null
    this.isAvailDiscount = false

    this.payment = 0
    this.initialPayment = 0
    this.term = 0

    this.isCanReject = false
    this.isCanComplete = false
    this.isNotCompletedByCallCenter = false

    this.pdAgreement = {}
    this.isUploadPdAgreement = false

    this.refund = {}

    this.orders = []
    this.services = []
    this.pickupAdditionalServices = []

    this.prepareProducts = {}
    this.isBlockAdded = false
    FactoryProvider.PoscansWsProvider.closeAll()
    this.products = []
    this.productsSubscribes = []

    this.isCreateRefundProcess = false
    this.isReviewRefundProcess = false
    this.isCancelRefundProcess = false
    this.isClonable = false

    this.isVisibleBrief = true

    this.isProcessSendDocuments = false
  })

  @computed get isExistPdAgreement() {
    return !_.isEmpty(this.pdAgreement)
  }

  @computed get isExistRefund() {
    return !_.isEmpty(this.refund)
  }

  @computed get isExistProducts() {
    return !_.isEmpty(this.products)
  }

  @computed get credit() {
    return _.find(this.products, product => product.groupStatus === PRODUCT_CONFIRMED_STATES)
  }

  @computed get singleSelectedOffer() {
    const selected = _.filter(this.products, product => product.groupStatus === PRODUCT_SELECTED_STATES)
    if (selected.length == 1) return selected[0]
    return null
  }

  @computed get creditId() {
    return this.credit?.creditId
  }

  @computed get isExistCredit() {
    return !_.isEmpty(this.credit)
  }

  @computed get creditWait() {
    return _.filter(this.products, product => product.groupStatus === PRODUCT_CONFIRM_WAITING_STATES)
  }

  @computed get isExistCreditWait() {
    return !_.isEmpty(this.creditWait)
  }

  @computed get sesCodeWait() {
    return _.filter(this.products, product => product.groupStatus === PRODUCT_SES_CODE_WAITING_STATES)
  }

  @computed get isExistSesCodeWait() {
    return !_.isEmpty(this.sesCodeWait)
  }

  @computed get selectProduct() {
    return _.filter(this.products, product => product.groupStatus === PRODUCT_SELECTED_STATES)
  }

  @computed get isExistSelectProduct() {
    return !_.isEmpty(this.selectProduct)
  }

  @computed get selectProductWait() {
    return _.filter(this.products, product => product.groupStatus === PRODUCT_SELECT_WAIT_STATES)
  }

  @computed get isExistSelectProductWait() {
    return !_.isEmpty(this.selectProductWait)
  }

  @computed get positiveProducts() {
    return _.flow(
      l => _.filter(l, product => product.groupStatus === PRODUCT_POSITIVE_DECISION_STATES),
      l => _.map(l, (product, index) => ({
        ...product,
        isExpand: !isSafe(product.isExpand) ? index === 0 : !!product.isExpand
      }))
    )(this.products)
  }

  @computed get isExistPositiveProducts() {
    return !_.isEmpty(this.positiveProducts)
  }

  @computed get prePositiveProducts() {
    return _.flow(
      l => _.filter(l, product => product.groupStatus === PRODUCT_PRE_POSITIVE_DECISION_STATES),
      l => _.map(l, (product, index) => ({
        ...product,
        isExpand: !isSafe(product.isExpand) ? index === 0 : !!product.isExpand
      }))
    )(this.products)
  }

  @computed get isExistPrePositiveProducts() {
    return !_.isEmpty(this.prePositiveProducts)
  }

  @computed get cancelProducts() {
    return _.flow(
      l => _.filter(l, product => product.groupStatus === PRODUCT_CANCEL_STATES),
      l => _.map(l, (product, index) => ({
        ...product,
        isExpand: !isSafe(product.isExpand) ? index === 0 : !!product.isExpand
      }))
    )(this.products)
  }

  @computed get isExistCancelProducts() {
    return !_.isEmpty(this.cancelProducts)
  }

  @computed get rejectProducts() {
    return _.filter(this.products, product => PRODUCT_REJECT_STATES.includes(product.groupStatus))
  }

  @computed get isExistRejectProducts() {
    return !_.isEmpty(this.rejectProducts)
  }

  @computed get registrationErrorsProducts() {
    return _.filter(this.products, product => product.groupStatus === PRODUCT_ERRORS_STATES)
  }

  @computed get isExistRegistrationErrorsProducts() {
    return !_.isEmpty(this.registrationErrorsProducts)
  }

  @computed get registrationWaitProducts() {
    return _.filter(this.products, product => PRODUCT_WAITING_REGISTER_STATES.includes(product.groupStatus))
  }

  @computed get isExistRegistrationWaitProducts() {
    return !_.isEmpty(this.registrationWaitProducts)
  }

  @computed get cardProducts() {
    return _.filter(this.products, product => CARD_STATES.includes(product.groupStatus))
  }

  @computed get isExistCardProducts() {
    return !_.isEmpty(this.cardProducts)
  }

  @computed get unknownGroups() {
    const products = _.filter(this.products, p => _.findIndex([...this.PRODUCT_STATES, ...this.CARD_STATES], g => g === p.groupStatus) === -1)
    const groups = []
    products.forEach(product => {
      const groupIndex = _.findIndex(groups, g => g.groupStatus === product.groupStatus)
      if (groupIndex !== -1) groups[groupIndex].products.push(product)
      else {
        groups.push({
          groupStatus: product.groupStatus,
          groupStatusHumanName: product.groupStatusHumanName,
          products: [product]
        })
      }
    })
    return groups
  }

  @computed get isExistUnknownGroups() {
    return !_.isEmpty(this.unknownGroups)
  }

  @computed get productsOverview() {
    const resolve = []
    const unknown = _.map(this.unknownGroups, group => ({
      label: group.groupStatusHumanName,
      scrollTo: group.groupStatus,
      count: group.products.length
    }))

    if (this.isExistRefund) resolve.push({ label: 'Возврат', scrollTo: REFUND_GROUP })
    if (this.isExistCredit) resolve.push({ label: this.credit?.groupStatusHumanName, scrollTo: CREDIT_GROUP, count: 1 })
    if (this.isExistCreditWait) resolve.push({ label: this.creditWait?.[0]?.groupStatusHumanName, scrollTo: CREDIT_GROUP_WAIT, count: this.creditWait.length })
    if (this.isExistSesCodeWait) resolve.push({ label: this.sesCodeWait?.[0]?.groupStatusHumanName, scrollTo: SES_CODE_WAIT_GROUP, count: this.sesCodeWait.length })
    if (this.isExistSelectProduct) resolve.push({ label: this.selectProduct?.[0]?.groupStatusHumanName, scrollTo: SELECT_PRODUCT_GROUP, count: this.selectProduct.length })
    if (this.isExistSelectProductWait) resolve.push({ label: this.selectProductWait?.[0]?.groupStatusHumanName, scrollTo: SELECT_PRODUCT_GROUP_WAIT, count: this.selectProductWait.length })
    if (this.isExistPositiveProducts) resolve.push({ label: this.positiveProducts?.[0].groupStatusHumanName, scrollTo: POSITIVE_PRODUCTS_GROUP, count: this.positiveProducts.length })
    if (this.isExistPrePositiveProducts) resolve.push({ label: this.prePositiveProducts?.[0].groupStatusHumanName, scrollTo: PRE_POSITIVE_PRODUCTS_GROUP, count: this.prePositiveProducts.length })

    unknown.forEach(group => resolve.push(group))

    if (this.isExistCancelProducts) resolve.push({ label: this.cancelProducts?.[0].groupStatusHumanName, scrollTo: CANCEL_PRODUCTS_GROUP, count: this.cancelProducts.length })
    if (this.isExistRejectProducts) resolve.push({ label: this.rejectProducts?.[0].groupStatusHumanName, scrollTo: REJECT_GROUP, count: this.rejectProducts.length })
    if (this.isExistRegistrationErrorsProducts) resolve.push({ label: this.registrationErrorsProducts?.[0].groupStatusHumanName, scrollTo: REGISTRATION_ERRORS_GROUP, count: this.registrationErrorsProducts.length })
    if (this.isExistRegistrationWaitProducts) resolve.push({ label: this.registrationWaitProducts?.[0].groupStatusHumanName, scrollTo: REGISTRATION_WAIT_GROUP, count: this.registrationWaitProducts.length })
    if (this.isExistCardProducts) resolve.push({ label: this.cardProducts?.[0].groupStatusHumanName, scrollTo: CARD_GROUP, count: this.cardProducts.length })

    return resolve
  }

  @computed get isExistProductsOverview() {
    return !_.isEmpty(this.productsOverview)
  }

  sendDocuments = async (offerId, email) => {
    try {
      this.applyData({ isProcessSendDocuments: true })
      await FactoryProvider.QuestionaryProvider.sendDocuments(this.id, offerId, email)
    } catch (e) {
      notifyError('Ошибка отправки документов', prepareErrorMessage(e))
    } finally {
      this.applyData({ isProcessSendDocuments: false })
    }
  }

  isAttachScansOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_ATTACH_SCANS) !== -1
  }

  isSelectOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_SELECT) !== -1
  }

  isMarkAsSelectOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_MARK_AS_SELECT) !== -1
  }

  isConfirmOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_CONFIRM) !== -1
  }

  isEnterSesCodeAction(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ENTER_SES_CODE) !== -1
  }

  isResendSesCodeAction(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_RESEND_SES_CODE) !== -1
  }

  isSelectExternalOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_SELECT_EXTERNAL) !== -1
  }

  isFallbackToManualSignOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_FALLBACK_TO_MANUAL_SIGN) !== -1
  }

  isCheckExternalOffer(offer) {
    return _.findIndex(offer?.actions, a => a === this.OFFER_ACTION_CHECK_EXTERNAL) !== -1
  }

  isRetryRegistration(product) {
    return _.findIndex(product?.actions, a => a === this.OFFER_ACTION_RETRY_REGISTRATION) !== -1
  }

  applyFromHistory = async ({ id }) => {
    this.applyData({ id })
    if (!id) return
    await this.initQuestionary()

    this.subId = FactoryProvider.ActionCableProvider.addSubscription({
      channel: 'QuestionaryV2Channel',
      options: {
        questionary_id: id
      },
      meta: {
        id
      }
    })

    this.products.forEach(product => {
      this.subscribeProduct(product)
    })
  }

  applyCommonDashboard = action(data => {
    this.fullName = data.fullName
    this.pointName = data.pointName
    this.pointId = data.pointId
    this.operatorName = data.operatorName
    this.operatorEmail = data.operatorEmail
    this.ownerEmail = data.ownerEmail
    this.ownerId = data.ownerId
    this.ownerFullname = data.ownerFullname
    this.retailName = data.retailName
    this.state = data.state
    this.createdDate = data.createdDate
    this.isAvailDiscount = data.isAvailDiscount

    this.payment = data.payment
    this.initialPayment = data.initialPayment
    this.term = data.term

    this.isCanReject = data.isCanReject
    this.isCanComplete = data.isCanComplete
    this.isClonable = data.isClonable
    this.isNotCompletedByCallCenter = data.isNotCompletedByCallCenter

    if (data.pdAgreement?.packageId) this.pdAgreement = data.pdAgreement

    this.refund = data.refund

    this.orders = data.orders
    this.services = data.services

    this.pickupAdditionalServices = data.pickupAdditionalServices
  })

  applyDashboard = action(data => {
    this.applyCommonDashboard(data)
    this.products = data.products
    this.prepareProducts = _.groupBy(data.products, product => product.appId)
  })

  initQuestionary = async () => {
    if (!this.id) return

    try {
      this.applyData({ isLoading: true })
      await this.getDashboard()
    } catch (e) {
      notifyError('Ошибка получения заявки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  getDashboard = async () => {
    if (!this.id) return

    const data = await FactoryProvider.QuestionaryProvider.getQuestionaryDashboard(this.id)
    this.applyDashboard(data)
  }

  getDashboardCommon = async () => {
    if (!this.id) return

    const data = await FactoryProvider.QuestionaryProvider.getQuestionaryDashboardCommon(this.id)
    this.applyCommonDashboard(data)
  }

  rejectQuestionary = async () => {
    try {
      this.applyData({ isRejectQuestionaryProcess: true })
      await FactoryProvider.QuestionaryProvider.rejectQuestionary(this.id)
    } catch (e) {
      notifyError('Ошибка отмены заявки', prepareErrorMessage(e))
      throw e
    }
  }

  duplicateQuestionary = async () => {
    try {
      const newID = await FactoryProvider.QuestionaryProvider.duplicateQuestionary(this.id)
      push({ uri: '/questionaries/[action]', href: `/questionaries/${newID}` })
    } catch (e) {
      notifyError('Ошибка копирования заявки', prepareErrorMessage(e))
      throw e
    }
  }

  completeQuestionary = async () => {
    try {
      this.applyData({ isCompleteQuestionaryProcess: true })
      await FactoryProvider.QuestionaryProvider.completeQuestionary(this.id)
    } catch (e) {
      notifyError('Ошибка завершения заявки', prepareErrorMessage(e))
      throw e
    }
  }

  uploadPdAgreement = async file => {
    if (!isSafe(this.pdAgreement?.packageId) || !isSafe(this.pdAgreement?.scanId)) return

    try {
      this.applyData({ isUploadPdAgreement: true })
      const { url, updatedAt } = await FactoryProvider.PoscansProvider.putScan({
        packageID: this.pdAgreement.packageId,
        scanID: this.pdAgreement.scanId,
        file
      })
      this.action(() => {
        this.pdAgreement.url = url
        if (updatedAt) this.pdAgreement.uploadDate = moment(updatedAt, DATE_FORMATS).toDate()
      })

      await this.getDashboard()
    } catch (e) {
      notifyError('Ошибка загрузки соглашения', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isUploadPdAgreement: false })
    }
  }

  // REFUNDS BEGIN
  @computed get isRefundInit() {
    return this.refund?.state === REFUND_INIT_STATE
  }

  @computed get isRefundReviewing() {
    return this.refund?.state === REFUND_REVIEWING_STATE
  }

  @computed get isRefundApproved() {
    return this.refund?.state === REFUND_APPROVED_STATE
  }

  @computed get isRefundRejected() {
    return this.refund?.state === REFUND_REJECTED_STATE
  }

  @computed get isAllRefundScansUploaded() {
    return this.refund?.scans?.filter(s => s.url)?.length === this.refund?.scans?.length
  }

  @computed get isAnyRefundScanCanBeUploaded() {
    return this.refund?.scans?.filter(s => s.canUpload)?.length > 0
  }

  getRefund = async () => {
    if (!this.creditId) return

    const refund = await FactoryProvider.RefundProvider.getRefund(this.creditId)
    this.applyData({ refund })
  }

  createRefund = async () => {
    if (!this.creditId) return

    try {
      this.applyData({ isCreateRefundProcess: true })
      const refund = await FactoryProvider.RefundProvider.createRefund(this.creditId)
      this.applyData({ refund })
      scrollTo(REFUND_GROUP)
      this.action(() => this.credit.isAvailRefund = false)
    } catch (e) {
      notifyError('Ошибка создания возврата', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isCreateRefundProcess: false })
    }
  }

  uploadRefundScan = async ({ packageID, scanId, file, index }) => {
    if (!this.creditId) return
    if (!packageID || !scanId || !file) return

    try {
      this.action(() => this.refund.scans[index].isProcessSave = true)
      const { url, fileName, updatedAt } = await FactoryProvider.PoscansProvider.putScan({
        packageID,
        scanID: scanId,
        file
      })
      this.action(() => {
        this.refund.scans[index] = {
          ...this.refund.scans[index],
          fileName,
          updatedAt,
          url
        }
      })
    } catch (e) {
      notifyError('Ошибка загрузки документа для возврата', prepareErrorMessage(e))
      throw e
    } finally {
      this.action(() => this.refund.scans[index].isProcessSave = false)
    }
  }

  reviewRefund = async () => {
    if (!this.creditId) return
    if (!this.isAllRefundScansUploaded) return

    try {
      this.applyData({ isReviewRefundProcess: true })
      const refund = await FactoryProvider.RefundProvider.reviewRefund(this.creditId)
      this.applyData({ refund })
    } catch (e) {
      notifyError('Ошибка отправки возврата на рассмотрение', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isReviewRefundProcess: false })
    }
  }

  cancelRefund = async () => {
    if (!this.creditId) return

    try {
      this.applyData({ isCancelRefundProcess: true })
      await FactoryProvider.RefundProvider.cancelRefund(this.creditId)
      this.applyData({ refund: {} })
      this.action(() => this.credit.isAvailRefund = true)
    } catch (e) {
      notifyError('Ошибка отмены возврата', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isCancelRefundProcess: false })
    }
  }
  // REFUNDS END

  expandOffer = action((tempId, isExpand) => {
    const index = _.findIndex(this.products, product => product.tempId === tempId)
    if (index === -1) return
    this.products[index].isExpand = isExpand
  })

  attachScans = async offerId => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isAttachScansProcess = true
      })
      await FactoryProvider.QuestionaryProvider.attachScans(this.id, offerId)
    } catch (e) {
      notifyError('Ошибка отправки сканов', prepareErrorMessage(e))
      throw e
    }
  }

  selectOffer = async (offerId, options) => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isSelectProcess = true
      })
      await FactoryProvider.QuestionaryProvider.selectOffer(this.id, offerId, options)
    } catch (e) {
      notifyError('Ошибка выбора предложения', prepareErrorMessage(e))
      throw e
    }
  }

  confirmOffer = async (offerId, sesCode) => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isConfirmProcess = true
      })
      await FactoryProvider.QuestionaryProvider.confirmOffer(this.id, offerId, sesCode)
    } catch (e) {
      notifyError('Ошибка выдачи кредита', prepareErrorMessage(e))
      throw e
    }
  }

  resendSesCode = async offerId => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isResendSesCodeProcess = true
      })
      await FactoryProvider.QuestionaryProvider.requestSesCode(this.id, offerId)
    } catch (e) {
      notifyError('Ошибка запроса кода ПЭП', prepareErrorMessage(e))
      throw e
    }
  }

  markAsSelectOffer = async offerId => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isMarkAsSelectProcess = true
      })
      await FactoryProvider.QuestionaryProvider.markAsSelectOffer(this.id, offerId)
    } catch (e) {
      notifyError('Ошибка отметки выбранными', prepareErrorMessage(e))
      throw e
    }
  }

  selectExternalOffer = async offerId => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isSelectExternalProcess = true
      })
      await FactoryProvider.QuestionaryProvider.selectExternalOffer(this.id, offerId)
    } catch (e) {
      notifyError('Ошибка проверки в Siebel', prepareErrorMessage(e))
      throw e
    }
  }

  fallbackToManualSignOffer = async offerId => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isFallbackToManualSignProcess = true
      })
      await FactoryProvider.QuestionaryProvider.fallbackToManualSignOffer(this.id, offerId)
    } catch (e) {
      notifyError('Ошибка перехода на ручное подписание', prepareErrorMessage(e))
      throw e
    }
  }

  checkExternalOffer = async offerId => {
    const index = _.findIndex(this.products, product => product.id === offerId)
    try {
      this.action(() => {
        this.products[index].isCheckExternalProcess = true
      })
      await FactoryProvider.QuestionaryProvider.checkExternalOffer(this.id, offerId)
    } catch (e) {
      notifyError('Ошибка перехода на ручное подписание', prepareErrorMessage(e))
      throw e
    }
  }

  retryRegistration = async appId => {
    const index = _.findIndex(this.products, product => product.appId === appId)
    try {
      this.action(() => {
        this.products[index].isRetryRegistrationProcess = true
      })
      await FactoryProvider.QuestionaryProvider.retryRegistration(this.id, appId)
    } catch (e) {
      notifyError('Ошибка повторной отправки на регистрацию', prepareErrorMessage(e))
      throw e
    }
  }

  uploadOfferDocument = async (params = {}) => {
    const { packageId, scanId, offerId, file, index, traceId, appId } = params

    if (!packageId || !scanId || !offerId || !file) return

    const productIndex = _.findIndex(this.products, p => p.id === offerId)
    if (productIndex === -1) return

    const maxFileSize = this.products[productIndex]?.maxFilesSize[file.type]
    if (maxFileSize && file.size > maxFileSize * 1024 * 1024) {
      notifyError(`Максимальный размер - ${maxFileSize} МБ`)
      return
    }

    try {
      this.action(() => {
        _.set(this.products, `${productIndex}.documentsUpload.${index}.isProcessSave`, true)
      })
      const { url, fileName, fileSize, updatedAt } = await FactoryProvider.PoscansProvider.putScan({
        packageID: packageId,
        scanID: scanId,
        file,
        traceID: traceId
      })
      this.action(() => {
        _.set(this.products, `${productIndex}.documentsUpload.${index}`, {
          ...this.products[productIndex]?.documentsUpload?.[index],
          fileUrl: url,
          fileName,
          fileSize,
          updatedAt
        })
      })
    } catch (e) {
      notifyError('Ошибка загрузки документа', prepareErrorMessage(e))
      throw e
    } finally {
      if (this.products[productIndex]?.documentsUpload?.[index]) {
        this.action(() => {
          _.set(this.products, `${productIndex}.documentsUpload.${index}.isProcessSave`, false)
        })
      }
    }
  }

  getProducts = async appId => {
    if (!this.id) return

    try {
      const products = await FactoryProvider.QuestionaryProvider.getProducts(
        this.id,
        appId
      )

      this.action(() => {
        this.prepareProducts[appId] = products
      })

      this.updatePostProducts()
    } catch (e) {
      notifyError('Ошибка получения кредитного продукта', prepareErrorMessage(e))
      throw e
    } finally {
    }
  }

  getPostProducts = action(() => {
    _.forIn(this.prepareProducts, (appProducts, appId) => {
      const resolve = [..._.filter(this.products, product => product.appId !== appId), ...appProducts]
      this.products = resolve
      this.productsSubscribes.forEach(product => {
        if (_.findIndex(resolve, p => p.packageId === product.packageId) === -1) FactoryProvider.PoscansWsProvider.close(product.packageId)
      })
      this.productsSubscribes = _.filter(resolve, p => !!p.packageId)
      this.productsSubscribes.forEach(packageId => this.subscribeProduct(packageId))
    })
  })

  subscribeProduct = product => {
    if (!product.packageId || _.isEmpty(product.documentsUpload)) return
    if (FactoryProvider.PoscansWsProvider.isExist(product?.packageId)) return

    FactoryProvider.PoscansWsProvider.subscribe({
      packageID: product.packageId,
      onReceived: (_data) => {
        if (!this.id) return
        this.getProducts(product.appId)
      }
    })
  }
}

export { QuestionaryDashboardModel }
