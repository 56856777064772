import _ from 'lodash'
import FactoryModel from 'models/factoryModel'

export default {
  onReceived: async ({ data }) => {
    const event = _.get(data, 'event')
    const payload = _.get(data, 'payload')
    const scope = FactoryModel.CreditRequestListModel.scope
    if (event === 'created' && !!payload) {
      if (scope === 'my') {
        FactoryModel.CreditRequestListModel.increaseNumberOfNew()
      } else {
        await FactoryModel.CreditRequestListModel.getCreditRequestDetails(payload.id)
      }
    }
  }
}
