import MobileDetect from 'mobile-detect'
import { action, computed, makeObservable, observable } from 'mobx'
import { breakPoints } from 'styles'
import BaseModel from '../baseModel'

class ResponsiveModel extends BaseModel {
  constructor(width = 0) {
    super()
    this.width = width
    makeObservable(this)
  }

  @observable isPostInit = false
  @observable width = 0
  @observable isTouch = false

  postInit = () => {
    this.applyData({
      isPostInit: true
    })
    this.applyData({
      width: window.innerWidth,
      isTouch: this.calculateIsTouch()
    })
  }

  calculateIsTouch = () => {
    const userAgent = window?.navigator?.userAgent ||
      window?.navigator?.vendor || window?.navigator?.opera
    const detect = new MobileDetect(userAgent)
    let isTouch = false
    try {
      document.createEvent('TouchEvent')
      isTouch = true
    } catch (e) {}

    return !!(detect.mobile() || detect.phone() || detect.tablet() || isTouch)
  }

  setIsTouch = action((isTouch = false) => {
    this.isTouch = isTouch
  })

  setWidth = action(width => {
    this.width = width
    this.isTouch = this.calculateIsTouch()
  })

  @computed get isMobile() {
    return this.width < breakPoints.mobile
  }

  @computed get isTablet() {
    return this.width >= breakPoints.mobile && this.width < breakPoints.tablet
  }

  @computed get isTabletAndPhone() {
    return this.width < breakPoints.tablet
  }

  @computed get isBeforeDesktop() {
    return this.width < breakPoints.desktop
  }

  @computed get isDesktop() {
    return this.width >= breakPoints.tablet
  }

  @computed get isMiddleDesktop() {
    return this.width >= breakPoints.desktop &&
      this.width < breakPoints.largeDesktop
  }

  @computed get isLargeDesktop() {
    return this.width >= breakPoints.largeDesktop
  }
}

export { ResponsiveModel }
