import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import BaseModel from '../baseModel'

class TutorialModel extends BaseModel {
  @observable videoLink = ''
  @observable tutorialLink = ''
  @observable additionalProductsLink = ''
  @observable refundPolicyLink = ''

  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  setMenuItem = async () => {
    FactoryModel.MenuModel.setMenuItem('tutorial')
  }

  getEducationMaterials = async () => {
    try {
      this.applyData({ isLoading: true })
      const data = await FactoryProvider.TutorialProvider.getEducationMaterials()
      this.applyData(data)
    } finally {
      this.applyData({ isLoading: false })
    }
  }
}

export { TutorialModel }
