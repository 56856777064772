import { computed, makeObservable, observable } from 'mobx'
import { isSafe, safeObject, scrollToTop } from 'uikit'
import _ from 'lodash'
import {
  ORGANIZATION_STEP, POINTS_STEP, USERS_STEP, ATTACHMENT_STEP,
  PROPOSITION_ARCHIVE_STATE, PROPOSITION_SIGN_STATE
} from 'providers/helpers/partnerProposition'
import { getHistoryQuery, push } from 'utils/history'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../../baseModel'

class CommonModel extends BaseModel {
  ctx = null

  @observable step = 0
  @observable state = ''
  @observable id = ''
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isFilling() {
    return !this.state ||
      _.findIndex([PROPOSITION_ARCHIVE_STATE, PROPOSITION_SIGN_STATE], s => s === this.state) === -1
  }

  @computed get isSigned() {
    return this.state === PROPOSITION_SIGN_STATE
  }

  @computed get isRightInfo() {
    if (this.isLoading) return false
    return this.step !== 0 || !this.isFilling
  }

  clear = () => {
    this.ctx.OrganizationModel.clear()
    this.ctx.PointModel.clear()
    this.ctx.UsersModel.clear()
    this.ctx.AttachmentModel.clear()
    this.action(() => {
      this.step = 0
      this.id = ''
      this.state = ''
      this.isLoading = false
    })
  }

  stepToNameStep = step => {
    switch (step) {
      case 0: return ORGANIZATION_STEP
      case 1: return POINTS_STEP
      case 2: return USERS_STEP
      case 3: return ATTACHMENT_STEP
    }
    return ORGANIZATION_STEP
  }

  nameStepToStep = step => {
    switch (step) {
      case ORGANIZATION_STEP: return 0
      case POINTS_STEP: return 1
      case USERS_STEP: return 2
      case ATTACHMENT_STEP: return 3
    }
    return 0
  }

  setStep = step => {
    if (!isSafe(step)) return

    const currentStep = _.isNumber(step) ? step : this.nameStepToStep(step)

    const isFollow = currentStep !== this.step
    this.applyData({ step: currentStep })
    if (isFollow) this.applyParamsToHistory()
    scrollToTop()
  }

  applyParamsToHistory = () => {
    push({
      uri: '/partner/proposition/[action]',
      href: `/partner/proposition/${this.id ? this.id : 'new'}`,
      query: safeObject({
        step: this.stepToNameStep(this.step),
        state: this.state
      })
    })
    scrollToTop()
  }

  applyParamsFromHistory = async (id = '') => {
    const { step, state } = getHistoryQuery()
    const resolveStep = this.nameStepToStep(step)
    if (state) this.applyData({ state })

    if (id && id !== this.id) {
      this.applyData({ id })
      this.setStep(resolveStep)
      await this.initProposition(id)
    } else if (this.id) {
      if (this.step > resolveStep) this.setStep(resolveStep)
    }
  }

  initProposition = async id => {
    try {
      this.applyData({ isLoading: true })
      const data = await FactoryProvider.PartnerProvider.getProposition(id)
      this.ctx.OrganizationModel.applyOrganizationData(data)
      this.ctx.PointModel.applyPoints(data)
      this.ctx.UsersModel.applyUsers(data)
      this.ctx.AttachmentModel.applyAttachmentData(data)
      if (this.step > this.nameStepToStep(data?.step)) this.setStep(data?.step)
      if (data.state !== this.state) {
        this.applyData({ state: data.state })
        this.applyParamsToHistory()
      }
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  next = async () => {
    switch (this.step) {
      case 0:
        await this.ctx.OrganizationModel.next()
        break
      case 1:
        await this.ctx.PointModel.next()
        break
      case 2:
        await this.ctx.UsersModel.next()
        break
      case 3:
        await this.ctx.AttachmentModel.next()
        this.applyParamsToHistory()
        break
    }
  }

  back = async () => {
    switch (this.step) {
      case 1:
        this.setStep(0)
        break
      case 2:
        this.setStep(1)
        break
      case 3:
        this.setStep(2)
        break
      case 4:
        this.setStep(3)
        break
    }
  }
}

export { CommonModel }
