import { action, observable, computed, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import _ from 'lodash'
import { isSafe, notifyError, safeObject } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import { scanMap } from 'providers/helpers/poscans'
import BaseModel from '../../baseModel'

class PdAgreementModel extends BaseModel {
  ctx = null

  @observable packageId = null
  @observable scanId = null
  @observable url = ''
  @observable qrCode = ''
  @observable isLoadingScan = true
  @observable isUpload = false
  @observable isUploadCamera = false
  @observable isProcessSave = false

  @computed get isNext() {
    return !!this.url
  }

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    if (this.packageId) FactoryProvider.PoscansWsProvider.close(this.packageId)

    this.packageId = null
    this.scanId = null
    this.qrCode = ''
    this.isLoadingScan = true
    this.isUpload = false
    this.isUploadCamera = false
    this.isProcessSave = false

    this.photoUrl = ''
  })

  applyPdData = async ({ packageId, qrCode }) => {
    if (!packageId || this.packageId) return

    this.action(() => {
      this.packageId = packageId
      this.qrCode = qrCode
    })
  }

  initPdData = async () => {
    if (this.scanId) return
    try {
      this.applyData({ isLoadingScan: true })
      const scan = await FactoryProvider.PoscansProvider.getBorrowerPdAgreementScan(this.packageId)
      this.applyData({
        url: _.get(scan, 'url', ''),
        scanId: _.get(scan, 'id', null)
      })
    } catch (e) {
      notifyError('Ошибка получения информации о пакете согласия', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingScan: false })
    }

    FactoryProvider.PoscansWsProvider.subscribe({
      packageID: this.packageId,
      onReceived: this.handleGetPoscansScan
    })
  }

  handleGetPoscansScan = source => {
    switch (source?.Action) {
      case 'Upload': {
        if (!source?.Payload) break

        const scan = scanMap({ scan: source?.Payload })
        this.applyData({
          url: scan.url,
          scanId: scan.id
        })
      }
    }
  }

  uploadPhoto = async ({ file, metadata = null, isCamera = false }) => {
    if (!isSafe(this.packageId) || !isSafe(this.scanId)) return

    try {
      this.applyData({ [!isCamera ? 'isUpload' : 'isUploadCamera']: true })
      const { url } = await FactoryProvider.PoscansProvider.putScan(safeObject({
        packageID: this.packageId,
        scanID: this.scanId,
        file,
        metadata
      }))
      this.applyData({ url })
    } catch (e) {
      notifyError('Ошибка загрузки соглашения', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ [!isCamera ? 'isUpload' : 'isUploadCamera']: false })
    }
  }

  next = async () => {
    try {
      this.applyData({ isProcessSave: true })
      const { step, ...data } = await FactoryProvider.QuestionaryProvider.updateQuestionary(
        this.ctx.CommonModel.id, {}, this.ctx.CommonModel.stepToNameStep(this.ctx.CommonModel.step), false
      )
      this.ctx.CommonModel.setStep(step, data)
    } finally {
      this.applyData({ isProcessSave: false })
    }
  }
}

export { PdAgreementModel }
