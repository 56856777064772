import _ from 'lodash'
import BaseApiProvider from './baseApiProvider'
import { callCenterMap } from '../helpers/callCenter'

class CallCenterProvider extends BaseApiProvider {
  async searchCallCenters() {
    const data = await this.fetch({
      uri: '/call_centers'
    })
    return _.map(data, callCenter => callCenterMap({ callCenter }))
  }
}

export { CallCenterProvider }
