import axios from 'axios'
import _ from 'lodash'
import queryString from 'query-string'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { safeObject } from 'uikit'

class ApiError extends Error {
  name = 'API error'
  constructor({ message, request, response, token, currentUser }) {
    super()

    this.name = message
    this.message = message
    this.request = request
    if (response) this.response = response
    if (token) this.token = token
    if (currentUser) this.currentUser = currentUser

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

class BaseProvider {
  baseHost = ''

  async execute({
    uri,
    method = 'GET',
    data = {},
    query = {},
    headers = {},
    isFormData = false,
    isMultiPartData = false,
    withToken = '',
    skipAuthorize = false,
    responseType = 'json',
    isFullResponse = false
  }, host = this.baseHost) {
    const resolveHost = host || `${FactoryProvider.ConfigProvider.config.apiUri}/api/v2`

    const token = withToken || FactoryModel.AuthModel.CommonModel.token
    const contentType = headers['Content-Type'] || (isFormData ? 'application/x-www-form-urlencoded' :
      isMultiPartData ? 'multipart/form-data' : 'application/json')

    let resolveData = {}
    if (isMultiPartData) {
      resolveData = new FormData()
      _.each(data, (value, key) => {
        resolveData.append(key, value)
      })
    } else if (isFormData) {
      resolveData = queryString.stringify(data)
    } else {
      resolveData = data
    }

    const prepareRequest = safeObject({
      method,
      url: `${resolveHost}${uri}`,
      data: method === 'POST' || method === 'PUT' || method === 'PATCH' ? resolveData : null,
      responseType,
      params: !_.isEmpty(query) ? query : null,
      withCredentials: true,
      headers: safeObject({
        'Content-Type': contentType,
        Authorization: (!skipAuthorize && token) ? `Bearer ${token}` : null,
        ...headers
      })
    })

    try {
      const res = await axios(prepareRequest)
      return isFullResponse ? res : res.data
    } catch (err) {
      let resolveErrorMessage = ''
      if (!err?.response?.status) resolveErrorMessage = 'Long wait response from API'
      else resolveErrorMessage += `API Error with status ${err?.response?.status} [METHOD: ${prepareRequest.method}]`
      throw new ApiError({
        message: resolveErrorMessage,
        request: prepareRequest,
        response: err?.response,
        token,
        currentUser: FactoryModel.SettingsModel.ProfileModel.currentUser()
      })
    }
  }
}

export default BaseProvider
