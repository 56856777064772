import _ from 'lodash'
import { isSafe } from 'uikit'
import FactoryModel from 'models/factoryModel'
import { shortApplicationMap } from 'providers/helpers/questionary'
import * as Sentry from '@sentry/node'

const NonPersistenceErrorMessage = 'Non-persistence questionary dashboard event'
class NonPersistenceError extends Error {
  name = NonPersistenceErrorMessage
  constructor() {
    super()
    this.name = NonPersistenceErrorMessage
    this.message = NonPersistenceErrorMessage

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NonPersistenceError)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

const sendNotPersistenceError = (currentQuestionaryId, receivedQuestionaryId, event) => {
  Sentry.withScope(scope => {
    scope.setTag('currentQuestionaryId', currentQuestionaryId)
    scope.setExtra('currentQuestionaryId', currentQuestionaryId)
    scope.setTag('receivedQuestionaryId', receivedQuestionaryId)
    scope.setTag('event', event)
    scope.setExtra('receivedQuestionaryId', receivedQuestionaryId)
    scope.setExtra('currentUser', FactoryModel.SettingsModel.ProfileModel.currentUser())
    scope.setExtra('event', event)
    Sentry.captureEvent(new NonPersistenceError())
  })
}

export default {
  onReceived: async ({ data, meta }) => {
    if (data?.event === 'dashboard_preview_update') {
      const apps = _.map(data?.states, application => shortApplicationMap({ application }))
      FactoryModel.QuestionaryModel.CreditModel.applyApplications(apps)
      return
    }

    if (!FactoryModel.QuestionaryDashboardModel.id) return

    if (meta?.id !== FactoryModel.QuestionaryDashboardModel.id) {
      sendNotPersistenceError(FactoryModel.QuestionaryDashboardModel.id, meta?.id, data?.event ?? 'unknown event')
      return
    }

    switch (data?.event) {
      case 'bank_application_update': {
        const appId = data?.bank_application_id
        if (!isSafe(appId)) return

        FactoryModel.QuestionaryDashboardModel.getProducts(appId)
        break
      }

      case 'questionary_update': {
        FactoryModel.QuestionaryDashboardModel.getDashboard()
        break
      }

      case 'refund_update': {
        FactoryModel.QuestionaryDashboardModel.getRefund()
        break
      }

      case 'dashboard_common_update': {
        FactoryModel.QuestionaryDashboardModel.getDashboardCommon()
        break
      }
    }
  }
}
