import _ from 'lodash'
import { observable, action, computed, toJS, makeObservable } from 'mobx'
import { validRequired, scrollTo, notifyError, validUrl } from 'uikit'
import { validationAddress } from 'utils/validators/address'
import { v4 as uuid } from 'uuid'
import FactoryProvider from 'providers/factoryProvider'
import { POINTS_STEP } from 'providers/helpers/partnerProposition'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../../baseModel'

const generatePoint = () => ({
  id: null,
  tempId: uuid(),
  type: null,
  address: null,
  webAddress: '',
  bankAccountId: null
})

class PointModel extends BaseModel {
  ctx = null

  POINT_BLOCK = 'pointData:point'

  @observable points = [generatePoint()]
  @observable isInitPoints = false
  @observable segmentList = {}
  @observable originalSegmentList = []
  @observable isLoadingSegmentList = {}

  @observable isForceValidate = false
  @observable isProcessSave = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.points = [
      generatePoint()
    ]
    this.segmentList = {}
    this.originalSegmentList = []
    this.isLoadingSegmentList = {}

    this.isForceValidate = false
    this.isProcessSave = false
  })

  validPointPostAddress(address) {
    if (validRequired(address)) return validRequired(address)
    if (validationAddress(address).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }
  validPointWebAddress(address) {
    if (validRequired(address)) return validRequired(address)
    if (validUrl(`${address}`)) return validUrl(address)
    return null
  }

  validPointSegmentType(type) {
    if (validRequired(type)) return validRequired(type)
    return null
  }

  validBankAccount(id) {
    if (validRequired(id)) return validRequired(id)
    return null
  }

  @computed get isValidPoints() {
    return !_.isEmpty(this.points) && _.reduce(this.points, (s, r) => s + (
      this.validPointPostAddress(r.address) ||
        this.validPointSegmentType(r.type) ||
        this.validBankAccount(r.bankAccountId) ? 1 : 0
    ), 0) === 0
  }

  @computed get finishedPoints() {
    return _.filter(this.points, point => !!point.id)
  }

  @computed get isNext() {
    return this.isValidPoints
  }

  addPoint = action(() => {
    this.points.push(generatePoint())
  })

  setPoint = action((index, field, value) => {
    this.points[index][field] = value
  })

  removePoint = action((id, index) => {
    this.points.splice(index, 1)
    _.unset(this.segmentList, id)
    _.unset(this.isLoadingSegmentList, id)
  })

  getSegments = async (params = {}) => {
    const { search = '', isAll = true, tempId = null } = params
    if (isAll && !_.isEmpty(this.originalSegmentList)) return
    if (!isAll && !tempId) return

    try {
      if (isAll) this.applyData({ isLoadingSegments: true })
      else {
        this.action(() => {
          this.isLoadingSegmentList[tempId] = true
        })
      }

      const list = await FactoryProvider.PointProvider.searchPointSegments({ search })
      const resolve = _.map(list, l => ({ id: l.id, name: l.name }))

      if (isAll) this.applyData({ originalSegmentList: resolve })
      else {
        this.action(() => {
          this.segmentList[tempId] = resolve
        })
      }
    } finally {
      if (isAll) this.applyData({ isLoadingSegments: false })
      else {
        this.action(() => {
          this.isLoadingSegmentList[tempId] = false
        })
      }
    }
  }

  applyPoints = action(data => {
    if (!_.isEmpty(data?.points)) {
      this.points = _.map(data?.points, point => {
        const id = point?.id ?? null
        const tempId = uuid()
        if (point?.segment) this.segmentList[tempId] = [point?.segment]
        return {
          id,
          tempId,
          address: point.address,
          type: point?.segment?.id ?? null,
          webAddress: point.webAddress,
          bankAccountId: point?.bankAccountId ?? null
        }
      })
    }
    if (!_.isEmpty(this.points)) this.isInitPoints = true
    if (!this.isInitPoints) this.points = [generatePoint()]

    this.points = _.map(this.points, point => {
      if (_.findIndex(
        this.ctx.OrganizationModel.bankAccounts,
        account => account.id === point.bankAccountId) === -1
      ) {
        return {
          ...point,
          bankAccountId: this.ctx.OrganizationModel.bankAccounts?.length === 1 ?
            _.get(this.ctx.OrganizationModel.bankAccounts, '0.id', null) : null
        }
      }
      return point
    })
  })

  getPoints = () => _.map(this.points, point => ({
    id: point?.id,
    address: !this.validPointPostAddress(point?.address) ? toJS(point?.address) : null,
    type: point?.type,
    webAddress: !this.validPointWebAddress(point?.webAddress) ? point.webAddress : '',
    bankAccountId: point?.bankAccountId
  }))

  next = async () => {
    if (!this.isNext) {
      this.applyData({ isForceValidate: true })
      let notValidIndex = -1
      _.each(this.points, (point, index) => {
        if (this.validPointPostAddress(point.address) || this.validPointSegmentType(point.type)) {
          notValidIndex = index
          return false
        }
      })
      if (notValidIndex !== -1) scrollTo(`${this.POINT_BLOCK}-${notValidIndex}`)
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const { points } = await FactoryProvider.PartnerProvider.updateProposition(this.ctx.CommonModel.id, { points: this.getPoints() }, POINTS_STEP)

        this.action(() => {
          this.points = _.map(points, point => {
            if (point?.segment) this.segmentList[point?.tempId] = [point?.segment]
            return {
              ...point,
              type: point?.segment?.id
            }
          })
        })

        this.ctx.UsersModel.applyUsers()
        this.ctx.CommonModel.setStep(2)
      } catch (e) {
        notifyError('Ошибка обновления точек', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }
}

export { PointModel }
