import _ from 'lodash'
import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'
import { serviceMap } from '../helpers/service'

class ServiceProvider extends BaseApiProvider {
  async getAdditionalServices() {
    const data = await this.fetch({
      uri: '/services'
    })
    return _.map(data, service => serviceMap({ service }))
  }

  async searchServices(params = {}) {
    const { search = '' } = params
    const data = await this.fetch({
      uri: '/services',
      query: safeObject({
        query: search !== '' ? search : null
      })
    })
    return _.map(data, service => serviceMap({ service }))
  }
}

export { ServiceProvider }
