import _ from 'lodash'

export const creditProductMap = ({ product }) => {
  const name = _.get(product, 'name', '')
  const code = _.get(product, 'code', '')
  const promoCode = _.get(product, 'promo_code', '')
  const onlyInstallments = _.get(product, 'only_installments', '')

  return {
    id: _.get(product, 'id', null),
    name,
    code,
    promoCode,
    onlyInstallments,
    viewName: `(${code} ${promoCode ? `(${promoCode})` : ''}) ${name}`
  }
}

export const insuranceProductMap = ({ product }) => {
  const name = _.get(product, 'name', '')
  const code = _.get(product, 'code', '')

  return {
    id: _.get(product, 'id', null),
    name,
    code,
    promoCode: _.get(product, 'promo_code', ''),
    insurer: _.get(product, 'insurer', ''),
    viewName: `${product.name} ${product.insurer ? `(${product.insurer})` : ''}`
  }
}

const productGroupMap = ({ group }) => ({
  id: _.get(group, 'id', null),
  name: _.get(group, 'name', null),
  productIDs: _.get(group, 'product_ids', null)
})

export const bankProductsMap = ({ bankProducts }) => ({
  creditProducts: _.map(_.get(bankProducts, 'credit_products', []), (product) => creditProductMap({ product })),
  creditProductGroups: _.map(_.get(bankProducts, 'credit_product_groups', []), (group) => productGroupMap({ group })),
  installmentProductGroups: _.map(_.get(bankProducts, 'installment_product_groups', []), (group) => productGroupMap({ group })),
  insuranceProducts: _.map(_.get(bankProducts, 'insurance_products', []), (product) => insuranceProductMap({ product })),
  insuranceProductGroups: _.map(_.get(bankProducts, 'insurance_product_groups', []), (group) => productGroupMap({ group }))
})
