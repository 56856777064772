import _ from 'lodash'
import { makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel/baseModel'

class AccreditationModel extends BaseModel {
  @observable user = []
  @observable point = []
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  getData = async () => {
    try {
      this.applyData({ isLoading: true })
      const data = await FactoryProvider.AccreditationProvider.getData()
      this.applyData({
        point: _.get(data, 'point'),
        user: _.get(data, 'user')
      })
    } finally {
      this.applyData({ isLoading: false })
    }
  }
}

export { AccreditationModel }
