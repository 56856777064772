import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import NextLink from 'next/link'
import styled from 'styled-components'
import { Underline } from 'uikit'

const UILink = styled(forwardRef((props, ref) => (
  <a {...props} ref={ref}>{props.children}</a>
)))`
  color: ${props => props.color && `${props.theme.colors[props.color]} !important`};
`

const Link = ({
  isNext,
  isUnderline,
  className,
  style,
  uri,
  href,
  onClick,
  children,
  htmlID,
  target,
  color
}) => {
  const handleClick = () => {
    if (onClick) onClick()
  }

  const linkProps = {
    href: href || uri,
    onClick: handleClick,
    id: htmlID,
    className,
    style,
    target,
    color
  }

  const Content = (
    <UILink {...linkProps}>
      {isUnderline ? <Underline>{children}</Underline> : children}
    </UILink>
  )

  if (isNext) {
    return (
      <NextLink as={href} href={uri}>
        {Content}
      </NextLink>
    )
  }

  return Content
}

Link.propTypes = {
  color: PropTypes.string,
  isUnderline: PropTypes.bool,
  isNext: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string,
  uri: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  htmlID: PropTypes.string
}

Link.defaultProps = {
  color: 'primary',
  isUnderline: false,
  isNext: true
}

export default Link
