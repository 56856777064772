import { action, computed, makeObservable, observable } from 'mobx'
import { notifyError, safeObject } from 'uikit'
import zxcvbn from 'zxcvbn'
import { prepareErrorMessage } from 'utils/error'
import FactoryProvider from 'providers/factoryProvider'
import { push } from 'utils/history'
import FactoryModel from 'models/factoryModel'
import { TARGET_PARTNER_WELCOME, TARGET_MOBILE_CONFIRM, TARGET_QUESTIONARIES, TARGET_SELECT_POINT } from 'utils/roles'
import { showRocketChat } from 'utils/rocket'
import BaseModel from '../../baseModel'

class RegistrationModel extends BaseModel {
  ctx = null

  @observable login = ''
  @observable password = ''
  @observable confirmPassword = ''
  @observable code = ''

  @observable isProcessSignUp = false
  @observable isFailedSignUp = false

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get validLogin() {
    if (this.login.trim() === '') return 'Введите логин или email'
    return null
  }

  @computed get validPassword() {
    if (this.password.trim() === '') return 'Укажите пароль'
    if (this.password.trim().length < 6) return 'Длина пароля меньше 6 символов'
    if (zxcvbn(this.password).score < 2) return 'Используйте цифры, буквы и символы'
    return null
  }

  @computed get validConfirmPassword() {
    if (this.password.trim() === '' || this.confirmPassword === '') return 'Укажите пароль'
    if (this.password !== this.confirmPassword) return 'Пароли не совпадают'
    return null
  }

  clear = () => {
    this.action(() => {
      this.login = ''
      this.password = ''
      this.confirmPassword = ''
      this.code = ''
    })
  }

  setLogin = action(v => {
    this.login = v
    this.isFailedSignIn = false
  })

  setPassword = action(v => {
    this.password = v
    this.isFailedSignIn = false
  })

  setConfirmPassword = action(v => {
    this.confirmPassword = v
    this.isFailedSignIn = false
  })

  signUp = async () => {
    try {
      this.applyData({ isProcessSignUp: true })
      const { token, target, isPointSelectionRequired } = await FactoryProvider.AuthProvider.selfPasswordSignUp(this.login, this.password, this.confirmPassword, this.code)
      this.ctx.CommonModel.applyData({ target, isPointSelectionRequired })
      await this.ctx.CommonModel.useTarget(target, token, isPointSelectionRequired)
      showRocketChat()
      FactoryModel.AnalyticsModel.setUserId()
    } catch (e) {
      notifyError('Ошибка регистрации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSignUp: false })
    }
  }
}

export { RegistrationModel }
