import _ from 'lodash'
import { observable, action, makeObservable } from 'mobx'
import { notifyError } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../baseModel'

class AdditionalServiceListModel extends BaseModel {
  @observable list = []
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.list = []
    this.isLoading = false
  })

  serviceNameByID = (serviceID) => {
    const additionalService = _.find(this.list, (service) => service.id === serviceID)
    return additionalService && additionalService.name
  }

  getAdditionalServiceList = async () => {
    try {
      this.applyData({ isLoading: true })
      const list = await FactoryProvider.ServiceProvider.getAdditionalServices()
      this.applyData({ list })
    } catch (e) {
      notifyError('Ошибка получения списка дополнительных услуг', prepareErrorMessage(e))
    } finally {
      this.applyData({ isLoading: false })
    }
  }
}

export { AdditionalServiceListModel }
