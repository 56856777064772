import { action, computed, makeObservable, observable } from 'mobx'
import { CyrillicNameWithSpace, validEmail, isSafe, notifyError, safeObject, validRequired } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import FactoryProvider from 'providers/factoryProvider'
import zxcvbn from 'zxcvbn'
import { getHistoryQuery, push } from 'utils/history'
import { TARGET_PARTNER_WELCOME } from 'utils/roles'
import BaseModel from '../../baseModel'

class PartnerModel extends BaseModel {
  ctx = null

  @observable partnerId = ''
  @observable firstName = ''
  @observable lastName = ''
  @observable patronymic = ''
  @observable email = ''
  @observable phone = ''
  @observable password = ''
  @observable confirmPassword = ''
  @observable isSendingConfirmPhone = false
  @observable confirmSmsCode = ''
  CONFIRM_INTERVAL_TIME = 30 // sec
  @observable confirmInterval = this.CONFIRM_INTERVAL_TIME
  @observable isProcessSendConfirmPhone = false
  @observable isProcessSendConfirmCode = false
  @observable isSignUp = false
  @observable isForceValidate = false
  @observable isProcessSignUp = false
  @observable isSuccessSignUp = false

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get validFirstName() {
    if (validRequired(this.firstName)) return validRequired(this.firstName)
    if (!CyrillicNameWithSpace.test(this.firstName)) return 'Только кириллические символы'
    return null
  }

  @computed get validLastName() {
    if (validRequired(this.lastName)) return validRequired(this.lastName)
    if (!CyrillicNameWithSpace.test(this.lastName)) return 'Только кириллические символы'
    return null
  }

  @computed get validPatronymic() {
    if (this.patronymic !== '' && !CyrillicNameWithSpace.test(this.patronymic)) return 'Только кириллические символы'
    return null
  }

  @computed get validEmail() {
    if (validRequired(this.email)) return validRequired(this.email)
    if (validEmail(this.email)) return validEmail(this.email)
    return null
  }

  @computed get validPhone() {
    if (validRequired(this.phone)) return validRequired(this.phone)
    if (this.phone.length !== 10) return 'Неверный формат'
    return null
  }

  @computed get validConfirmSmsCode() {
    const value = `${this.confirmSmsCode}`.replace(/-/gi, '')
    if (validRequired(value)) return 'Не указан'
    if (value.length < 5) return 'Неверный'
    return null
  }

  @computed get validPassword() {
    if (this.password.trim() === '') return 'Укажите пароль'
    if (this.password.trim().length < 6) return 'Длина пароля меньше 6 символов'
    if (zxcvbn(this.password).score < 2) return 'Используйте цифры, буквы и символы'
    return null
  }

  @computed get validConfirmPassword() {
    if (this.password.trim() === '' || this.confirmPassword === '') return 'Укажите пароль'
    if (this.password !== this.confirmPassword) return 'Пароли не совпадают'
    return null
  }

  @computed get isNext() {
    return !this.validFirstName && !this.validLastName && !this.validPatronymic &&
      !this.validEmail && !this.validPhone && !this.validPassword && !this.validConfirmPassword
  }

  clear = () => {
    this.action(() => {
      this.partnerId = ''
      this.firstName = ''
      this.lastName = ''
      this.patronymic = ''
      this.email = ''
      this.phone = ''
      this.isSendingConfirmPhone = false
      this.confirmSmsCode = ''
      this.isProcessSendConfirmPhone = false
      this.isProcessSendConfirmCode = false
      this.confirmInterval = this.CONFIRM_INTERVAL_TIME
      this.isSignUp = false
      this.password = ''
      this.confirmPassword = ''
      this.isForceValidate = false
      this.isProcessSignUp = false
      this.isSuccessSignUp = false
    })
    this.clearConfirmInterval()
  }

  setPhone = action(phone => {
    this.phone = phone
    this.isSendingConfirmPhone = false
    this.confirmSmsCode = ''
    this.isProcessSendConfirmPhone = false
    this.isProcessSendConfirmCode = false
    this.confirmInterval = this.CONFIRM_INTERVAL_TIME
    this.isSignUp = false
    this.clearConfirmInterval()
  })

  clearConfirmInterval = () => {
    if (this.confirmPhoneIntervalID) {
      clearInterval(this.confirmPhoneIntervalID)
      this.confirmPhoneIntervalID = null
    }
  }

  sendConfirmPhone = async () => {
    try {
      this.applyData({
        isProcessSendConfirmPhone: true,
        isEnableSendConfirmPhone: false,
        confirmSmsCode: ''
      })

      const interval = await FactoryProvider.AuthProvider.partnerSendConfirmCode(this.partnerId)
      this.applyData({ confirmInterval: interval })

      this.clearConfirmInterval()
      this.startInterval()

      this.applyData({ isSendingConfirmPhone: true })
    } catch (e) {
      notifyError('Ошибка отправки sms кода', prepareErrorMessage(e))
      this.applyData({ isEnableSendConfirmPhone: true })
      throw e
    } finally {
      this.applyData({ isProcessSendConfirmPhone: false })
    }
  }

  startInterval = () => {
    this.confirmPhoneIntervalID = setInterval(() => {
      if (this.confirmInterval <= 1) {
        clearInterval(this.confirmPhoneIntervalID)
        this.applyData({
          confirmInterval: this.CONFIRM_INTERVAL_TIME,
          isEnableSendConfirmPhone: true
        })
      } else {
        this.applyData({
          confirmInterval: this.confirmInterval - 1
        })
      }
    }, 1000)
  }

  confirmPhone = async () => {
    try {
      this.applyData({ isProcessSendConfirmCode: true })
      const token = await FactoryProvider.AuthProvider.partnerConfirmPhone(this.confirmSmsCode, this.partnerId)
      this.applyData({ isSuccessSignUp: true })

      this.applyParamsToHistory()
      this.ctx.CommonModel.setField('target')(TARGET_PARTNER_WELCOME)
      await this.ctx.CommonModel.setToken(token)
      await this.ctx.CommonModel.globalInit(token)
    } catch (e) {
      notifyError('Ошибка подтверждения номера телефона', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSendConfirmCode: false })
    }
  }

  getSignUpParams = () => ({
    firstName: this.firstName,
    lastName: this.lastName,
    patronymic: this.patronymic,
    email: this.email,
    phone: this.phone,
    password: this.password,
    confirmPassword: this.confirmPassword,
    referId: FactoryProvider.StorageProvider.getSessionItem('referId')
  })

  signUp = async () => {
    if (!this.isNext) {
      this.applyData({ isForceValidate: true })
      return
    }
    try {
      this.applyData({ isProcessSignUp: true })
      const { partnerId, interval } = await FactoryProvider.AuthProvider.partnerSignUp(this.getSignUpParams())

      this.applyData({
        isSignUp: true,
        partnerId,
        confirmInterval: interval
      })

      this.applyParamsToHistory()
      this.clearConfirmInterval()
      this.startInterval()
    } catch (e) {
      notifyError('Ошибка регистрации партнёра', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSignUp: false })
    }
  }

  applyParamsToHistory = () => {
    push({
      uri: '/partner/sign_up',
      href: '/partner/sign_up',
      query: safeObject({
        phone: this.phone,
        partnerId: this.partnerId,
        success: this.isSuccessSignUp || null
      })
    })
  }

  applyParamsFromHistory = action(async () => {
    const { phone = '', partnerId = '', success } = getHistoryQuery()
    const isSuccessSignUp = isSafe(success) ? success === 'true' : false

    this.phone = phone
    this.partnerId = partnerId
    this.isSuccessSignUp = isSuccessSignUp
    if (!this.validPhone && this.partnerId && !isSuccessSignUp) {
      this.isSignUp = true
      await this.sendConfirmPhone()
    }
  })

  followToProposition = async () => {
    push({
      uri: '/partner/proposition/[action]',
      href: '/partner/proposition/new'
    })
  }
}

export { PartnerModel }
