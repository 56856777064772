import _ from 'lodash'
import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'

class IdeaProvider extends BaseApiProvider {
  async sendIdea(message) {
    const data = await this.fetch({
      uri: '/idea',
      method: 'POST',
      data: safeObject({
        message
      })
    })
    return _.get(data, 'result', false)
  }
}

export { IdeaProvider }
