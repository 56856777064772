import { observable, action, makeObservable } from 'mobx'
import { getHistoryQuery, pathname, push } from 'utils/history'
import { notifyError, notifySuccess, isSafe, safeObject } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'
import FactoryModel from '../factoryModel'

class PointListModel extends BaseModel {
  @observable list = []
  @observable page = 1
  @observable pageSize = 20
  @observable isLoadingList = false
  @observable queryText = ''
  @observable partnerID = null
  @observable isActive = true

  defaultUrlParams = {
    page: 1,
    queryText: '',
    isActive: true
  }

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.list = []
    this.page = this.defaultUrlParams.page
    this.pageSize = 20
    this.isLoadingList = false
    this.queryText = this.defaultUrlParams.queryText
    this.partnerID = null
    this.isActive = this.defaultUrlParams.isActive
  })

  isCurrentPathName = () => pathname() === '/points'

  applyParamsToHistory = () => {
    push({
      uri: '/points',
      query: safeObject({
        page: this.page !== this.defaultUrlParams.page ? this.page : null,
        queryText: this.queryText !== this.defaultUrlParams.queryText ? this.queryText : null,
        isActive: this.isActive !== this.defaultUrlParams.isActive ? this.isActive : null
      })
    })
  }

  applyParamsFromHistory = () => {
    const { page = this.defaultUrlParams.page, queryText = this.defaultUrlParams.queryText, isActive } = getHistoryQuery()
    const resolvePage = Number(page) > 1 ? Number(page) : this.defaultUrlParams.page
    const resolveIsActive = isSafe(isActive) ? isActive === 'true' : true

    this.applyData({
      page: Number(resolvePage),
      queryText,
      isActive: resolveIsActive
    })

    FactoryModel.MenuModel.setMenuItem('points')
    this.getList(false)
  }

  getList = async (isReload = true) => {
    try {
      this.applyData({
        isLoadingList: true,
        page: isReload ? 1 : this.page
      })
      const list = await FactoryProvider.PointProvider.getPointList({
        page: this.page,
        pageSize: this.pageSize,
        queryText: this.queryText,
        partnerID: this.partnerID,
        isActive: this.isActive
      })

      if (this.isCurrentPathName() && !this.partnerID) this.applyParamsToHistory()
      this.applyData({ list })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  setPage = page => {
    this.applyData({ page })
    this.getList(false)
  }

  setQueryText = text => {
    this.applyData({ queryText: isSafe(text) ? text : '' })
  }

  lockPoint = async (id, reason = '') => {
    const pointIndex = _.findIndex(this.list, l => l.id === id)
    if (pointIndex === -1) return
    try {
      const isActive = this.list[pointIndex].isActive
      this.action(() => {
        this.list[pointIndex].isProcessLock = true
        this.list = deepMerge([], this.list)
      })
      await FactoryProvider.PointProvider.lockPoint(id, isActive, isActive ? reason : '')
      notifySuccess(`Точка успешно ${isActive ? 'заблокирована' : 'разблокирована'}`)
      this.action(() => {
        this.list[pointIndex].isProcessLock = false
        this.list[pointIndex].isActive = !isActive
        this.list[pointIndex].lockReason = reason
        this.list = deepMerge([], this.list)
      })
      this.action(() => {
        this.list[pointIndex].isActive = !isActive
      })
    } catch (e) {
      notifyError(`Ошибка ${this.isActive ? 'блокировки' : 'активации'} точки`, prepareErrorMessage(e))
      this.action(() => {
        this.list[pointIndex].isProcessLock = false
        this.list = deepMerge([], this.list)
      })
      throw e
    }
  }

  applyPartner = action(partnerID => {
    this.partnerID = partnerID
    this.pageSize = 10
  })

  setIsActive = action(isActive => {
    this.isActive = isActive
    this.getList()
  })
}

export { PointListModel }
