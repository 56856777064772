import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { push } from 'utils/history'
import { TARGETS_MAP, TARGET_QUESTIONARIES, TARGET_MOBILE_CONFIRM, TARGET_PARTNER_WELCOME, TARGET_CREDIT_REQUESTS, TARGET_NEWS } from 'utils/roles'
import { safeObject } from 'uikit'
import { hideRocketChat, showRocketChat } from 'utils/rocket'
import BaseModel from '../../baseModel'

class CommonModel extends BaseModel {
  ctx = null

  @observable token = ''
  @observable target = ''
  @observable isPointSelectionRequired = false
  @observable tempToken = ''
  @observable expires = null
  currentUri = ''

  @observable login = ''
  @observable password = ''

  @observable isProcessSignIn = false
  @observable isFailedSignIn = false

  @observable points = []
  @observable isSearchPoints = true
  @observable isProcessSelectPoint = false

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isExistToken() {
    return !!this.token
  }

  @computed get isExistTempToken() {
    return !!this.tempToken
  }

  @computed get isExpires() {
    return !this.expires || this.expires - new Date() < 0
  }

  @computed get validLogin() {
    if (this.login.trim() === '') return 'Введите логин или email'
    return null
  }

  @computed get validPassword() {
    if (this.password.trim() === '') return 'Укажите пароль'
    if (this.password.trim().length < 6) return 'Длина пароля меньше 6 символов'
    return null
  }

  @computed get isExistPoints() {
    return !_.isEmpty(this.points)
  }

  init = (token = '', tempToken = '', expires = null, target = '', isPointSelectionRequired = false) => {
    this.applyData({
      token,
      tempToken,
      expires,
      target,
      isPointSelectionRequired
    })
  }

  applyUnknownHistory = () => {
    if (!this.isExistToken) {
      push({ uri: '/login' })
    }
    if (this.isExistToken) {
      switch (this.target) {
        case TARGET_QUESTIONARIES:
          push({ uri: '/questionaries' })
          break
        case TARGET_CREDIT_REQUESTS:
          push({
            uri: '/credit_requests',
            query: safeObject({
              scope: 'my'
            })
          })
          break
        case TARGET_MOBILE_CONFIRM:
          if (FactoryModel.SettingsModel?.ProfileModel?.mobilePhone && FactoryModel.SettingsModel?.ProfileModel?.id) {
            push({
              uri: '/partner/sign_up',
              href: '/partner/sign_up',
              query: safeObject({
                phone: FactoryModel.SettingsModel?.ProfileModel?.mobilePhone,
                partnerId: FactoryModel.SettingsModel?.ProfileModel?.id,
                success: false
              })
            })
          }
          break
        case TARGET_PARTNER_WELCOME:
          push({ uri: '/partner/welcome' })
          break
        case TARGET_NEWS:
          push({ uri: '/news' })
          break
        default:
          push({ uri: '/login' })
      }
    }
  }

  clear = action(() => {
    this.login = ''
    this.password = ''
    this.isProcessSignIn = false
    this.points = []
    this.isProcessSelectPoint = false
    this.target = ''
    this.isPointSelectionRequired = false
  })

  setToken = async token => {
    this.applyData({
      token
    })
    const { currentUri, expires } = await FactoryProvider.StorageProvider.setToken({
      value: token,
      target: this.target,
      isPointSelectionRequired: this.isPointSelectionRequired
    })
    if (!this.currentUri) this.currentUri = currentUri || ''
    this.applyData({ expires })
  }

  removeToken = async () => {
    this.action(() => {
      this.token = ''
    })
    await FactoryProvider.StorageProvider.removeToken()
  }

  setTempToken = async tempToken => {
    this.applyData({
      tempToken
    })
    await FactoryProvider.StorageProvider.setToken({
      value: tempToken,
      isTemp: true,
      target: this.target,
      isPointSelectionRequired: this.isPointSelectionRequired
    })
  }

  removeTempToken = async () => {
    this.action(() => {
      this.tempToken = ''
    })
    await FactoryProvider.StorageProvider.removeToken({ isTemp: true })
  }

  removeAllTokens = async () => {
    this.action(() => {
      this.token = ''
      this.tempToken = ''
    })
    await FactoryProvider.StorageProvider.removeToken({ isAll: true })
    FactoryModel.SettingsModel.CommonModel.setPoint('', '', '')
    FactoryModel.SettingsModel.CommonModel.setManager('', '', '')

    push({ uri: '/login' })
    FactoryProvider.ActionCableProvider.disconnect()
  }

  setLogin = action(v => {
    this.login = v
    this.isFailedSignIn = false
  })

  setPassword = action(v => {
    this.password = v
    this.isFailedSignIn = false
  })

  useTarget = async (target, token, isPointSelectionRequired) => {
    if (isPointSelectionRequired) {
      await this.setTempToken(token)
      push({ uri: '/my_points' })
      return
    }
    switch (target) {
      case TARGET_QUESTIONARIES:
        await this.setToken(token)
        await this.globalInit(token)

        if (this.currentUri && !/^\/_next/i.test(this.currentUri)) {
          push({ uri: this.currentUri })
        } else {
          push({ uri: '/questionaries' })
        }
        this.currentUri = ''
        break
      case TARGET_CREDIT_REQUESTS:
        await this.setToken(token)
        await this.globalInit(token)

        if (this.currentUri && !/^\/_next/i.test(this.currentUri)) {
          push({ uri: this.currentUri })
        } else {
          push({
            uri: '/credit_requests',
            query: safeObject({
              scope: 'my'
            })
          })
        }
        this.currentUri = ''
        break
      case TARGET_MOBILE_CONFIRM:
        await this.setToken(token)
        await this.globalInit(token)

        push({
          uri: '/partner/sign_up',
          href: '/partner/sign_up',
          query: safeObject({
            phone: FactoryModel.SettingsModel?.ProfileModel?.mobilePhone,
            partnerId: FactoryModel.SettingsModel?.ProfileModel?.id,
            success: false
          })
        })
        break
      case TARGET_PARTNER_WELCOME:
        await this.setToken(token)
        await this.globalInit(token)

        push({ uri: '/partner/welcome' })
        break
      case TARGET_NEWS:
        await this.setToken(token)
        await this.globalInit(token)

        if (this.currentUri && !/^\/_next/i.test(this.currentUri)) {
          push({ uri: this.currentUri })
        } else {
          push({ uri: '/news' })
        }
        this.currentUri = ''
        break
      default:
        if (this.currentUri && !/^\/_next/i.test(this.currentUri)) {
          push({ uri: this.currentUri })
        }
        this.currentUri = ''
        push({ uri: '/my_points' })
    }
  }

  signIn = async () => {
    try {
      this.applyData({ isProcessSignIn: true, isFailedSignIn: false })
      const { token, target, isPointSelectionRequired } = await FactoryProvider.AuthProvider.signIn(this.login, this.password)
      this.applyData({ target, isPointSelectionRequired })
      await this.useTarget(target, token, isPointSelectionRequired)
      showRocketChat()
      FactoryModel.AnalyticsModel.setUserId()
    } catch (e) {
      this.applyData({ isFailedSignIn: true })
    } finally {
      this.applyData({ isProcessSignIn: false })
    }
  }

  searchPoints = async (search = '') => {
    try {
      this.applyData({ isSearchPoints: true })
      const points = await FactoryProvider.AuthProvider.searchPoints(this.tempToken, search)
      this.applyData({ points })
    } finally {
      this.applyData({ isSearchPoints: false })
    }
  }

  selectPoint = async (pointID, pointName, macroregionName) => {
    if (this.isProcessSelectPoint) return
    try {
      this.applyData({ isProcessSelectPoint: true })
      const newToken = await FactoryProvider.AuthProvider.selectPoint(pointID, this.tempToken)
      await this.setToken(newToken)
      await this.removeTempToken()

      if (this.currentUri && !/^\/_next/i.test(this.currentUri)) {
        push({ uri: this.currentUri })
      }

      this.currentUri = ''

      FactoryModel.SettingsModel.CommonModel.setPoint(pointID, pointName, macroregionName)
      await this.globalInit(newToken)

      push({ uri: TARGETS_MAP[this.target] })
    } finally {
      this.applyData({ isProcessSelectPoint: false })
    }
  }

  prepareCurrentUri = () => {
    this.currentUri = `${window.location.pathname}${window.location.search}`
  }

  logout = async () => {
    this.prepareCurrentUri()
    await FactoryProvider.AuthProvider.signOut()
    await this.removeAllTokens()
    hideRocketChat()
    FactoryModel.AnalyticsModel.resetUserId()
  }

  getTempSettings = action(() => {
    FactoryModel.SettingsModel.CommonModel.getSettings(this.tempToken)
  })

  globalInit = async token => {
    FactoryProvider.ActionCableProvider.run(token)
    await Promise.all([
      FactoryModel.DictModel.getDict(),
      FactoryModel.SettingsModel.CommonModel.getSettings(token),
      FactoryModel.ConfigModel.initConfig()
    ])
  }

  switchUser = async ({ target, token, isPointSelectionRequired }) => {
    this.applyData({ target, isPointSelectionRequired })
    this.currentUri = ''
    if (isPointSelectionRequired) {
      await this.setTempToken(token)
      await this.removeToken()
      FactoryProvider.ActionCableProvider.disconnect()
      push({ uri: '/my_points' })
      return
    }
    switch (target) {
      case TARGET_QUESTIONARIES:
        await this.setToken(token)
        await this.globalInit(token)
        push({ uri: '/questionaries' })
        break
      case TARGET_CREDIT_REQUESTS:
        await this.setToken(token)
        await this.globalInit(token)
        push({
          uri: '/credit_requests',
          query: safeObject({
            scope: 'my'
          })
        })
        break
      case TARGET_MOBILE_CONFIRM:
        await this.setToken(token)
        await this.globalInit(token)
        push({
          uri: '/partner/sign_up',
          href: '/partner/sign_up',
          query: safeObject({
            phone: FactoryModel.SettingsModel?.ProfileModel?.mobilePhone,
            partnerId: FactoryModel.SettingsModel?.ProfileModel?.id,
            success: false
          })
        })
        break
      case TARGET_PARTNER_WELCOME:
        await this.setToken(token)
        await this.globalInit(token)
        push({ uri: '/partner/welcome' })
        break
      case TARGET_NEWS:
        await this.setToken(token)
        await this.globalInit(token)
        push({ uri: '/news' })
        break
      default:
        await this.setToken(token)
        await this.globalInit(token)
        push({ uri: '/partner/welcome' })
        break
        // await this.setTempToken(token)
        // await this.removeToken()
        // FactoryProvider.ActionCableProvider.disconnect()
        // push({ uri: '/my_points' })
        // break
    }
    FactoryModel.SettingsModel.ProfileModel.setConfirmEmail(FactoryModel.SettingsModel.ProfileModel.isConfirmEmail)
  }
}

export { CommonModel }
