import _ from 'lodash'
import { observable, computed, action, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { notifyError, safeObject } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import FactoryModel from 'models/factoryModel'
import { push } from 'utils/history'
import { MAIN_STEP } from 'providers/helpers/questionary'
import BaseModel from '../baseModel'

class CreditRequestModel extends BaseModel {
  @observable id = ''
  @observable source = ''
  @observable translatedSource = ''
  @observable point = {}
  @observable state = ''
  @observable actions = []
  @observable canAssign = false
  @observable canUnassign = false
  @observable canCopy = false
  @observable canCancel = false
  @observable createdAt = {}
  @observable borrower = {}
  @observable operator = ''
  @observable blackListReason = ''
  @observable callCenter = {}
  @observable comment = ''
  @observable additionalComment = ''
  @observable comments = []
  @observable documents = []
  @observable creditParams = {}
  @observable goods = []
  @observable photoUrl = ''
  @observable orderPayload = {}
  @observable formPayload = {}
  @observable questionaryID = ''
  @observable questionaryState = ''
  @observable target = ''
  @observable questionaryStep = ''
  @observable isWithoutPhoto = false
  @observable categories = []
  @observable questionaries = []

  @observable isLoadingCreditRequest = true
  @observable isSendAction = true
  @observable additionalCommentCreating = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.id = ''
    this.source = ''
    this.translatedSource = ''
    this.point = {}
    this.state = ''
    this.actions = []
    this.canAssign = false
    this.canUnassign = false
    this.canCopy = false
    this.canCancel = false
    this.createdAt = {}
    this.borrower = {}
    this.operator = ''
    this.blackListReason = ''
    this.callCenter = {}
    this.comment = ''
    this.additionalComment = ''
    this.comments = []
    this.documents = []
    this.creditParams = {}
    this.goods = []
    this.photoUrl = ''
    this.orderPayload = {}
    this.formPayload = {}
    this.questionaryID = ''
    this.questionaryState = ''
    this.target = ''
    this.questionaryStep = ''
    this.isWithoutPhoto = false
    this.categories = []
    this.questionaries = []

    this.isLoadingCreditRequest = false
    this.isSendAction = false
  })

  @computed get borrowerFullName() {
    return `${this.borrower.lastName} ${this.borrower.firstName} ${this.borrower.patronymic}`.trim()
  }

  @computed get borrowerPassport() {
    if (!this?.formPayload?.borrower.passport.series) return ''
    return `${this.formPayload.borrower.passport.series} - ${this.formPayload.borrower.passport.number}`
  }

  @computed get borrowerGender() {
    if (!this?.formPayload?.borrower.gender) return ''
    return _.find(FactoryModel.DictModel?.dict.gender, g => g.value === this.formPayload.borrower.gender)?.text || ''
  }

  @computed get borrowerFamilyType() {
    if (!this?.formPayload?.borrower.familyType) return ''
    return _.find(FactoryModel.DictModel?.dict.marital_status, m => m.value === this.formPayload.borrower.familyType)?.text || ''
  }

  documentUrl = (doctype) => (
    _.find(this.documents, d => d.doctype === doctype)?.url
  )

  getCreditRequest = async (id) => {
    if (this.id) return
    try {
      this.applyData({ isLoadingCreditRequest: true })
      const creditRequest = await FactoryProvider.CreditRequestProvider.getCreditRequest(id)
      this.applyData(creditRequest)
    } catch (e) {
      notifyError('Ошибка получения информации о заявке', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingCreditRequest: false })
    }
  }

  sendAction = async (sentAction, comment) => {
    try {
      this.applyData({ isSendAction: true })
      const creditRequest = await FactoryProvider.CreditRequestProvider.sendAction(safeObject({ action: sentAction, id: this.id, comment: comment || null }))
      this.applyData(creditRequest)
    } catch (e) {
      notifyError('Ошибка выполнения', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSendAction: false })
    }
  }

  assign = async () => {
    await this.sendAction('assign')
    const questionaryID = _.isEmpty(this.questionaries) ? this.questionaryID : this.questionaries[0]?.id
    const questionaryStep = _.isEmpty(this.questionaries) ? this.questionaryStep : this.questionaries[0]?.step
    push({
      uri: '/questionaries/[action]',
      href: !questionaryID ? '/questionaries/new' : `/questionaries/${questionaryID}`,
      query: safeObject({
        step: questionaryID ? (questionaryStep || MAIN_STEP) : null,
        creditRequest: this.id
      })
    })
  }

  createAdditionalComment = async () => {
    try {
      this.applyData({ additionalCommentCreating: true })
      await FactoryProvider.CreditRequestProvider.createAdditionalComment(this.id, this.additionalComment)
      this.applyData({ additionalComment: '',
        comments: [
          {
            text: this.additionalComment,
            createdAt: new Date(),
            author: FactoryModel.SettingsModel.ProfileModel?.fullName
          },
          ...this.comments
        ] })
    } catch (e) {
      notifyError('Ошибка выполнения', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ additionalCommentCreating: false })
    }
  }
}

export { CreditRequestModel }
