import _ from 'lodash'
import { observable, action, computed, makeObservable } from 'mobx'
import {
  isSafe, CyrillicNameWithSpace, validRequired,
  getDateByAge, moment, dateLessThan, dateGreaterThan, notifyError,
  scrollTo, safeObject
} from 'uikit'
import { passportDateValidator } from 'utils/validators/passport'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import debounce from 'lodash/debounce'
import { MAIN_STEP } from 'providers/helpers/questionary'
import { BORROWER_PHOTO, CC_PASSPORT23, CC_PASSPORTREG, scanMap } from 'providers/helpers/poscans'
import { passportIdentificationMap23, passportIdentificationMapReg } from 'providers/helpers/passport'
import { isValidNumber } from 'utils/validators/number'
import FactoryModel from 'models/factoryModel'
import BaseModel from '../../baseModel'

const handleChangeQuery = debounce(async (onChange) => {
  await onChange()
}, 250)

class MainModel extends BaseModel {
  ctx = null

  PHONE_BLOCK = 'mainData:phone'
  PASSPORT_BLOCK = 'mainData:passport'
  PERSONAL_BLOCK = 'mainData:personal'

  @observable clientId = ''

  @observable phone = ''
  @observable isFindByPhone = false
  @observable findByPhoneClients = []
  @observable isTurnPhoneEdit = false

  @observable passport = ''
  @observable passportSeries = ''
  @observable passportNumber = ''
  @observable isFindByPassport = false
  @observable findByPassportClients = []

  @observable passportDate = null
  @observable firstName = ''
  @observable lastName = ''
  @observable patronymic = ''
  @observable fullName = ''
  @observable gender = null
  @observable birthDate = null
  @observable income = ''
  @observable isCheat = false

  PASSPORT_STATUS_PROCESS = 'passport-process'
  PASSPORT_STATUS_FAILED = 'passport-failed'
  PASSPORT_STATUS_FINISHED = 'passport-finished'
  @observable passportIdentification = {
    scans: [],
    isOpenQr: false,
    isLoading: false,
    unCorrect: {},
    status: '',
    prepareData: {}
  }
  passportIdentificationIntervalId = null
  PASSPORT_IDENTIFICATION_TIMEOUT = 20 // sec.

  @observable isForceValidate = false
  @observable isLoading = false
  @observable isProcessSave = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.clientId = ''

    this.phone = ''
    this.isFindByPhone = false
    this.findByPhoneClients = []
    this.isTurnPhoneEdit = false

    this.passport = ''
    this.passportSeries = ''
    this.passportNumber = ''
    this.isFindByPassport = false
    this.findByPassportClients = []

    this.passportDate = null
    this.firstName = ''
    this.lastName = ''
    this.patronymic = ''
    this.fullName = ''
    this.gender = null
    this.birthDate = null
    this.income = ''
    this.isCheat = false

    this.clearPassportIdentification()

    this.isForceValidate = false
    this.isLoading = false
    this.isProcessSave = false
  })

  clearPassportIdentification = action(() => {
    this.passportIdentification = {
      scans: [],
      isOpenQr: false,
      isLoading: false,
      unCorrect: {},
      status: '',
      prepareData: {}
    }
    this.ctx.CommonModel.setField('packageId')(null)
    this.ctx.CommonModel.setField('isOpenQr')(false)
    this.passportIdentificationIntervalId = null
    this.closePackage()
    this.clearPassportIdentificationInterval()
  })

  @computed get validPhone() {
    if (validRequired(this.phone)) return validRequired(this.phone)
    if (this.phone.length !== 10) return 'Неверный формат'
    if (_.get(this.phone.replace(/[\s()-]*/gi, ''), '0') !== '9') return 'Начинается не с 9'
    return null
  }

  @computed get isAccessPhoneEdit() {
    return !this.ctx.CommonModel.isExist || this.isTurnPhoneEdit
  }

  @computed get isShowEditAction() {
    return this.ctx.CommonModel.isExist && !this.isTurnPhoneEdit
  }

  @computed get validPassportSeries() {
    if (validRequired(this.passportSeries)) return validRequired(this.passportSeries)
    if (!isValidNumber(this.passportSeries) || this.passportSeries.length < 4) return 'Некорректная серия'
    return null
  }

  @computed get validPassportNumber() {
    if (this.passportNumber === '') return 'Номер не найден'
    if (!isValidNumber(this.passportNumber) || this.passportNumber.length < 6) return 'Некорректный номер'
    return null
  }

  @computed get validPassport() {
    return this.validPassportSeries || this.validPassportNumber
  }

  @computed get validPassportDate() {
    if (validRequired(this.passportDate)) return validRequired(this.passportDate)

    const validPassport = passportDateValidator(this.passportDate, this.birthDate)
    if (validPassport) return validPassport

    return null
  }

  @computed get validFio() {
    if (validRequired(this.lastName)) return validRequired(this.lastName)
    if (validRequired(this.firstName)) return validRequired(this.firstName)
    if (!CyrillicNameWithSpace.test(this.lastName)) return 'Допустимы только кириллические символы'
    if (!CyrillicNameWithSpace.test(this.firstName)) return 'Допустимы только кириллические символы'
    if (this.patronymic !== '' && !CyrillicNameWithSpace.test(this.patronymic)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validGender() {
    if (validRequired(this.gender)) return validRequired(this.gender)
    return null
  }

  @computed get validBirthDate() {
    if (validRequired(this.birthDate)) return validRequired(this.birthDate)

    const young = dateLessThan(this.birthDate, getDateByAge(18), 'Младше 18 лет')
    if (young) return young

    const old = dateGreaterThan(this.birthDate, moment()
      .subtract(99, 'years'), 'Старше 99 лет')
    if (old) return old

    return null
  }

  @computed get validIncome() {
    if (validRequired(this.income)) return validRequired(this.income)
    if (this.income <= 0) return 'Меньше нуля'
    return null
  }

  @computed get isDisableReset() {
    return !!validRequired(this.phone) &&
      !!validRequired(this.passport) &&
      !!validRequired(this.passportDate) &&
      !!validRequired(this.fullName) &&
      !!validRequired(this.gender) &&
      !!validRequired(this.birthDate) &&
      !!validRequired(this.income) &&
      !this.isCheat
  }

  @computed get isValidPhone() {
    return !this.validPhone
  }

  @computed get isValidPassport() {
    return !this.validPassportSeries && !this.validPassportNumber && !this.validPassportDate
  }

  @computed get isValidPersonal() {
    return !this.validFio && !this.validGender && !this.validBirthDate && !this.validIncome
  }

  @computed get isAllowIdentificationPassport() {
    return !this.ctx.CommonModel.isExist && !this.ctx.CommonModel.packageId &&
      !this.validPhone
  }

  @computed get isExistIdentificationPassport() {
    return !this.ctx.CommonModel.isExist && !!this.ctx.CommonModel.packageId &&
      !_.isEmpty(this.passportIdentification.scans)
  }

  @computed get isExistPassportIdentificationPrepare() {
    return !_.isEmpty(this.passportIdentification.prepareData)
  }

  @computed get isShowPassportIdentificationButton() {
    return !this.ctx.CommonModel.isExist && !this.isExistIdentificationPassport &&
      FactoryModel.SettingsModel.CommonModel.options.isQuestionaryPassportRecognition
  }

  @computed get isNext() {
    return this.isValidPhone && this.isValidPassport && this.isValidPersonal
  }

  setPhone = async v => {
    this.applyData({ phone: v, clientId: this.ctx.CommonModel.isExist ? this.clientId : '' })

    if (this.ctx.CommonModel.isExist) return

    if (this.isExistIdentificationPassport) this.applyData({ isForceValidate: false })

    this.clearPassportIdentification()
    if (!this.validPhone) {
      handleChangeQuery(async () => {
        try {
          this.applyData({ isFindByPhone: true })
          const findByPhoneClients = await FactoryProvider.QuestionaryProvider.findClients({ phone: v })
          this.applyData({ findByPhoneClients })
        } finally {
          this.applyData({ isFindByPhone: false })
        }
      })
    }
  }

  turnPhoneEdit = action(() => {
    this.isTurnPhoneEdit = true
  })

  setPassport = async v => {
    const series = v.substring(0, 4)
    const number = v.substring(4)
    this.applyData({
      passportSeries: series,
      passportNumber: number,
      passport: v,
      clientId: ''
    })

    if (!this.validPassportNumber) {
      handleChangeQuery(async () => {
        try {
          this.applyData({ isFindByPassport: true })
          const findByPassportClients = await FactoryProvider.QuestionaryProvider.findClients({ passport: v })
          this.applyData({ findByPassportClients })
        } finally {
          this.applyData({ isFindByPassport: false })
        }
      })
    }
  }

  setPassportDate = action(passportDate => {
    this.passportDate = passportDate
    this.clientId = ''
  })

  setBirthDate = action(birthDate => {
    this.birthDate = birthDate
    this.clientId = ''
  })

  setFullName = action((fullName = '') => {
    this.applyData({
      fullName,
      lastName: '',
      firstName: '',
      patronymic: ''
    })
  })

  setFio = action(item => {
    const { name, surname, patronymic, gender } = item
    let fullName = ''
    if (surname) fullName += `${surname} `
    if (name) fullName += `${name} `
    if (patronymic) fullName += `${patronymic}`
    this.applyData(_.omitBy({
      firstName: name,
      lastName: surname,
      patronymic,
      gender: isSafe(gender) ? gender === 'MALE' || gender === 1 ? 1 : 2 : null,
      fullName
    }, _.isNull))
  })

  getFields = () => [
    'clientId',
    'phone',
    'passportSeries',
    'passportNumber',
    'passportDate',
    'firstName',
    'lastName',
    'patronymic',
    'fullName',
    'gender',
    'birthDate',
    'income',
    'isCheat'
  ]

  get = (questionary = null) => {
    const resolve = {}
    _.each(this.getFields(), field => {
      resolve[field] = questionary ? questionary[field] : this[field]
    })

    if (resolve.passportSeries) {
      resolve.passport = `${resolve.passportSeries}${resolve.passportNumber}`
    }

    if (this.ctx?.CommonModel?.creditRequestID) {
      resolve.creditRequestId = this.ctx.CommonModel.creditRequestID
    }

    if (this.ctx.CommonModel.packageId) {
      resolve.packageId = this.ctx.CommonModel.packageId
    }

    return resolve
  }

  selectClientByPhone = index => {
    this.applyFindClient(this.findByPhoneClients[index])
  }

  selectClientByPassport = index => {
    this.applyFindClient(this.findByPassportClients[index])
  }

  applyFindClient = data => {
    this.applyMainData(data)
    this.clearPassportIdentification()
  }

  applyMainData = (questionary) => {
    this.applyData(this.get(questionary))
  }

  createQuestionary = async () => {
    try {
      this.applyData({ isProcessSave: true })
      const { id, ...data } = await FactoryProvider.QuestionaryProvider.createQuestionary(safeObject({
        ...this.get(),
        passportCode: this.ctx.AdditionalModel.passportCode,
        passportBy: this.ctx.AdditionalModel.passportBy,
        birthPlace: this.ctx.AdditionalModel.birthPlace,
        addressRegistration: this.ctx.AdditionalModel.addressRegistration,
        registrationDate: this.ctx.AdditionalModel.registrationDate,
        residenceAddress: this.ctx.AdditionalModel.residenceAddress,
        residenceDate: this.ctx.AdditionalModel.residenceDate
      }),
      this.ctx.CommonModel.creditRequestID)

      this.clearPassportIdentification()

      this.ctx.CommonModel.postCreateQuestionary(id)
      this.ctx.CommonModel.applyDataAfterUpdate(data)
    } catch (e) {
      notifyError('Ошибка создания заявки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSave: false })
    }
  }

  updateQuestionary = async () => {
    if (this.ctx.CommonModel.isMetaStep()) return

    try {
      this.applyData({ isProcessSave: true })
      const data = await FactoryProvider.QuestionaryProvider.updateQuestionary(this.ctx.CommonModel.id, this.get(), MAIN_STEP)

      this.ctx.CommonModel.applyDataAfterUpdate(data)
    } catch (e) {
      notifyError('Ошибка обновления основных данных', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSave: false })
    }
  }

  createPassportIdentificationPackage = async () => {
    try {
      this.action(() => this.passportIdentification.isLoading = true)
      const { packageId, scans, qrCode } =
        await FactoryProvider.QuestionaryProvider.createPassportIdentificationPackage(this.phone)

      this.closePackage(this.ctx.CommonModel.packageId)
      this.ctx.CommonModel.setField('packageId')(packageId)
      this.ctx.CommonModel.setField('qrCode')(qrCode)
      this.action(() => {
        this.passportIdentification.scans = scans
        this.clientId = ''
      })
      this.subscribePackage()
    } catch (e) {
      notifyError('Ошибка получения пакета идентификации паспорта', prepareErrorMessage(e))
      throw e
    } finally {
      this.action(() => this.passportIdentification.isLoading = false)
    }
  }

  checkPackageId = packageId => {
    if (!this.isExistIdentificationPassport) return false
    return this.ctx.CommonModel.packageId === packageId
  }

  uploadScan = async (id, file) => {
    const index = _.findIndex(this.passportIdentification.scans, scan => scan.id === id)
    const packageId = this.ctx.CommonModel.packageId

    try {
      this.action(() => this.passportIdentification.scans[index].isLoading = true)

      const { url } = await FactoryProvider.PoscansProvider.putScan({
        packageID: this.ctx.CommonModel.packageId,
        scanID: id,
        file
      })

      if (!this.passportIdentification?.scans[index]) return
      if (!this.checkPackageId(packageId)) return

      this.action(() => this.passportIdentification.scans[index].url = url)
      this.startIdentificationPassport(this.passportIdentification.scans[index])
    } catch (e) {
      notifyError('Ошибка загрузки скана', prepareErrorMessage(e))
      throw e
    } finally {
      if (this.passportIdentification?.scans[index]) this.action(() => this.passportIdentification.scans[index].isLoading = false)
    }
  }

  // TODO In future, replace this code on other relative property (not commonCode: CC_PASSPORT23)
  startIdentificationPassport = action(scan => {
    if (scan.commonCode !== CC_PASSPORT23 && scan.commonCode !== CC_PASSPORTREG) return

    this.passportIdentification.status = this.PASSPORT_STATUS_PROCESS
    this.ctx.CommonModel.setIsOpenQr(false)

    this.waitPassportIdentification()
  })

  subscribePackage = () => {
    this.closePackage(this.ctx.CommonModel.packageId)
    const packageId = this.ctx.CommonModel.packageId

    FactoryProvider.PoscansWsProvider.subscribe({
      packageID: this.ctx.CommonModel.packageId,
      onReceived: data => {
        switch (data?.Action) {
          case 'Upload': {
            if (!this.checkPackageId(packageId)) break
            if (!data?.Payload) break

            const resolveScan = scanMap({ scan: data?.Payload })
            const passportScanIndex = _.findIndex(this.passportIdentification?.scans, scan => scan.id === resolveScan.id)
            if (resolveScan.commonCode === BORROWER_PHOTO) {
              this.ctx.OtherModel.applyPhoto(resolveScan)
            }

            if (passportScanIndex === -1) break

            this.action(() => this.passportIdentification.scans[passportScanIndex] = resolveScan)
            this.startIdentificationPassport(resolveScan)
            break
          }
          case 'RecognizeStarted': {
            if (!this.checkPackageId(packageId)) break

            this.action(() => this.passportIdentification.status = this.PASSPORT_STATUS_PROCESS)

            this.waitPassportIdentification()
            break
          }
          case 'RecognizeFailed': {
            if (!this.checkPackageId(packageId)) break

            this.clearPassportIdentificationInterval()
            this.action(() => this.passportIdentification.status = this.PASSPORT_STATUS_FAILED)
            break
          }
          case 'RecognizeFinished': {
            if (!this.checkPackageId(packageId)) break
            if (!data?.Payload) break

            this.clearPassportIdentificationInterval()
            switch (data?.Payload?.doctype) {
              case 'passport_main': {
                this.applyPassportIdentification23(passportIdentificationMap23({ data: data?.Payload?.data ?? {} }))
                break
              }
              case 'passport_registration': {
                this.applyPassportIdentificationReg(passportIdentificationMapReg({ data: data?.Payload?.data ?? {} }))
                break
              }
            }
            this.action(() => this.passportIdentification.status = this.PASSPORT_STATUS_FINISHED)
            break
          }
        }
      }
    })
  }

  closePackage = (packageId) => {
    FactoryProvider.PoscansWsProvider.close(packageId || this.ctx.CommonModel.packageId)
  }

  waitPassportIdentification = () => {
    this.clearPassportIdentificationInterval()
    const packageId = this.ctx.CommonModel.packageId

    this.passportIdentificationIntervalId = setInterval(() => {
      this.clearPassportIdentificationInterval()

      if (!this.checkPackageId(packageId)) return

      this.action(() => this.passportIdentification.status = this.PASSPORT_STATUS_FAILED)
    }, this.PASSPORT_IDENTIFICATION_TIMEOUT * 1000)
  }

  clearPassportIdentificationInterval = () => {
    if (this.passportIdentificationIntervalId) clearInterval(this.passportIdentificationIntervalId)
  }

  applyPassportIdentification23 = action(prepareData => {
    this.lastName = prepareData.lastName
    this.firstName = prepareData.firstName
    this.patronymic = prepareData.patronymic
    this.fullName = prepareData.fullName
    this.birthDate = prepareData.birthDate
    this.gender = prepareData.gender
    this.passportSeries = prepareData.passportSeries
    this.passportNumber = prepareData.passportNumber
    this.passport = prepareData.passport
    this.passportDate = prepareData.passportDate
    this.passportIdentification.unCorrect = { ...this.passportIdentification.unCorrect, ...prepareData.unCorrect }

    this.ctx.AdditionalModel.setField('passportCode')(prepareData.passportCode)
    if (this.ctx.AdditionalModel.validPassportCode) this.ctx.AdditionalModel.setField('passportCode')('')
    this.ctx.AdditionalModel.setField('passportBy')(prepareData.passportBy)
    if (this.ctx.AdditionalModel.validPassportBy) this.ctx.AdditionalModel.setField('passportBy')('')
    this.ctx.AdditionalModel.setField('birthPlace')(prepareData.birthPlace)
    if (this.ctx.AdditionalModel.validBirthPlace) this.ctx.AdditionalModel.setField('birthPlace')('')

    this.isForceValidate = true
    this.passportIdentification.status = ''
  })

  applyPassportIdentificationReg = action(prepareData => {
    this.passportIdentification.unCorrect = { ...this.passportIdentification.unCorrect, ...prepareData.unCorrect }

    this.ctx.AdditionalModel.setField('addressRegistration')(prepareData.addressRegistration)
    if (this.ctx.AdditionalModel.validAddressRegistration) this.ctx.AdditionalModel.setField('addressRegistration')('')
    this.ctx.AdditionalModel.setField('registrationDate')(prepareData.registrationDate)
    if (this.ctx.AdditionalModel.validRegistrationDate) this.ctx.AdditionalModel.setField('registrationDate')('')

    if (this.ctx.AdditionalModel.isRepeatResidenceAddress) {
      this.ctx.AdditionalModel.setField('residenceAddress')(prepareData.addressRegistration)
      if (this.ctx.AdditionalModel.validResidenceAddress) this.ctx.AdditionalModel.setField('residenceAddress')('')
      this.ctx.AdditionalModel.setField('residenceDate')(prepareData.registrationDate)
      if (this.ctx.AdditionalModel.validResidenceDate) this.ctx.AdditionalModel.setField('residenceDate')('')
    }

    this.isForceValidate = true
    this.passportIdentification.status = ''
  })

  next = async () => {
    if (!this.isNext) {
      this.applyData({ isForceValidate: true })
      if (!this.isValidPhone) scrollTo(this.PHONE_BLOCK)
      else if (!this.isValidPassport) scrollTo(this.PASSPORT_BLOCK)
      else if (!this.isValidPersonal) scrollTo(this.PERSONAL_BLOCK)
    } else if (!this.ctx.CommonModel.isExist) await this.createQuestionary()
    else await this.updateQuestionary()
  }
}

export { MainModel }
