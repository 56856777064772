import _ from 'lodash'
import BaseApiProvider from './baseApiProvider'
import { agentCommissionGroupMap } from '../helpers/agentCommissionGroup'

class AgentCommissionGroupsProvider extends BaseApiProvider {
  async getAgentCommissionGroups() {
    const data = await this.fetch({
      uri: '/agent_commission_groups'
    })
    return _.map(data, acGroup => agentCommissionGroupMap({ agentCommissionGroup: acGroup }))
  }
}

export { AgentCommissionGroupsProvider }
