import { observable, computed, action, makeObservable } from 'mobx'
import { validRequired } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class CreditOfferModel extends BaseModel {
  @observable creditAmount = ''
  @observable fullname = ''
  @observable phone = ''

  @observable isFailedSendForm = false
  @observable isProcessSendForm = false
  @observable isSuccessSendForm = false

  @observable result = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.creditAmount = ''
    this.fullname = ''
    this.phone = ''
    this.isFailedSendForm = false
    this.isProcessSendForm = false
  })

  @computed get validCreditAmount() {
    if (validRequired(this.creditAmount)) return 'Укажите размер желаемого кредита'
    if (Number(this.creditAmount) < 100000) return 'Размер кредита должен быть более 100 000 рублей '
    if (Number(this.creditAmount) > 5000000) return 'Размер кредита должен быть менее 5 000 000 рублей '
    return null
  }

  @computed get validFullname() {
    if (this.fullname.trim() === '') return 'Введите ФИО'
    return null
  }

  @computed get validPhone() {
    if (validRequired(this.phone)) return validRequired(this.phone)
    if (this.phone.length !== 10) return 'Неверный формат'
    return null
  }

  setCreditAmount = action(v => {
    this.creditAmount = v
    this.isFailedSendForm = false
  })

  setFullname = action(v => {
    this.fullname = v
    this.isFailedSendForm = false
  })

  setPhone = action(v => {
    this.phone = v
    this.isFailedSendForm = false
  })

  showSuccess = async () => {

  }

  incrementCounter = async () => {
    await FactoryProvider.CreditOfferProvider.incrementCounter()
  }

  sendForm = async () => {
    try {
      this.applyData({ isProcessSendForm: true, isFailedSendForm: false, isSuccessSendForm: false })
      const result = await FactoryProvider.CreditOfferProvider.sendForm(this.creditAmount, this.fullname, this.phone)
      this.applyData({ result })
    } catch (e) {
      this.applyData({ isFailedSendForm: true, isSuccessSendForm: false })
    } finally {
      this.applyData({ isProcessSendForm: false })
    }
    if (this.result === true) {
      this.applyData({ isSuccessSendForm: true })
      this.clear()
    }
  }
}

export { CreditOfferModel }
