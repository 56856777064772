import _ from 'lodash'
import { contentMap } from 'providers/helpers/content'
import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'

class PostProvider extends BaseApiProvider {
  async getPosts(params = {}) {
    const { queryText = '', pageSize = 20, page = 1 } = params
    const data = await this.fetch({
      uri: '/news',
      query: safeObject({
        query: queryText !== '' ? queryText : null,
        page,
        per_page: pageSize
      })
    })

    const source = _.map(data, post => contentMap({ data: post }))
    const topPosts = []
    const newPosts = []
    const resolve = []
    _.each(source, post => {
      if (post.isTop) topPosts.unshift(post)
      else if (post.isNew) newPosts.unshift(post)
      else resolve.push(post)
    })
    return [
      ...topPosts,
      ...newPosts,
      ...resolve
    ]
  }

  async getDetails(slug) {
    const data = await this.fetch({
      uri: `/news/${slug}`
    })
    return contentMap({ data })
  }
}

export { PostProvider }
