import React from 'react'
import { Modal, Block, Alert, Text, Button, Title } from 'uikit'
import { inject, observer } from 'mobx-react'

const Update = ({ UpdateModel }) => (
  UpdateModel.isUpdate ?
    <Modal
      isOpen={UpdateModel.isUpdate}
      onCancel={() => UpdateModel.setUpdate(false)}
      title={
        <Block isCenter flex={1}>
          <Block>
            <Title
              level={3}
              type="gray6"
              isRem={false}
              marginTop={2}
              marginBottom={0}
              align="center"
            >
              Внимание
            </Title>
            <Title
              level={3}
              type="gray6"
              isRem={false}
              marginTop={0}
              marginBottom={16}
              align="center"
            >
              Выпущено обновление системы
            </Title>
          </Block>
        </Block>
      }
    >
      <Alert
        type="info"
        message={
          <Text>
            Для корректного использования приложения <Text type="primary8" isMedium>перезагрузите страницу</Text>!
          </Text>
        }
      />

      <Block isCenter marginTop={32} isRem={false}>
        <Button
          type="primary"
          isMarginLeft
          onClick={() => location.reload()}
        >
          Перезагрузить страницу
        </Button>
      </Block>
    </Modal> : null
)

export default inject('UpdateModel')(observer(Update))
