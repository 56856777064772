import FactoryProvider from 'providers/factoryProvider'
import { safeObject } from 'uikit'
import { getMetadata } from 'utils/cameraMetadata'
import _ from 'lodash'
import BaseHttpProvider from './baseHttpProvider'
import {
  bundleMap, scanMap, scanMapToApi,
  PD_AGREEMENT, BORROWER_PHOTO, PHOTO
} from '../helpers/poscans'

class PoscansProvider extends BaseHttpProvider {
  host() {
    const uri = FactoryProvider.ConfigProvider.config.poscans.uri
    return `${uri === '/' || !uri ? '/scans-backend' : uri}/api/v1`
  }

  async fetch(params) {
    return await super.execute({ ...params, skipAuthorize: true }, this.host())
  }

  async putScan({ packageID, scanID, file, metadata = {} }) {
    const commonMetadata = await getMetadata()
    const data = await this.fetch({
      uri: `/package/${packageID}/scans/${scanID}/upload`,
      method: 'PUT',
      data: safeObject({
        file,
        metadata: JSON.stringify({
          ...commonMetadata,
          ...metadata
        })
      }),
      isMultiPartData: true
    })
    return scanMap({ scan: data })
  }

  async addScan({ packageID, bundleID, scan }) {
    const data = await this.fetch({
      uri: `/package/${packageID}/bundles/${bundleID}/scans`,
      method: 'POST',
      data: scanMapToApi({ scan })
    })
    return scanMap({ scan: data })
  }

  async renameScan({ packageID, scanID, scan }) {
    const data = await this.fetch({
      uri: `/package/${packageID}/scans/${scanID}`,
      method: 'PUT',
      data: scanMapToApi({ scan })
    })
    return scanMap({ scan: data })
  }

  async clearScan({ packageID, scanID }) {
    const data = await this.fetch({
      uri: `/package/${packageID}/scans/${scanID}/clean`,
      method: 'PUT'
    })
    return scanMap({ scan: data })
  }

  async deleteScan({ packageID, scanID }) {
    return await this.fetch({
      uri: `/package/${packageID}/scans/${scanID}`,
      method: 'DELETE'
    })
  }

  async getBorrowerPhotoScan(packageId) {
    const data = await this.fetch({
      uri: `/package/${packageId}`
    })
    const bundle = _.find(data?.Bundles, b => b?.Code === PHOTO)
    if (!bundle) return null
    const scan = _.find(bundle?.Scans, s => s.CommonCode = BORROWER_PHOTO)
    return scan ? scanMap({ scan }) : null
  }

  async getBorrowerPdAgreementScan(packageId) {
    const data = await this.fetch({
      uri: `/package/${packageId}`
    })
    const bundle = _.find(data?.Bundles, b => b?.Code === PD_AGREEMENT)
    if (!bundle) return null
    const scan = _.find(bundle?.Scans, s => s.CommonCode = PD_AGREEMENT)
    return scan ? scanMap({ scan }) : null
  }

  async getBundle({ packageID }) {
    const data = await this.fetch({
      uri: `/package/${packageID}`
    })

    return _.map(data.Bundles, bundle => bundleMap({ bundle }))
  }
}

export { PoscansProvider }
