import { DATE_FORMATS, moment } from 'uikit'
import { addressMap } from './address'

export const passportIdentificationMap23 = ({ data }) => {
  const prepare = { unCorrect: {} }

  prepare.birthDate = moment(data?.birthdate?.value, DATE_FORMATS).isValid() ?
    moment(data?.birthdate?.value, DATE_FORMATS).toDate() : null
  if (!data?.birthdate?.correct) prepare.unCorrect.birthDate = true

  prepare.firstName = data?.firstname?.value ?? ''
  prepare.lastName = data?.lastname?.value ?? ''
  prepare.patronymic = data?.middlename?.value ?? ''
  if (!data?.firstname?.correct ||
    !data?.lastname?.correct ||
    !data?.middlename?.correct
  ) prepare.unCorrect.fio = true

  let fullName = prepare.lastName && prepare.firstName ? `${prepare.lastName} ${prepare.firstName}` : ''
  if (prepare.patronymic) fullName += ` ${prepare.patronymic}`
  prepare.fullName = fullName

  const series = data?.series?.value ?? ''
  const number = data?.number?.value ?? ''
  const passport = `${series}${number}`

  prepare.passportSeries = series
  prepare.passportNumber = number
  prepare.passport = passport
  if (!data?.series?.correct || !data?.number?.correct) prepare.unCorrect.passport = true

  prepare.gender = data?.sex?.value !== 'female' ? 1 : 2
  if (!data?.sex?.correct) prepare.unCorrect.gender = true

  prepare.passportDate = moment(data?.issued_on?.value, DATE_FORMATS).isValid() ?
    moment(data?.issued_on?.value, DATE_FORMATS).toDate() : null
  if (!data?.issued_on?.correct) prepare.unCorrect.passportDate = true

  prepare.passportCode = `${data?.issuer_code?.value ?? ''}`.replace(/-/gi, '')
  if (!data?.issuer_code?.correct) prepare.unCorrect.passportCode = true

  prepare.passportBy = data?.issued_by?.value ?? ''
  if (!data?.issued_by?.correct) prepare.unCorrect.passportBy = true

  prepare.birthPlace = data?.birthplace?.value ?? ''
  if (!data?.birthplace?.correct) prepare.unCorrect.birthPlace = true

  return prepare
}

export const passportIdentificationMapReg = ({ data }) => {
  const prepare = { unCorrect: {} }

  const address = {
    country: data.country.correct ? data.country.value : '',
    post_code: data.post_code.correct ? data.post_code.value : '',
    region: data.region.correct ? data.region.value : '',
    region_type: data.region_type.correct ? data.region_type.value : '',
    region_code: data.region_code.correct ? data.region_code.value : '',
    district: data.area.correct ? data.area.value : '',
    district_type: data.area_type.correct ? data.area_type.value : '',
    city: data.city.correct ? data.city.value : '',
    city_type: data.city_type.correct ? data.city_type.value : '',
    settlement: data.settlement.correct ? data.settlement.value : '',
    settlement_type: data.settlement_type.correct ? data.settlement_type.value : '',
    street: data.street.correct ? data.street.value : '',
    street_type: data.street_type.correct ? data.street_type.value : '',
    house: data.house.correct ? data.house.value : '',
    house_type: data.house_type.correct ? data.house_type.value : '',
    block: data.block.correct ? data.block.value : '',
    block_type: data.block_type.correct ? data.block_type.value : '',
    apartment_number: data.apartment.correct ? data.apartment.value : '',
    apartment_type: data.apartment_type.correct ? data.apartment_type.value : ''
  }

  const address_correct = data.country.correct &&
    data.post_code.correct &&
    data.region.correct &&
    data.region_type.correct &&
    data.region_code.correct &&
    data.area.correct &&
    data.area_type.correct &&
    data.city.correct &&
    data.city_type.correct &&
    data.settlement.correct &&
    data.settlement_type.correct &&
    data.street.correct &&
    data.street_type.correct &&
    data.house.correct &&
    data.house_type.correct &&
    data.block.correct &&
    data.block_type.correct &&
    data.apartment.correct &&
    data.apartment_type.correct

  prepare.addressRegistration = addressMap({ address })
  if (!address_correct) prepare.unCorrect.addressRegistration = true

  prepare.registrationDate = moment(data?.registration_date?.value, DATE_FORMATS).isValid() ?
    moment(data?.registration_date?.value, DATE_FORMATS).toDate() : null

  if (!data?.registration_date?.correct) prepare.unCorrect.registrationDate = true

  return prepare
}
