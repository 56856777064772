import { ThemeProvider } from 'styled-components'
import React from 'react'
import { Provider as MobxProvider } from 'mobx-react'
import { factoryProviderInit } from 'providers/factoryProvider/init'
import { theme } from 'styles'
import Root from './Root'

factoryProviderInit()

const Provider = ({ children, models }) => (
  <MobxProvider {...models}>
    <ThemeProvider theme={theme}>
      <Root>
        {children}
      </Root>
    </ThemeProvider>
  </MobxProvider>
)

export default Provider
