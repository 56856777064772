import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'

export const initRocketChat = () => {
  if (FactoryProvider.ConfigProvider.config.showRocketChat !== 'true' && FactoryProvider.ConfigProvider.config.showRocketChat !== true) return
  window.RocketChat = c => { window.RocketChat._.push(c) }
  window.RocketChat._ = []
  window.RocketChat.url = 'https://support.s-finance.pro/livechat'
  const h = document.getElementsByTagName('script')[0]
  const j = document.createElement('script')
  j.async = 'true'
  j.src = 'https://support.s-finance.pro/livechat/rocketchat-livechat.min.js?_=201903270000'
  h.parentNode.insertBefore(j, h)

  // eslint-disable-next-line func-names
  window.RocketChat(function () {
    this.setGuestName(FactoryModel.SettingsModel?.ProfileModel?.fullName)
    this.setGuestEmail(FactoryModel.SettingsModel?.ProfileModel?.email)
    this.setGuestToken(FactoryModel.SettingsModel?.ProfileModel?.id)
  })
}
export const hideRocketChat = () => {
  const rocket = document.getElementsByClassName('rocketchat-widget')[0]
  if (rocket) rocket.style.display = 'none'
}
export const showRocketChat = () => {
  const rocket = document.getElementsByClassName('rocketchat-widget')[0]
  if (rocket) {
    rocket.style.display = 'block'
  } else {
    initRocketChat()
  }
}
