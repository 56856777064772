import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { action, computed, makeObservable, observable } from 'mobx'
import { notifyError, notifySuccess, moment, DATE_FORMATS, isSafe, safeObject } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import _ from 'lodash'
import { contentMapApi } from 'providers/helpers/content'
import BaseModel from '../baseModel'

const AUTO_SAVE_DELAY = 3000

class ContentModel extends BaseModel {
  constructor() {
    super([])
    this.onChangeContent = _.debounce(this.autoSave, AUTO_SAVE_DELAY)
    makeObservable(this)
  }
  @observable activeTab = 'preview'

  @observable isSaving = false
  @observable isPublishingProcess = false
  @observable isFileUploading = false

  @observable slug = ''
  @observable isTop = false
  @observable title = ''
  @observable preview = ''
  @observable content = ''
  @observable category = ''
  @observable publishedAt = null
  @observable updatedAt = null
  @observable publishedBy = ''
  @observable updatedBy = ''

  @observable packageId = ''
  @observable scans = []
  @observable bundleId = ''

  @observable savedContent = ''
  @observable savedPreview = ''

  @computed get isPublished() {
    return isSafe(this.publishedAt)
  }

  @computed get isNotChanged() {
    return this.content === this.savedContent && this.preview === this.savedPreview
  }

  clear = action(() => {
    this.activeTab = 'preview'

    this.isSaving = false
    this.isPublishingProcess = false
    this.isFileUploading = false

    this.slug = ''
    this.isTop = false
    this.title = ''
    this.preview = ''
    this.content = ''
    this.category = ''
    this.publishedAt = null
    this.updatedAt = null
    this.publishedBy = ''
    this.updatedBy = ''

    this.packageId = ''
    this.scans = []
    this.bundleId = ''

    this.savedContent = ''
    this.savedPreview = ''
  })

  setTab = action(tab => {
    this.activeTab = tab
  })

  setContent = action(v => {
    this.content = v
    this.onChangeContent()
  })

  setPreview = action(v => {
    this.preview = v
    this.onChangeContent()
  })

  setSavedContent = action(() => {
    this.savedContent = this.content
    this.savedPreview = this.preview
  })

  togglePublicState = () => {}

  getContent = async () => {
    await FactoryProvider.ContentProvider.getContent()
  }

  getContentDetails = async (slug) => {
    try {
      const data = await FactoryProvider.ContentProvider.getDetails(slug)
      this.applyData(data)
      this.setSavedContent()
      const bundles = await FactoryProvider.PoscansProvider.getBundle({ packageID: data?.packageId })
      this.applyData({ bundleId: bundles[0].id })
      const scans = bundles[0].scans
      this.applyData({
        scans
      })
    } catch (e) {
      notifyError('Ошибка получения контента', prepareErrorMessage(e))
      throw e
    }
  }

  togglePublished = async () => {
    try {
      this.applyData({ isPublishingProcess: true })
      await FactoryProvider.ContentProvider.togglePublished(this.slug)
      this.applyData({
        publishedAt: this.isPublished ? null : moment(new Date(), DATE_FORMATS),
        publishedBy: FactoryModel.SettingsModel.ProfileModel.fullName
      })
    } catch (e) {
      notifyError('Ошибка публикации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isPublishingProcess: false })
    }
  }

  saveContent = async () => {
    if (this.isNotChanged) return
    try {
      this.applyData({ isSaving: true })
      const data = safeObject({
        content: this.content !== this.savedContent ? this.content : null,
        preview: this.preview !== this.savedPreview ? this.preview : null,
        slug: this.slug,
        title: this.title,
        category: this.category,
        isTop: this.isTop
      })
      await FactoryProvider.ContentProvider.putDetails(this.slug, contentMapApi(data))
      this.applyData({
        updatedAt: moment(new Date(), DATE_FORMATS),
        updatedBy: FactoryModel.SettingsModel.ProfileModel.fullName
      })
      this.setSavedContent()
      notifySuccess('Изменения успешно сохранены')
    } catch (e) {
      notifyError('Ошибка изменения контента', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSaving: false })
    }
  }

  autoSave = async () => {
    if (this.isNotChanged) return
    await this.saveContent()
  }

  uploadFile = async ({ file }) => {
    try {
      this.applyData({ isFileUploading: true })
      const scan = await FactoryProvider.PoscansProvider.addScan({
        packageID: this.packageId,
        bundleID: this.bundleId,
        scan: file
      })
      this.applyData({
        scans: [...this.scans, scan]
      })
      const { url, minUrl } = await FactoryProvider.PoscansProvider.putScan({
        packageID: this.packageId,
        scanID: scan.id,
        file
      })
      const index = _.findIndex(this.scans, s => s.id === scan.id)
      this.action(() => {
        this.scans[index].url = url
        this.scans[index].minUrl = minUrl
      })
    } catch (e) {
      notifyError('Ошибка загрузки файла', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isFileUploading: false })
    }
  }

  removeScan = async (id) => {
    try {
      FactoryProvider.PoscansProvider.deleteScan({
        packageID: this.packageId,
        scanID: id
      })
      this.applyData({
        scans: this.scans.filter(s => s.id !== id)
      })
    } catch (e) {
      notifyError('Ошибка удаления файла', prepareErrorMessage(e))
      throw e
    }
  }
}

export { ContentModel }
