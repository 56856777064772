import _ from 'lodash'
import { safeObject, moment, DATE_FORMATS, DATE_FORMAT, isSafe } from 'uikit'
import * as Sentry from '@sentry/node'
import FactoryModel from 'models/factoryModel'
import {
  sentryGetMissProductsPickupOffers,
  sentryGetUnselectedPickupOffers,
  sentryPreparePickupOffers
} from 'providers/helpers/pickup'
import BaseApiProvider from './baseApiProvider'
import { questionaryShortMap } from '../helpers/shortQuestionary'
import { scanMap, BORROWER_PHOTO, CC_PASSPORT23 } from '../helpers/poscans'
import {
  questionaryMap, questionaryMapApi, questionaryDashboardMap,
  shortApplicationMap, productMap, d2ItemMapApi, preparePickupOffersSelected, CALC_STEP
} from '../helpers/questionary'

const QuestionaryPickupFillingErrorMessage = 'Missing any products of pickup in calc step questionary'
class QuestionaryPickupFillingError extends Error {
  name = QuestionaryPickupFillingErrorMessage
  constructor() {
    super()
    this.name = QuestionaryPickupFillingErrorMessage
    this.message = QuestionaryPickupFillingErrorMessage

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, QuestionaryPickupFillingError)
    } else {
      this.stack = (new Error()).stack
    }
  }
}

class QuestionaryProvider extends BaseApiProvider {
  async getQuestionaryList(params = {}) {
    const {
      page = 1, pageSize = 10, status = '', queryText = '', queryType = '',
      isOnlyUnauthorized = false
    } = params
    const data = await this.fetch({
      uri: !isOnlyUnauthorized ? '/questionaries' : '/questionaries/unauthorized',
      query: safeObject({
        status: status || null,
        q: queryText !== '' ? queryText : null,
        fields: !_.isEmpty(queryType) ? [queryType] : null,
        page,
        per_page: pageSize
      })
    })
    return _.map(data, questionary => questionaryShortMap({ questionary }))
  }

  async getUnauthorizationCount() {
    const data = await this.fetch({
      uri: '/questionaries/unauthorized_count'
    })
    return data?.count ?? 0
  }

  async duplicateQuestionary(questionaryId, creditRequestId) {
    const data = await this.fetch({
      uri: `/questionaries/${questionaryId}/duplicate`,
      method: 'PUT',
      query: safeObject({
        credit_request_id: creditRequestId || null
      })
    })
    return _.get(data, 'id')
  }

  async assignQuestionary(id) {
    return await this.fetch({
      uri: `/questionaries/${id}/assign`,
      method: 'PUT'
    })
  }

  async getQuestionary(id) {
    const data = await this.fetch({
      uri: `/questionaries/${id}`
    })
    return questionaryMap({ ...data })
  }

  async findClients(params = {}) {
    const { phone = '', passport = '' } = params
    const data = await this.fetch({
      uri: '/clients/search',
      query: safeObject({
        phone: phone ? `7${phone}` : null,
        passport: passport || null
      })
    })
    return _.map(data, questionary => questionaryMap({ questionary }))
  }

  async createQuestionary(questionary, creditRequestID = null) {
    const data = await this.fetch({
      uri: '/questionaries',
      method: 'POST',
      data: safeObject({
        questionary: questionaryMapApi({ questionary, step: 'main' }),
        credit_request_id: creditRequestID || null
      })
    })
    return questionaryMap({ questionary: data })
  }

  async updateQuestionary(id, questionary, step, isMap = true) {
    const data = await this.fetch({
      uri: `/questionaries/${id}`,
      method: 'PATCH',
      data: {
        questionary: isMap ? questionaryMapApi({ questionary, step }) : {
          ...questionary,
          current_step: step
        }
      }
    })

    if (step === CALC_STEP) {
      const unSelectedOffers = sentryGetUnselectedPickupOffers(questionary?.offers)
      const emptyBanks = sentryGetMissProductsPickupOffers(questionary?.offers)

      if (!_.isEmpty(unSelectedOffers) || !_.isEmpty(emptyBanks)) {
        Sentry.withScope(scope => {
          scope.setTag('questionaryId', id)
          scope.setTag('pickupResultId', FactoryModel.PickupModel.calculationId)
          scope.setTag('calculationDate', FactoryModel.PickupModel.calculationDate)
          scope.setTag('getAfterWait', questionary?.isGetOffersAfterWait)
          scope.setExtra('questionaryId', id)
          scope.setExtra('pickupResultId', FactoryModel.PickupModel.calculationId)
          scope.setExtra('calculationDate', FactoryModel.PickupModel.calculationDate)
          scope.setExtra('unSelectedOffers', unSelectedOffers)
          scope.setExtra('emptyBanks', emptyBanks)
          scope.setExtra('getAfterWait', questionary?.isGetOffersAfterWait)
          scope.setExtra('allOffers', sentryPreparePickupOffers(questionary?.offers))
          scope.setExtra('postSentryOffers', sentryPreparePickupOffers(questionary?.postSentryOffers))
          Sentry.captureEvent(new QuestionaryPickupFillingError())
        })
      }
    }
    return questionaryMap({ questionary: data })
  }

  async sendConfirmCode(id) {
    const data = await this.fetch({
      uri: `/questionaries/${id}/code`,
      method: 'POST'
    })
    const date = data?.expires_at ? moment(data?.expires_at, DATE_FORMATS).toDate() : null
    if (!date) return 60
    return parseInt((+date - +new Date()) / 1000)
  }

  async confirmPhone(id, smsCode) {
    await this.fetch({
      uri: `/questionaries/${id}/code/confirm`,
      method: 'PUT',
      data: {
        code: smsCode
      }
    })
  }

  async getApplications(id) {
    const data = await this.fetch({
      uri: `/questionaries/${id}/dashboard/preview`
    })
    return _.map(data, application => shortApplicationMap({ application }))
  }

  async addPhoto(id) {
    const data = await this.fetch({
      uri: `/questionaries/${id}/photo/new`
    })
    return {
      photoPackageId: _.get(data, 'package_id', null),
      photoScanId: _.get(data, 'scans.0.poscan_id', null),
      photoQrCode: _.get(data, 'qr_code', '')
    }
  }

  async getQuestionaryDashboard(id) {
    const data = await this.fetch({ uri: `/questionaries/${id}/dashboard` })
    return questionaryDashboardMap({ questionary: data })
  }

  async getQuestionaryDashboardCommon(id) {
    const data = await this.fetch({ uri: `/questionaries/${id}/dashboard/common` })
    return questionaryDashboardMap({ questionary: data })
  }

  async selectOffer(questionaryId, offerId, options) {
    const { deliveryDate, d2Items, eanCardNumber } = options ?? {}
    let data = {}
    if (isSafe(deliveryDate)) {
      data = {
        payload: { delivery_date: moment(deliveryDate, DATE_FORMATS).format(DATE_FORMAT) }
      }
    }
    if (d2Items?.length) {
      data = {
        payload: { items: _.map(d2Items, item => d2ItemMapApi({ item })) }
      }
    }
    if (isSafe(eanCardNumber)) {
      data = safeObject({
        ean_card_number: eanCardNumber
      })
    }

    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/select`,
      method: 'POST',
      data
    })
  }

  async confirmOffer(questionaryId, offerId, sesCode) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/confirm`,
      method: 'POST',
      data: safeObject({
        ses_code: isSafe(sesCode) ? sesCode : null
      })
    })
  }

  async requestSesCode(questionaryId, offerId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/request_ses_code`,
      method: 'POST'
    })
  }

  async markAsSelectOffer(questionaryId, offerId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/mark_as_select`,
      method: 'POST'
    })
  }

  async selectExternalOffer(questionaryId, offerId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/select_external`,
      method: 'POST'
    })
  }

  async fallbackToManualSignOffer(questionaryId, offerId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/fallback_to_manual_sign`,
      method: 'POST'
    })
  }

  async checkExternalOffer(questionaryId, offerId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/check_external`,
      method: 'POST'
    })
  }

  async retryRegistration(questionaryId, appId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/bank_applications/${appId}/retry_registration`,
      method: 'POST'
    })
  }

  async attachScans(questionaryId, offerId) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/attach_scans`,
      method: 'POST'
    })
  }

  async rejectQuestionary(id) {
    return await this.fetch({
      uri: `/questionaries/${id}/reject`,
      method: 'PUT'
    })
  }

  async cancelQuestionary(id) {
    return await this.fetch({
      uri: `/questionaries/${id}/cancel`,
      method: 'PUT'
    })
  }

  async completeQuestionary(id) {
    return await this.fetch({
      uri: `/questionaries/${id}/complete`,
      method: 'PUT'
    })
  }

  async getProducts(questionaryId, appId) {
    const res = await this.fetch({
      uri: `/questionaries/${questionaryId}/dashboard/bank_applications/${appId}`
    })
    return _.map(_.get(res, 'bank_applications', []), product => productMap({ product }))
  }

  async getPreview(id) {
    const data = await this.fetch({
      uri: `/questionaries/${id}/preview`
    })

    return questionaryMap({ questionary: data })
  }

  async createPassportIdentificationPackage(phone) {
    const res = await this.fetch({
      uri: '/questionaries/documents_package/create',
      method: 'POST',
      data: {
        mobile_phone: `7${phone}`
      }
    })

    const currentScans = _.map(res?.scans, scan => scanMap({ scan }))
    const scans = _.filter(currentScans, scan => scan?.commonCode !== BORROWER_PHOTO && scan?.commonCode !== CC_PASSPORT23)
    const passport23Scan = _.find(currentScans, scan => scan?.commonCode === CC_PASSPORT23)
    if (passport23Scan) scans.unshift(passport23Scan)

    return {
      packageId: _.get(res, 'package_id', null),
      scans,
      qrCode: _.get(res, 'qr_code', '')
    }
  }

  async sendDocuments(questionaryId, offerId, email) {
    return await this.fetch({
      uri: `/questionaries/${questionaryId}/offers/${offerId}/send_documents`,
      method: 'POST',
      data: {
        email
      }
    })
  }
}

export { QuestionaryProvider }
