import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'

class RequestCallProvider extends BaseApiProvider {
  async requestCall(params) {
    const { fullName, mobilePhone, comment } = params
    return await this.fetch({
      uri: '/request_call',
      method: 'POST',
      data: safeObject({
        name: fullName,
        phone_number: mobilePhone,
        comment
      })
    })
  }
}

export { RequestCallProvider }
