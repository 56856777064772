import { observable, computed, action, makeObservable } from 'mobx'
import { MENU_ITEMS } from 'utils/menu'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { phoneSimpleMap } from 'providers/helpers/phone'
import BaseModel from '../../baseModel'

class CommonModel extends BaseModel {
  ctx = null

  @observable isLoading = false

  @observable callCenter = {
    id: '',
    requestsView: '',
    isScansRequired: false
  }
  @observable categoryIDS = []
  @observable userRoles = []
  @observable withoutDiscountCategoryIDS = []
  @observable yellowSubmarineJournal = {
    endpoint: '',
    username: '',
    password: ''
  }
  @observable point = {
    id: '',
    name: '',
    macroregionName: '',
    segment: ''
  }
  @observable manager = {
    id: '',
    fullName: '',
    phone: ''
  }
  @observable calculator = {
    isOwnerChange: false,
    showTips: false
  }
  @observable navigation = {
    calc: false,
    tutorial: false,
    settings: false,
    tips: false,
    news: false,
    dailyReport: false,
    accreditation: false,
    motivation: false,
    idea: false,
    selectPoint: false,
    questionaries: {
      list: false,
      create: false
    },
    creditRequests: {
      list: false,
      create: false
    },
    points: {
      list: false,
      create: false,
      details: false,
      update: false,
      codes: false,
      update_codes: false,
      bank_mix: false,
      update_bank_mix: false,
      sales: false,
      history: false,
      users: false,
      update_users: false,
      agent_commission: false,
      update_agent_comission: false,
      accreditation: false,
      update_comment: false
    },
    users: {
      list: false,
      create: false,
      details: false,
      update: false,
      codes: false,
      update_codes: false,
      sales: false,
      history: false,
      agent_commission: false,
      update_agent_comission: false,
      update_comment: false
    },
    organizations: {
      list: false,
      create: false,
      details: false,
      update: false,
      bank_accounts: false,
      update_bank_accounts: false,
      codes: false,
      update_codes: false,
      bank_mix: false,
      update_bank_mix: false,
      history: false,
      settings: false,
      update_settings: false,
      docs: false,
      update_docs: false,
      points: false,
      update_points: false,
      full_view: false
    },
    profile: {
      details: false,
      update: false
    },
    registries: {
      list: false,
      create: false,
      reject: false
    }
  }
  @observable options = {
    isQuestionaryPassportRecognition: false
  }

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isFullCallCenter() {
    return this.callCenter.requestsView === 'full'
  }

  @computed get menuItems() {
    const navigation = this.navigation
    return !navigation ? [] : MENU_ITEMS.filter(
      item => (Object.prototype.hasOwnProperty.call(navigation[item.id], 'list') ? navigation[item.id].list : navigation[item.id] == true) || item.isForce
    )
  }

  getSettings = async (tempToken) => {
    this.ctx.ProfileModel.userChannelRemove()

    try {
      this.applyData({ isLoading: true })
      const isExistToken = FactoryModel.AuthModel.CommonModel.isExistToken
      const settings = isExistToken ? await FactoryProvider.SettingsProvider.getSettings() : await FactoryProvider.SettingsProvider.getTempSettings(tempToken)

      const point = {
        id: _.get(settings, 'point.id') || _.get(settings, 'point._id'),
        name: _.get(settings, 'point.name', ''),
        macroregionName: _.get(settings, 'point.macroregion_name', ''),
        segment: _.get(settings, 'point.segment', '')
      }

      this.applyData({
        callCenter: {
          id: _.get(settings, 'call_center.id'),
          requestsView: _.get(settings, 'call_center.requests_view'),
          isScansRequired: _.get(settings, 'call_center.scans_required', false)
        },
        categoryIDS: _.get(settings, 'category_ids', []),
        userRoles: _.get(settings, 'user_roles', []),
        withoutDiscountCategoryIDS: _.get(settings, 'without_discount_category_ids', []),
        yellowSubmarineJournal: {
          endpoint: _.get(settings, 'yellow_submarine_journal.endpoint', ''),
          username: _.get(settings, 'yellow_submarine_journal.username', ''),
          password: _.get(settings, 'yellow_submarine_journal.password', '')
        },
        point,
        manager: {
          id: _.get(settings, 'manager.id'),
          fullName: _.get(settings, 'manager.fullname', ''),
          phone: _.get(settings, 'manager.phone', '')
        },
        calculator: {
          isOwnerChange: _.get(settings, 'calculator.owner_change', false),
          showTips: _.get(settings, 'calculator.show_tips', false)
        },
        navigation: settings.navigation ? {
          calc: _.get(settings, 'navigation.pickup', false),
          tutorial: _.get(settings, 'navigation.tutorial', false),
          settings: _.get(settings, 'navigation.settings', false),
          my_applications: _.get(settings, 'navigation.my_applications', false),
          tips: _.get(settings, 'navigation.tips', false),
          partner_cabinet: _.get(settings, 'navigation.partner_cabinet', false),
          credit_offer: _.get(settings, 'navigation.credit_offer', false),
          news: _.get(settings, 'navigation.news', false),
          dailyReport: _.get(settings, 'navigation.daily_report', false),
          accreditation: _.get(settings, 'navigation.accreditation', false),
          motivation: _.get(settings, 'navigation.motivation', false),
          idea: _.get(settings, 'navigation.idea', false),
          selectPoint: _.get(settings, 'navigation.select_point', false),
          points: _.get(settings, 'navigation.points', {}),
          users: _.get(settings, 'navigation.users', {}),
          organizations: _.get(settings, 'navigation.partners', {}),
          profile: _.get(settings, 'navigation.profile', {}),
          registries: _.get(settings, 'navigation.registries', {}),
          questionaries: _.get(settings, 'navigation.questionaries', {}),
          credit_requests: _.get(settings, 'navigation.credit_requests', {})
        } : null,
        options: {
          isQuestionaryPassportRecognition: _.get(settings, 'options.questionary_passport_recognition', false)
        }
      })

      this.ctx.ProfileModel.applyProfile(settings?.me ?? {})

      if (settings?.calculator?.owner_change) {
        FactoryModel.PickupModel.applyOwnerChangeAccess({ globalAccess: true })
      } else {
        FactoryModel.PickupModel.applyOwnerChangeAccess({ globalAccess: false })
      }
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  setPoint = action((id, name, macroregionName) => {
    this.point.id = id
    this.point.name = name
    this.point.macroregionName = macroregionName
  })

  setManager = action((id, name, phone) => {
    this.manager.id = id
    this.manager.fullName = name
    this.manager.phone = phone
  })
}

export { CommonModel }
