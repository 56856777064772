import _ from 'lodash'
import BaseApiProvider from './baseApiProvider'
import { bankMap } from '../helpers/bank'
import { bankProductsMap } from '../helpers/product'

class BankProvider extends BaseApiProvider {
  async getBanks(extended = true) {
    const data = await this.fetch({
      uri: '/banks',
      query: {
        extended
      }
    })
    return _.map(data, bank => bankMap({ bank, isShort: false }))
  }

  async searchBanks() {
    const data = await this.fetch({
      uri: '/banks'
    })
    return _.map(data, bank => bankMap({ bank }))
  }

  async getProducts(bankID) {
    const data = await this.fetch({
      uri: `/banks/${bankID}/products`
    })
    return bankProductsMap({ bankProducts: data })
  }
}

export { BankProvider }
