import _ from 'lodash'
import { safeObject, moment, DATE_FORMATS, DATE_FORMAT } from 'uikit'
import { EMPLOYMENT_STATUS_NOT_WORKING, EMPLOYMENT_STATUS_PERMANENT } from 'utils/dict'
import BaseApiProvider from './baseApiProvider'
import { offerMap, offerShortMap, categoryMap, serviceGroupMap } from '../helpers/pickup'

class PickupProvider extends BaseApiProvider {
  async calculate(params = {}) {
    const {
      term,
      initialPayment,
      goods,
      birthDate = null,
      isAvailDiscount = false,
      additionalServices = [],
      pointId = null,
      userId = null
    } = params
    const data = await this.fetch({
      uri: '/calc',
      method: 'POST',
      data: safeObject({
        term: Number(term),
        initial_payment: Number(initialPayment),
        goods: !_.isEmpty(goods) ? goods : null,
        avail_discount: isAvailDiscount,
        birthdate: birthDate ? moment(birthDate, DATE_FORMATS).format(DATE_FORMAT) : '01.01.1990',
        additional_service_ids: !_.isEmpty(additionalServices) ? additionalServices : null,
        point_id: pointId || null,
        owner_id: userId || null
      })
    })
    return {
      calculationId: data.id,
      offers: _.map(data?.banks, offer => offerShortMap({ offer }))
    }
  }

  async getMyOffers(calculationId, isFinish = false) {
    const data = await this.fetch({
      uri: `/calc/${calculationId}`
    })
    return {
      offers: _.map(data?.banks, offer => offerMap({ offer, isFinish })),
      services: data?.additional_service_ids ?? [],
      initialPayment: data?.initial_payment ? Number(data?.initial_payment) : 0,
      term: data?.term ? Number(data?.term) : 0
    }
  }

  async getCategories(params = {}) {
    const { search = '' } = params
    const data = await this.fetch({
      uri: '/goods_categories',
      query: safeObject({
        query: search !== '' ? search : null
      })
    })
    return _.map(data, category => categoryMap({ category }))
  }

  async getServices(params = {}) {
    const { pointId = '' } = params
    const data = await this.fetch({
      uri: '/calc/new',
      query: safeObject({
        point_id: pointId || null
      })
    })
    return _.map(data, group => serviceGroupMap({ group }))
  }

  async getServiceCost(id, initialPayment, term, goods) {
    const { cost } = await this.fetch({
      uri: `/services/${id}/calc`,
      method: 'POST',
      data: safeObject({
        calculation: {
          initial_payment: initialPayment ? Number(initialPayment) : 0,
          term: parseInt(term, 10),
          goods
        }
      })
    })

    return cost
  }
}

export { PickupProvider }
