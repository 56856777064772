import BaseApiProvider from './baseApiProvider'
import { educationMaterialsMap } from '../helpers/educationMaterials'

class TutorialProvider extends BaseApiProvider {
  async getEducationMaterials() {
    const data = await this.fetch({
      uri: '/education_materials'
    })
    return educationMaterialsMap(data)
  }
}

export { TutorialProvider }
