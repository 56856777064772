import _ from 'lodash'
import fp from 'lodash/fp'
import { action, computed, makeObservable, observable } from 'mobx'
import { push } from 'utils/history'
import FactoryProvider from 'providers/factoryProvider'
import { moment, notifySuccess, notifyError } from 'uikit'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'

const fields = [
  { field: 'periodBegin', path: 'details.periodBegin' },
  { field: 'periodEnd', path: 'details.periodEnd' },
  { field: 'bank', path: 'details.bank' },
  { field: 'point', path: 'details.point' },
  { field: 'workerType', path: 'details.workerType' },
  { field: 'worker', path: 'details.worker' },
  { field: 'isCheckAll', path: 'details.isCheckAll' },
  { field: 'isShowDetailsConstrains', path: 'details.isShowDetailsConstrains' },
  { field: 'isShowDetailsConfirm', path: 'details.isShowDetailsConfirm' },

  { field: 'searchNumber', path: 'listFilter.searchNumber' },
  { field: 'filterType', path: 'listFilter.filterType' },
  { field: 'receiverType', path: 'listFilter.receiverType' }
]

const defaultDetails = {
  actID: null,
  periodBegin: moment(new Date(), 'DD.MM.YYYY').startOf('month').toDate(),
  periodEnd: moment(new Date(), 'DD.MM.YYYY').endOf('month').toDate(),
  bank: null,
  point: [],
  workerType: 'internal',
  worker: null,
  isCheckAll: false,
  isLoadingResultDetails: false,
  isLoadingSave: false,
  isLoadingDownload: false,
  isShowDetailsConstrains: false,
  countConstrains: 0,
  maxConstrains: 0,
  isShowDetailsConfirm: false,
  intersectingActID: null,
  isSave: false
}

class AcceptanceCertificateModel extends BaseModel {
  constructor() {
    super(fields)
    makeObservable(this)
  }

  handleAfterChangeFields = state => {
    const field = _.get(state, 'field.field')
    if (field === 'periodBegin' || field === 'periodEnd' || field === 'bank' || field === 'point' ||
      field === 'workerType' || field === 'worker') {
      this.applyData({ resultDetails: [] })
    }
  }

    @observable details = deepMerge({}, defaultDetails)
    @observable banks = []
    @observable points = []
    @observable users = []
    @observable isLoadingUsers = false
    @observable resultDetails = []

    @observable listFilter = {
      searchNumber: '',
      filterType: ['contract_no'],
      receiverType: 'all'
    }
    @observable list = {
      data: [],
      total: 0,
      pageSize: 10,
      page: 1,
      isLoadingList: false
    }

    @computed get isExistResultDetails() {
      return !_.isEmpty(this.resultDetails)
    }

    @computed get isAcceptFindResultDetails() {
      return !!this.details.periodBegin && !!this.details.periodEnd && !!this.details.bank &&
          !_.isEmpty(this.details.point) &&
          !!this.details.workerType && this.details.worker !== '' && !_.isNull(this.details.worker)
    }

    @computed get isExistCheckResultDetails() {
      return !_.isEmpty(fp.filter(r => r.isCheck)(this.resultDetails))
    }

    @computed get resultDetailsCount() {
      return this.resultDetails.length
    }

    @computed get checkResultDetailsCount() {
      return _.filter(this.resultDetails, r => r.isCheck).length
    }

    @action getBanks = async () => {
      if (_.isEmpty(this.banks)) {
        const banks = await FactoryProvider.ActProvider.getBanks()
        this.applyData({
          banks: fp.map(b => ({
            id: `${b.id}`,
            name: b.name
          }))(banks)
        })
      }
    }

    @action getPoints = async value => {
      const points = await FactoryProvider.ActProvider.getPoints(value)
      this.applyData({
        points
      })
    }

    @action getUsers = async value => {
      try {
        this.applyData({ isLoadingUsers: true })
        const users = await FactoryProvider.ActProvider.getUsers(value)
        this.applyData({
          users
        })
      } finally {
        this.applyData({ isLoadingUsers: false })
      }
    }

    @action setWorkerType = type => {
      this.applyData({
        'details.workerType': type,
        'details.worker': type !== 'internal' ? '' : null
      })
    }

    @action getResultDetails = async () => {
      try {
        this.applyData({
          'details.isCheckAll': false,
          'details.isLoadingResultDetails': true,
          'details.isLoadingSave': false,
          'details.isLoadingDownload': false,
          'details.isShowDetailsConstrains': false,
          'details.countConstrains': 0,
          'details.maxConstrains': 0,
          'details.isShowDetailsConfirm': false,
          'details.intersectingActID': null,
          'details.isSave': false
        })
        const { credits, countConstrains, maxConstrains } = await FactoryProvider.ActProvider.getRegistriesCredits({
          periodBegin: this.details.periodBegin,
          periodEnd: this.details.periodEnd,
          point: fp.map(p => p.key)(this.details.point),
          bank: this.details.bank
        })
        if (countConstrains > 0) {
          this.applyData({
            'details.countConstrains': countConstrains,
            'details.maxConstrains': maxConstrains,
            'details.isShowDetailsConstrains': true
          })
        } else {
          this.applyData({
            resultDetails: fp.map(r => ({
              ...r,
              isCheck: true
            }))(credits),
            'details.isCheckAll': true,
            'details.countConstrains': 0,
            'details.maxConstrains': 0,
            'details.isShowDetailsConstrains': false
          })
          if (_.isEmpty(credits)) notifyError('Не найдено ни одного кредита')
        }
      } finally {
        this.applyData({ 'details.isLoadingResultDetails': false })
      }
    }

    @action changeFilterType = v => {
      let newType = []
      _.each(v, n => {
        if (_.findIndex(this.listFilter.filterType, f => f === n) === -1) {
          newType = [n]
          return false
        }
      })
      this.applyData({
        'listFilter.filterType': newType
      })
    }

    @action changeReceiverType = async type => {
      this.applyData({ 'listFilter.receiverType': type })
      await this.getList()
    }

    @action checkResultDetailItem = (id, isCheck) => {
      const findIndex = _.findIndex(this.resultDetails, r => r.id === id)
      if (findIndex !== -1) {
        this.resultDetails[findIndex].isCheck = isCheck
        this.applyData(_.omitBy({
          resultDetails: this.resultDetails,
          'details.isSave': false,
          'details.isCheckAll': !_.isEmpty(fp.filter(r => !r.isCheck)(this.resultDetails)) ? false : null
        }, _.isNull))
      }
    }

    @action checkResultAll = isCheck => {
      _.each(this.resultDetails, r => {
        r.isCheck = isCheck
      })
      this.details.isCheckAll = isCheck
      this.details.isSave = false
    }

    @action saveResultDetails = async (isForce = false) => {
      try {
        this.applyData({
          'details.isLoadingSave': true
        })
        const { actID, intersectingActID } = await FactoryProvider.ActProvider.createRegistriesAct({
          periodBegin: this.details.periodBegin,
          periodEnd: this.details.periodEnd,
          bank: this.details.bank,
          workerType: this.details.workerType,
          worker: this.details.workerType !== 'internal' ? this.details.worker :
            _.get(_.find(this.users, u => u.id === this.details.worker), 'name', ''),
          creditIDS: fp.flow(
            fp.filter(r => r.isCheck),
            fp.map(r => r.id)
          )(this.resultDetails),
          pointIDS: fp.map(p => p.key)(this.details.point),
          isForce
        })
        if (intersectingActID) {
          this.applyData({
            'details.isShowDetailsConfirm': true,
            'details.intersectingActID': intersectingActID
          })
        } else {
          this.applyData({
            'details.isSave': true,
            'details.actID': actID
          })
        }
      } finally {
        this.applyData({
          'details.isLoadingSave': false
        })
      }
    }

    @action downloadResultDetails = async (actID = null) => {
      try {
        if (!actID) {
          this.applyData({
            'details.isLoadingDownload': true
          })
          push({ uri: '/bank-registry' })
        }
        const res = await FactoryProvider.ActProvider.downloadAct(actID || this.details.actID)
        this.downloadFile(res)
      } finally {
        if (!actID) {
          this.applyData({
            details: deepMerge.all([{}, defaultDetails, { isLoadingDownload: false }])
          })
        }
      }
    }

    setDetailPoint = action(v => {
      this.details.point = v
    })

    @computed get isExistList() {
      return !_.isEmpty(this.list.data)
    }

    @action getList = async (page = 1) => {
      try {
        this.applyData({
          'list.isLoadingList': true,
          'list.page': page
        })
        const { list, total, pageSize } = await FactoryProvider.ActProvider.getActs(_.omitBy({
          searchNumber: this.listFilter.searchNumber,
          receiverType: this.listFilter.receiverType !== 'all' ? this.listFilter.receiverType : null,
          filterType: this.listFilter.filterType,
          page,
          pageSize: this.list.pageSize
        }, _.isNull))
        this.applyData({
          'list.data': list,
          'list.pageSize': pageSize,
          'list.total': total
        })
      } finally {
        this.applyData({ 'list.isLoadingList': false })
      }
    }

    @action createAcceptance = () => {
      push({ uri: '/bank-registry/new' })
    }

    @action downloadAct = async id => {
      // eslint-disable-next-line no-useless-catch
      try {
        this.applyData({ 'list.data': fp.map(l => ({
          ...l,
          isDownloadingAct: l.id === id ? true : !!l.isDownloadingAct
        }))(this.list.data) })

        const res = await FactoryProvider.ActProvider.downloadAct(id || this.details.actID)
        this.downloadFile(res)

        this.applyData({ 'list.data': fp.map(l => ({
          ...l,
          isDownloadingAct: l.id === id ? false : !!l.isDownloadingAct
        }))(this.list.data) })
      } catch (e) {
        throw e
      }
    }

    @action uploadAct = async (actID, file) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const act = await FactoryProvider.ActProvider.addScan(actID, file)
        notifySuccess('Скан загружен')
        const acts = fp.map(l => (l.id === actID ? act : l))(this.list.data)
        this.applyData({ 'list.data': acts })
      } catch (e) {
        throw e
      }
    }

    @action downloadScan = async (id) => {
      // eslint-disable-next-line no-useless-catch
      try {
        this.applyData({ 'list.data': fp.map(l => ({
          ...l,
          isDownloadingScan: l.id === id ? true : !!l.isDownloadingScan
        }))(this.list.data) })

        const res = await FactoryProvider.ActProvider.downloadScan(id)
        this.downloadFile(res)

        this.applyData({ 'list.data': fp.map(l => ({
          ...l,
          isDownloadingScan: l.id === id ? false : !!l.isDownloadingScan
        }))(this.list.data) })
      } catch (e) {
        throw e
      }
    }

    @action removeAct = async id => {
      await FactoryProvider.ActProvider.removeAct(id)
      notifySuccess('Акт удален')
      const acts = fp.map(l => ({
        ...l,
        state: l.id === id ? 'rejected' : l.state,
        status: l.id === id ? 'Отклонен' : l.status
      }))(this.list.data)
      this.applyData({ 'list.data': acts })
    }

    @action removeActScan = async (id) => {
      const act = await FactoryProvider.ActProvider.removeActScan(id)
      notifySuccess('Скан удален')
      const acts = fp.map(l => (l.id === id ? act : l))(this.list.data)
      this.applyData({ 'list.data': acts })
    }

    downloadFile = (res) => {
      const blob = new Blob([res.data])
      const url = window.URL.createObjectURL(blob)
      const ref = document.createElement('a')
      ref.href = url
      const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
      ref.setAttribute('download', decodeURIComponent(escape(filename)) || 'file.pdf')
      ref.click()
      ref.remove()
    }
}

export { AcceptanceCertificateModel }
