const notRedirectPaths = [
  '/',
  '/login',
  '/form',
  '/partner/sign_up',
  '/partner/proposition/[action]',
  '/users/finish_registration',
  '/restore_password',
  '/users/reset_password',
  '/users/reset_password_by_phone',
  '/welcome/[action]'
]

export const isNotRedirectAuth = pathname => notRedirectPaths.indexOf(pathname) !== -1
