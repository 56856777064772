import BaseApiProvider from './baseApiProvider'

class DictProvider extends BaseApiProvider {
  async getDict() {
    return await this.fetch({
      uri: '/dict'
    })
  }
}

export { DictProvider }
