import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import BaseModel from '../baseModel'

class AnalyticsModel extends BaseModel {
  getPaq = () => window._paq = window._paq || []

  initMatomo = () => {
    if (!FactoryProvider.ConfigProvider.config.matomoUrl) return
    const paq = this.getPaq()
    paq.push(['trackPageView'])
    paq.push(['enableLinkTracking'])
    const u = FactoryProvider.ConfigProvider.config.matomoUrl
    paq.push(['setTrackerUrl', `${u}matomo.php`])
    paq.push(['setSiteId', FactoryProvider.ConfigProvider.config.matomoId])
    const d = document
    const g = d.createElement('script')
    const s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.async = true
    g.src = `${u}matomo.js`
    s.parentNode.insertBefore(g, s)
    window.addEventListener('hashchange', () => {
      paq.push(['setCustomUrl', `/${window.location.hash.substr(1)}`])
      paq.push(['setDocumentTitle', 'My New Title'])
      paq.push(['trackPageView'])
    })
  }

  setUserId = () => {
    const paq = this.getPaq()
    paq.push(['setUserId', FactoryModel.SettingsModel?.ProfileModel?.id])
    paq.push(['trackPageView'])
  }

  resetUserId = () => {
    const paq = this.getPaq()
    paq.push(['resetUserId'])
    paq.push(['appendToTrackingUrl', 'new_visit=1'])
    paq.push(['trackPageView'])
    paq.push(['appendToTrackingUrl', ''])
  }

  handleServiceInfoOpen = (title) => {
    const paq = this.getPaq()
    paq.push(['setCustomDimension', FactoryProvider.ConfigProvider.config.matomoUserNameCode, FactoryModel.SettingsModel?.ProfileModel?.fullName])
    paq.push(['setCustomDimension', FactoryProvider.ConfigProvider.config.matomoPointIdCode, FactoryModel.SettingsModel?.CommonModel?.point?.id])
    paq.push(['trackEvent', 'Информация об услуге', 'Показ диалога', title])
  }
  handleServiceInfoClose = (title) => {
    const paq = this.getPaq()
    paq.push(['trackEvent', 'Информация об услуге', 'Закрытие диалога без действия', title])
  }
  handleServiceInfoEnable = (title) => {
    const paq = this.getPaq()
    paq.push(['trackEvent', 'Информация об услуге', 'Включить услугу', title])
  }

  handleReminderOpen = (title) => {
    const paq = this.getPaq()
    paq.push(['setCustomDimension', FactoryProvider.ConfigProvider.config.matomoUserNameCode, FactoryModel.SettingsModel?.ProfileModel?.fullName])
    paq.push(['setCustomDimension', FactoryProvider.ConfigProvider.config.matomoPointIdCode, FactoryModel.SettingsModel?.CommonModel?.point?.id])
    paq.push(['trackEvent', 'Уведомление об отключении', 'Показ диалога', title])
  }
  handleReminderDisable = (title) => {
    const paq = this.getPaq()
    paq.push(['trackEvent', 'Уведомление об отключении', 'Выключить', title])
  }
  handleReminderEnable = (title) => {
    const paq = this.getPaq()
    paq.push(['trackEvent', 'Уведомление об отключении', 'Оставить включенным', title])
  }

  handleAlfaBankBannerClick = () => {
    this.handleTracedClick('Баннер Альфа банка', 'Переход по баннеру')
  }
  handleCallBackClick = () => {
    this.handleTracedClick('Заказ обратного звонка', 'Открытие диалога')
  }
  handleTracedClick = (category, action) => {
    const paq = this.getPaq()
    paq.push(['setCustomDimension', FactoryProvider.ConfigProvider.config.matomoUserNameCode, FactoryModel.SettingsModel?.ProfileModel?.fullName])
    paq.push(['setCustomDimension', FactoryProvider.ConfigProvider.config.matomoPointIdCode, FactoryModel.SettingsModel?.CommonModel?.point?.id])
    paq.push(['trackEvent', category, action])
  }
}

export { AnalyticsModel }
