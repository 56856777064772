import { ROLE_ADMIN, ROLE_MANAGER, ROLE_PARTNER, ROLE_CALL_CENTER_OPERATOR, ROLE_USER } from 'utils/roles'

const ROLES = [
  {
    value: ROLE_USER,
    label: 'Пользователь'
  },
  {
    value: ROLE_MANAGER,
    label: 'Менеджер'
  },
  {
    value: ROLE_PARTNER,
    label: 'Администратор партнёра'
  },
  {
    value: ROLE_CALL_CENTER_OPERATOR,
    label: 'Оператор колл-центра'
  },
  {
    value: ROLE_ADMIN,
    label: 'Администратор'
  }
]

const ROLES_MAP = {
  [ROLE_USER]: 'Пользователь',
  [ROLE_MANAGER]: 'Менеджер',
  [ROLE_PARTNER]: 'Администратор партнёра',
  [ROLE_CALL_CENTER_OPERATOR]: 'Оператор колл-центра',
  [ROLE_ADMIN]: 'Администратор'
}

export { ROLES, ROLES_MAP }

export const accessRoleMap = ({ role }) => ({
  id: role.id,
  name: role.name,
  behaviors: role.behaviors,
  isRequiresRegionsAndMacroregionsForUser: !!role.requires_regions_and_macroregions_for_user
})
