import { observable, action, computed, makeObservable } from 'mobx'
import { validRequired, scrollTo, CyrillicNameWithSpace, notifyError } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { PROPOSITION_SIGN_STATE } from 'providers/helpers/partnerProposition'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../../baseModel'

class AttachmentModel extends BaseModel {
  ctx = null

  RESPONSIBLE_BLOCk = 'attachmentBlock:responsible'
  MAIN_BLOCK = 'attachmentBlock:main'

  @observable fullName = ''
  @observable isChangeFullName = false

  @observable url = 'https://s-finance.pro'

  @observable isInitCode = false
  @observable confirmCode = ''
  @observable isUpdateCode = false
  @observable isSendingConfirmAttachment = false
  @observable confirmIntervalAttachment = 60
  confirmAttachmentIntervalID = null

  @observable isForceValidate = false
  @observable isProcessSave = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.fullName = ''
    this.isChangeFullName = false

    this.url = 'https://s-finance.pro'

    this.isInitCode = false
    this.confirmCode = ''
    this.isUpdateCode = false
    this.isSendingConfirmAttachment = false
    this.confirmIntervalAttachment = 60

    this.isForceValidate = false
    this.isProcessSave = false

    this.clearConfirmAttachmentInterval()
  })

  @computed get validFio() {
    if (!this.isChangeFullName) return null
    if (validRequired(this.fullName)) return validRequired(this.fullName)
    if (!CyrillicNameWithSpace.test(this.fullName)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validConfirmCode() {
    const value = `${this.confirmCode}`.replace(/-/gi, '')
    if (validRequired(value)) return 'Отсутствует код'
    if (value.length !== 5) return 'Некорректный код'
    return null
  }

  @computed get isNext() {
    return !this.validFio && !FactoryModel.SettingsModel.ConfirmEmailModel.validConfirmCodeEmail &&
      this.isInitCode && !this.validConfirmCode
  }

  setFullName = action(fio => {
    this.fullName = fio
    this.isChangeFullName = true
  })

  applyAttachmentData = action(data => {
    this.fullName = data?.responsibleFullName
  })

  clearConfirmAttachmentInterval = () => {
    if (this.confirmAttachmentIntervalID) {
      clearInterval(this.confirmAttachmentIntervalID)
      this.confirmAttachmentIntervalID = null
    }
  }

  sendAttachmentConfirmCode = async () => {
    try {
      this.applyData({ isUpdateCode: true })
      const interval = await FactoryProvider.PartnerProvider.sendAttachmentConfirmCode(this.ctx.CommonModel.id)
      this.applyData({ confirmIntervalAttachment: interval, isInitCode: true })
      this.startConfirmAttachmentInterval()
    } catch (e) {
      notifyError('Ошибка отправки кода подтверждения заявления', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isUpdateCode: false })
    }
  }

  startConfirmAttachmentInterval = () => {
    this.clearConfirmAttachmentInterval()

    this.applyData({ isSendingConfirmAttachment: true })
    this.confirmAttachmentIntervalID = setInterval(() => {
      if (this.confirmIntervalAttachment <= 1) {
        clearInterval(this.confirmAttachmentIntervalID)
        this.applyData({
          confirmIntervalAttachment: 60,
          isSendingConfirmAttachment: false
        })
      } else {
        this.applyData({
          confirmIntervalAttachment: this.confirmIntervalAttachment - 1
        })
      }
    }, 1000)
  }

  next = async () => {
    if (!this.isNext) {
      this.applyData({ isForceValidate: true })
      if (this.validFio) scrollTo(this.RESPONSIBLE_BLOCk)
      else if (this.validConfirmCode) scrollTo(this.MAIN_BLOCK)
    } else {
      try {
        this.applyData({ isProcessSave: true })
        await FactoryProvider.PartnerProvider.confirmAttachmentCode(this.ctx.CommonModel.id, this.confirmCode)
        this.ctx.CommonModel.setField('state')(PROPOSITION_SIGN_STATE)
      } catch (e) {
        notifyError('Ошибка подписания', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }
}

export { AttachmentModel }
