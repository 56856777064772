import FactoryModel from 'models/factoryModel'
import { offerMap } from 'providers/helpers/pickup'

export default {
  onReceived: async ({ data }) => {
    switch (data?.action) {
      case 'bank:complete': {
        if (!data.bank_id || !data.result) return

        FactoryModel.PickupModel.recalculateOffer({ bankId: data.bank_id, offer: offerMap({ offer: data.result }) })
        break
      }
      case 'complete': {
        FactoryModel.PickupModel.finishCalculateOffers()
        break
      }

      case 'tips:complete': {
        if (!data.product_id || !data.bank_id || !data.result) return

        FactoryModel.PickupModel.finishCalculateOfferTipsSum(data.bank_id, data.product_id, data.result.result.tips, data.result.result.tips_error)
        break
      }
    }
  }
}
