import { action, observable, computed, makeObservable } from 'mobx'
import { notifyError } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../../baseModel'

class ConfirmPhoneModel extends BaseModel {
  ctx = null

  @observable isSendingConfirmPhone = false
  @observable confirmSmsCode = ''
  CONFIRM_INTERVAL_TIME = 60 // sec
  @observable confirmInterval = this.CONFIRM_INTERVAL_TIME
  @observable isEnableSendConfirmPhone = false
  @observable isProcessSendConfirmPhone = false
  @observable isProcessApply = false

  confirmPhoneIntervalID = null

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.isSendingConfirmPhone = false
    this.confirmSmsCode = ''
    this.confirmInterval = this.CONFIRM_INTERVAL_TIME
    this.isEnableSendConfirmPhone = false
    this.isProcessSendConfirmPhone = false
    this.isProcessApply = false
    this.clearConfirmInterval()
  })

  @computed get isNext() {
    return this.confirmSmsCode.length === 5
  }

  clearConfirmInterval = () => {
    if (this.confirmPhoneIntervalID) {
      clearInterval(this.confirmPhoneIntervalID)
      this.confirmPhoneIntervalID = null
    }
  }

  sendConfirmPhone = async () => {
    try {
      this.applyData({
        isProcessSendConfirmPhone: true,
        isEnableSendConfirmPhone: false,
        confirmSmsCode: ''
      })
      this.confirmInterval = await FactoryProvider.QuestionaryProvider.sendConfirmCode(this.ctx.CommonModel.id)

      this.clearConfirmInterval()
      this.startInterval()

      this.applyData({ isSendingConfirmPhone: true })
    } catch (e) {
      notifyError('Ошибка отправки sms кода', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSendConfirmPhone: false })
    }
  }

  startInterval = () => {
    this.confirmPhoneIntervalID = setInterval(() => {
      if (this.confirmInterval <= 1) {
        clearInterval(this.confirmPhoneIntervalID)
        this.applyData({
          confirmInterval: this.CONFIRM_INTERVAL_TIME,
          isEnableSendConfirmPhone: true
        })
      } else {
        this.applyData({
          confirmInterval: this.confirmInterval - 1
        })
      }
    }, 1000)
  }

  next = async () => {
    try {
      this.applyData({ isProcessApply: true })
      const { step, ...data } = await FactoryProvider.QuestionaryProvider.updateQuestionary(this.ctx.CommonModel.id, { confirmation_code: this.confirmSmsCode }, 'phone_confirmation')
      this.ctx.CommonModel.setField('metaStep')(0)
      this.ctx.CommonModel.setField('isConfirmPhone')(false)
      this.ctx.CommonModel.setStep(step, data)
    } catch (e) {
      notifyError('Ошибка подтверждения телефона', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessApply: false })
    }
  }
}

export { ConfirmPhoneModel }
