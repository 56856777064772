import _ from 'lodash'
import { moment, DATE_FORMATS } from 'uikit'
import { notNull } from 'providers/helpers/null'

export const codesMap = (data) => _.map(_.get(data, 'peer_configs', []), peer => ({
  peerID: _.get(peer, 'peer_id', ''),
  values: _.map(_.get(peer, 'values', []), value => ({
    name: value?.name ?? '',
    value: value?.value ?? ''
  }))
}))

export const codesHistoryMap = ({ history }) => ({
  id: history.id ?? history._id,
  author: notNull(history?.author, mod => ({
    id: mod.id ?? mod._id,
    login: _.get(mod, 'login', ''),
    fullName: _.get(mod, 'fullname', ''),
    email: _.get(mod, 'email', ''),
    isAccessGranted: _.get(mod, 'access_granted', false),
    lastActivityAt: mod.last_activity_at ? moment(mod.last_activity_at, DATE_FORMATS).toDate() : null
  })),
  modifiedAt: history.modified_at ? moment(history.modified_at, DATE_FORMATS).toDate() : null,
  modifications: _.get(history, 'modifications', [])
})

export const codesMapApi = ({ codes }) => _.map(codes, code => ({
  peer_id: code.peerID,
  values: _.map(code.values, value => ({
    name: value.name,
    value: value.value.trim()
  }))
}))
