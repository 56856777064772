import _ from 'lodash'
import { safeObject } from 'uikit'
import { bankMap } from './bank'
import { creditProductMap, insuranceProductMap } from './product'

const serviceStrategySettingMap = (serviceStrategySetting) => ({
  additionalServiceID: _.get(serviceStrategySetting, 'additional_service_id', null),
  penetrationPercentage: _.get(serviceStrategySetting, 'penetration_percentage', 0),
  amountMin: _.get(serviceStrategySetting, 'amount_min', 0),
  amountMax: _.get(serviceStrategySetting, 'amount_max', 0)
})

const bankPieceMap = (bankPiece) => ({
  bankID: _.get(bankPiece, 'bank_id', null),
  bankDBID: _.get(bankPiece, '_id', null),
  bank: bankMap({ bank: _.get(bankPiece, 'bank', {}) }),
  formType: _.get(bankPiece, 'form_type', ''),
  hard: _.get(bankPiece, 'hard', null),
  percentage: _.get(bankPiece, 'percentage', 0),
  insuranceStrategy: _.get(bankPiece, 'insurance_strategy', ''),
  insurancePenetrationPercentage: _.get(bankPiece, 'insurance_penetration_percentage', 0),
  bankInsurancePercentage: _.get(bankPiece, 'bank_insurance_percentage', 0),
  externalInsurancePercentage: _.get(bankPiece, 'external_insurance_percentage', 0),
  smsStrategy: _.get(bankPiece, 'sms_strategy', ''),
  smsPenetrationPercentage: _.get(bankPiece, 'sms_penetration_percentage', 0),
  bankSmsPercentage: _.get(bankPiece, 'bank_sms_percentage', 0),
  externalSmsPercentage: _.get(bankPiece, 'external_sms_percentage', 0),
  creditProducts: _.map(_.get(bankPiece, 'credit_products', []), product => creditProductMap({ product })),
  creditProductsOriginal: _.map(_.get(bankPiece, 'credit_products', []), product => creditProductMap({ product })),
  installmentProducts: _.map(_.get(bankPiece, 'installment_products', []), product => creditProductMap({ product })),
  installmentProductsOriginal: _.map(_.get(bankPiece, 'installment_products', []), product => creditProductMap({ product })),
  insuranceProducts: _.map(_.get(bankPiece, 'insurance_products', []), product => insuranceProductMap({ product })),
  insuranceProductsOriginal: _.map(_.get(bankPiece, 'insurance_products', []), product => insuranceProductMap({ product })),
  serviceStrategySettings: _.map(_.get(bankPiece, 'service_strategy_settings', []), setting => serviceStrategySettingMap(setting))
})

export const bankMixMap = ({ bankMix }) => ({
  macroregionID: _.get(bankMix, 'macroregion_id', null),
  insuranceDiscount: _.get(bankMix, 'insurance_discount', null),
  insuranceInitialPayment: _.get(bankMix, 'insurance_initial_payment', null),
  initialPaymentVolatility: _.get(bankMix, 'initial_payment_volatility', null),
  applyToPartner: _.get(bankMix, 'apply_to_partner', null),
  bankPieces: _.map(_.get(bankMix, 'bank_pieces', []), bankPiece => bankPieceMap(bankPiece))
})

const serviceStrategySettingMapApi = (serviceStrategySetting) => ({
  additional_service_id: serviceStrategySetting.additionalServiceID,
  penetration_percentage: serviceStrategySetting.penetrationPercentage,
  amount_min: serviceStrategySetting.amountMin,
  amount_max: serviceStrategySetting.amountMax
})

const bankPieceMapApi = (bankPiece) => ({
  bank_id: bankPiece.bankID,
  form_type: bankPiece.formType,
  hard: bankPiece.hard,
  percentage: bankPiece.percentage,
  insurance_strategy: bankPiece.insuranceStrategy,
  insurance_penetration_percentage: bankPiece.insurancePenetrationPercentage,
  bank_insurance_percentage: bankPiece.bankInsurancePercentage,
  external_insurance_percentage: bankPiece.externalInsurancePercentage,
  sms_strategy: bankPiece.smsStrategy,
  sms_penetration_percentage: bankPiece.smsPenetrationPercentage,
  bank_sms_percentage: bankPiece.bankSmsPercentage,
  external_sms_percentage: bankPiece.externalSmsPercentage,
  credit_product_ids: _.map(bankPiece.creditProducts, product => product.id),
  installment_product_ids: _.map(bankPiece.installmentProducts, product => product.id),
  insurance_product_ids: _.map(bankPiece.insuranceProducts, product => product.id),
  service_strategy_settings: _.map(bankPiece.serviceStrategySettings, settings => serviceStrategySettingMapApi(settings))
})

export const bankMixMapApi = ({ bankMix }) => safeObject({
  macroregion_id: bankMix.macroregionID,
  insurance_discount: bankMix.insuranceDiscount,
  insurance_initial_payment: bankMix.insuranceInitialPayment,
  initial_payment_volatility: bankMix.initialPaymentVolatility,
  apply_to_partner: bankMix.applyToPartner,
  bank_pieces: _.map(bankMix.bankPieces, bankPiece => bankPieceMapApi(bankPiece))
})
