import _ from 'lodash'
import BaseHttpProvider from './baseHttpProvider'

class PartnerFormProvider extends BaseHttpProvider {
  async sendForm(organizationName, city, typeOfActivity, fullname, phone, email, ctag) {
    const data = await this.execute({
      uri: '/send_partner_form',
      method: 'POST',
      data: { organizationName, city, typeOfActivity, fullname, phone, email, ctag }
    })
    return _.get(data, 'result', false)
  }
}

export { PartnerFormProvider }
