import _ from 'lodash'

export const educationMaterialsMap = (educationMaterials) => (
  {
    videoLink: _.get(educationMaterials, 'video_link', ''),
    tutorialLink: _.get(educationMaterials, 'tutorial_link', ''),
    additionalProductsLink: _.get(educationMaterials, 'additional_products_link', ''),
    refundPolicyLink: _.get(educationMaterials, 'refund_policy_link', '')
  }
)
