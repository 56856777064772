import { observable, action, computed, makeObservable } from 'mobx'
import { validRequired, notifyError } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../../baseModel'

class ConfirmEmailModel extends BaseModel {
  ctx = null

  @observable confirmEmailCode = ''
  @observable isUpdateCodeEmail = false
  @observable isUpdateConfirmEmail = false
  @observable isSendingConfirmEmail = false
  @observable confirmIntervalEmail = 60
  confirmEmailIntervalID = null

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.confirmEmailCode = ''
    this.isUpdateCodeEmail = false
    this.isUpdateConfirmEmail = false
    this.isSendingConfirmEmail = false
    this.confirmIntervalEmail = 60
    this.clearConfirmEmailInterval()
  })

  @computed get validConfirmCodeEmail() {
    if (this.ctx.ProfileModel?.isConfirmEmail) return null
    if (validRequired(this.confirmEmailCode)) return 'Отсутствует код'
    if (this.confirmEmailCode.length < 5) return 'Некорректный код'
    return null
  }

  sendEmailConfirmCode = async () => {
    try {
      this.applyData({ isUpdateCodeEmail: true })
      const interval = await FactoryProvider.UserProvider.sendConfirmEmailCode(this.ctx.ProfileModel?.id, this.ctx.ProfileModel.email)
      this.applyData({ confirmIntervalEmail: interval })
      this.startConfirmEmailInterval()
    } catch (e) {
      notifyError('Ошибка отправки кода подтверждения Email', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isUpdateCodeEmail: false })
    }
  }

  confirmEmail = async () => {
    try {
      this.applyData({ isUpdateConfirmEmail: true })
      await FactoryProvider.UserProvider.confirmEmail(this.ctx?.ProfileModel?.id, this.confirmEmailCode)
      this.ctx.ProfileModel.setConfirmEmail(true)
    } catch (e) {
      notifyError('Ошибка подтверждения Email', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isUpdateConfirmEmail: false })
    }
  }

  clearConfirmEmailInterval = () => {
    if (this.confirmEmailIntervalID) {
      clearInterval(this.confirmEmailIntervalID)
      this.confirmEmailIntervalID = null
    }
  }

  startConfirmEmailInterval = () => {
    this.clearConfirmEmailInterval()

    this.applyData({ isSendingConfirmEmail: true })
    this.confirmEmailIntervalID = setInterval(() => {
      if (this.confirmIntervalEmail <= 1) {
        clearInterval(this.confirmEmailIntervalID)
        this.applyData({
          confirmIntervalEmail: 60,
          isSendingConfirmEmail: false
        })
      } else {
        this.applyData({
          confirmIntervalEmail: this.confirmIntervalEmail - 1
        })
      }
    }, 1000)
  }
}

export { ConfirmEmailModel }
