import _ from 'lodash'
import { observable, action, computed, makeObservable } from 'mobx'
import { notifyError, notifyInfo, safeObject, scrollToTop, isSafe } from 'uikit'
import { getHistoryQuery, push } from 'utils/history'
import { BORROWER_PHOTO, CC_PASSPORTREG, CC_PASSPORT19, CC_PASSPORT23 } from 'providers/helpers/poscans'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { prepareErrorMessage } from 'utils/error'
import {
  MAIN_STEP,
  ORDERS_STEP,
  CALC_STEP,
  ADDITIONAL_STEP,
  CUSTOM_STEP,
  PHONE_STEP,
  PD_AGREEMENT_STEP,
  DASHBOARD_STEP
} from 'providers/helpers/questionary'
import BaseModel from '../../baseModel'

class CommonModel extends BaseModel {
  ctx = null

  @observable step = 0
  @observable metaStep = 0
  @observable id = ''
  @observable isLoadingQuestionary = false
  @observable isReset = false

  @observable isPdAgreement = false
  @observable isConfirmPhone = false

  @observable creditRequestID = ''
  @observable creditRequestIsExpand = true
  @observable creditRequest = null

  @observable packageId = null
  @observable qrCode = ''
  @observable isOpenQr = false
  @observable scans = []

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isExist() {
    return !!this.id
  }

  clear = () => {
    this.clearExceptCreditRequest()
    this.action(() => {
      this.creditRequestID = ''
      this.creditRequest = null
    })
    this.clearCommonPackage()
  }

  clearCommonPackage = action(() => {
    this.packageId = null
    this.qrCode = ''
    this.isOpenQr = false
    this.scans = []
  })

  clearExceptCreditRequest = () => {
    this.action(() => {
      this.step = 0
      this.id = ''
      this.isLoadingQuestionary = false
      this.creditRequestIsExpand = true
    })
    this.clearMetaStep()
    this.clearCommonPackage()

    this.ctx.MainModel.clear()
    this.ctx.OrderModel.clear()
    FactoryModel.PickupModel.clear()
    this.ctx.AdditionalModel.clear()
    this.ctx.CreditModel.clear()
    this.ctx.OtherModel.clear()
    this.ctx.CallCenterModel.clear()
    this.ctx.ConfirmPhoneModel.clear()
    this.ctx.PdAgreementModel.clear()
  }

  clearMetaStep = () => {
    this.action(() => {
      this.metaStep = 0
      this.isPdAgreement = false
      this.isConfirmPhone = false
    })
  }

  reset = () => {
    this.applyData({ isReset: true })
    push({
      uri: '/questionaries/[action]',
      href: '/questionaries/new',
      query: safeObject({
        creditRequest: this.creditRequestID || null
      })
    })
    this.clearExceptCreditRequest()
    notifyInfo('Сброс выполнен')
  }

  init = action(() => {
    this.isReset = false
  })

  isMetaStep = () => {
    if (this.metaStep > 0) {
      this.setMetaStep(this.metaStep)
    }
    return this.metaStep > 0
  }

  setStep = (step, data) => {
    if (!isSafe(step)) return
    if (step === DASHBOARD_STEP) {
      this.followToDashboard()
      return
    }

    const currentStep = _.isNumber(step) ? step : this.nameStepToStep(step)
    const metaStep = currentStep >= this.step ?
      _.isNumber(data?.metaStep) ? data?.metaStep : this.nameMetaStepToStep(data?.metaStep) :
      0
    if (metaStep > 0) {
      this.setMetaStep(metaStep, data)
    } else {
      this.applyData({
        isPdAgreement: false,
        isConfirmPhone: false,
        metaStep: 0
      })
    }

    const isFollow = currentStep !== this.step
    this.applyData({ step: currentStep })
    if (isFollow) this.applyParamsToHistory()
    if (metaStep <= 0) scrollToTop()
  }

  setMetaStep = (step, data = null) => {
    const currentStep = _.isNumber(step) ? step : this.nameMetaStepToStep(step)
    switch (currentStep) {
      case 1:
        if (data?.options?.pdAgreement) this.ctx.PdAgreementModel.applyPdData(data?.options?.pdAgreement)
        this.applyData({
          isPdAgreement: true,
          isConfirmPhone: false,
          metaStep: 1
        })
        break
      case 2:
        this.applyData({ isConfirmPhone: true, isPdAgreement: false, metaStep: 2 })
        break
    }
  }

  stepToNameStep = step => {
    switch (step) {
      case 0: return MAIN_STEP
      case 1: return ORDERS_STEP
      case 2: return CALC_STEP
      case 3: return ADDITIONAL_STEP
      case 4: return CUSTOM_STEP
    }
    return MAIN_STEP
  }

  nameStepToStep = step => {
    switch (step) {
      case MAIN_STEP: return 0
      case ORDERS_STEP: return 1
      case CALC_STEP: return 2
      case ADDITIONAL_STEP: return 3
      case CUSTOM_STEP: return 4
    }
    return 0
  }

  nameMetaStepToStep = metaStep => {
    switch (metaStep) {
      case PD_AGREEMENT_STEP: return 1
      case PHONE_STEP: return 2
    }
    return 0
  }

  applyParamsToHistory = () => {
    push({
      uri: '/questionaries/[action]',
      href: `/questionaries/${this.id ? this.id : 'new'}`,
      query: safeObject({
        step: this.stepToNameStep(this.step),
        creditRequest: this.creditRequestID || null
      })
    })
  }

  applyParamsFromHistory = async (id = '') => {
    const { step, creditRequest } = getHistoryQuery()
    const resolveStep = this.nameStepToStep(step)

    if (creditRequest) {
      this.applyData({
        creditRequestID: creditRequest
      })
    }
    if (id && !this.id && !this.isReset) {
      this.applyData({ id })
      this.setStep(resolveStep)
      await this.initQuestionary(id)
    } else if (this.id && resolveStep <= step) {
      this.setStep(resolveStep)
      this.applyData({
        step: resolveStep
      })
    }
  }

  initQuestionary = async id => {
    try {
      this.applyData({ isLoadingQuestionary: true })
      const data = await this.getQuestionary(id)
      this.applyDataFromStep(data.step, data)

      FactoryModel.PickupModel.applyPrepareData({
        point: data.point,
        user: data.user
      })
    } catch (e) {
      notifyError('Ошибка получения заявки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingQuestionary: false })
    }
  }

  getQuestionary = async id => await FactoryProvider.QuestionaryProvider.getQuestionary(id)

  applyDataFromStep = (step, data) => {
    if (step === DASHBOARD_STEP) {
      this.followToDashboard()
      return
    }

    this.applyAllData(data)
    this.ctx.CallCenterModel.applyCallCenter(data)
    FactoryModel.PickupModel.applyParams({ calculationId: data.calculationId, signedProducts: data.signedProducts })
    FactoryModel.PickupModel.applyOwnerChangeAccess({ questionaryAccess: data.options.isOwnerChange })
    this.ctx.OtherModel.applyOptions(data.options)

    const currentStep = this.nameStepToStep(step)
    if (this.step > currentStep) this.setStep(currentStep, data)
    const currentMetaStep = this.nameMetaStepToStep(data?.metaStep)
    this.setMetaStep(currentMetaStep, data)
  }

  applyAllData = data => {
    this.action(() => {
      this.packageId = data?.packageId
      this.qrCode = data?.qrCode
      this.scans = data?.scans
    })
    this.ctx.MainModel.applyMainData(data)
    this.ctx.OrderModel.applyOrders(data)
    this.ctx.AdditionalModel.applyAdditionalData(data)
    this.ctx.OtherModel.applyOtherData(data)
  }

  fillDataFromClient = async () => {
    const data = await this.getQuestionary(this.id)
    this.applyAllData(data)
  }

  postCreateQuestionary = action(id => {
    this.id = id
    this.isReset = false
  })

  followToDashboard = () => {
    push({ uri: '/questionaries/[action]/dashboard', href: `/questionaries/${this.id}/dashboard` })
  }
  getCreditRequest = async (id) => {
    try {
      const creditRequest = await FactoryProvider.CreditRequestProvider.getCreditRequest(id)
      this.applyData({
        creditRequestID: creditRequest.id,
        creditRequest
      })
      if (creditRequest.creditParams?.requestType === 'installment') {
        FactoryModel.PickupModel.setPrepareField('isAvailDiscount')(true)
      }
      if (this.ctx?.CommonModel?.creditRequest?.creditParams) {
        const { term, initialPayment } = this.ctx.CommonModel.creditRequest.creditParams
        if (term) FactoryModel.PickupModel.setPrepareField('term')(Number(term))
        if (initialPayment) FactoryModel.PickupModel.setPrepareField('initialPayment')(Number(initialPayment))
      }
      if (!this.id) {
        this.ctx.MainModel.setPhone(creditRequest?.borrower?.phone)
        const fullName = `${creditRequest?.borrower?.lastName} ${creditRequest?.borrower?.firstName} ${creditRequest?.borrower?.patronymic}`.trim()
        this.ctx.MainModel.applyData({
          fullName,
          lastName: creditRequest?.borrower?.lastName,
          firstName: creditRequest?.borrower?.firstName,
          patronymic: creditRequest?.borrower?.patronymic,
          gender: creditRequest?.borrower?.gender
        })
      }
      if (!this.ctx.OrderModel.isNext) {
        const orders = _.map(creditRequest?.goods, order => ({
          orderName: _.get(order, 'name', ''),
          producer: _.get(order, 'manufacturer', ''),
          cost: Number(_.get(order, 'price', 0)),
          count: Number(_.get(order, 'count', 0)),
          category: {
            id: _.get(order, 'category.id', null),
            name: _.get(order, 'category.name', null)
          }
        }))
        this.ctx.OrderModel.applyOrders({ orders })
      }
    } catch (e) {
      notifyError('Ошибка', prepareErrorMessage(e))
      throw e
    }
  }
  @computed get photoScan() {
    return this.creditRequest?.documents.find(doc => doc.doctype === BORROWER_PHOTO)?.url
  }
  @computed get passportMainScan() {
    return this.creditRequest?.documents.find(doc => doc.doctype === CC_PASSPORT23)?.url ||
      this.scans.find(doc => doc.commonCode === CC_PASSPORT23 && !!doc.url)?.url
  }
  @computed get passportRegScan() {
    return this.creditRequest?.documents.find(doc => doc.doctype === CC_PASSPORTREG)?.url ||
      this.scans.find(doc => doc.commonCode === CC_PASSPORTREG && !!doc.url)?.url
  }
  @computed get passport19Scan() {
    return this.creditRequest?.documents.find(doc => doc.doctype === CC_PASSPORT19)?.url ||
      this.scans.find(doc => doc.commonCode === CC_PASSPORT19 && !!doc.url)?.url
  }

  setIsOpenQr = action(isOpen => {
    this.isOpenQr = isOpen
  })

  applyDataAfterUpdate = action(data => {
    this.setStep(data?.step, data)

    this.ctx.CallCenterModel.applyCallCenter({ options: data.options })

    if (data?.packageId) this.packageId = data?.packageId
    if (data?.qrCode) this.qrCode = data?.qrCode
    if (!_.isEmpty(data?.scans)) this.scans = data.scans
    if (data?.photo) this.ctx.OtherModel.applyPhoto(data?.photo)
  })
}

export { CommonModel }
