import React from 'react'
import { inject, observer } from 'mobx-react'
import FactoryModel from 'models/factoryModel'
import { push } from 'utils/history'
import { useRouter } from 'next/router'
import FactoryProvider from 'providers/factoryProvider'
import { isServer } from 'utils/isomorphic'
import { isNotRedirectAuth } from 'utils/auth'
import styled from 'styled-components'
import { Block, mountHook } from 'uikit'
import { showRocketChat } from 'utils/rocket'
import Update from './Update'

const Placeholder = styled(Block)`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.gray1};
`

const Root = ({ children }) => {
  const router = useRouter()

  const isAuth = () => {
    const isExistToken = FactoryModel.AuthModel.CommonModel.isExistToken
    const isExistTempToken = FactoryModel.AuthModel.CommonModel.isExistTempToken
    return !(!isExistToken && !isExistTempToken && !isNotRedirectAuth(router.pathname))
  }

  mountHook(async () => {
    FactoryProvider.ConfigProvider.showDebugConfig()

    if (!isServer() && FactoryModel.AuthModel.CommonModel.token) {
      FactoryProvider.ActionCableProvider.run(FactoryModel.AuthModel.CommonModel.token)
    }

    if (!isAuth()) {
      FactoryModel.AuthModel.CommonModel.prepareCurrentUri()
      push({ uri: '/login' })
    } else if (FactoryModel.AuthModel.CommonModel.isExistToken) {
      await Promise.all([
        FactoryModel.DictModel.getDict(),
        FactoryModel.SettingsModel.CommonModel.getSettings(),
        FactoryModel.ConfigModel.initConfig()
      ])
      showRocketChat()
      FactoryModel.AnalyticsModel.setUserId()
    }
  })

  return (
    <>
      {isAuth() ? children : <Placeholder/>}
      <Update/>
    </>
  )
}

export default inject(store => ({
  ResponsiveModel: store.ResponsiveModel
}))(observer(Root))
