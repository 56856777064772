import _ from 'lodash'
import BaseHttpProvider from './baseHttpProvider'

class CreditOfferProvider extends BaseHttpProvider {
  async sendForm(creditAmount, fullname, phone) {
    const data = await this.execute({
      uri: '/credit_offer',
      method: 'POST',
      data: { creditAmount, fullname, phone }
    })
    return _.get(data, 'result', false)
  }

  async incrementCounter() {
    const now = new Date()
    const data = await this.execute({
      uri: '/credit_offer/viewings',
      method: 'POST',
      data: { now }
    })
    return _.get(data, 'result', false)
  }
}

export { CreditOfferProvider }
