import _ from 'lodash'
import Provider from 'containers/Provider'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { factoryModelInit } from 'models/factoryModel/init'
import NextApp from 'next/app'
import React from 'react'
import Root from 'containers/Root'
import 'viewerjs/dist/viewer.min.css'
import 'easymde/dist/easymde.min.css'
import { isServer } from 'utils/isomorphic'
import * as Sentry from '@sentry/node'
import { RewriteFrames, ExtraErrorData } from '@sentry/integrations'
import getConfig from 'next/config'
import Head from 'next/head'

if (FactoryProvider.ConfigProvider?.config?.sentryDsn) {
  const config = getConfig()
  const distDir = `${config.serverRuntimeConfig.rootDir}/build`
  Sentry.init({
    ignoreErrors: [
      'TokenError',
      'EmptyToken',
      'ExpiresToken'
    ],
    enabled: true,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        }
      }),
      new ExtraErrorData({ depth: 10 })
    ],
    normalizeDepth: 11,
    dsn: FactoryProvider.ConfigProvider?.config?.sentryDsn,

    beforeSend(event, hint) {
      const error = hint?.originalException || hint?.originalException?.error
      const extra = {}
      const tags = {}
      if (error?.request) {
        extra.request = error.request
        tags['api.url'] = error.request.url
        tags['api.method'] = error.request.method
      }
      if (error?.response) {
        extra.response = error.response
        if (error.response?.status) {
          tags['api.status'] = error.response?.status
        }
      }
      if (error?.token) extra.token = error.token
      if (error?.currentUser) extra.currentUser = error.currentUser

      if (!_.isEmpty(extra)) event.extra = extra
      if (!_.isEmpty(tags)) event.tags = tags
      return event
    }
  })
}

factoryModelInit()

class App extends NextApp {
  constructor(props) {
    super(props)
    this.store = FactoryModel
  }

  static handleSentry({ req }) {
    Sentry.addBreadcrumb({
      message: `Preparing app (${!isServer() ? 'browser' : 'server'})`,
      level: Sentry.Severity.Debug
    })

    if (req) {
      const { headers } = req
      Sentry.configureScope(scope => {
        scope.setContext('headers', headers)
      })
    }
  }

  static async getInitialProps({ ctx }) {
    App.handleSentry(ctx)

    if (isServer()) {
      const secret = require('../utils/secret')
      const current = secret.parseCId(ctx?.req)

      current[secret.CURRENT_URI] = !/^\/my_points/.test(ctx?.req?.originalUrl) && !/^\/login/.test(ctx?.req?.originalUrl) && !/^\//.test(ctx?.req?.originalUrl) ?
        ctx?.req?.originalUrl : current[secret.CURRENT_URI]

      const expires = current[secret.EXPIRES] ? new Date(current[secret.EXPIRES]) : null
      const isExpires = !expires || expires - new Date() < 0

      if (isExpires) {
        current[secret.TOKEN_FIELD] = ''
        current[secret.TEMP_TOKEN_FIELD] = ''
        current[secret.EXPIRES] = null
      }

      secret.setCookies({
        current,
        req: ctx?.req,
        res: ctx?.res
      })

      const token = current[secret.TOKEN_FIELD] || ''
      const tempToken = current[secret.TEMP_TOKEN_FIELD] || ''
      const target = current[secret.TARGET] || ''
      const isPointSelectionRequired = current[secret.IS_POINT_SELECTION_REQUIRED] || false
      FactoryModel.AuthModel.CommonModel.init(token, tempToken, expires, target, isPointSelectionRequired)

      return {
        token,
        tempToken,
        expires,
        target,
        isPointSelectionRequired
      }
    }

    return {
      token: '',
      tempToken: '',
      expires: null,
      target: '',
      isPointSelectionRequired: false
    }
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <Provider models={this.store}>
          <Root>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1"/>
            </Head>
            <Component {...pageProps}/>
          </Root>
      </Provider>
    )
  }
}

export default App
