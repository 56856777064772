import _ from 'lodash'
import { isServer } from 'utils/isomorphic'
import FactoryModel from './factoryModel'
import { ResponsiveModel } from '../responsiveModel'
import { ConfigModel } from '../configModel'
import { UpdateModel } from '../updateModel'
import { QuestionaryListModel } from '../questionaryListModel'
import { QuestionaryModel } from '../questionaryModel'
import { PickupModel } from '../pickupModel'
import { CreditRequestListModel } from '../creditRequestListModel'
import { CreditRequestModel } from '../creditRequestModel'
import { UserListModel } from '../userListModel'
import { MenuModel } from '../menuModel'
import { AuthModel } from '../authModel'
import { AdditionalServiceListModel } from '../additionalServiceListModel'
import { SettingsModel } from '../settingsModel'
import { DictModel } from '../dictModel'
import { UserModel } from '../userModel'
import { BankModel } from '../bankModel'
import { BankMixModel } from '../bankMixModel'
import { PartnerModel } from '../partnerModel'
import { PointListModel } from '../pointListModel'
import { PartnerListModel } from '../partnerListModel'
import { PointModel } from '../pointModel'
import { AcceptanceCertificateModel } from '../acceptanceCertificateModel'
import { QuestionaryDashboardModel } from '../questionaryDashboardModel'
import { TutorialModel } from '../tutorialModel'
import { AgentCommissionGroupListModel } from '../agentCommissionGroupListModel'
import { AgentRewardListModel } from '../agentRewardListModel'
import { AgentRewardModel } from '../agentRewardModel'
import { AccreditationModel } from '../accreditationModel'
import { PartnerPropositionModel } from '../partnerPropositionModel'
import { PartnerApplicationListModel } from '../partnerApplicationListModel'
import { AnalyticsModel } from '../analyticsModel'
import { PostModel } from '../postModel'
import { ContentModel } from '../contentModel'
import { PartnerFormModel } from '../partnerFormModel'
import { CallBackModel } from '../callBackModel'
import { CreditOfferModel } from '../creditOfferModel'
import { IdeaModel } from '../ideaModel'

export const factoryModelInit = () => {
  const authModel = new AuthModel()
  if (!isServer()) {
    const { token = '', tempToken = '', expires = null, target = '', isPointSelectionRequired = false } = _.get(window, '__NEXT_DATA__.props', {})
    authModel.ctx.CommonModel.init(token, tempToken, expires ? new Date(expires) : null, target, isPointSelectionRequired)
  }

  FactoryModel.init({
    AuthModel: authModel,
    AdditionalServiceListModel: new AdditionalServiceListModel(),
    SettingsModel: new SettingsModel(),
    ConfigModel: new ConfigModel(),
    UpdateModel: new UpdateModel(),
    BankMixModel: new BankMixModel(),
    DictModel: new DictModel(),
    ResponsiveModel: new ResponsiveModel(),
    MenuModel: new MenuModel(),
    QuestionaryModel: new QuestionaryModel(),
    PickupModel: new PickupModel(),
    QuestionaryListModel: new QuestionaryListModel(),
    CreditRequestListModel: new CreditRequestListModel(),
    CreditRequestModel: new CreditRequestModel(),
    UserModel: new UserModel(),
    UserListModel: new UserListModel(),
    BankModel: new BankModel(),
    PartnerModel: new PartnerModel(),
    PointListModel: new PointListModel(),
    PartnerListModel: new PartnerListModel(),
    PointModel: new PointModel(),
    AcceptanceCertificateModel: new AcceptanceCertificateModel(),
    QuestionaryDashboardModel: new QuestionaryDashboardModel(),
    TutorialModel: new TutorialModel(),
    PartnerPropositionModel: new PartnerPropositionModel(),
    AgentCommissionGroupListModel: new AgentCommissionGroupListModel(),
    AgentRewardListModel: new AgentRewardListModel(),
    AgentRewardModel: new AgentRewardModel(),
    AccreditationModel: new AccreditationModel(),
    PartnerApplicationListModel: new PartnerApplicationListModel(),
    AnalyticsModel: new AnalyticsModel(),
    PostModel: new PostModel(),
    ContentModel: new ContentModel(),
    PartnerFormModel: new PartnerFormModel(),
    CallBackModel: new CallBackModel(),
    CreditOfferModel: new CreditOfferModel(),
    IdeaModel: new IdeaModel()
  })
}
