import _ from 'lodash'
import { DATE_FORMATS, moment } from 'uikit'
import { callCenterMap } from './callCenter'
import { phoneSimpleMap, phoneSimpleShortMapApi } from './phone'
import { shortPointMap } from './point'
import { goodsMap } from './goods'
import { borrowerMap } from './borrower'
import { questionaryShortMap } from './shortQuestionary'
import { notNull } from './null'

export const CREDIT_REQUEST_DEFAULT_COMMENTS = [
  'Не дозвонились',
  'Уже не актуальна клиенту, передумал',
  'Боится/не хочет диктовать данные',
  'Просил перезвонить позднее',
  'Хочет уточнить данные по товару у продавца',
  'Условия кредита/рассрочки не устраивают',
  'Товара нет в наличии',
  'Заявку не подавал'
]

export const creditRequestMap = ({ creditRequest, isShort = true }) => {
  const actions = _.get(creditRequest, 'actions', [])
  const comments = _.map(creditRequest?.comments, comment => ({
    text: _.get(comment, 'text', ''),
    createdAt: comment?.created_at ? moment(comment?.created_at, DATE_FORMATS).toDate() : null,
    author: _.get(comment, 'author.fullname', '')
  })).reverse()

  let resolve = {
    id: _.get(creditRequest, '_id') || _.get(creditRequest, 'id'),
    source: _.get(creditRequest, 'source', ''),
    questionaries: _.reverse(_.map(_.get(creditRequest, 'questionaries', []),
      questionary => questionaryShortMap({ questionary }))),
    translatedSource: _.get(creditRequest, 'translated_source', ''),
    point: shortPointMap({ point: _.get(creditRequest, 'point', {}) }),
    state: _.get(creditRequest, 'state', ''),
    actions,
    canAssign: _.findIndex(actions, a => a === 'assign') !== -1,
    canUnassign: _.findIndex(actions, a => a === 'unassign') !== -1,
    canCopy: _.findIndex(actions, a => a === 'copy') !== -1,
    canCancel: _.findIndex(actions, a => a === 'cancel') !== -1,
    canDelay: _.findIndex(actions, a => a === 'delay') !== -1,
    comment: _.get(comments, '0', '')?.text ?? '',
    comments,
    createdAt: _.get(creditRequest, 'created_at', ''),
    borrower: {
      firstName: _.get(creditRequest, 'firstname', ''),
      lastName: _.get(creditRequest, 'lastname', ''),
      patronymic: _.get(creditRequest, 'patronymic', ''),
      phone: phoneSimpleMap({ phone: _.get(creditRequest, 'phone', {}) }),
      gender: _.get(creditRequest, 'gender', null)
    },
    operator: _.get(creditRequest, 'operator.fullname', ''),
    operatorID: _.get(creditRequest, 'operator.id'),
    questionaryID: _.get(creditRequest, 'questionary_id', ''),
    questionaryStep: _.get(creditRequest, 'questionary_step', '')
  }

  if (creditRequest.owner) {
    resolve.owner = {
      id: _.get(creditRequest, 'owner.id', ''),
      fullName: _.get(creditRequest, 'owner.fullname', '')
    }
  }

  if (!isShort) {
    const orderPayload = Object.keys(_.get(creditRequest, 'order_payload', {})).length === 0 ? null : creditRequest.order_payload
    const formPayload = Object.keys(_.get(creditRequest, 'form_payload', {})).length === 0 ? null : creditRequest.form_payload

    resolve = {
      ...resolve,
      blackListReason: _.get(creditRequest, 'black_list_reason', ''),
      callCenter: callCenterMap({ callCenter: _.get(creditRequest, 'call_cener', {}), isShort: false }),
      documents: _.map(_.get(creditRequest, 'documents', []), document => ({
        id: _.get(document, 'id', ''),
        doctype: _.get(document, 'doctype', ''),
        filename: _.get(document, 'filename', '') || _.get(document, 'url', '').split('/').pop(),
        url: _.get(document, 'url', '')
      })),
      creditParams: {
        term: _.get(creditRequest, 'term', 0),
        requestType: _.get(creditRequest, 'request_type', ''),
        initialPayment: _.get(creditRequest, 'initial_payment', 0)
      },
      goods: _.map(_.get(creditRequest, 'goods', []), goods => goodsMap({ goods })),
      photoUrl: _.get(creditRequest, 'photo_url', ''),
      orderPayload,
      formPayload: { borrower: notNull(formPayload?.borrower, borrower => borrowerMap({ borrower })) },
      questionaryState: _.get(creditRequest, 'questionary_state', ''),
      target: _.get(creditRequest, 'target', ''),
      isWithoutPhoto: _.get(creditRequest, 'without_photo', false)
    }
  }

  return resolve
}
export const creditRequestMapApi = (creditRequest) => ({
  firstname: creditRequest.firstName,
  lastname: creditRequest.lastName,
  patronymic: creditRequest.patronymic,
  gender: creditRequest.gender,
  documents_package_id: creditRequest.packageID,
  comment: creditRequest.comment,
  goods: _.map(creditRequest.orders, order => ({
    name: order.orderName,
    manufacturer: order.producer,
    price: Number(order.cost),
    count: Number(order.count),
    category_id: order.category
  })),
  phone: phoneSimpleShortMapApi({ phone: creditRequest.phone }),
  request_type: creditRequest.requestType,
  questionary_id: creditRequest.questionaryID,
  without_photo: creditRequest.withoutPhoto
})
