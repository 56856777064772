import { observable, computed, action, makeObservable } from 'mobx'
import { validRequired, validEmail } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'

class PartnerFormModel extends BaseModel {
  @observable organizationName = ''
  @observable city = ''
  @observable typeOfActivity = ''
  @observable fullname = ''
  @observable phone = ''
  @observable email = ''
  @observable ctag = ''

  @observable isFailedSendForm = false
  @observable isProcessSendForm = false
  @observable isSuccessSendForm = false

  @observable result = false

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.organizationName = ''
    this.city = ''
    this.typeOfActivity = ''
    this.fullname = ''
    this.phone = ''
    this.email = ''
    this.ctag = ''
    this.isFailedSendForm = false
    this.isProcessSendForm = false
  })

  @computed get validOrganizationName() {
    if (this.organizationName.trim() === '') return 'Введите название организации'
    return null
  }

  @computed get validCity() {
    if (this.city.trim() === '') return 'Введите город'
    return null
  }

  @computed get validTypeOfActivity() {
    if (this.typeOfActivity.trim() === '') return 'Введите вид деятельности'
    return null
  }

  @computed get validFullname() {
    if (this.fullname.trim() === '') return 'Введите имя представителя'
    return null
  }

  @computed get validPhone() {
    if (validRequired(this.phone)) return validRequired(this.phone)
    if (this.phone.length !== 10) return 'Неверный формат'
    return null
  }

  @computed get validEmail() {
    if (validRequired(this.email)) return validRequired(this.email)
    if (validEmail(this.email)) return validEmail(this.email)
    return null
  }

  setOrganizationName = action(v => {
    this.organizationName = v
    this.isFailedSendForm = false
  })

  setCity = action(v => {
    this.city = v
    this.isFailedSendForm = false
  })

  setTypeOfActivity = action(v => {
    this.typeOfActivity = v
    this.isFailedSendForm = false
  })

  setFullname = action(v => {
    this.fullname = v
    this.isFailedSendForm = false
  })

  setPhone = action(v => {
    this.phone = v
    this.isFailedSendForm = false
  })

  setEmail = action(v => {
    this.email = v
    this.isFailedSendForm = false
  })

  setCTag = action(v => {
    this.ctag = v
  })

  showSuccess = async () => {

  }

  sendForm = async () => {
    try {
      this.applyData({ isProcessSendForm: true, isFailedSendForm: false, isSuccessSendForm: false })
      const result = await FactoryProvider.PartnerFormProvider.sendForm(this.organizationName, this.city, this.typeOfActivity, this.fullname, this.phone, this.email, this.ctag)
      this.applyData({ result })
    } catch (e) {
      this.applyData({ isFailedSendForm: true, isSuccessSendForm: false })
    } finally {
      this.applyData({ isProcessSendForm: false })
    }
    if (this.result === true) {
      this.applyData({ isSuccessSendForm: true })
      this.clear()
    }
  }
}

export { PartnerFormModel }
