import getConfig from 'next/config'

class ConfigProvider {
  config = {
    apiUri: '',
    actionCableUri: '',
    poscans: {
      uri: '',
      wss: ''
    },
    sentryDsn: '',
    showRocketChat: '',
    ci: {},
    matomoUrl: '',
    matomoId: '',
    matomoUserNameCode: '',
    matomoPointIdCode: ''
  }

  constructor() {
    const { publicRuntimeConfig } = getConfig()
    this.config = publicRuntimeConfig || {}
  }

  showDebugConfig() {
    window.config = this.config
  }
}

export { ConfigProvider }
