import { observable, action, makeObservable } from 'mobx'
import { getHistoryQuery, pathname, push } from 'utils/history'
import { isSafe, notifyError, notifySuccess, safeObject } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import _ from 'lodash'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'
import FactoryModel from '../factoryModel'
import { prepareErrorMessage } from '../../utils/error'

class PartnerListModel extends BaseModel {
  @observable list = []
  @observable page = 1
  @observable isLoadingList = false
  @observable queryText = ''
  @observable isActive = true

  defaultUrlParams = {
    page: 1,
    queryText: '',
    isActive: true
  }

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.list = []
    this.page = this.defaultUrlParams.page
    this.isLoadingList = false
    this.queryText = this.defaultUrlParams.queryText
    this.isActive = this.defaultUrlParams.isActive
  })

  applyParamsToHistory = () => {
    push({
      uri: '/organizations',
      query: safeObject({
        page: this.page !== this.defaultUrlParams.page ? this.page : null,
        queryText: this.queryText !== this.defaultUrlParams.queryText ? this.queryText : null,
        isActive: this.isActive !== this.defaultUrlParams.isActive ? this.isActive : null
      })
    })
  }

  applyParamsFromHistory = () => {
    const { page = this.defaultUrlParams.page, queryText = this.defaultUrlParams.queryText, isActive } = getHistoryQuery()
    const resolvePage = Number(page) > 1 ? Number(page) : this.defaultUrlParams.page
    const resolveIsActive = isSafe(isActive) ? isActive === 'true' : true

    this.applyData({
      page: Number(resolvePage),
      queryText,
      isActive: resolveIsActive
    })

    FactoryModel.MenuModel.setMenuItem('organizations')
    this.getList(false)
  }

  getList = async (isReload = true) => {
    try {
      this.applyData({
        isLoadingList: true,
        page: isReload ? 1 : this.page
      })
      const list = await FactoryProvider.PartnerProvider.getPartnerList({
        page: this.page,
        queryText: this.queryText,
        pageSize: 20,
        isActive: this.isActive
      })

      if (pathname() !== '/organizations') return
      this.applyParamsToHistory()
      this.applyData({ list })
    } finally {
      this.applyData({ isLoadingList: false })
    }
  }

  setPage = page => {
    this.applyData({ page })
    this.getList(false)
  }

  setQueryText = text => {
    this.applyData({ queryText: isSafe(text) ? text : '' })
  }

  setIsActive = action(isActive => {
    this.isActive = isActive
    this.getList()
  })

  lockPartner = async (id, reason = '') => {
    const index = _.findIndex(this.list, l => l.id === id)
    if (index === -1) return
    try {
      const isActive = this.list[index].isActive
      this.action(() => {
        this.list[index].isProcessLock = true
        this.list = deepMerge([], this.list)
      })
      await FactoryProvider.PartnerProvider.lockPartner(id, isActive, isActive ? reason : '')
      notifySuccess(`Организация успешно ${isActive ? 'заблокирована' : 'разблокирована'}`)
      this.action(() => {
        this.list[index].isProcessLock = false
        this.list[index].isActive = !isActive
        this.list[index].lockReason = reason
        this.list = deepMerge([], this.list)
      })
      this.action(() => {
        this.list[index].isActive = !isActive
      })
    } catch (e) {
      notifyError(`Ошибка ${this.isActive ? 'блокировки' : 'активации'} организации`, prepareErrorMessage(e))
      this.action(() => {
        this.list[index].isProcessLock = false
        this.list = deepMerge([], this.list)
      })
      throw e
    }
  }
}

export { PartnerListModel }
