import _ from 'lodash'
import { safeObject } from 'uikit'
import BaseApiProvider from './baseApiProvider'
import { creditRequestMap, creditRequestMapApi } from '../helpers/creditRequest'

class CreditRequestProvider extends BaseApiProvider {
  async getCreditRequestList(params = {}) {
    const { page = 1, pageSize = 10, scope = 'actual', queryText = '' } = params
    const { data, headers } = await this.fetch({
      uri: '/credit_requests',
      query: safeObject({
        scope,
        page,
        per_page: pageSize,
        query: queryText !== '' ? queryText : null
      }),
      isFullResponse: true
    })

    return {
      list: _.map(data.credit_requests, creditRequest => creditRequestMap({ creditRequest })),
      total: parseInt(headers.total)
    }
  }

  async getCreditRequest(id) {
    const data = await this.fetch({ uri: `/credit_requests/${id}` })
    return creditRequestMap({ creditRequest: data.credit_request, isShort: false })
  }

  async sendAction(params = {}) {
    const { action, id, comment } = params
    const data = await this.fetch(safeObject({
      uri: `/credit_requests/${id}/${action}`,
      method: 'PUT',
      data: comment ? { comment } : null
    }))

    return creditRequestMap({ creditRequest: data.credit_request, isShort: false })
  }

  async getCreditRequestDetails(params = {}) {
    const { id } = params
    const data = await this.fetch({
      uri: `/credit_requests/${id}`
    })
    return creditRequestMap({ creditRequest: _.get(data, 'credit_request', null) })
  }
  async getNewCreditRequest() {
    const data = await this.fetch({
      uri: '/credit_requests/new'
    })

    return {
      packageID: _.get(data, 'package_id', ''),
      qrCode: _.get(data, 'qr_code', ''),
      scans: _.map(_.get(data, 'scans', []), strategy_update_tasks => ({
        name: _.get(strategy_update_tasks, 'name', ''),
        commonCode: _.get(strategy_update_tasks, 'common_code', ''),
        poscanID: _.get(strategy_update_tasks, 'poscan_id', ''),
        url: ''
      }))
    }
  }

  async createCreditRequest(creditRequest) {
    const data = await this.fetch({
      uri: '/credit_requests',
      method: 'POST',
      data: { credit_request: creditRequestMapApi(creditRequest) }
    })

    return creditRequestMap({ creditRequest: data.credit_request, isShort: false })
  }

  async createAdditionalComment(id, comment) {
    await this.fetch({
      uri: `/credit_requests/${id}/comment`,
      method: 'POST',
      data: { comment }
    })
  }
}

export { CreditRequestProvider }
