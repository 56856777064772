export const ROLE_ADMIN = 'admin'
export const ROLE_MANAGER = 'manager'
export const ROLE_USER = 'user'
export const ROLE_CALL_CENTER_OPERATOR = 'call_center_operator'
export const ROLE_PARTNER = 'partner_admin'

export const TARGET_QUESTIONARIES = 'questionaries'
export const TARGET_SELECT_POINT = 'choose_point'
export const TARGET_CREDIT_REQUESTS = 'call_center'
export const TARGET_MOBILE_CONFIRM = 'mobile_confirm'
export const TARGET_PARTNER_WELCOME = 'partner_welcome'
export const TARGET_NEWS = 'news'

export const TARGETS_MAP = {
  [TARGET_QUESTIONARIES]: '/questionaries',
  [TARGET_CREDIT_REQUESTS]: '/credit_requests',
  [TARGET_MOBILE_CONFIRM]: '/partner/sign_up',
  [TARGET_PARTNER_WELCOME]: '/partner/welcome',
  [TARGET_NEWS]: '/news'
}
