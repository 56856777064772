import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { push } from 'utils/history'
import deepMerge from 'deepmerge'
import { notifyError, notifySuccess, scrollToTop, validRequired, validEmail, isSafe } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import { validationAddress } from 'utils/validators/address'
import { v4 as uuid } from 'uuid'
import BaseModel from '../baseModel'
import { DocumentsDataModel } from './documentsDataModel'
import { BankAccountModel } from './bankAccountModel'

class PartnerModel extends BaseModel {
  constructor() {
    super()

    const bankAccountModel = new BankAccountModel()

    bankAccountModel.ctx = {
      CommonModel: this
    }
    this.BankAccountModel = bankAccountModel
    makeObservable(this)
  }

  DEFAULT_TAB = 'details'
  POINT_LIST_TAB = 'points'

  @observable activeTab = this.DEFAULT_TAB

  @observable id = ''

  @observable isActive = true
  @observable isProcessLock = false
  @observable lockReason = ''

  @observable name = ''
  @observable inn = ''
  @observable kpp = ''
  @observable contractNo = ''
  @observable estoreContacts = ''
  @observable logoUrl = ''

  @observable bankMixID = null
  @observable vatType = null
  @observable address = null
  @observable urAddress = null
  @observable contractDate = null
  @observable mainPartner = null
  @observable channel = null

  @observable partners = []
  @observable isLoadingPartners = true
  @observable isLoadingPartner = true

  @observable contacts = []

  @observable settings = []
  @observable isLoadingSettings = true
  @observable isProcessSaveSettings = false

  documentsData = new DocumentsDataModel()

  @observable isProcessSave = false
  @observable showServicesInSpecification = true
  @observable allowSendWithoutConfirmationCode = false
  @observable allowSendWithoutPdAgreement = false
  @observable allowPassportRecognition = false
  @observable printCreditPaymentsMemo = false
  @observable clientVerification = false

  @observable isShowForceValidate = false

  @observable codes = []
  @observable isShowForceValidateCodes = false
  @observable isLoadingCodes = false
  @observable isSavingCodes = false
  @observable bankCode = null

  @observable partnerChannels = []
  @observable isLoadingPartnerChannels = false

  @observable historyEdit = []
  @observable isLoadingHistoryEdit = false

  clear = action(() => {
    this.activeTab = this.DEFAULT_TAB

    this.id = ''

    this.isActive = true
    this.isProcessLock = false
    this.lockReason = ''

    this.name = ''
    this.kpp = ''
    this.inn = ''
    this.contractNo = ''
    this.estoreContacts = ''
    this.logoUrl = ''

    this.bankMixID = null
    this.vatType = null
    this.address = null
    this.urAddress = null
    this.contractDate = null
    this.mainPartner = null
    this.channel = null

    this.isShowForceValidate = false

    this.partners = []
    this.isLoadingPartners = true

    this.contacts = []

    this.isProcessSave = false
    this.isActive = true
    this.showServicesInSpecification = true
    this.allowSendWithoutConfirmationCode = false
    this.allowSendWithoutPdAgreement = false

    this.codes = []
    this.isShowForceValidateCodes = false
    this.isLoadingCodes = false
    this.isSavingCodes = false
    this.bankCode = null

    this.historyEdit = []
    this.isLoadingHistoryEdit = false

    this.settings = []
    this.isLoadingSettings = false
    this.isProcessSaveSettings = false

    this.partnerChannels = []
    this.isLoadingPartnerChannels = false

    this.BankAccountModel.clear()
    this.documentsData.clear()
  })

  @computed get validLockReason() {
    return validRequired(this.lockReason)
  }

  @computed get validName() {
    if (validRequired(this.name)) return validRequired(this.name)
    return null
  }

  @computed get validInn() {
    if (validRequired(this.inn)) return validRequired(this.inn)
    if (![10, 12].includes(this.inn.length) && Number(this.inn) !== 0) return 'ИНН должен содержать 10 цифр для юр.лиц или 12 цифр для физ.лиц'
    return null
  }

  @computed get validKpp() {
    if (validRequired(this.kpp)) return validRequired(this.kpp)
    if (this.kpp.length !== 9 && Number(this.kpp) !== 0) return 'Длина КПП должна быть равна 9'
    return null
  }

  @computed get validAddress() {
    if (validRequired(this.address)) return validRequired(this.address)
    if (validationAddress(this.address).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  @computed get validVatType() {
    if (validRequired(this.vatType)) return validRequired(this.vatType)
    return null
  }

  @computed get validPartnerChannel() {
    if (validRequired(this.channel)) return validRequired(this.channel)
    return null
  }

  @computed get validContractNo() {
    if (validRequired(this.contractNo)) return validRequired(this.contractNo)
    return null
  }

  @computed get validContractDate() {
    if (validRequired(this.contractDate)) return validRequired(this.contractDate)
    return null
  }

  @computed get validUrAddress() {
    if (validRequired(this.urAddress)) return validRequired(this.urAddress)
    if (validationAddress(this.urAddress).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  @computed get isValidContacts() {
    if (_.isEmpty(this.contacts)) return false
    return !(_.reduce(this.contacts, (s, r) => (this.validContactEmail(r.email) || this.validContactName(r.fullName) || this.validContactPhone(r.phone) ? s + 1 : s), 0) > 0)
  }

  @computed get isProcessSettings() {
    return this.isLoadingSettings || this.isProcessSaveSettings
  }

  validContactEmail(email) {
    if (validRequired(email)) return validRequired(email)
    if (validEmail(email)) return validEmail(email)
    return null
  }

  validContactName(name) {
    if (validRequired(name)) return validRequired(name)
    return null
  }

  validContactPhone(phone) {
    if (validRequired(phone)) return validRequired(phone)
    if (phone.length !== 10) return 'Неверный формат'
    return null
  }

  @computed get isNext() {
    return !this.validName && !this.validInn &&
      (!this.id ? this.BankAccountModel.isValidBankAccounts : true) &&

      !this.validContractNo && !this.validContractDate && !this.validVatType &&
      !this.validKpp && this.isValidContacts && !this.validPartnerChannel
  }

  @computed get isValidCodes() {
    if (_.isEmpty(this.codes)) return true
    const required = this.codes.map(code => this.isRequiredCodeValue(code))
    const flattened = _.reduce(required, (s, r) => [...s, ...r.values], [])
    return _.reduce(flattened, (s, r) => s + (!r.value ? 1 : 0), 0) === 0
  }

  isRequiredCodeValue = (code) => {
    const bankTemplates = _.find(FactoryModel.BankModel.banks, b => b.id === code.peerID)?.templates.filter(t => !!t.isRequired)
    const values = _.intersectionBy(code.values, bankTemplates, 'name')
    return { ...code, values }
  }

  @computed get isValidBankCode() {
    return isSafe(this.bankCode) &&
      _.findIndex(this.codes, code => code.peerID === this.bankCode) === -1
  }

  validCodeValue(value, isRequired) {
    if (isRequired && validRequired(value)) return validRequired(value)
    return null
  }

  setTab = action(tab => {
    this.activeTab = tab
  })

  getPartners = async () => {
    try {
      this.applyData({ isLoadingPartners: true })
      const list = await FactoryProvider.BankProvider.searchBanks()
      this.applyData({
        partners: _.map(list, m => ({ id: m.id, name: m.name }))
      })
    } finally {
      this.applyData({ isLoadingPartners: false })
    }
  }

  getPartnerChannels = async () => {
    try {
      this.applyData({ isLoadingPartnerChannels: true })
      const partnerChannels = await FactoryProvider.PartnerProvider.getPartnerChannels()
      this.applyData({
        partnerChannels
      })
    } finally {
      this.applyData({ isLoadingPartnerChannels: false })
    }
  }

  addContact = action(() => {
    this.contacts.push({
      email: '',
      phone: '',
      fullName: '',
      position: ''
    })
  })

  setContact = action((index, value, key) => {
    this.contacts[index][key] = value
  })

  removeContact = action(index => {
    this.contacts.splice(index, 1)
  })

  partnerFields = () => [
    'id',
    'isActive',
    'name',
    'inn',
    'kpp',
    'bankMixID',
    'address',
    'urAddress',
    'contractNo',
    'contractDate',
    'mainPartner',
    'contacts',
    'vatType',
    'channel'
  ]

  settingsFields = () => [
    'settings',

    'estoreContacts',
    'logoUrl',

    'showServicesInSpecification',
    'allowSendWithoutConfirmationCode',
    'allowSendWithoutPdAgreement',
    'allowPassportRecognition',
    'printCreditPaymentsMemo',
    'clientVerification'
  ]

  partner = (partner = null) => {
    const resolve = {}
    _.each(this.partnerFields(), field => {
      resolve[field] = partner ? partner[field] : this[field]
    })

    resolve.bankAccounts = this.BankAccountModel.bankAccounts
    if (this.isActive) _.unset(resolve, 'lockReason')
    return resolve
  }

  preparePartner = (partner = null) => {
    if (!partner) return
    const resolve = {}
    _.each(this.partnerFields(), field => {
      resolve[field] = partner[field]
    })
    if (resolve.mainPartner) {
      if (!_.find(this.partners, m => m.id === resolve.mainPartner.id)) {
        resolve.mainPartners = _.uniqBy(deepMerge.all([[], this.partners, [resolve.mainPartner]]), 'id')
      }
      resolve.mainPartner = resolve.mainPartner.id
    }
    return resolve
  }

  applyPartner = (partner = null) => {
    if (!partner) return
    this.applyData({ ...this.preparePartner(partner), isShowForceValidate: true })

    this.BankAccountModel.applyAccounts(partner)
  }

  createPartner = async () => {
    if (!this.isNext) {
      this.applyData({ isShowForceValidate: true })
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const id = await FactoryProvider.PartnerProvider.createPartner(this.partner())
        this.applyData({ id })
        scrollToTop()
        push({ uri: '/organizations/[action]', href: `/organizations/${id}`, isShallow: true })
        notifySuccess('Организация успешно создана')
      } catch (e) {
        notifyError('Ошибка создания организации', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({
          isProcessSave: false,
          isLoadingPartner: false
        })
      }
    }
  }

  updatePartner = async () => {
    if (!this.isNext) {
      this.applyData({ isShowForceValidate: true })
    } else {
      try {
        this.applyData({ isProcessSave: true })
        await FactoryProvider.PartnerProvider.updatePartner(this.partner())
        notifySuccess('Организация успешно сохранёна')
        scrollToTop()

        this.BankAccountModel.applyParams({ partnerId: this.id })
      } catch (e) {
        notifyError('Ошибка сохранения организации', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  getPartner = async (id) => {
    if (this.id) return
    try {
      this.applyData({ isLoadingPartner: true })
      const partner = await FactoryProvider.PartnerProvider.getPartner(id)
      this.applyPartner(partner)
    } catch (e) {
      notifyError('Ошибка получения информации об организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingPartner: false })
    }
  }

  getCodes = async id => {
    if (!_.isEmpty(this.codes)) return
    try {
      this.applyData({ isLoadingCodes: true })
      const codes = await FactoryProvider.PartnerProvider.getCodes(id)
      this.applyData({ codes })
    } catch (e) {
      notifyError('Ошибка получения кодов организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingCodes: false })
    }
  }

  setCode = action((peerID, name, value) => {
    const codes = this.codes.map(code => {
      if (code.peerID !== peerID) return code
      const isExistCode = _.findIndex(code.values, v => v.name === name) !== -1
      const values = isExistCode ? code.values : [...code.values, {
        name,
        value
      }]
      return {
        ...code,
        values: values.map(v => (v.name === name ? { ...v, value } : v))
      }
    })
    this.applyData({ codes })
  })

  removeCode = action(index => {
    this.codes.splice(index, 1)
  })

  addCode = action(() => {
    const bank = _.find(FactoryModel.BankModel.banks, b => b.id === this.bankCode)
    if (!bank) return

    this.codes.unshift({ peerID: bank.id,
      values: _.map(bank.templates, template => ({
        name: _.get(template, 'name', ''),
        value: template.value || template.defaultValue || ''
      })) })
    this.applyData({ bankCode: null })
  })

  updateCodes = async () => {
    if (!this.isValidCodes) {
      this.applyData({ isShowForceValidateCodes: true })
      return
    }
    try {
      this.applyData({ isSavingCodes: true })
      this.applyData({
        codes: this.codes.map(code => ({
          ...code,
          values: code.values.map(v => ({
            name: v.name,
            value: v.value.trim()
          }))
        }))
      })
      await FactoryProvider.PartnerProvider.updateCodes(this.id, this.codes)
      notifySuccess('Коды организации успешно обновлены')
      this.getEditHistory(this.id, true)
      FactoryModel.SettingsModel.CommonModel.getSettings()
    } catch (e) {
      notifyError('Ошибка обновления кодов пользователя', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSavingCodes: false })
    }
  }

  getEditHistory = async (id, isForce = false) => {
    if (!_.isEmpty(this.historyEdit) && !isForce) return
    try {
      this.applyData({ isLoadingHistoryEdit: true })
      const historyEdit = await FactoryProvider.PartnerProvider.getEditHistory(id)
      this.applyData({ historyEdit })
    } catch (e) {
      notifyError('Ошибка получения истории изменений организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingHistoryEdit: false })
    }
  }

  getSettings = async () => {
    try {
      this.applyData({ isLoadingSettings: true })
      const {
        settings,
        estoreContacts,
        logoUrl,
        showServicesInSpecification,
        allowSendWithoutConfirmationCode,
        allowSendWithoutPdAgreement,
        allowPassportRecognition,
        printCreditPaymentsMemo,
        clientVerification,
        lockReason
      } = await FactoryProvider.PartnerProvider.getSettings(this.id)
      this.applyData({
        settings,
        estoreContacts,
        logoUrl,
        showServicesInSpecification,
        allowSendWithoutConfirmationCode,
        allowSendWithoutPdAgreement,
        allowPassportRecognition,
        printCreditPaymentsMemo,
        clientVerification,
        lockReason
      })
    } catch (e) {
      notifyError('Ошибка получения настроек организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingSettings: false })
    }
  }

  setSetting = action((index, field) => (v) => {
    this.settings[index][field] = v
  })

  addDiscountByTermRow = action((settingIndex) => {
    const rowId = uuid()
    this.settings[settingIndex].discountByTerm.push({ id: rowId, term: 0, discount: 0 })
  })

  removeDiscountByTermRow = action((settingIndex, rowId) => {
    const newItems = this.settings[settingIndex].discountByTerm.filter(row => row.id !== rowId)
    this.settings[settingIndex].discountByTerm = newItems
    return this.settings[settingIndex].discountByTerm
  })

  updateDiscountByTermValue = action((settingIndex, rowId, field, value) => {
    const rowIndex = this.settings[settingIndex].discountByTerm.findIndex(row => row.id === rowId)
    this.settings[settingIndex].discountByTerm[rowIndex][field] = value
  })

  updateSettings = async () => {
    try {
      this.applyData({ isProcessSaveSettings: true })
      await FactoryProvider.PartnerProvider.updateSettings({
        id: this.id,
        settings: this.settings,
        estoreContacts: this.estoreContacts,
        logoUrl: this.logoUrl,
        showServicesInSpecification: this.showServicesInSpecification,
        allowSendWithoutConfirmationCode: this.allowSendWithoutConfirmationCode,
        allowSendWithoutPdAgreement: this.allowSendWithoutPdAgreement,
        allowPassportRecognition: this.allowPassportRecognition,
        printCreditPaymentsMemo: this.printCreditPaymentsMemo,
        clientVerification: this.clientVerification,
        lockReason: this.lockReason
      })
      notifySuccess('Настройки организации успешно обновлены')
      scrollToTop()
    } catch (e) {
      notifyError('Ошибка обновления настроек организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSaveSettings: false })
    }
  }

  applyDataForPoint = () => {
    if (!this.id) return
    FactoryModel.PointModel.setField('dataFromPartner')({
      partner: {
        id: this.id,
        name: this.name
      }
    })
  }

  lockPartner = async () => {
    try {
      this.applyData({ isProcessLock: true })
      await FactoryProvider.PartnerProvider.lockPartner(this.id, this.isActive, this.isActive ? this.lockReason : '')
      notifySuccess(`Организация успешно ${this.isActive ? 'заблокирована' : 'разблокирована'}`)
      this.applyData({
        isActive: !this.isActive,
        lockReason: !this.isActive ? '' : this.lockReason
      })
    } catch (e) {
      notifyError(`Ошибка ${this.isActive ? 'блокировки' : 'разблокировки'} организации`, prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessLock: false })
    }
  }
}

export { PartnerModel }
