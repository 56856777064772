import BaseApiProvider from './baseApiProvider'
import { refundMap } from '../helpers/refund'

class RefundProvider extends BaseApiProvider {
  async getRefund(creditID) {
    const data = await this.fetch({
      uri: `/credits/${creditID}/refund`
    })
    return refundMap(data)
  }

  async createRefund(creditID) {
    const data = await this.fetch({
      uri: `/credits/${creditID}/refund`,
      method: 'POST'
    })
    return refundMap(data)
  }

  async reviewRefund(creditID) {
    const data = await this.fetch({
      uri: `/credits/${creditID}/refund/review`,
      method: 'PUT'
    })
    return refundMap(data)
  }

  async cancelRefund(creditID) {
    await this.fetch({
      uri: `/credits/${creditID}/refund`,
      method: 'DELETE'
    })
  }
}

export { RefundProvider }
